import { Options } from 'highcharts';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import HighChart from 'react-highcharts';
import { SparklineAnalytics } from 'utils/types';
import blankChartBase from './constants';
import sparkline from './styles';

type Props = {
  analytics: Array<SparklineAnalytics>
};

HighChart.Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

export const createSparklineConfig = (impData: Array<Array<number>>) => ({
  ...blankChartBase,
  chart: {
    ...blankChartBase.chart,
  },
  yAxis: [
    {
      ...blankChartBase.yAxis,
      id: 'impY',
    },
  ],
  series: [
    {
      name: 'Impressions',
      color: 'var(--wpp-dataviz-color-cat-neutral-1)',
      lineWidth: 2,
      lineColor: 'var(--wpp-dataviz-color-cat-dark-1)',
      data: impData,
      yAxis: 'impY',
      type: 'area',
      zIndex: 1,
    },
  ],
});

enum DataKeyEnum {
  impressions = 'impressions',
  revenue = 'revenue',
}

// adds missing dates data as 0
const addDataForMissingDates = (data: Array<Array<string | number>>) => {
  const dates = _.map(data, (d) => moment(_.head(d)));
  const startDate = moment.min(dates);
  const endDate = moment.max(dates);
  const dateRange = endDate.diff(startDate, 'days');

  const days = [];
  const lastDay = endDate.utc().startOf('date');
  for (let i = dateRange; i >= 0; i -= 1) {
    days.push(moment.utc(lastDay).subtract(i, 'days').valueOf());
  }

  const dataByDay = _.fromPairs(data);
  const res = _.map(days, (day) => [day, dataByDay[day] || 0]);
  return _.sortBy(res, ([day]) => day);
};

const prepareData = (dataArr: Array<SparklineAnalytics>, key: DataKeyEnum) => {
  const series = _.map(dataArr, (data: SparklineAnalytics) => [
    data.date,
    _.round(_.get(data, key), key === DataKeyEnum.revenue ? 2 : 0),
  ]);
  return addDataForMissingDates(series);
};

const Sparkline = ({ analytics }: Props) => {
  const impData = prepareData(analytics, DataKeyEnum.impressions);

  return (
    <div id="sparkline-high-chart-container" style={sparkline}>
      <HighChart
        config={createSparklineConfig(impData) as Options}
        isPureConfig
      />
    </div>
  ); };

export default Sparkline;
