import _ from 'lodash';
import React, { Component } from 'react';

import { Header, Grid, ObjectDropdown } from 'buildingBlocks';
import { pluralizer } from 'utils/formattingUtils';
import { Flight } from 'utils/types';

// TODO: refactor this to not use state

type HeliosOptionsProps = {
  flights: { [key: string]: Flight },
  flightSelected: string | null,
  flightChange: Function,
};

type HeliosOptionsState = {
  flightOptions: Array<Flight>,
  flightPlaceholder: string,
  flightTitle: string,
};

class HeliosOptions extends Component<HeliosOptionsProps, HeliosOptionsState> {
  static setFlightTitle(flightOptions: Array<Flight>) {
    return pluralizer('DSP Object', 'DSP Objects')(flightOptions.length);
  }

  static setFlightPlaceholder(props: HeliosOptionsProps) {
    const { flights, flightSelected } = props;
    return _.get(flights, `${flightSelected || ''}.name`, 'Select DSP Object') as string;
  }

  constructor(props: HeliosOptionsProps) {
    super(props);
    const flightOptions = _.values(props.flights);

    this.state = {
      flightOptions,
      flightPlaceholder: HeliosOptions.setFlightPlaceholder(props),
      flightTitle: HeliosOptions.setFlightTitle(flightOptions),
    };
  }

  UNSAFE_componentWillReceiveProps(newProps: HeliosOptionsProps) {
    if (!_.isEqual(this.props.flights, newProps.flights)) {
      const flightOptions = _.values(newProps.flights);
      this.setState({
        flightOptions,
        flightTitle: HeliosOptions.setFlightTitle(flightOptions),
      });
    }
    if (!_.isEqual(this.props.flightSelected, newProps.flightSelected)) {
      this.setState({
        flightPlaceholder: HeliosOptions.setFlightPlaceholder(newProps),
      });
    }
  }

  render() {
    return (
      <Grid.Row>
        <Grid.Column width={12}>
          <Header as="h4">Attached {this.state.flightTitle} ({this.state.flightOptions.length})</Header>
          <ObjectDropdown
            // @ts-ignore passthrough props
            id="flight"
            name="flight"
            keyFn={(flight: Flight) => `[${flight.externalId}] - ${flight.name}`}
            options={this.state.flightOptions}
            text={this.state.flightPlaceholder}
            onChange={(selected) => this.props.flightChange(selected)}
            selection
            fluid
          />
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default HeliosOptions;
