import { Strategy } from 'utils/types';
import {
  FETCH_SEGMENTS,
  FETCH_SEGMENT_RECENCY_ANALYTICS,
  DOWNLOAD_SEGMENT_RECENCY_ANALYTICS,
  RESET_DATA,
} from './constants';

export const resetState = () => ({
  type: RESET_DATA,
});

export const fetchAnalytics = ({ strategy, bucket }: { strategy: Strategy, bucket?: boolean }) => ({
  type: FETCH_SEGMENT_RECENCY_ANALYTICS.STARTED,
  payload: { strategy, bucket },
});

export const downloadAnalytics = ({ strategy, bucket }: { strategy: Strategy, bucket?: boolean }) => ({
  type: DOWNLOAD_SEGMENT_RECENCY_ANALYTICS.STARTED,
  payload: { strategy, bucket },
});

// export const fetchSegments = (params: { ...Strategy, ids: Array<number> }) => (
export const fetchSegments = (params: Strategy & { ids: Array<number> }) => (
  {
    type: FETCH_SEGMENTS.STARTED,
    payload: { ...params },
  }
);
