import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Form, WppSpinner, Dimmer, WppSideModal, WppButton, WppTypography, WppGrid, WppToggle, WppInlineMessage } from 'buildingBlocks';
import { isQAGlobalReadOnly } from 'utils/functionHelpers';
import { GlobalState } from 'reducers';
import { User } from 'utils/types';
import { COPILOT_COLORS } from 'globalStyles';
import { NEW_SEAT } from '../reducers/alterSeatReducer';
import SeatFormFields from './SeatFormFields';
import { stopAlterSeat, saveSeat } from '../actions/index';
import { memberStyle } from '../style';
import validator from '../validate';

const { WPP } = COPILOT_COLORS;

const SeatForm = ({ open }: { open: boolean }) => {
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement | null>(null);

  // Retrieve state values using selectors
  const { dspList, businessUnits, countries, regions, alterSeat: { seat: initialValues, saving, error: errorMessage }, businessModels, feeOptions, feeTypes, permissions } = useSelector<GlobalState>((state) => state.seats) as any;
  const user = useSelector<GlobalState>((state) => state.login.session.data.user) as User;

  const methods = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: validator,
  });

  const { reset, formState: { errors, isValid }, getValues, control, watch } = methods;
  const memberId = watch('id');

  useEffect(() => {
    if (memberId) {
      reset(initialValues);
    } else {
      reset(NEW_SEAT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, initialValues]);

  const onSubmit = () => {
    const formValues = getValues();
    const seatObject = { ...formValues, accountId: formValues.prefix };
    dispatch(saveSeat(seatObject));
  };

  const handleCancel = () => {
    dispatch(stopAlterSeat());
  };

  const handleSaveClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const qaReadOnly = isQAGlobalReadOnly(user);

  // disable when qaReadOnly, form errors, or when user initially clicks create member button
  const disableSave = qaReadOnly || !_.isEmpty(errors) || (!memberId && !isValid);
  return (
    <WppSideModal
      open={open}
      onWppSideModalClose={handleCancel}
      disableOutsideClick
      size="xl"
    >
      <div slot="header">
        <WppTypography type="2xl-heading">{memberId ? 'Edit member' : 'Create a new member'}</WppTypography>
      </div>
      <div slot="body">
        {saving && (
          <Dimmer active inverted>
            <WppSpinner size="l" color={`${WPP.primary500}`} />
          </Dimmer>
        )}
        <FormProvider {...methods}>
          <Form ref={formRef} onSubmit={onSubmit}>
            <WppGrid container fullWidth all={24} style={memberStyle.seatFormModelBodyContainerStyle}>
              <SeatFormFields
                initialValues={initialValues}
                dspList={dspList}
                countries={countries}
                businessUnits={businessUnits}
                regions={regions}
                businessModels={businessModels}
                feeOptions={feeOptions}
                feeTypes={feeTypes}
                permissions={permissions}
              />
              <Controller
                name="enabled"
                control={control}
                render={({ field }) => (
                  <WppToggle
                    className="wpp-toggle-step"
                    labelConfig={{
                      icon: '',
                      text: 'Enabled',
                      description: '',
                      locales: {
                        optional: 'Optional',
                      },
                    }}
                    checked={field.value}
                    onWppChange={({ detail: { checked } }) => field.onChange(checked)}
                  />
                )}
              />
            </WppGrid>
            <WppGrid container fullWidth all={24}>
              {errorMessage && (
                <WppInlineMessage
                  size="l"
                  style={memberStyle.seatFormModelErrorStyle}
                  hideCloseBtn
                  titleText={errorMessage.title}
                  message={errorMessage.message}
                  type="error"
                />
              )}
            </WppGrid>
          </Form>
        </FormProvider>
      </div>
      <div slot="actions" style={memberStyle.seatFormModelFooterStyle}>
        <WppButton variant="secondary" onClick={handleCancel}>Cancel</WppButton>
        <WppButton disabled={disableSave} onClick={handleSaveClick}>Save</WppButton>
      </div>
    </WppSideModal>
  );
};

export default SeatForm;
