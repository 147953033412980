import { COPILOT_COLORS } from 'globalStyles';

export const noBorderRadius = {
  borderRadius: '0',
  borderStyle: 'none',
};

export const inputField = {
  width: '100%',
  borderRadius: '0',
};

export const newRoleButton = {
  float: 'right',
  background: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE,
  color: 'white',
};
