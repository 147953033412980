const PREFIX = 'SEGMENT_RECENCY_ANALYTICS:';

export const FETCH_SEGMENT_RECENCY_ANALYTICS = {
  STARTED: `${PREFIX}FETCH_SEGMENT_RECENCY_ANALYTICS`,
  COMPLETED: `${PREFIX}FETCH_SEGMENT_RECENCY_ANALYTICS_COMPLETED`,
  NO_DATA: `${PREFIX}FETCH_SEGMENT_RECENCY_ANALYTICS_NO_DATA`,
  FAILED: `${PREFIX}FETCH_SEGMENT_RECENCY_ANALYTICS_FAILED`,
};

export const DOWNLOAD_SEGMENT_RECENCY_ANALYTICS = {
  STARTED: `${PREFIX}DOWNLOAD_SEGMENT_RECENCY_ANALYTICS`,
  COMPLETED: `${PREFIX}DOWNLOAD_SEGMENT_RECENCY_ANALYTICS_COMPLETED`,
  FAILED: `${PREFIX}DOWNLOAD_SEGMENT_RECENCY_ANALYTICS_FAILED`,
};

export const FETCH_SEGMENTS = {
  STARTED: `${PREFIX}FETCH_SEGMENTS`,
  COMPLETED: `${PREFIX}FETCH_SEGMENTS_COMPLETED`,
  NO_DATA: `${PREFIX}FETCH_SEGMENTS_NO_DATA`,
  FAILED: `${PREFIX}FETCH_SEGMENTS_FAILED`,
};

export const RESET_DATA = `${PREFIX}RESET_DATA`;

export const SEGMENT_AGE = [
  { id: '<15m', value: ['< 15', 'Minutes'] },
  { id: '15m-30m', value: ['15 - 30', 'Minutes'] },
  { id: '30m-1h', value: ['30 - 60', 'Minutes'] },
  { id: '1h-2h', value: ['1 - 2', 'Hours'] },
  { id: '2h-3h', value: ['2 - 3', 'Hours'] },
  { id: '3h-7h', value: ['3 - 7', 'Hours'] },
  { id: '7h-14h', value: ['7 - 14', 'Hours'] },
  { id: '14h-24h', value: ['14 - 24', 'Hours'] },
  { id: '1d-2d', value: ['1 - 2', 'Days'] },
  { id: '2d-3d', value: ['2 - 3', 'Days'] },
  { id: '3d-4d', value: ['3 - 4', 'Days'] },
  { id: '4d-7d', value: ['4 - 7', 'Days'] },
  { id: '7d-14d', value: ['7 - 14', 'Days'] },
  { id: '14d-30d', value: ['14 - 30', 'Days'] },
  { id: '30d-60d', value: ['30 - 60', 'Days'] },
  { id: '60d-90d', value: ['60 - 90', 'Days'] },
  { id: '>90d', value: ['> 90', 'Days'] },
];
