import { CSSProperties } from 'react';
import { COPILOT_COLORS, COPILOT_LAYOUT, COPILOT_TYPOGRAPHY } from 'globalStyles';

const { NEW_DESIGN_SYSTEM: {
  NEUTRALS, BLUES, YELLOWS,
  METTLES, REDS, GREENS,
} } = COPILOT_COLORS;
const { HEADING, BODY, DESCRIPTION } = COPILOT_TYPOGRAPHY;
const { SPACING } = COPILOT_LAYOUT;

const SPACED_DASHED_BORDER_URL = '/img/components/StrategyAnalytics/dashed-border.svg';

type CSSObject = { [key: string]: CSSProperties };

export const dataUploader: CSSObject = {
  container: {
    width: '100%',
    height: '952px',
    backgroundColor: NEUTRALS.N0_WHITE,
  },
  infoTextStyle: {
    ...BODY.MEDIUM,
    width: '1152px',
    margin: 'auto',
  },
  anchorText: {
    color: BLUES.B500_WAVE,
  },
  mainSection: {
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    width: '1152px',
    minHeight: '320px',
    margin: '0 24px',
    borderRadius: SPACING[4],
    padding: '16px 0',
  },
  grid: {
    width: '100%',
    margin: 0,
  },
  list: {
    margin: '0 16px',
    gap: SPACING[4],
    display: 'flex',
    flexDirection: 'column',
  },
  bulletPoint: {
    ...BODY.MEDIUM,
    color: NEUTRALS.N600_ABBEY,
  },
  column: {
    padding: '0 16px',
  },
  dropzoneContainer: {
    position: 'relative',
    width: '100%',
    height: '225px',
    backgroundColor: METTLES.M50_FLASH,
    borderRadius: '10px',
    backgroundImage: `url("${SPACED_DASHED_BORDER_URL}")`,
    cursor: 'pointer',
  },
  disabledUploadBtn: {
    backgroundColor: METTLES.M50_FLASH,
    color: NEUTRALS.N300_ALTO,
  },
  dropzoneContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    gap: SPACING[8],
  },
  dropzoneIcon: {
    color: BLUES.B500_WAVE,
    fontWeight: 400,
    fontSize: SPACING[24],
    lineHeight: '28px',
  },
  disabledDropzoneIcon: {
    color: BLUES.B100_FISH,
  },
  dropzoneText: {
    ...BODY.LARGE,
    color: NEUTRALS.N400_GRANITE,
    margin: 0,
  },
  dropzoneButton: {
    ...HEADING.H6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '120px',
    height: SPACING[32],
    border: `1px solid ${NEUTRALS.N300_ALTO}`,
    borderRadius: SPACING[4],
    backgroundColor: NEUTRALS.N0_WHITE,
    textTransform: 'capitalize',
  },
  fileText: {
    ...BODY.MEDIUM,
    color: NEUTRALS.N600_ABBEY,
  },
  fileIcon: {
    color: NEUTRALS.N600_ABBEY,
    height: SPACING[24],
    width: '14px',
  },
  fileNameText: {
    ...HEADING.H5,
    color: NEUTRALS.N600_ABBEY,
    margin: 0,
    width: '92%',
    paddingLeft: SPACING[8],
  },
  trashIcon: {
    color: REDS.R700_VALENTINE,
    fontSize: 16,
    cursor: 'pointer',
  },
  fileContainer: {
    display: 'flex',
    width: '100%',
    padding: SPACING[16],
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    borderRadius: SPACING[4],
    minHeight: SPACING[56],
    flexDirection: 'column',
    gap: SPACING[16],
  },
  uploaderSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[16],
  },
  uploadBtn: {
    ...HEADING.H6,
    padding: 0,
    backgroundColor: BLUES.B500_WAVE,
    borderRadius: SPACING[4],
    width: '100px',
    height: SPACING[32],
    color: NEUTRALS.N0_WHITE,
    textTransform: 'capitalize',
    cursor: 'pointer',
  },
  modal: {
    width: '575px',
    height: '176px',
    backgroundColor: NEUTRALS.N0_WHITE,
    borderRadius: SPACING[4],
  },
  modalContentContainer: {
    width: '575px',
    minHeight: '176px',
    backgroundColor: NEUTRALS.N0_WHITE,
    borderRadius: SPACING[4],
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[24],
    padding: SPACING[32],
  },
  modalHeaderContainer: {
    display: 'flex',
    gap: SPACING[16],
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  modalHeader: {
    ...HEADING.H4,
    color: NEUTRALS.N800_MATTER,
    margin: 0,
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: SPACING[16],
  },
  cancelBtn: {
    ...HEADING.H6,
    padding: 0,
    backgroundColor: NEUTRALS.N0_WHITE,
    borderRadius: SPACING[4],
    border: `1px solid ${NEUTRALS.N300_ALTO}`,
    width: '100px',
    height: SPACING[32],
    color: NEUTRALS.N800_MATTER,
    textTransform: 'capitalize',
    cursor: 'pointer',
  },
  downloadIcon: {
    color: BLUES.B500_WAVE,
    cursor: 'pointer',
  },
  subHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[4],
  },
  subheader: {
    ...DESCRIPTION.BOLD,
    display: 'flex',
    gap: SPACING[4],
    color: REDS.R700_VALENTINE,
  },
  subheaderDescription: {
    ...BODY.SMALL,
    color: NEUTRALS.N600_ABBEY,
  },
  successHeaderContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: SPACING[8],
  },
  successHeader: {
    ...HEADING.H6,
    color: GREENS.G700_SLIME,
    margin: 0,
  },
  successDescription: {
    ...BODY.SMALL,
    color: GREENS.G700_SLIME,
  },
  green: {
    backgroundColor: GREENS.G50_CANE,
    border: `1px solid ${GREENS.G700_SLIME}`,
  },
  loader: {
    display: 'flex',
    gap: SPACING[8],
    alignItems: 'center',
  },
  flashStyle: {
    backgroundColor: YELLOWS.Y50_DAWN,
    transitionDuration: '3000ms',
  },
  dropzoneInfo: {
    ...BODY.LARGE,
    color: NEUTRALS.N400_GRANITE,
    margin: 0,
    fontSize: '14px',
    display: 'flex',
  },
  uploadErrorMessage: {
    color: REDS.R700_VALENTINE,
    fontSize: '12px',
    fontWeight: 600,
    display: 'flex',
    margin: 0,
  },
  successBanner: {
    padding: SPACING[12],
    borderRadius: SPACING[4],
  },
  loaderText: {
    fontSize: '12px',
  },
  uploadButtonWidth: {
    width: '100px',
  },
  fileNameTextWidth: {
    width: '96%',
  },
  fileDownloadDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  fileUploadDiv: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
