export const hyperLinkText = {
  padding: '10px',
  fontSize: '0.85em',
  color: '#00b4e3',
};

export const gridStyle = {
  padding: '25px',
};

export const pStyle = {
  padding: '0 0 10px 10px',
};
