import {
  START_ADD_SEAT,
  START_EDIT_SEAT,
  STOP_ALTER_SEAT,
  SAVE_SEAT,
  SUCCESSFULLY_SAVED_SEAT_SECRETS,
  SAVE_SEAT_FAILED,
} from '../constants/ActionTypes';

export const INITIAL_STATE = {
  modalOpen: false,
  saving: false,
  seat: null,
  error: null,
};

export const NEW_SEAT = {
  id: undefined,
  accountId: '',
  externalId: '',
  prefix: '',
  displayName: '',
  name: '',
  businessUnit: {},
  country: {},
  dsp: {},
  apiUser: '',
  apiPassword: '',
  jsonObject: '',
  enabled: true,
  businessModel: {},
  feeOption: {},
};

const alterSeatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case START_ADD_SEAT:
      return { ...state, seat: { ...NEW_SEAT }, modalOpen: true };

    case START_EDIT_SEAT:
      return { ...state, seat: { ...action.payload, apiPassword: undefined }, modalOpen: true };

    case SAVE_SEAT:
      return { ...state, saving: true };

    case SUCCESSFULLY_SAVED_SEAT_SECRETS:
      return {
        ...state,
        seat: null,
        modalOpen: false,
        saving: false,
        error: null,
      };

    case SAVE_SEAT_FAILED:
      return {
        ...state,
        error: action.payload,
        saving: false,
      };

    case STOP_ALTER_SEAT:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default alterSeatReducer;
