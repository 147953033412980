import _ from 'lodash';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { Form, WppIconMoney, ValidatedCustomWppInput, WppLabel, WppListItem, WppSelect, WppSlider, WppTypography } from 'buildingBlocks';
import { getRealValue, setNumberValue } from 'buildingBlocks/ReactHookFormComponents/utils';
import { DSP, GOAL_VALUE_TYPE } from 'constantsBase';
import { EditStrategiesFormType } from 'containers/StrategiesList/constants';
import { selectedStrategiesPanel } from 'containers/StrategiesList/style';
import { getGoalTargetLabel, getGoalTypeOptions, isAmazonStrategy, stratsHaveRevTypeOrCustomGoal } from 'containers/StrategiesList/utils';
import PacingGraph from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/Pacing/PacingGraph';
import { PACING_LABELS, PACING_MARKS, TOOLTIP_DESCRIPTIONS, VIEWABILITY_TOOLTIP } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { isCYODGoalTypeValue } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { EnhancedStrategy, InputChangeEventDetail, SelectChangeEventDetail, WppInputCustomEvent, WppSelectCustomEvent } from 'utils/types';
import { useAsyncEffect } from 'utils/functionHelpers';
import { MultipleStrategiesSelectedToggle, SingleStrategySelectedToggle } from './Toggles';
import { EditingRevTypeOrCustomGoalWarning, AmznSettingsWarning } from './Warnings';

const { formStyle, labelStyle, moneyIcon, currencyInputLabel, pacingLabelContainer } = selectedStrategiesPanel;

const EditStrategiesForm = () => {
  const [goalTargetLabel, setGoalTargetLabel] = useState<string>(null);
  const selectedStrategies = useSelector<GlobalState>((state) => state.strategiesList.selectedStrategies) as Array<EnhancedStrategy>;
  const editingSingleStrategy = _.size(selectedStrategies) === 1;
  const singleStrategySelected = editingSingleStrategy && _.head(selectedStrategies);
  const { control, watch } = useFormContext<EditStrategiesFormType>();
  const selectedGoal = watch('goal');
  const pacing = watch('pacing');
  const viewabilityEnabled = watch('viewabilityEnabled');
  const allAmznStrategies = _.every(selectedStrategies, isAmazonStrategy);
  const someAmznStrategies = _.some(selectedStrategies, isAmazonStrategy);
  const intelChildObjsTooltip = editingSingleStrategy ? TOOLTIP_DESCRIPTIONS[singleStrategySelected.dspId].intelligentChildObjects : TOOLTIP_DESCRIPTIONS[DSP.MULTIPLE.id].intelligentChildObjects;
  const selectedGoalValueType = selectedGoal?.valueType;
  const goalTypeOptions = getGoalTypeOptions(selectedStrategies);
  const strategyGoals = _.flatMap(selectedStrategies, (s) => _.map(s.strategyGoals, (g) => _.camelCase(g?.type)));
  const containsCyodGoalType = isCYODGoalTypeValue(selectedGoal?.value) || (!selectedGoal && _.some(strategyGoals, isCYODGoalTypeValue));

  // goal and goal target are not be editable if a rev type enabled or custom goal strategy is selected
  const editingRevTypeOrCustomGoalStrats = stratsHaveRevTypeOrCustomGoal(selectedStrategies);
  const shouldValidatePercentage = selectedGoalValueType === GOAL_VALUE_TYPE.PERCENTAGE;
  const currencyValueType = selectedGoalValueType === GOAL_VALUE_TYPE.CURRENCY;
  const showGoalTargetInputWithLabel = shouldValidatePercentage || (editingSingleStrategy && currencyValueType);

  useAsyncEffect(async () => {
    const targetLabel = await getGoalTargetLabel(selectedGoalValueType, singleStrategySelected);
    setGoalTargetLabel(targetLabel);
  }, [selectedGoal, editingSingleStrategy]);

  return (
    <Form style={formStyle}>
      {editingRevTypeOrCustomGoalStrats && <EditingRevTypeOrCustomGoalWarning />}
      {editingSingleStrategy && (
        <>
          <WppLabel style={labelStyle}>Strategy Name</WppLabel>
          <Controller
            name="name"
            control={control}
            rules={{
              required: { value: true, message: 'Required' },
              pattern: { value: /\w/, message: 'Name must contain at least one character' },
              maxLength: { value: 255, message: 'Name has a maximum of 255 characters.' },
            }}
            render={({ field, fieldState }) => (
              <ValidatedCustomWppInput
                type="text"
                placeholder="Enter a Strategy name"
                onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => field.onChange(e.detail.value)}
                field={field}
                fieldState={fieldState}
              />
            )}
          />
        </>
      )}
      {!editingRevTypeOrCustomGoalStrats && (
        <>
          <WppLabel style={labelStyle}>Goal</WppLabel>
          <Controller
            name="goal"
            control={control}
            render={({ field }) => (
              <WppSelect
                value={field.value}
                displayValue={field.value?.shortText ?? 'Various'}
                onWppChange={(e:WppSelectCustomEvent<SelectChangeEventDetail>) => field.onChange(e.detail.value)}
              >
                {_.map(goalTypeOptions, (goal) => (
                  <WppListItem value={goal} key={goal.value}>
                    <p slot="label">{goal.shortText}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            )}
          />
          <WppLabel style={labelStyle}>Goal Target (For Reporting Only)</WppLabel>
          <Controller
            name="goalTarget"
            rules={{
              required: { value: true, message: 'Required' },
              min: { value: 0, message: 'Goal target must be at least 0' },
              max: { value: (shouldValidatePercentage ? 1 : Number.MAX_VALUE), message: 'Goal target must be at most 100' },
            }}
            control={control}
            render={({ field, fieldState }) => (showGoalTargetInputWithLabel ? (
              <ValidatedCustomWppInput
                type="decimal"
                placeholder={editingSingleStrategy ? '' : 'Various'}
                disabled={!selectedGoal}
                label={goalTargetLabel}
                onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => field.onChange(setNumberValue(e.detail.value, shouldValidatePercentage))}
                value={(shouldValidatePercentage && _.isNumber(field.value)) ? getRealValue(field.value, 2) : field.value}
                field={field}
                fieldState={fieldState}
              />
            ) : (
              <>
                <ValidatedCustomWppInput
                  type="decimal"
                  placeholder="Various"
                  disabled={!selectedGoal}
                  inputIcon={currencyValueType && <WppIconMoney slot="icon-end" style={moneyIcon} />}
                  field={field}
                  fieldState={fieldState}
                  onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => field.onChange(setNumberValue(e.detail.value))}
                />
                {currencyValueType && <WppTypography type="xs-midi" style={currencyInputLabel}>The value will apply to each strategy at local currency</WppTypography>}
              </>
            ))}
          />
        </>
      )}
      <WppLabel style={labelStyle}>Pacing</WppLabel>
      <PacingGraph pacing={pacing} />
      <Controller
        name="pacing"
        rules={{
          required: { value: true, message: 'Required' },
          min: { value: 0.5, message: 'Pacing must be at least 50%' },
          max: { value: 1.5, message: 'Pacing must be at most 100' },
        }}
        control={control}
        render={({ field: { value, onChange } }) => (
          <>
            <WppSlider
              className="edit-strategy-side-modal-slider"
              type="single"
              size="m"
              continuous={false}
              step={0.1}
              min={0.5}
              max={1.5}
              value={value}
              marks={PACING_MARKS}
              onWppChange={onChange}
              required
            />
            <div style={pacingLabelContainer}>
              {_.map(PACING_LABELS, (l) => <WppTypography key={l} type="xs-body" className="pacing-slider-label">{l}</WppTypography>)}
            </div>
          </>
        )}
      />
      {!allAmznStrategies && (
        <>
          {someAmznStrategies && <AmznSettingsWarning />}
          <WppLabel
            config={{
              icon: 'wpp-icon-info',
              text: 'Viewability',
              description: VIEWABILITY_TOOLTIP,
            }}
            style={labelStyle}
          />
          <Controller
            name="viewabilityEnabled"
            control={control}
            render={({ field: { value, onChange } }) => (editingSingleStrategy
              ? <SingleStrategySelectedToggle value={value} onChange={onChange} />
              : <MultipleStrategiesSelectedToggle value={value} onChange={onChange} />
            )}
          />
          {viewabilityEnabled && (
            <Controller
              name="viewabilityTarget"
              control={control}
              rules={{
                required: { value: true, message: 'Required' },
                validate: {
                  greaterThanZero: (v) => (_.toNumber(v) > 0 || 'Viewability target must be greater than 0%'),
                },
                max: { value: 1, message: 'Viewability target must be at most 100%' },
              }}
              render={({ field, fieldState }) => (
                <>
                  <WppLabel style={labelStyle}>Viewability Target</WppLabel>
                  <ValidatedCustomWppInput
                    type="decimal"
                    label="%"
                    placeholder={editingSingleStrategy ? '' : 'Various'}
                    onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => field.onChange(setNumberValue(e.detail.value, true))}
                    value={_.isNumber(field.value) ? getRealValue(field.value, 2) : field.value}
                    field={field}
                    fieldState={fieldState}
                  />
                </>
              )}
            />
          )}
          {!containsCyodGoalType && (
            <>
              <WppLabel
                config={{
                  icon: 'wpp-icon-info',
                  text: 'Intelligent Line Items',
                  description: intelChildObjsTooltip,
                }}
                style={labelStyle}
              />
              <Controller
                name="intelligentChildObjects"
                control={control}
                render={({ field: { value, onChange } }) => (editingSingleStrategy
                  ? <SingleStrategySelectedToggle value={value} onChange={onChange} />
                  : <MultipleStrategiesSelectedToggle value={value} onChange={onChange} />
                )}
              />
            </>
          )}
        </>
      )}
    </Form>
  );
};

export default EditStrategiesForm;
