import _ from 'lodash';
import React from 'react';
import ExpandText from 'components/ExpandText';
import { Message } from 'buildingBlocks';
import { COLLAPSED_FEATURES_LENGTH } from '../constants';
import { featureStyle } from '../style';

type Props = {
  data: Array<unknown>,
  metricsIndex: { [key: string]: number },
  feature: string,
  positive?: boolean
};

const Features = ({
  data, metricsIndex, feature, positive,
}: Props) => {
  // @ts-ignore
  const features = JSON.parse(_.get(data, `[${_.get(metricsIndex, feature)}]`, {}));
  const symbol = positive ? '+' : '-';
  const getText = (key, values) => `${symbol} ${key} (${values.length}): ${values.join(', ')}`;

  return features && !_.isEmpty(features)
    && (
    <Message
      attached
      positive={positive}
      negative={!positive}
      style={featureStyle}
    >
      {
        _.map(
          _.keys(features),
          (key) => <ExpandText key={key} text={getText(key, _.get(features, key))} length={COLLAPSED_FEATURES_LENGTH} />,
        )
      }
    </Message>
    );
};

export default Features;
