import React from 'react';
import _ from 'lodash';
import { EnhancedStrategy, Strategy, User, ColDef, CellStyle } from 'utils/types';
import { WppTooltip, WppTag, WppTypography } from 'buildingBlocks';
import { FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import Sparkline from 'containers/StrategiesList/components/Sparkline';
import EditWppStrategyStep from 'components/EditStrategyStep/EditWppStrategyStep';
import StarredUserStrategy from 'containers/StarredUserStrategy';
import { dateTimeFormatter } from 'utils/dateTime';
import style, { strategyTableColumns } from 'containers/StrategiesList/style';
import StratInfo from './StrategyInfo';

const { legendBadgeContainer, sparklineContainer } = strategyTableColumns;

const { strategyCellStyle, cellFullHeight } = style;

export const strategiesTableColumns = (user: User, handleDeleteStrategy: (strategy: Strategy) => void, handleDownloadReport: (data: any) => void, showToast: Function): Array<ColDef> => [
  {
    field: 'strategies',
    headerComponent: () => <WppTypography tag="p" type="s-strong" style={{ paddingLeft: '32px' }}>Strategies</WppTypography>,
    sortable: false,
    resizable: false,
    cellRenderer: ({ data }: { data: EnhancedStrategy }) => {
      const formattedLastModifiedTime = dateTimeFormatter.date(data.lastModifiedTime, false, 'MM/DD/YYYY HH:mm');
      return (
        <div style={strategyCellStyle}>
          <StarredUserStrategy
            userId={user.id}
            strategyId={data.id}
            className="star-homepage"
          />
          <StratInfo
            stratId={data.id}
            advertiser={_.get(data.advertiser, 'name')}
            name={data.name}
            status={data.status}
            dspId={data.dspId}
            lastModifiedTime={formattedLastModifiedTime}
            type={data.strategyType.displayName}
            member={_.get(data.member, 'displayName')}
            brand={_.get(data.brand, 'name')}
            clientTest={data.clientTest}
          />
        </div>
      );
    },
    flex: 1,
    autoHeight: true,
    wrapText: true,
  },
  {
    field: 'impressions',
    headerName: 'Impressions',
    sortable: false,
    resizable: false,
    cellStyle: cellFullHeight as CellStyle,
    width: 332,
    cellRenderer: ({ data }: { data: EnhancedStrategy }) => (
      <div style={sparklineContainer}>
        {_.isEmpty(data.analytics) ? '-' : (<Sparkline analytics={data.analytics} />)}
      </div>
    ),
  },
  {
    field: 'dspObject',
    headerName: 'DSP Object',
    sortable: false,
    resizable: false,
    autoHeight: true,
    cellStyle: cellFullHeight as CellStyle,
    cellRenderer: ({ data }: { data: EnhancedStrategy }) => {
      const flightExtTypes = _.map(_.uniq(_.map(_.values(data.flights), 'externalType')), (extType) => FLIGHT_EXTERNAL_TYPE.getById(extType));
      const uniqWizardAbbreviations = _.uniq(_.map(flightExtTypes, 'wizardAbbreviation'));
      const popUpContent = `${data.numFlights} ${_.size(flightExtTypes) === 1 ? _.get(_.head(flightExtTypes), 'displayName') : 'Object'}${data.numFlights > 1 ? 's' : ''}`;
      const legendBadges = _.map(
        uniqWizardAbbreviations,
        (wizAbbrev: string) => (
          <WppTooltip key={wizAbbrev} text={popUpContent}>
            <WppTag label={wizAbbrev} variant="neutral" />
          </WppTooltip>
        ),
      );
      return <div style={legendBadgeContainer}>{legendBadges}</div>;
    },
    maxWidth: 115,
  },
  {
    field: 'action',
    sortable: false,
    resizable: false,
    headerName: '',
    maxWidth: 84,
    cellStyle: cellFullHeight as CellStyle,
    cellRenderer: ({ data }: { data: any }) => (
      <EditWppStrategyStep
        {...data}
        handleDownloadReport={handleDownloadReport}
        user={user}
        showAnalyticsLink
        onDeleteClick={() => handleDeleteStrategy(data)}
        showToast={showToast}
      />
    ),
  },
];
