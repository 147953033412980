import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Container, Image, Header } from 'buildingBlocks';
import { dataUploader } from '../style';
import { CSV_FILE_IMG_PATH, DOWNLOAD_FILE_IMG_PATH, FileStates } from '../constants';
import { ExternalDataForm } from '../types';

const {
  fileContainer, fileIcon,
  fileNameText, downloadIcon,
  flashStyle, fileText,
} = dataUploader;

type FileToUploadProps = {
  fileName: string
  fileUploadState: FileStates
};

const FileToUpload = ({ fileName, fileUploadState }: FileToUploadProps) => {
  const [flash, setFlash] = useState<boolean>(false);
  const presignedUrl = useWatch<ExternalDataForm>({ name: 'presignedUrl' });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (_.isEqual(FileStates.success, fileUploadState)) {
      setFlash(true);

      const timer = setTimeout(() => {
        setFlash(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadState]);

  return (
    <>
      <div style={fileText}>Currently using this file to optimize your campaign</div>
      <Container style={{ ...fileContainer, ...(flash && flashStyle) }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Image alt="csv file" src={CSV_FILE_IMG_PATH} style={fileIcon} />
          <Header style={fileNameText} content={fileName} />
          <a href={presignedUrl} download>
            <Image alt="download" src={DOWNLOAD_FILE_IMG_PATH} style={downloadIcon} />
          </a>
        </div>
      </Container>
    </>
  );
};

export default FileToUpload;
