import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  ObjectMultiDropdown,
  ObjectDropdownSearch,
  WppGrid,
  WppSelect,
  WppListItem,
  WppDivider,
  WppButton,
  WppDatepicker,
} from 'buildingBlocks';
import { getOptionsWithDspIcon } from 'components/OptionWithIcon';
import { wppBodyContainer } from 'components/PageTemplate/style';
import { DSP, EXCLUDE_STRATEGY_TYPES } from 'constantsBase';
import { TIME_ZONE_OPTIONS } from 'containers/Jobs/constants';
import { TimeZoneValue, TimeZoneOption } from 'containers/Jobs/types';
import {
  SelectChangeEventDetail,
  WppSelectCustomEvent,
  DatePickerEventDetail,
  WppDatepickerCustomEvent,
  AlgorithmType,
} from 'utils/types';
import {
  dateTimeFormatter,
  NUMBER_DATE_FORMAT,
  WPP_DATE_PICKER_FORMAT,
  WPP_DATE_PICKER_PLACEHOLDER_FORMAT,
} from 'utils/dateTime';
import { searchByNameOrExtId } from 'utils/semanticUISearch';
import {
  strategySearchUpdated,
  flightSearchUpdated,
  algorithmSearchUpdated,
  updateFilter,
} from './actions';
import { STRATEGY_FLIGHT_RUNS_FILTER } from './constants';
import { filterDividerStyle, filterActionBtnStyle } from './style';
import { ExtendedRootState } from './types';

const FilterDropdown = <T extends {}>({ filterType, ...passthroughProps }: FilterDropdownProps<T>) => (
  <ObjectMultiDropdown
    {...passthroughProps}
    fluid
    selection
    scrolling
    // @ts-ignore ObjectMultiDropdown
    id={filterType.name}
    name={filterType.name}
    placeholder={filterType.placeholder}
    disabled={passthroughProps.loading || !!passthroughProps.error}
    noResultsMessage={passthroughProps.options.length > 0
      ? 'No remaining options.'
      : `Search ${filterType.placeholder}s.`}
  />
);

type FilterDropdownProps<T> = {
  filterType: {
    placeholder: string
    name: string
  },
  loading?: boolean
  error?: string
  options: Array<T>
  value: Array<T>
  onChange: Function
  keyFn?: (obj: T) => string
  search?: ObjectDropdownSearch
};

type FilterSectionProps = {
  selectedTimezone: TimeZoneValue
  formatAlgoTypeName: (algoType: AlgorithmType) => string
  onResetFilter: () => void
  onApplyFilter: () => void
  handleTimeZoneWppChange: (event: WppSelectCustomEvent<SelectChangeEventDetail>) => void
};

const FilterSection: React.FC<FilterSectionProps> = ({ selectedTimezone, formatAlgoTypeName, onResetFilter, onApplyFilter, handleTimeZoneWppChange }) => {
  const dispatch = useDispatch();
  const {
    members,
    strategyTypes,
    strategies,
    flights,
    algorithms,
    algorithmTypes,
    filter,
    selected,
  } = useSelector((state: ExtendedRootState) => state.strategyFlightRuns);
  const memberOptions = getOptionsWithDspIcon(members);
  const stratTypeOptions = getOptionsWithDspIcon(_.sortBy(_.reject(strategyTypes, ({ id }) => _.includes(EXCLUDE_STRATEGY_TYPES, id)), 'displayName'));
  const algoTypeOptions = _.sortBy(algorithmTypes, 'name');

  const wppDatePickerProps = {
    locale: { dateFormat: WPP_DATE_PICKER_FORMAT },
    size: 'm' as const,
    placeholder: WPP_DATE_PICKER_PLACEHOLDER_FORMAT,
    minDate: moment().subtract(65, 'days').format(NUMBER_DATE_FORMAT),
    maxDate: moment().format(NUMBER_DATE_FORMAT),
  };

  return (
    <div style={wppBodyContainer} className="flightRunFilter">
      <WppGrid container style={filterDividerStyle}>
        <WppGrid item all={4}>
          <WppDatepicker
            {...wppDatePickerProps}
            labelConfig={{ text: 'Start Date' }}
            value={filter.startDate ? moment(filter.startDate).format(NUMBER_DATE_FORMAT) : ''}
            onWppChange={(event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
              const date = event.detail.date as Date;
              dispatch(updateFilter({
                ...filter,
                startDate: dateTimeFormatter.isoDateTime(date),
              }));
            }}
            className="wppDateRangePicker"
            required
          />
        </WppGrid>
        <WppGrid item all={4}>
          <WppDatepicker
            {...wppDatePickerProps}
            labelConfig={{ text: 'End Date' }}
            value={moment(filter.endDate).format(NUMBER_DATE_FORMAT)}
            onWppChange={(event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
              const date = event.detail.date as Date;
              dispatch(updateFilter({
                ...filter,
                endDate: dateTimeFormatter.isoDateTime(moment(date).endOf('day')),
              }));
            }}
            className="wppDateRangePicker"
            minDate={moment(filter.startDate).format(NUMBER_DATE_FORMAT)}
            required
          />
        </WppGrid>
        <WppGrid item all={4}>
          <WppSelect
            onWppChange={handleTimeZoneWppChange}
            placeholder={selectedTimezone}
            value={selectedTimezone}
            labelConfig={{ text: 'Time Zone' }}
            required
          >
            {_.map(TIME_ZONE_OPTIONS, (item: TimeZoneOption) => (
              <WppListItem key={item.value} value={item.value}>
                <p slot="label">{item.text}</p>
              </WppListItem>
            ))}
          </WppSelect>
        </WppGrid>
      </WppGrid>
      <WppGrid container>
        <WppGrid item all={24} style={filterDividerStyle}>
          <WppDivider />
        </WppGrid>
      </WppGrid>
      <WppGrid container style={filterDividerStyle}>
        <WppGrid item all={4}>
          <FilterDropdown
            onChange={(selectedStrategies) => {
              dispatch(updateFilter({
                ...filter,
                strategy: _.map(selectedStrategies, 'id'),
              }));
            }}
            filterType={STRATEGY_FLIGHT_RUNS_FILTER.INPUT.STRATEGIES}
            options={strategies}
            value={selected.strategy}
            keyFn={(strategy) => `${strategy.id}`}
            search={{
              searchType: 'api',
              onSearchChange: (_e, { searchQuery: search }) => {
                dispatch(strategySearchUpdated(filter, search));
              },
              debounce: { timer: 250 },
            }}
          />
        </WppGrid>
        <WppGrid item all={4}>
          <FilterDropdown
            onChange={(selectedAlgorithms) => {
              dispatch(updateFilter({
                ...filter,
                algorithm: _.map(selectedAlgorithms, 'id'),
              }));
            }}
            filterType={STRATEGY_FLIGHT_RUNS_FILTER.INPUT.ALGORITHMS}
            options={algorithms}
            value={selected.algorithm}
            keyFn={(algorithm) => `${algorithm.id}`}
            search={{
              searchType: 'api',
              onSearchChange: (_e, { searchQuery: search }) => {
                dispatch(algorithmSearchUpdated(filter, search));
              },
              debounce: { timer: 250 },
            }}
          />
        </WppGrid>
        <WppGrid item all={4}>
          <FilterDropdown
            onChange={(selectedFlights) => {
              dispatch(updateFilter({
                ...filter,
                flight: _.map(selectedFlights, 'id'),
              }));
            }}
            filterType={STRATEGY_FLIGHT_RUNS_FILTER.INPUT.FLIGHTS}
            options={flights}
            value={selected.flight}
            keyFn={(flight) => `${flight.id}`}
            search={{
              searchType: 'api',
              onSearchChange: (_e, { searchQuery: search }) => {
                dispatch(flightSearchUpdated(filter, search));
              },
              debounce: { timer: 250 },
            }}
          />
        </WppGrid>
        <WppGrid item all={4}>
          <FilterDropdown
            onChange={(selectedMembers) => {
              dispatch(updateFilter({
                ...filter,
                member: _.map(selectedMembers, 'id'),
              }));
            }}
            filterType={STRATEGY_FLIGHT_RUNS_FILTER.INPUT.MEMBERS}
            options={memberOptions}
            value={selected.member}
            keyFn={(mem) => `${DSP.getById(mem.dsp as number).code} - ${_.get(mem, 'displayName', _.get(mem, 'name'))}`}
            search={{
              searchType: 'local',
              onSearchChange: searchByNameOrExtId(selected.member),
            }}
          />
        </WppGrid>
        <WppGrid item all={4}>
          <FilterDropdown
            onChange={(status) => {
              dispatch(updateFilter({
                ...filter,
                status: _.map(status, 'value'),
              }));
            }}
            filterType={STRATEGY_FLIGHT_RUNS_FILTER.STATUS}
            options={STRATEGY_FLIGHT_RUNS_FILTER.STATUS.options}
            value={selected.status}
            keyFn={(status) => status.text}
          />
        </WppGrid>
        <WppGrid item all={4}>
          <FilterDropdown
            onChange={(triggerTypes) => {
              dispatch(updateFilter({
                ...filter,
                triggerType: _.map(triggerTypes, 'value'),
              }));
            }}
            filterType={STRATEGY_FLIGHT_RUNS_FILTER.TRIGGER_TYPE}
            options={STRATEGY_FLIGHT_RUNS_FILTER.TRIGGER_TYPE.options}
            value={selected.triggerType}
            keyFn={(triggerType) => triggerType.text}
          />
        </WppGrid>
      </WppGrid>
      <WppGrid container style={filterDividerStyle}>
        <WppGrid item all={12}>
          <FilterDropdown
            onChange={(selectedTypes) => {
              dispatch(updateFilter({
                ...filter,
                strategyType: _.map(selectedTypes, 'id'),
              }));
            }}
            filterType={STRATEGY_FLIGHT_RUNS_FILTER.STRATEGY_TYPE}
            options={stratTypeOptions}
            value={selected.strategyType}
            keyFn={(st) => `${DSP.getById(st.dsp as number).code} - ${st.displayName}`}
          />
        </WppGrid>
        <WppGrid item all={12}>
          <FilterDropdown
            onChange={(selectedTypes) => {
              dispatch(updateFilter({
                ...filter,
                algorithmType: _.map(selectedTypes, 'id'),
              }));
            }}
            filterType={STRATEGY_FLIGHT_RUNS_FILTER.ALGORITHM_TYPE}
            options={algoTypeOptions}
            value={selected.algorithmType}
            keyFn={(algorithmType) => formatAlgoTypeName(algorithmType)}
          />
        </WppGrid>
      </WppGrid>
      <WppGrid container style={filterDividerStyle}>
        <WppGrid item all={24} style={filterActionBtnStyle}>
          <WppButton
            variant="secondary"
            size="m"
            onClick={onResetFilter}
          >
            Reset Filter
          </WppButton>
          <WppButton
            variant="primary"
            size="m"
            onClick={onApplyFilter}
          >
            Apply Filter
          </WppButton>
        </WppGrid>
      </WppGrid>
    </div>
  );
};

export default FilterSection;
