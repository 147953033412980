import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const memberStyle: { [key: string]: CSSProperties } = {
  headerContainerStyle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  setDetailsTextStyle: {
    wordBreak: 'break-all',
  },
  setFormFieldContainerStyle: {
    marginBottom: SPACING[20],
  },
  setFormFieldLabelStyle: {
    marginBottom: SPACING[8],
  },
  seatFormModelFooterStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: SPACING[8],
  },
  seatFormModelBodyContainerStyle: {
    marginTop: SPACING[16],
  },
  seatFormModelErrorStyle: {
    marginTop: SPACING[20],
  },
  setFormFieldStyle: {
    textAlign: 'center',
  },
};

const borderStyle = '1px solid rgba(34, 36, 38, 0.15)';

export const containerStyle = {
  padding: {
    padding: '1.25em 1em',
  },
  header: {
    borderBottom: borderStyle,
  },
  footer: {
    borderTop: borderStyle,
    display: 'flex',
    alignItems: 'center',
  },
};

export const newSeatButton = {
  float: 'right',
  background: '#1BA6EF',
  color: 'white',
};

export const memberButtons = {
  padding: '9px',
};
