import _ from 'lodash';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { WppButton, WppGrid, WppIconPlus, WppTable, WppTableBody, WppTableHeader, WppTableHeaderCell, WppTableHeaderRow, WppTypography } from 'buildingBlocks';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { Permission } from 'utils/featureFlags';
import { FetchState } from 'utils/types';
import DataLoading from 'components/DataLoading';
import ErrorBoundary from 'components/ErrorBoundary';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import withRouter from 'utils/withRouter';
import { GlobalState } from 'reducers';
import useSideModalHideFreshdesk from 'utils/hooks/useSideModalHideFreshdesk';
import {
  getSeatsData,
  getDSPs,
  getRegions,
  addNewSeat,
  editSeat,
  checkSeatCredentials,
  getCountries,
  getBusinessUnits,
  getBusinessModels,
  getFeeOptions,
  getFeeTypes,
  getPermissions,
} from '../actions/index';
import SeatDetailsContainer, { Seat, SeatStatusStates } from './SeatDetails';
import SeatFormContainer from './SeatForm';
import { memberStyle } from '../style';

const PAGE_NAME = 'Members';
const REQUIRED_PERMISSIONS = [Permission.manageMembers];
const headers = [
  'ID',
  'External ID',
  'Prefix',
  'Name',
  'DSP',
  'Display Name',
  'Country',
  'Business Unit',
  'Region',
  'API User',
  'Enabled',
  'Created',
  'Updated',
];

type SeatListProps = {
  getSeatsData: Function
  getDSPs: Function
  getCountries: Function
  getBusinessUnits: Function
  getBusinessModels: Function
  getFeeOptions: Function
  getFeeTypes: Function
  getRegions: Function
  editSeat: Function
  checkSeatCredentials: Function
  seats: Seat[]
  alterSeat: {
    modalOpen: boolean
    seat: { id: number }
  };
  seatConnectionStatus: { [seatId: string]: SeatStatusStates }
  loading: boolean
  getPermissions: Function
};

type AddNewSeatButtonProps = {
  addNewSeat: Function
};

const AddNewSeatButton = () => {
  const dispatch = useDispatch();

  const handleAddNewSeat = () => {
    dispatch(addNewSeat());
  };

  return (
    <WppGrid container fullWidth>
      <WppGrid item all={24} style={memberStyle.headerContainerStyle}>
        <WppTypography tag="h1" type="3xl-heading">
          {PAGE_NAME}
        </WppTypography>
        <WppButton onClick={handleAddNewSeat}>
          <WppIconPlus slot="icon-start" /> New Member
        </WppButton>
      </WppGrid>
    </WppGrid>
  );
};

export const SeatList: React.FC<SeatListProps> = () => {
  const dispatch = useDispatch();

  const seats = useSelector<GlobalState, Array<Seat>>((state) => state.seats.seatList.seats);
  const { modalOpen } = useSelector<GlobalState, { modalOpen: boolean }>((state) => state.seats.alterSeat);
  const seatConnectionStatus = useSelector<GlobalState, { [seatId: string]: SeatStatusStates }>((state) => state.seats.seatList.seatConnectionStatus);
  const { loading } = useSelector<GlobalState, { loading: boolean }>((state) => state.seats.seatList);

  useMount(() => {
    dispatch(getSeatsData());
    dispatch(getDSPs());
    dispatch(getCountries());
    dispatch(getBusinessUnits());
    dispatch(getBusinessModels());
    dispatch(getFeeOptions());
    dispatch(getFeeTypes());
    dispatch(getRegions());
    dispatch(getPermissions());
  });
  // hide freshdesk button when side modal is open
  useSideModalHideFreshdesk(modalOpen);

  return (
    <DataLoading loading={loading} pageName={PAGE_NAME}>
      <ErrorBoundary>
        <WppTable>
          <WppTableHeader>
            <WppTableHeaderRow>
              {_.map(headers, (header: string) => (
                <WppTableHeaderCell key={header}>
                  <WppTypography type="s-strong" tag="p">{header}</WppTypography>
                </WppTableHeaderCell>
              ))}
              <WppTableHeaderCell style={memberStyle.setFormFieldStyle} colSpan={2}>
                <WppTypography type="s-strong" tag="p">Action</WppTypography>
              </WppTableHeaderCell>
            </WppTableHeaderRow>
          </WppTableHeader>
          <WppTableBody>
            {_.map(seats, (seat: Seat) => (
              <SeatDetailsContainer
                key={seat.id}
                editSeat={editSeat}
                checkSeatCredentials={checkSeatCredentials}
                seatConnectionStatus={seatConnectionStatus[seat.id] || { kind: FetchState.initial }}
                seat={seat}
              />
            ))}
          </WppTableBody>
        </WppTable>
        {modalOpen && (<SeatFormContainer open={modalOpen} />)}
      </ErrorBoundary>
    </DataLoading>
  );
};

const PermissionSeatList = (props: AddNewSeatButtonProps) => (
  <WppPermissionPageTemplate
    title={PAGE_NAME}
    name={PAGE_NAME}
    customHeader={<AddNewSeatButton />}
    permissions={REQUIRED_PERMISSIONS}
  >
    {/*
      // @ts-ignore */}
    <SeatList {...props} />
  </WppPermissionPageTemplate>
);

export default withRouter(PermissionSeatList);
