const PREFIX = 'STRATEGYANALYTICS';

export const optionsUpdated = {
  aggregator: `${PREFIX}:OPTION_UPDATED_AGGREGATOR`,
  ratePercentage: `${PREFIX}:OPTION_UPDATED_RATE_PERCENTAGE`,
  byObject: `${PREFIX}:OPTION_UPDATED_BY_OBJECT`,
  bySegment: `${PREFIX}:OPTION_UPDATED_BY_SEGMENT`,
  shownAs: `${PREFIX}:OPTION_UPDATED_SHOWN_AS`,
  dateRange: `${PREFIX}:OPTION_UPDATED_DATE_RANGE`,
  flight: `${PREFIX}:OPTION_UPDATED_FLIGHT`,
  segment: `${PREFIX}:OPTION_UPDATED_SEGMENT`,
  segmentGroup: `${PREFIX}:OPTION_UPDATED_SEGMENT_GROUP`,
  tableSort: `${PREFIX}:OPTION_UPDATED_TABLE_SORT`,
};

export const RESET_PROPS = `${PREFIX}:RESET_PROPS`;
export const FETCH_STRATEGY = `${PREFIX}:FETCH_STRATEGY`;
export const FETCH_STRATEGY_COMPLETED = `${PREFIX}:FETCH_STRATEGY_COMPLETED`;
export const FETCH_STRATEGY_FAILED = `${PREFIX}:FETCH_STRATEGY_FAILED`;
export const FETCH_SEGMENTS = `${PREFIX}:FETCH_SEGMENTS`;
export const FETCH_SEGMENTS_COMPLETED = `${PREFIX}:FETCH_SEGMENTS_COMPLETED`;
export const FETCH_SEGMENTS_FAILED = `${PREFIX}:FETCH_SEGMENTS_FAILED`;
export const FETCH_PIXELS = `${PREFIX}:FETCH_PIXELS`;
export const FETCH_PIXELS_COMPLETED = `${PREFIX}:FETCH_PIXELS_COMPLETED`;
export const FETCH_PIXELS_FAILED = `${PREFIX}:FETCH_PIXELS_FAILED`;
export const TOGGLE_DELETE_MODAL = `${PREFIX}:TOGGLE_DELETE_MODAL`;
export const DELETE_STRATEGY = `${PREFIX}:DELETE_STRATEGY`;
export const DELETE_STRATEGY_COMPLETED = `${PREFIX}:DELETE_STRATEGY_COMPLETED`;
export const DELETE_STRATEGY_FAILED = `${PREFIX}:DELETE_STRATEGY_FAILED`;
export const DATE_RANGE_CHANGED = `${PREFIX}:DATE_RANGE_CHANGED`;
export const UPDATE_CLIENT_TEST_FOR_STRATEGY = `${PREFIX}:UPDATE_CLIENT_TEST_FOR_STRATEGY`;
export const UPDATE_CLIENT_TEST_FOR_STRATEGY_COMPLETED = `${PREFIX}:UPDATE_CLIENT_TEST_FOR_STRATEGY_COMPLETED`;
export const UPDATE_CLIENT_TEST_FOR_STRATEGY_FAILED = `${PREFIX}:UPDATE_CLIENT_TEST_FOR_STRATEGY_FAILED`;
