import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Table, Grid, Icon,
} from 'buildingBlocks';
import BtnPageHeader from 'components/BtnPageHeader';
import { roleType, ADMIN_ROLE_ID } from 'constantsBase';
import { Permission } from 'utils/featureFlags';
import DataLoading from 'components/DataLoading';
import PermissionPageTemplate from 'components/PageTemplate/PermissionPageTemplate';
import { getRoles } from './actions';
import { noBorderRadius } from './style';
import { roleListingTableColumns } from './constants';

const REQUIRED_PERMISSIONS = [Permission.manageRoles];
const PAGE_NAME = 'Roles';

export const CustomHeader = () => (
  <BtnPageHeader title={PAGE_NAME} to="/roles/create" permission={REQUIRED_PERMISSIONS} />
);

type Props = {
  roles: Array<{
    id: number,
    name: string,
    description: string,
    type: number,
  }>,
  getRoles: Function,
  loading: boolean,
};

export class Roles extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.props.getRoles();
  }

  renderRoleTableBody() {
    return (
      <Table.Body>
        { _.map(this.props.roles, (role) => (
          <Table.Row key={role.id}>
            <Table.Cell>{role.id}</Table.Cell>
            <Table.Cell>{role.name}</Table.Cell>
            <Table.Cell>{role.description}</Table.Cell>
            <Table.Cell>{_.upperFirst(_.findKey(roleType, (key) => key === role.type))}</Table.Cell>
            <Table.Cell disabled={role.id === ADMIN_ROLE_ID}>
              <Link to={`/roles/${role.id}`}>
                <Icon circular color="blue" name="edit" disabled={role.id === ADMIN_ROLE_ID} />
              </Link>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    );
  }

  renderRolesTable() {
    return (
      <Grid.Row>
        <Grid.Column>
          <Table style={noBorderRadius}>
            <Table.Header>
              <Table.Row>
                { _.map(roleListingTableColumns, (column) => (
                  <Table.HeaderCell key={column.value}>
                    {column.text}
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            { this.renderRoleTableBody() }
          </Table>
        </Grid.Column>
      </Grid.Row>
    );
  }

  render() {
    return (
      <DataLoading loading={this.props.loading} pageName={PAGE_NAME}>
        <Grid>
          { this.renderRolesTable() }
        </Grid>
      </DataLoading>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.roles.roles,
  loading: state.roles.loading,
});

const PermissionRoles = (props) => (
  // @ts-ignore - page template
  <PermissionPageTemplate
    title={PAGE_NAME}
    name={PAGE_NAME}
    customHeader={<CustomHeader />}
    permissions={REQUIRED_PERMISSIONS}
  >
    <Roles {...props} />
  </PermissionPageTemplate>
);

export default connect(mapStateToProps, { getRoles })(PermissionRoles);
