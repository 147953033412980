import _ from 'lodash';
import React from 'react';
import parse from 'html-react-parser';
import { Grid } from 'buildingBlocks';
import { BULLET_POINTS } from '../constants';
import { dataUploader } from '../style';
import FileUploaderSection from './FileUploaderSection';

const { mainSection, list, bulletPoint, column } = dataUploader;

const DataUploaderForm = () => (
  <Grid.Row columns={2} style={mainSection} divided>
    <Grid.Column style={column}>
      <ul style={list}>
        {_.map(BULLET_POINTS, (bullet: string, idx: number) => (
          <li key={idx} style={bulletPoint}>{parse(bullet)}</li>
        ))}
      </ul>
    </Grid.Column>
    <Grid.Column style={column}>
      <FileUploaderSection />
    </Grid.Column>
  </Grid.Row>
);

export default DataUploaderForm;
