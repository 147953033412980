import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';
import { Strategy, User, Currency, Flight, EnvironmentVariables } from 'utils/types';
import { Container, Header, Menu } from 'buildingBlocks';
import PermissionPageTemplate from 'components/PageTemplate/PermissionPageTemplate';
import Strategy403 from 'containers/403/components/Strategy403';
import { WidgetWrapper, clearAllWidgetFieldsBesidesEmail } from 'utils/widget';
import PageLoading from 'components/PageLoading';
import { header } from 'components/SubNav/style';
import PageLoadError from 'components/PageLoadError';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';
import { TabView } from 'components/Tabs';
import { TabType } from 'components/Tabs/TabView';
import TextLinkBack from 'components/TextLinkBack';
import { stratHasRevenueType } from 'charts/InsightsViz/utils';
import { checkPermissions, Permission } from 'utils/featureFlags';
import withRouter, { RouterProps } from 'utils/withRouter';
import UnknownError from 'containers/UnknownError/components/UnknownError';
import { fetchStrategy, resetProps, toggleDeleteModal, deleteStrategy } from './actions';
import Metadata from './components/Metadata';
import { TABS, overviewTab, INSIGHTS_TAB_LABEL, DATA_UPLOADER_TAB_LABEL } from './utils/tabUtils';
import { Error, Status, StrategyGoalAnalyticsMetadataType, StrategyGoalAnalyticsGoalType } from './types';
import { strategyContainer } from './styles';
import { SYSTEM_ERROR_STATUS_CODE } from './components/View/DataUploader/constants';

const PAGE_NAME = 'Strategy Analytics';

const Loading = ({ id }: { id: string }) => (
  <PageLoading pageName={PAGE_NAME}>{`Loading data for strategy [${id}]`}</PageLoading>
);

const DataProcessing = ({ id, name }: { id: string, name: string }) => (
  <PageLoading pageName={PAGE_NAME}>
    {`Processing data for strategy [${name} (${id})]`}
  </PageLoading>
);

const analyticsHeader = (
  <Menu.Item fitted>
    <Header style={header}>
      Strategy Analytics
    </Header>
  </Menu.Item>
);

type StrategyAnalyticsProps = RouterProps & {
  isDeleteModalOpen: boolean
  deleteStrategy: typeof deleteStrategy
  deletionFailed: boolean
  error: Error | null
  fetchStrategy: typeof fetchStrategy
  loading: boolean
  processingData: boolean
  shouldRenderPage: boolean
  resetProps: typeof resetProps
  status: Status
  strategy: Strategy
  toggleDeleteModal: typeof toggleDeleteModal
  user: User
  hasFlights: boolean
  env: EnvironmentVariables
  strategyGoal: StrategyGoalAnalyticsGoalType
};

export class StrategyAnalytics extends Component<StrategyAnalyticsProps> {
  static getTabs(strategy: Strategy, user: User, hasFlights: boolean, env: EnvironmentVariables, strategyGoal: StrategyGoalAnalyticsGoalType) {
    const strategyTypeId = _.get(strategy, 'strategyType.id');
    const strategyEquation = _.get(strategyGoal, 'equation');
    const includeDataUploader = _.includes(strategyEquation, 'externalCustomValue') && checkPermissions(user, Permission.cyodGoalType, strategy.member);
    const baseTabs = _.get(TABS.getById(strategyTypeId), 'tabs', [overviewTab]);
    const hasInsightsPermission = checkPermissions(user, Permission.insightsViz, strategy.member);
    const hasCustomRevenueType = stratHasRevenueType(strategy);
    const includeInsightsTab = env === EnvironmentVariables.demo
      ? hasInsightsPermission && !hasCustomRevenueType && hasFlights
      : hasInsightsPermission && hasFlights;
    const excludedTabLabels = [
      ...(includeDataUploader ? [] : [DATA_UPLOADER_TAB_LABEL]),
      ...(includeInsightsTab ? [] : [INSIGHTS_TAB_LABEL]),
    ];
    return _.filter(baseTabs, (t: { label: string }) => !_.includes(excludedTabLabels, t.label));
  }

  componentDidMount() {
    const strategyId = this.props.router.params.id;
    this.props.fetchStrategy(strategyId);
    WidgetWrapper('prefill', 'ticketForm', { custom_fields: { cf_copilot_strategy_id: strategyId } });
  }

  componentDidUpdate(prevProps: StrategyAnalyticsProps) {
    if (prevProps.router.params.id !== this.props.router.params.id) {
      this.props.fetchStrategy(this.props.router.params.id);
    }
  }

  componentWillUnmount() {
    this.props.resetProps();
    clearAllWidgetFieldsBesidesEmail(this.props.user.email);
  }

  render() {
    const {
      strategy,
      strategyGoal,
      loading,
      processingData,
      error,
      shouldRenderPage,
      status,
      isDeleteModalOpen,
      deletionFailed,
      user,
      hasFlights,
      env,
      router,
    } = this.props;

    const tabs = StrategyAnalytics.getTabs(strategy, user, hasFlights, env, strategyGoal) as Array<TabType>;

    const showStrategyError = () => (!loading && _.isEqual(_.get(error, 'status'), SYSTEM_ERROR_STATUS_CODE) ? (<UnknownError />)
      : (
        <Strategy403
          strategyId={router.params.id}
          user={user}
          loadingComponent={<Loading id={router.params.id} />}
        />
      )
    );

    return (
      loading || strategy.active
        ? (
          // @ts-ignore doesnt pass permission
          <PermissionPageTemplate
            title={PAGE_NAME}
            name={PAGE_NAME}
            customHeader={analyticsHeader}
            subNavElements={<TextLinkBack name="Back to Strategies" linkTo="/" />}
            // @ts-ignore status isnt a number
            unauthorized={status === 403}
            unauthorizedProps={{ message: `You are not authorized to see strategy ${router.params.id}.` }}
          >
            {loading && <Loading id={router.params.id} />}
            {error && <PageLoadError error={`Error while loading strategy (${router.params.id})`} pageName={PAGE_NAME} />}
            {processingData && <DataProcessing id={router.params.id} name={strategy.name} />}
            {
              shouldRenderPage
              && (
                <>
                  <Container fluid style={strategyContainer}>
                    <Metadata />
                    <TabView id={strategy.id} tabs={tabs} />
                    <DeleteConfirmationModal
                      open={isDeleteModalOpen}
                      // @ts-ignore object key mismatch
                      itemsToDelete={{ [strategy.id]: strategy }}
                      onCancel={this.props.toggleDeleteModal}
                      onDelete={(itemsToDelete) => this.props.deleteStrategy(itemsToDelete, router)}
                      footerMessage={deletionFailed ? `Strategy ${strategy.id} failed to delete.` : null}
                      modalName="Delete Strategy"
                      deleteMessage={'The flights attached to this strategy will be '
                        + 'deactivated in the DSP and stop buying immediately.'}
                    />
                  </Container>
                  <Outlet />
                </>
              )
            }
          </PermissionPageTemplate>
        )
        : showStrategyError()
    );
  }
}

export type State = {
  strategyAnalytics: {
    metadata: {
      isDeleteModalOpen: boolean
      deleting: boolean
      deletionFailed: boolean
      error: Error | null
      loading: boolean
      processingData: boolean
      status: Status
      strategy: Strategy
      flights: Array<Flight>
      currency: Currency
      strategyGoalAnalyticsMetadata: StrategyGoalAnalyticsMetadataType
    }
  },
  login: { session: { data: { user: { id: number } } }, env: EnvironmentVariables },
};

const mapStateToProps = (state: State) => ({
  strategy: state.strategyAnalytics.metadata.strategy,
  strategyGoal: state.strategyAnalytics.metadata.strategyGoalAnalyticsMetadata.goal,
  loading: state.strategyAnalytics.metadata.loading,
  processingData: state.strategyAnalytics.metadata.processingData,
  error: state.strategyAnalytics.metadata.error,
  status: state.strategyAnalytics.metadata.status,
  shouldRenderPage: !_.some([state.strategyAnalytics.metadata.loading,
    state.strategyAnalytics.metadata.processingData,
    state.strategyAnalytics.metadata.error,
  ]),
  isDeleteModalOpen: state.strategyAnalytics.metadata.isDeleteModalOpen,
  deleting: state.strategyAnalytics.metadata.deleting,
  deletionFailed: state.strategyAnalytics.metadata.deletionFailed,
  user: state.login.session.data.user,
  hasFlights: !!_.size(state.strategyAnalytics.metadata.flights),
  env: state.login.env,
});

export default connect(mapStateToProps, {
  fetchStrategy,
  resetProps,
  toggleDeleteModal,
  deleteStrategy,
})(withRouter(StrategyAnalytics));
