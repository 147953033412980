import React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Icon, Popup, Message, WppTableBodyCell, WppTableBodyRow, WppTypography, WppIconEdit, WppIconRefresh, WppActionButton, WppCheckbox } from 'buildingBlocks';
import DspIcon from 'components/DspIcon';
import { convertDatabaseDateToLongDate } from 'utils/formattingUtils';
import { assertUnreachable, FetchState, JsonDataType } from 'utils/types';
import { isValidDSP } from 'utils/functionHelpers';
import { COPILOT_COLORS } from 'globalStyles';
import { memberStyle } from '../style';

const { WPP } = COPILOT_COLORS;
const { setDetailsTextStyle, setFormFieldStyle } = memberStyle;

export type Seat = {
  id: number
  accountId: number
  externalId: string
  prefix: number
  displayName: string
  name: string
  dsp: {
    displayName: string
    code: string
    id: number
    createdAt: string
    updatedAt: string
  }
  country: {
    name: string
    isocode: string
    isonumber: number
    region: string
    id: number
    createdAt: string
    updatedAt: string
  }
  businessModel: {
    name: string
    id: number
    createdAt: string
    updatedAt: string
  }
  businessUnit: {
    name: string
    id: number
    createdAt: string
    updatedAt: string
  },
  feeOption: {
    name: string
    id: number
    createdAt: string
    updatedAt: string
  },
  feeType: {
    name: string
    id: number
    createdAt: string
    updatedAt: string
  },
  feeValue: number
  region: {
    name: string
    id: number
    createdAt: string
    updatedAt: string
  },
  apiUser: string
  apiPassword: string
  jsonObject: JsonDataType
  enabled: boolean
  createdAt: string
  updatedAt: string
};

export type SeatStatusStates =
  | { kind: FetchState.initial }
  | { kind: FetchState.loading }
  | { kind: FetchState.success }
  | { kind: FetchState.error, errors: Array<string> };

type Props = {
  seat: Seat
  editSeat: (x: Seat) => void
  checkSeatCredentials: (x: Seat) => void
  loading?: boolean
  negative?: boolean
  positive?: boolean
  renderStatusInfo?: () => JSX.Element
};

type SeatDetailsProps = {
  seat: Seat
  editSeat: (x: Seat) => void
  checkSeatCredentials: (x: Seat) => void
  seatConnectionStatus: SeatStatusStates
};

export const SeatDetailsBase = ({
  seat, editSeat, checkSeatCredentials, loading, negative, positive, renderStatusInfo,
}: Props) => {
  const dispatch = useDispatch();

  const handleEditSeat = (seatDetails: Seat) => {
    dispatch(editSeat(seatDetails));
  };

  const handleTestSeat = (seatDetails: Seat) => {
    dispatch(checkSeatCredentials(seatDetails));
  };

  let backgroundColor = '';

  switch (true) {
    case positive === true:
      backgroundColor = WPP.datavizPositive100;
      break;
    case negative === true:
      backgroundColor = WPP.danger200;
      break;
    default:
      backgroundColor = '';
  }

  return (
    <WppTableBodyRow style={{ backgroundColor }}>
      <WppTableBodyCell>
        <WppTypography type="s-body" tag="p">{seat.id}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell style={setDetailsTextStyle}>
        <WppTypography type="s-body" tag="p">{seat.externalId}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell style={setDetailsTextStyle}>
        <WppTypography type="s-body" tag="p">{seat.prefix}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell style={setDetailsTextStyle}>
        <WppTypography type="s-body" tag="p">{seat.name}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell>
        <DspIcon dspId={seat.dsp.id} />
      </WppTableBodyCell>
      <WppTableBodyCell style={setDetailsTextStyle}>
        <WppTypography type="s-body" tag="p">{seat.displayName}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell style={setDetailsTextStyle}>
        <WppTypography type="s-body" tag="p">{seat.country.name}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell style={setDetailsTextStyle}>
        <WppTypography type="s-body" tag="p">{seat.businessUnit.name}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell style={setDetailsTextStyle}>
        <WppTypography type="s-body" tag="p">{seat.region.name}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell style={setDetailsTextStyle}>
        {_.isFunction(renderStatusInfo) && renderStatusInfo()}
        <WppTypography type="s-body" tag="p">{seat.apiUser}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell style={setFormFieldStyle}>
        <WppCheckbox
          checked={!!seat.enabled}
          disabled
        />
      </WppTableBodyCell>
      <WppTableBodyCell style={setDetailsTextStyle}>
        <WppTypography type="s-body" tag="p">{convertDatabaseDateToLongDate(seat.createdAt)}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell style={setDetailsTextStyle}>
        <WppTypography type="s-body" tag="p">{convertDatabaseDateToLongDate(seat.updatedAt)}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell>
        <WppActionButton onClick={() => handleEditSeat(seat)} variant="secondary">
          <WppIconEdit /> Edit
        </WppActionButton>
      </WppTableBodyCell>
      <WppTableBodyCell>
        <WppActionButton
          onClick={() => handleTestSeat(seat)}
          disabled={!isValidDSP(seat.dsp.id)}
          loading={loading}
          variant="secondary"
        >
          <WppIconRefresh /> Test
        </WppActionButton>
      </WppTableBodyCell>
    </WppTableBodyRow>
  );
};

const ConnectionStatusInfo = ({ errors }: { errors: Array<string> }) => (
  <span>
    <Popup
      hoverable
      wide="very"
      trigger={<span><Icon name="info circle" /></span>}
      content={_.map(errors, (e) => <Message color="red">Error: {`${JSON.stringify(e)}`}</Message>)}
    />
  </span>
);

export const SeatDetails = ({ seatConnectionStatus, ...props }: SeatDetailsProps) => {
  switch (seatConnectionStatus.kind) {
    case FetchState.initial:
      return <SeatDetailsBase {...props} />;

    case FetchState.loading:
      return <SeatDetailsBase loading {...props} />;

    case FetchState.success:
      return <SeatDetailsBase positive {...props} />;

    case FetchState.error:
      return (
        <SeatDetailsBase
          negative
          renderStatusInfo={() => <ConnectionStatusInfo errors={seatConnectionStatus.errors} />}
          {...props}
        />
      );
    default:
      return assertUnreachable(seatConnectionStatus);
  }
};

export default SeatDetails;
