import {
  DOWNLOAD_CUSTOM_TREE_ANALYTICS,
  RESET_DATA,
} from './constants';

export const resetState = () => ({
  type: RESET_DATA,
});

type GetCustomTreeAnalyticsProps = {
  flightId: number,
  reportType: string,
  startDate: string,
  endDate: string,
  download: boolean
};

export const getCustomTreeAnalytics = ({
  flightId, reportType, startDate, endDate, download,
}: GetCustomTreeAnalyticsProps) => ({
  type: DOWNLOAD_CUSTOM_TREE_ANALYTICS.STARTED,
  payload: {
    flightId, reportType, startDate, endDate, download,
  },
});
