import React from 'react';
import { Grid, Message } from 'buildingBlocks';
import strategyWizard from 'containers/StrategyWizard/styles';
import { DoneButton } from 'components/FormButtons';

const { column } = strategyWizard;

type SubmitMessageProps = {
  content: string,
  icon: string,
  className: string,
  resetFields: Function,
};

export const SubmitMessage = ({
  content, icon, className, resetFields,
} : SubmitMessageProps) => (
  <>
    <Grid.Row>
      <Grid.Column width={10} style={column}>
        <Message
          className={className}
          icon={icon}
          content={content}
          style={{ textAlign: 'center' }}
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row style={{ textAlign: 'center' }}>
      <DoneButton resetFields={resetFields} name="filter-job-done" />
    </Grid.Row>
  </>

);

export default SubmitMessage;
