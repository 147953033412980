import _ from 'lodash';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import {
  ViewStrategyFlightRun, StrategyType, Algorithm, AlgorithmType, Strategy, Flight, Member, ViewStrategyFlightMember,
} from 'utils/copilotAPI';
import { OPERATOR, RESULTS_LIMIT } from 'constantsBase';
import {
  STRATEGY_FLIGHT_RUNS_FETCH,
  STRATEGY_FLIGHT_RUNS_FETCH_COMPLETED,
  STRATEGY_FLIGHT_RUNS_FETCH_FAILED,
  STRATEGY_FLIGHT_RUNS_FETCH_STRATEGY_TYPE,
  STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHM_TYPE,
  STRATEGY_FLIGHT_RUNS_FETCH_STRATEGIES,
  STRATEGY_FLIGHT_RUNS_FETCH_FLIGHTS,
  STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHMS,
  STRATEGY_FLIGHT_RUNS_FETCH_MEMBERS,
  STRATEGY_FLIGHT_RUNS_FILTER,
} from './constants';

import { DEFAULT_FILTER } from './reducer';

import {
  fetchStrategyTypeCompleted,
  fetchStrategyTypeFailed,
  fetchAlgorithmTypeCompleted,
  fetchAlgorithmTypeFailed,
  getStrategiesCompleted,
  getStrategiesFailed,
  getFlightsCompleted,
  getFlightsFailed,
  getAlgorithmsCompleted,
  getAlgorithmsFailed,
  getMembersCompleted,
  getMembersFailed,
} from './actions';

const MAX_ITEMS_TO_RENDER = 5;
const DEFAULT_DATA_RESPONSE = { data: [] };

export const buildQuery = ({ uiFilter, input }) => {
  const filter = { ...uiFilter };

  if (!_.isUndefined(input)) {
    filter.or = [
      { id: { [OPERATOR.START_WITH]: input } },
    ];
  }

  return {
    params: {
      filter,
    },
  };
};

const buildDateQueryFilter = (filter) => {
  const updatedAt = {
    [OPERATOR.GREATER_OR_EQUAL]: filter.startDate,
    [OPERATOR.LESS_OR_EQUAL]: filter.endDate,
  };
  const omitted = _.omit(filter, ['startDate', 'endDate']);
  return { ...omitted, updatedAt };
};

export function* getStrategyFlightRunsSaga({
  payload: {
    filter = {}, limit = RESULTS_LIMIT, skip = 0, sort = 'updatedAt DESC',
  },
}: AnyAction) {
  try {
    const populate = ['strategyType', 'algorithmType', 'member'];
    const where = buildDateQueryFilter(filter);

    const [strategyFlightRunsCount, strategyFlightRuns] = yield all([
      call(ViewStrategyFlightMember.count, { where }),
      call(ViewStrategyFlightRun.get, {
        limit, skip, where, populate, sort,
      }),
    ]);
    yield put({
      type: STRATEGY_FLIGHT_RUNS_FETCH_COMPLETED,
      payload: {
        strategyFlightRuns: strategyFlightRuns.data,
        strategyFlightRunsCount: strategyFlightRunsCount.data.count,
      },
    });
  } catch (error) {
    yield put({ type: STRATEGY_FLIGHT_RUNS_FETCH_FAILED, error });
  }
}

export function* getFilterObjects(model, field, condition) {
  const skip = 0;
  const sort = 'id ASC';
  const limit = MAX_ITEMS_TO_RENDER;
  let selected = DEFAULT_DATA_RESPONSE;
  let modelObjects = DEFAULT_DATA_RESPONSE;
  if (field.length > 0) {
    selected = yield call(model.get, {
      where: { id: field },
      limit: field.length,
      skip,
      sort,
    });
  }
  if (condition != null) {
    modelObjects = yield call(model.get, {
      where: { id: { '!': field }, or: condition },
      limit,
      skip,
      sort,
    });
  }
  return [selected, modelObjects];
}

export function* getStrategiesSaga({
  payload: {
    params: {
      filter = DEFAULT_FILTER,
    },
  },
}) {
  try {
    const [selected, strategies] = yield* getFilterObjects(Strategy, filter.strategy, filter.or);
    const payload = {
      selected: (_.isArray(selected.data) ? selected.data : [selected.data]), strategies: strategies.data,
    };
    yield put(getStrategiesCompleted(payload));
  } catch (error) {
    yield put(getStrategiesFailed('Failed to fetch strategies'));
  }
}

export function* getFlightsSaga({
  payload: {
    params: {
      filter = DEFAULT_FILTER,
    },
  },
}) {
  try {
    const [selected, flights] = yield* getFilterObjects(Flight, filter.flight, filter.or);
    const payload = {
      selected: (_.isArray(selected.data) ? selected.data : [selected.data]), flights: flights.data,
    };
    yield put(getFlightsCompleted(payload));
  } catch (error) {
    yield put(getFlightsFailed('Failed to fetch flights'));
  }
}

export function* getAlgorithmsSaga({
  payload: {
    params: {
      filter = DEFAULT_FILTER,
    },
  },
}) {
  try {
    const [selected, algorithms] = yield* getFilterObjects(Algorithm, filter.algorithm, filter.or);
    const payload = {
      selected: (_.isArray(selected.data) ? selected.data : [selected.data]), algorithms: algorithms.data,
    };
    yield put(getAlgorithmsCompleted(payload));
  } catch (error) {
    yield put(getAlgorithmsFailed('Failed to fetch algorithms'));
  }
}

export function* getMembersSaga() {
  try {
    const members = yield call(Member.get, {
      limit: RESULTS_LIMIT,
      skip: 0,
      sort: 'displayName ASC',
      populate: [],
    });
    yield put(getMembersCompleted(members.data));
  } catch (error) {
    yield put(getMembersFailed('Failed to fetch members'));
  }
}

export function* fetchStrategyType() {
  try {
    const strategyTypes = yield call(StrategyType.get, { limit: RESULTS_LIMIT });
    yield put(fetchStrategyTypeCompleted(strategyTypes.data));
  } catch (error) {
    yield put(fetchStrategyTypeFailed('Failed to fetch strategy type'));
  }
}

export function* fetchAlgorithmType() {
  try {
    const algoTypes = yield call(AlgorithmType.get, { limit: RESULTS_LIMIT });
    yield put(fetchAlgorithmTypeCompleted(algoTypes.data));
  } catch (error) {
    yield put(fetchAlgorithmTypeFailed('Failed to fetch algorithm type'));
  }
}

export function* buildStrategyQueryPayloadAndFetchData({ payload: { filter, input } }: AnyAction) {
  yield* getStrategiesSaga({ payload: buildQuery({ uiFilter: filter, input }) });
}

export function* buildFlightQueryPayloadAndFetchData({ payload: { filter, input } }: AnyAction) {
  yield* getFlightsSaga({ payload: buildQuery({ uiFilter: filter, input }) });
}

export function* buildAlgoQueryPayloadAndFetchData({ payload: { filter, input } }: AnyAction) {
  yield* getAlgorithmsSaga({ payload: buildQuery({ uiFilter: filter, input }) });
}

export function* strategyFlightRunsSagas() {
  yield all([
    takeLatest(STRATEGY_FLIGHT_RUNS_FETCH, getStrategyFlightRunsSaga),
    takeLatest(STRATEGY_FLIGHT_RUNS_FETCH_STRATEGY_TYPE.STARTED, fetchStrategyType),
    takeLatest(STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHM_TYPE.STARTED, fetchAlgorithmType),
    takeLatest(STRATEGY_FLIGHT_RUNS_FETCH_STRATEGIES.STARTED, buildStrategyQueryPayloadAndFetchData),
    takeLatest(STRATEGY_FLIGHT_RUNS_FETCH_FLIGHTS.STARTED, buildFlightQueryPayloadAndFetchData),
    takeLatest(STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHMS.STARTED, buildAlgoQueryPayloadAndFetchData),
    takeLatest(STRATEGY_FLIGHT_RUNS_FILTER.INPUT.STRATEGIES.SEARCH_UPDATED, buildStrategyQueryPayloadAndFetchData),
    takeLatest(STRATEGY_FLIGHT_RUNS_FILTER.INPUT.FLIGHTS.SEARCH_UPDATED, buildFlightQueryPayloadAndFetchData),
    takeLatest(STRATEGY_FLIGHT_RUNS_FILTER.INPUT.ALGORITHMS.SEARCH_UPDATED, buildAlgoQueryPayloadAndFetchData),
    takeLatest(STRATEGY_FLIGHT_RUNS_FILTER.UPDATED.ANY, getStrategyFlightRunsSaga),
    takeLatest(STRATEGY_FLIGHT_RUNS_FILTER.UPDATED.ANY, buildStrategyQueryPayloadAndFetchData),
    takeLatest(STRATEGY_FLIGHT_RUNS_FILTER.UPDATED.ANY, buildFlightQueryPayloadAndFetchData),
    takeLatest(STRATEGY_FLIGHT_RUNS_FILTER.UPDATED.ANY, buildAlgoQueryPayloadAndFetchData),
    takeLatest(STRATEGY_FLIGHT_RUNS_FETCH_MEMBERS.STARTED, getMembersSaga),
  ]);
}

export default strategyFlightRunsSagas;
