import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const strategyFlightRunsTable = {
  marginTop: '1%',
  borderRadius: '0',
};

export const tableHeader = {
  backgroundColor: '#fff',
};

export const cellPadding = {
  padding: SPACING[8],
};

export const timeZoneDropDownStyle = {
  paddingTop: '5px',
};

export const renderFlightRunsSectionStyle = {
  marginTop: SPACING[24],
};

export const filterDividerStyle = {
  marginBottom: SPACING[12],
};

export const filterActionBtnStyle = {
  display: 'flex',
  gap: SPACING[12],
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: SPACING[8],
};

export const flightWordBreakStyle = {
  wordBreak: 'break-all',
};

export const noFlightRunContainer: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: SPACING[24],
  marginTop: SPACING[32],
};
