import _ from 'lodash';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { WppButton, WppModal, WppSpinner } from 'buildingBlocks';
import { truncateText } from 'charts/InsightsViz/utils';
import { getStrategyList, selectNone } from 'containers/StrategiesList/actions';
import { EditStrategiesFormType, StrategiesListFilterType } from 'containers/StrategiesList/constants';
import { selectedStrategiesPanel } from 'containers/StrategiesList/style';
import { saveEditedStrategies } from 'containers/StrategiesList/utils';
import { GlobalState } from 'reducers';
import { pluralizer } from 'utils/formattingUtils';
import { EnhancedStrategy, ToastConfig } from 'utils/types';

const { modalBody, strategyModalList, modalButtonContainer } = selectedStrategiesPanel;

type EditConfirmationModalProps = {
  confirmModalOpen: boolean
  setConfirmModalOpen: (x: boolean) => void
  setEditModalOpen: (x: boolean) => void
  defaultValues: EditStrategiesFormType
  showToast: (config: ToastConfig) => void
};

const EditConfirmationModal = (props: EditConfirmationModalProps) => {
  const { confirmModalOpen, setConfirmModalOpen, setEditModalOpen, defaultValues, showToast } = props;
  const [updating, setUpdating] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { selectedStrategies, filter, limit, skip } = useSelector<GlobalState>((state) => state.strategiesList) as { selectedStrategies: Array<EnhancedStrategy>, filter: StrategiesListFilterType, limit: number, skip: number };

  const selectedStrategiesCount = _.size(selectedStrategies);
  const strategyText = pluralizer('strategy', 'strategies')(selectedStrategiesCount);

  const { control } = useFormContext();
  const formValues = useWatch({ control }) as EditStrategiesFormType;

  const afterUpdate = () => {
    // close modals and re-fetch strategies
    setConfirmModalOpen(false);
    setEditModalOpen(false);
    dispatch(getStrategyList(filter, limit, skip));
    dispatch(selectNone());
  };

  return (
    <WppModal className="edit-confirmation-modal" open={confirmModalOpen}>
      <h3 slot="header">Updating {_.capitalize(strategyText)}</h3>
      <div slot="body" className="bulkModalBody" style={modalBody}>
        <p>Are you sure you wish to proceed? This action cannot be undone.</p>
        <p>The following {strategyText} will be updated:</p>
        <ul style={strategyModalList}>
          {_.map(selectedStrategies, (s) => <li key={s.id}>{truncateText(s.name, 90)}</li>)}
        </ul>
      </div>
      <div slot="actions" style={modalButtonContainer}>
        <WppButton variant="secondary" size="s" disabled={updating} onClick={() => setConfirmModalOpen(false)}>Close</WppButton>
        <WppButton
          variant="primary"
          size="s"
          onClick={() => saveEditedStrategies(selectedStrategies, formValues, defaultValues, setUpdating, afterUpdate, showToast)}
          disabled={updating}
        >
          {updating ? <WppSpinner color="white" /> : 'Update'}
        </WppButton>
      </div>
    </WppModal>
  );
};

export default EditConfirmationModal;
