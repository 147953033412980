import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ImageFile } from 'react-dropzone';
import { WppFileUpload, WppInlineMessage, WppTypography } from 'buildingBlocks';
import { dataUploader } from 'containers/StrategyAnalytics/components/View/DataUploader/style';
import { FileStates as FileStatesEnum, FileStates, JSON_FILE_NAME, JsonErrTypes, incorrectFile, incorrectFileName, SUBHEADER_TEXT } from 'containers/StrategyAnalytics/components/View/DataUploader/constants';
import { JsonDataType } from 'utils/types';

const { uploaderSection, grid, fileUploaderStyle } = dataUploader;

type FileUploadProps = {
  id?: number
};

const FileUploadZone = ({ id: memberId }: FileUploadProps) => {
  const { setValue, formState: { errors }, trigger, watch, unregister } = useFormContext();
  const [reader, setReader] = useState<FileReader>(new FileReader());
  const [fileUploadState, setFileUploadState] = useState<FileStatesEnum>(FileStatesEnum.initial);
  const [jsonData, setJsonData] = useState<JsonDataType>(null);
  const [fileName, setFileName] = useState<string>('');
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const jsonObjErr = _.get(errors, 'jsonObject', null);
  const jsonFormObj = watch('jsonObject');
  const disableDrop = _.isEqual(FileStatesEnum.failed, fileUploadState) || (_.isEmpty(jsonFormObj) && !!jsonData);

  useEffect(() => {
    if (memberId) {
      unregister('jsonObject');
    }
  }, [unregister, memberId]);

  useEffect(() => {
    if (!reader) {
      setReader(new FileReader());
    }

    // Cleanup function
    return () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reader]);

  useEffect(() => {
    if (_.includes(jsonObjErr, JsonErrTypes.invalid)) {
      setFileUploadState(FileStates.failed);
    }
  }, [jsonObjErr]);

  const updateJsonDetails = (jsonObj: JsonDataType, newFileName: string, newFileUrl: string) => {
    setJsonData(jsonObj);
    setFileName(newFileName);
    setFileUrl(newFileUrl);
  };

  const updateJsonObjectState = (jsonString: JsonDataType) => {
    setValue('jsonObject', jsonString);
    trigger('jsonObject');
    setFileUploadState(FileStates.success);
  };

  const resetFileState = () => {
    setJsonData(null);
    setFileUploadState(FileStatesEnum.initial);
    setFileName('');
    setValue('jsonObject', '');
    trigger('jsonObject');
  };

  const uploadClickHandle = () => {
    setFileUploadState(FileStates.uploading);
    if (fileName && !_.isEqual(fileName, JSON_FILE_NAME)) {
      setFileUploadState(FileStates.failed);
    } else {
      setValue('jsonObject', jsonData);
      setFileUploadState(FileStates.success);
      updateJsonObjectState(jsonData);
    }
  };

  const onDrop = (accepted: Array<ImageFile>) => {
    setFileUploadState(FileStatesEnum.initial);
    const file = _.first(accepted);
    if (fileUrl) URL.revokeObjectURL(fileUrl);
    if (file) {
      reader.onload = () => {
        setValue('jsonObject', '');
        trigger('jsonObject');
        const jsonObj = reader.result;
        updateJsonDetails(jsonObj, _.get(file, 'name'), URL.createObjectURL(file));
      };
      reader.readAsText(file);
    }
  };

  const handleFileUploadChange = (event: CustomEvent) => {
    const fileUploadValue = event.detail.value;
    onDrop(fileUploadValue);
  };

  useEffect(() => {
    if (fileName && jsonData) {
      uploadClickHandle();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName, jsonData]);

  const errType = _.get(jsonObjErr, 'type');
  const fileUploadSuccess = !_.isEmpty(jsonData) && !_.size(jsonObjErr) && _.isEqual(JSON_FILE_NAME, fileName);
  const nonSuccessState = fileUploadState in SUBHEADER_TEXT;

  return (
    <div style={{ ...uploaderSection, ...grid }}>
      <WppTypography type="s-strong">JSON File</WppTypography>
      <WppFileUpload
        onWppChange={handleFileUploadChange}
        disabled={disableDrop}
        style={fileUploaderStyle}
        onWppFileUploadItemDelete={resetFileState}
        acceptConfig={{ 'application/json': ['.json'] }}
        maxFiles={1}
        locales={{
          label: 'Choose a file',
          text: 'to upload or drag it here',
          info: () => 'File name must be “sa.json”',
          sizeError: 'File exceeds size limit',
          formatError: 'File format is not .json. Please resolve the issue and try uploading again.',
        }}
      />
      {!_.isEmpty(jsonData) && nonSuccessState && (
        <WppInlineMessage
          size="s"
          message={_.isEqual(errType, JsonErrTypes.required) ? incorrectFileName : incorrectFile}
          type="error"
          showTooltipFrom={100}
        />
      )}
      <WppInlineMessage
        size="s"
        message={fileUploadSuccess ? 'File Successfully Uploaded. This file will be used to create new members in AWS bucket.' : 'No file uploaded yet'}
        type={fileUploadSuccess ? 'success' : 'information'}
        showTooltipFrom={100}
      />
    </div>
  );
};

export default FileUploadZone;
