import React from 'react';
import { Link } from 'react-router-dom';
import { WppDivider, WppTypography, WppTag } from 'buildingBlocks';
import StratTypeBadge from 'components/StratTypeBadge';
import DspIcon from 'components/DspIcon';
import { strategyTableColumns } from 'containers/StrategiesList/style';

const {
  brandAdvMemberContainer,
  brandNameContainer,
  strategyTypeDSPContainer,
  strategyDetail,
  strategiesContainer,
  advertiserContainer,
  testLabel,
} = strategyTableColumns;

type StratInfoProps = {
  name: string
  dspId: number
  status: string
  lastModifiedTime: string
  type: string
  member: string
  advertiser: string
  brand: string
  stratId: number
  clientTest: boolean
};

const StratInfo: React.FC<StratInfoProps> = ({
  name,
  status,
  dspId,
  lastModifiedTime,
  type,
  member,
  advertiser,
  brand,
  stratId,
  clientTest,
}: StratInfoProps): React.ReactElement => (
  <div style={strategiesContainer}>
    <WppTypography tag="span" type="s-midi">
      <Link className="strat-info-name" to={`/strategies/${stratId}`} style={{ color: 'var(--wpp-grey-color-1000)' }}>
        {name}
      </Link>
    </WppTypography>
    <div style={strategyDetail}>
      <div style={brandAdvMemberContainer}>
        {brand && (
          <WppTypography tag="span" style={brandNameContainer} type="xs-body">
            {brand}
          </WppTypography>
        )}
        {advertiser && (
          <WppTypography tag="span" style={advertiserContainer} type="xs-body">
            {advertiser}
          </WppTypography>
        )}
        {member && (
          <WppTypography tag="span" type="xs-body">
            {member}
          </WppTypography>
        )}
      </div>
      <WppDivider alignment="vertical" />
      <WppTypography tag="span" type="xs-body">
        Last Modified:&nbsp;
        {lastModifiedTime}
      </WppTypography>
    </div>
    <div style={strategyTypeDSPContainer}>
      <StratTypeBadge stratType={type} />
      <DspIcon dspId={dspId} status={status} />
      {clientTest && (
        <WppTag
          label="Test"
          variant="warning"
          className="tag"
          style={testLabel}
        />
      )}
    </div>
  </div>
);

export default StratInfo;
