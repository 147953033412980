const PREFIX = 'CUSTOM_TREE_ANALYTICS:';

export const DOWNLOAD_CUSTOM_TREE_ANALYTICS = {
  STARTED: `${PREFIX}DOWNLOAD_CUSTOM_TREE_ANALYTICS`,
  COMPLETED: `${PREFIX}DOWNLOAD_CUSTOM_TREE_ANALYTICS_COMPLETED`,
  FAILED: `${PREFIX}DOWNLOAD_CUSTOM_TREE_ANALYTICS_FAILED`,
  NO_DATA: `${PREFIX}DOWNLOAD_CUSTOM_TREE_ANALYTICS_NO_DATA`,
};

export const RESET_DATA = `${PREFIX}RESET_DATA`;

export const REPORT_TYPES = {
  DAILY: 'daily',
};
