import _ from 'lodash';
import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import moment from 'moment';

import { StratCustomTreeLeafAnalytics } from 'utils/copilotAPI';
import { convertArrayOfObjectsToStringCSV, exportFile } from 'utils/exportUtils';
import { dateTimeFormatter } from 'utils/dateTime';
import { replaceAll } from 'utils/functionHelpers';
import {
  DOWNLOAD_CUSTOM_TREE_ANALYTICS,
} from './constants';

const getHeaders = (data) => (_.isEmpty(data) ? [] : _.map(_.keys(data[0]), _.camelCase));

const formatAnalyticsCell = (data) => _.map(
  data,
  (row) => _.map(row, (cell) => (_.isNil(cell) ? '' : replaceAll(cell.toString(), '"', '""'))),
);

const createCSVAndDownload = (headers, analytics, filename) => {
  const csv = _.isEmpty(analytics) ? '' : convertArrayOfObjectsToStringCSV(analytics, headers);
  const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  exportFile(filename, csvBlob);
};

const strategySelector = (state) => state.strategyAnalytics.metadata.strategy;

export function* fetchCustomTreeLeafAnalytics(action) {
  const {
    flightId,
    reportType,
    startDate,
    endDate,
    download,
  } = action.payload;
  // add 1 day to get full 24-hour report
  const newEndDate = moment(endDate).add(1, 'days');
  try {
    const { id: strategyId, name: stratName } = yield select(strategySelector);
    const { data } = yield call(
      StratCustomTreeLeafAnalytics.getCustomTreeLeafAnalytics,
      {
        strategyId, flightId, reportType, startDate, endDate: dateTimeFormatter.isoDate(newEndDate),
      },
    );
    const headers = getHeaders(data);
    const analytics = formatAnalyticsCell(data);
    if (!_.isEmpty(analytics)) {
      if (download) {
        const appendendDateRange = startDate !== endDate ? `-thru-${dateTimeFormatter.isoDate(endDate)}` : '';
        const filename = `${dateTimeFormatter.isoDate(startDate)}${appendendDateRange}`
          + `-${reportType}-Analytics-${stratName}.csv`;
        createCSVAndDownload(headers, analytics, filename);
      }
      return yield put({ type: DOWNLOAD_CUSTOM_TREE_ANALYTICS.COMPLETED, payload: { headers, analytics, reportType } });
    }
    return yield put({ type: DOWNLOAD_CUSTOM_TREE_ANALYTICS.NO_DATA });
  } catch (error) {
    return yield put({ type: DOWNLOAD_CUSTOM_TREE_ANALYTICS.FAILED });
  }
}

export function* customTreeAnalyticsSagas() {
  yield all([
    takeEvery(DOWNLOAD_CUSTOM_TREE_ANALYTICS.STARTED, fetchCustomTreeLeafAnalytics),
  ]);
}

export default customTreeAnalyticsSagas;
