import moment from 'moment';
import React, { ReactNode } from 'react';
import { ELEMENT_ID } from 'cssTagConstants';
import { User } from 'utils/types';
import { dateTimeFormatter } from 'utils/dateTime';

export const DropdownItemWrapper = (params: { children: Array<ReactNode> }) => (
  <div className={ELEMENT_ID.navBar.searchDropdownItem}>
    {params.children}
  </div>
);

export const formatResult = (result: any, user: User) => ({
  title: result.strategyName,
  key: result.strategyId,
  stratId: result.strategyId,
  name: result.strategyName,
  brand: result.brandName,
  member: result.memberName,
  advertiser: result.advertiserName,
  dsp: result.dsp,
  stratType: result.displayName,
  lastModified: dateTimeFormatter.date(moment.utc(result.updatedAt), false),
  as: DropdownItemWrapper,
  noResultsFound: false,
  user,
});

export const generateNoResultsObj = () => ({ key: 0, as: DropdownItemWrapper, noResultsFound: true });
