import { CSSProperties } from 'react';
import { COPILOT_LAYOUT, COPILOT_COLORS } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;
const { WPP } = COPILOT_COLORS;

const style: { [key: string]: CSSProperties } = {
  tableHeaderStyle: {
    color: '#5b6e86',
  },
  marginTop: {
    marginTop: '8px',
  },
  tableStyle: {
    backgroundColor: '#f3ebeb',
  },
  td: {
    position: 'relative',
  },
  dropDown: {
    boxShadow: 'none',
    border: 'solid #ddd 1px',
  },
  pending: {
    color: '#ccc',
    background: '#EFEFEF',
  },
  filterContainer: {
    marginBottom: SPACING[16],
    marginTop: SPACING[2],
  },
  filerComponent: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'end',
  },
  textAlignRight: {
    textAlign: 'right',
    flex: 1,
    margin: 'auto',
  },
  autoWidth: {
    width: 'auto',
  },
  strategyCellStyle: {
    display: 'flex',
    flexDirection: 'row',
    gap: SPACING[12],
  },
  cellFullHeight: {
    height: '100%',
    display: 'flex',
  },
};

export const snackBar: CSSProperties = {
  position: 'fixed',
  bottom: '0',
  zIndex: 10,
  textAlign: 'center',
  width: '100%',
  boxShadow: '0px 0px 10px 0px',
  padding: SPACING[12],
};

export const sortDropdownContainer: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: SPACING[12],
  minHeight: '34px',
  flexWrap: 'wrap',
};

export const pageHeaderContainer: CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
};

export const strategyTableColumns: { [key: string]: CSSProperties } = {
  strategyTypeDSPContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: SPACING[12],
  },
  brandNameContainer: {
    color: WPP.primary400,
  },
  brandAdvMemberContainer: {
    display: 'flex',
    gap: SPACING[16],
    alignItems: 'center',
  },
  strategiesContainer: {
    lineHeight: '30px',
    padding: '8px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[8],
  },
  strategyDetail: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '24px',
    flexWrap: 'wrap',
    gap: SPACING[16],
  },
  advertiserContainer: {
    color: WPP.success500,
  },
  legendBadgeContainer: {
    display: 'flex',
    gap: SPACING[8],
    height: '100%',
    flexWrap: 'wrap',
    padding: '8px 0px',
  },
  sparklineContainer: {
    padding: '8px 0',
    display: 'flex',
    alignItems: 'center',
  },
  testLabel: {
    height: SPACING[20],
  },
  selectCheckbox: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 'normal',
  },
};

export const gridPagination: CSSProperties = {
  marginTop: SPACING[16],
};

export const segmentedControlItem: CSSProperties = {
  width: '80px',
  height: '24px',
};

export const strategyHeaderCell = {
  paddingLeft: '50px',
};

export const secondaryFilterStyles = {
  display: 'flex',
  justifyContent: 'right',
  gap: '50px',
};

export const dropDownColWidth = {
  width: '25%',
};

export const selectedStrategiesPanel: { [key: string]: CSSProperties } = {
  panelContainer: {
    display: 'flex',
    gap: SPACING[12],
    minWidth: 394,
    justifyContent: 'flex-end',
  },
  countCloseIcon: {
    color: WPP.grey600,
    marginLeft: SPACING[2],
  },
  formStyle: {
    marginBottom: SPACING[24],
  },
  modalBody: {
    maxHeight: 210,
    overflowY: 'scroll',
    position: 'relative',
  },
  strategyModalList: {
    marginLeft: SPACING[16],
    marginRight: SPACING[4],
    fontWeight: 600,
    wordBreak: 'break-all',
  },
  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: SPACING[12],
  },
  editModalBanner: {
    wordBreak: 'break-word',
  },
  editStrategiesListContainer: {
    padding: '12px 16px',
    borderRadius: 4,
    backgroundColor: WPP.grey100,
    marginTop: SPACING[20],
  },
  strategiestListBanner: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: SPACING[8],
  },
  strategiesListRow: {
    display: 'flex',
    alignItems: 'center',
    width: 664,
    padding: '4px 8px',
    gap: SPACING[8],
  },
  listRowStratName: {
    width: 550,
  },
  listDivider: {
    margin: '8px 0px',
  },
  labelStyle: {
    fontSize: 14,
    fontWeight: 600,
    color: WPP.grey800,
    marginTop: SPACING[24],
    marginBottom: SPACING[4],
  },
  moneyIcon: {
    cursor: 'default',
  },
  pacingLabelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  amznSettingsWarning: {
    display: 'flex',
    marginTop: SPACING[24],
    lineHeight: '20px',
    fontSize: 13,
    backgroundColor: WPP.warning200,
    borderRadius: 6,
    alignItems: 'center',
  },
  multiStratToggleContainer: {
    display: 'flex',
    gap: SPACING[8],
    alignItems: 'center',
  },
};

export default style;
