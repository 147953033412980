import { SUCCESSFULLY_GET_PERMISSIONS_DATA, FETCH_PERMISSIONS_ACTION_FAILED } from '../constants/ActionTypes';

const INITIAL_STATE = [];

const permissionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUCCESSFULLY_GET_PERMISSIONS_DATA:
      return action.payload;
    case FETCH_PERMISSIONS_ACTION_FAILED:
      return action.payload;
    default:
      return state;
  }
};

export default permissionsReducer;
