import { Flight, Strategy, $Values } from 'utils/types';
import { LeafAnalytics } from 'containers/StrategyAnalytics/types';
import {
  FETCH_HELIOS_ANALYTICS,
  DOWNLOAD_HELIOS_ANALYTICS,
  RESET_DATA,
  REPORT_TYPES,
} from './constants';

export const resetState = () => ({
  type: RESET_DATA,
});

export const fetchHeliosAnalytics = (
  { strategy, flight, reportType }: { strategy: Strategy, flight: Flight, reportType: $Values<typeof REPORT_TYPES> },
) => ({
  type: FETCH_HELIOS_ANALYTICS.STARTED,
  payload: { strategy, flight, reportType },
});

export const fetchHeliosAnalyticsCompleted = (headers: Array<string>, analytics: Array<Array<string>>, data: LeafAnalytics) => ({
  type: FETCH_HELIOS_ANALYTICS.COMPLETED,
  payload: { headers, analytics, data },
});

export const downloadHeliosAnalytics = (
  { strategy, flight, reportType }: { strategy: Strategy, flight: Flight, reportType: string },
) => ({
  type: DOWNLOAD_HELIOS_ANALYTICS.STARTED,
  payload: { strategy, flight, reportType },
});
