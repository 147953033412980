import { COPILOT_LAYOUT } from 'globalStyles';

export const strategyFlightRunsTable = {
  marginTop: '1%',
  borderRadius: '0',
};

export const tableHeader = {
  backgroundColor: '#fff',
};

export const cellPadding = {
  // This removes the unintended ellipsis
  padding: `${COPILOT_LAYOUT.SPACING[8]}px`,
};
