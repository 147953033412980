import {
  FETCH_ROLES_COMPLETED,
  FETCH_ROLES_FAILED,
  FETCH_ROLE_COMPLETED,
  SAVE_ROLE_FAILED,
  FETCH_PERMISSIONS_COMPLETED,
  FETCH_PERMISSIONS_FAILED,
  RESET_ALL,
  RESET_PERMISSIONS,
  TYPE_GLOBAL,
} from './constants';

const DEFAULT_STATE = {
  role: {
    name: '',
    description: '',
    permissions: [],
    type: TYPE_GLOBAL,
  },
  saveError: null,
  fetchPermissionsError: null,
};

const INITIAL_STATE = {
  roles: [],
  permissions: [],
  loading: true,
  ...DEFAULT_STATE,
};

export default function roles(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ROLES_COMPLETED:
      return {
        ...state,
        roles: action.payload.roles,
        loading: false,
      };
    case FETCH_PERMISSIONS_COMPLETED:
      return {
        ...state,
        permissions: action.payload.permissions,
      };
    case FETCH_ROLE_COMPLETED:
      return {
        ...state,
        role: action.payload,
      };
    case RESET_PERMISSIONS:
      return {
        ...state,
        role: {
          permissions: [],
          type: action.payload.type,
        },
      };
    case RESET_ALL:
      return {
        ...state,
        ...DEFAULT_STATE,
      };
    case FETCH_ROLES_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case FETCH_PERMISSIONS_FAILED:
      return {
        ...state,
        fetchPermissionsError: true,
      };
    case SAVE_ROLE_FAILED:
      return {
        ...state,
        saveError: true,
      };
    default:
      return state;
  }
}
