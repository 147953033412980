// @ts-nocheck redux-form & ModalMultiSelect props issues
import _ from 'lodash';
import React from 'react';
import { Field } from 'redux-form';
import { Flight } from 'utils/types';
import { DataProviderInterface, ModalMultiSelectProps } from 'buildingBlocks/TwoListMultiSelect/TwoListMultiSelect';

import { ArrayDataProvider } from 'buildingBlocks/TwoListMultiSelect/DataProvider';
import { Form, ModalMultiSelect, FieldProps } from 'buildingBlocks';
import { pluralizer } from 'utils/formattingUtils';

const flightOptionLabel = (flight) => `[${flight.externalId}] - ${flight.name}`;
const createFlightOption = (flight) => ({ text: flightOptionLabel(flight), value: flight.id });

type FlightSelectProps = {
  flights: Array<Flight>,
};

function flightOptionMapper(flightId: string, flightLookup: Partial<Flight>) {
  const flight = _.get(flightLookup, flightId);
  if (flight) {
    return createFlightOption(flight);
  }
  return {};
}

type Option = { text: string, value: string };

export default ({ flights }: FlightSelectProps) => {
  const flightOptions = _.map(flights, createFlightOption);
  const flightLookup = _.keyBy(flights, 'id');
  const dataProvider: DataProviderInterface = new ArrayDataProvider(flightOptions);

  return (
    <Form.Field>
      { /*
      // @ts-ignore ModalMultiSelect refactor */}
      <Field<FieldProps<ModalMultiSelectProps>>
        component={ModalMultiSelect}
        name="flights"
        label="Flights"
        dataProvider={dataProvider}
        keyFn={(flight: Option) => flight.value}
        displayFn={(flight: Option) => flight.text}
        sortFn={(flight: Option) => flight.text}
        outMapper={(flight: Option) => flight.value}
        // @ts-ignore ModalMultiSelect refactor
        inMapper={(flightId: string) => flightOptionMapper(flightId, flightLookup)}
        pluralize={pluralizer('Flight', 'Flights')}
      />
    </Form.Field>
  );
};
