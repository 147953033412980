import _ from 'lodash';
import moment from 'moment';
import { dateTimeFormatter } from 'utils/dateTime';
import {
  STRATEGY_FLIGHT_RUNS_FETCH_COMPLETED,
  STRATEGY_FLIGHT_RUNS_FETCH_FAILED,
  STRATEGY_FLIGHT_RUNS_FILTER,
  STRATEGY_FLIGHT_RUNS_FETCH_STRATEGY_TYPE,
  STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHM_TYPE,
  STRATEGY_FLIGHT_RUNS_FETCH_STRATEGIES,
  STRATEGY_FLIGHT_RUNS_FETCH_FLIGHTS,
  STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHMS, STRATEGY_FLIGHT_RUNS_FETCH_MEMBERS,
} from './constants';
import { Filter } from './types';

export const DEFAULT_FILTER: Filter = {
  strategyType: [],
  status: [],
  strategy: [],
  flight: [],
  algorithm: [],
  member: [],
  algorithmType: [],
  triggerType: [],
  startDate: dateTimeFormatter.isoDateTime(moment().subtract(7, 'days').startOf('day')),
  endDate: dateTimeFormatter.isoDateTime(moment().endOf('day')),
};

export const INITIAL_STATE = {
  strategyFlightRuns: [],
  strategyFlightRunsCount: 0,
  strategies: [],
  flights: [],
  algorithms: [],
  members: [],
  strategyTypes: [],
  algorithmTypes: [],

  selected: { ...DEFAULT_FILTER },
  filter: { ...DEFAULT_FILTER },
  loading: true,
  strategyFlightRunFetchError: null,
};

const getFilterUpdatedState = (state, action) => ({
  ...state,
  filter: action.payload.filter,
  selected: {
    ...state.selected,
    strategyType: _.filter(state.strategyTypes, (s) => _.includes(action.payload.filter.strategyType, s.id)),
    algorithmType: _.filter(state.algorithmTypes, (s) => _.includes(action.payload.filter.algorithmType, s.id)),
    strategy: _.filter(state.strategies, (s) => _.includes(action.payload.filter.strategy, s.id)),
    flight: _.filter(state.flights, (s) => _.includes(action.payload.filter.flight, s.id)),
    algorithm: _.filter(state.algorithms, (s) => _.includes(action.payload.filter.algorithm, s.id)),
    member: _.filter(state.members, (m) => _.includes(action.payload.filter.member, m.id)),
    status: _.filter(STRATEGY_FLIGHT_RUNS_FILTER.STATUS.options, (s) => (
      _.includes(action.payload.filter.status, s.value)
    )),
    triggerType: _.filter(STRATEGY_FLIGHT_RUNS_FILTER.TRIGGER_TYPE.options, (s) => (
      _.includes(action.payload.filter.triggerType, s.value)
    )),
  },
  loading: true,
});

export default function strategyFlightRuns(state = INITIAL_STATE, action) {
  switch (action.type) {
    case STRATEGY_FLIGHT_RUNS_FETCH_COMPLETED:
      return {
        ...state,
        strategyFlightRuns: action.payload.strategyFlightRuns,
        strategyFlightRunsCount: action.payload.strategyFlightRunsCount,
        loading: false,
      };
    case STRATEGY_FLIGHT_RUNS_FETCH_FAILED:
      return { ...state, loading: false, strategyFlightRunFetchError: action.error };
    case STRATEGY_FLIGHT_RUNS_FETCH_STRATEGY_TYPE.COMPLETED:
      return {
        ...state,
        strategyTypes: action.payload,
        selected: {
          ...state.selected,
          strategyType: _.filter(action.payload, (s) => _.includes(state.filter.strategyType, s.id)),
        },
        loadingStrategyType: false,
      };
    case STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHM_TYPE.COMPLETED:
      return {
        ...state,
        algorithmTypes: _.filter(action.payload, 'active'),
        selected: {
          ...state.selected,
          algorithmType: _.filter(action.payload, (s) => _.includes(state.filter.algorithmType, s.id)),
        },
        loadingAlgorithmType: false,
      };
    case STRATEGY_FLIGHT_RUNS_FILTER.INPUT.STRATEGIES.SEARCH_UPDATED:
      return { ...state, strategySearch: action.payload.input };
    case STRATEGY_FLIGHT_RUNS_FILTER.INPUT.FLIGHTS.SEARCH_UPDATED:
      return { ...state, flightSearch: action.payload.input };
    case STRATEGY_FLIGHT_RUNS_FILTER.INPUT.ALGORITHMS.SEARCH_UPDATED:
      return { ...state, algorithmSearch: action.payload.input };
    case STRATEGY_FLIGHT_RUNS_FILTER.INPUT.MEMBERS.SEARCH_UPDATED:
      return { ...state, memberSearch: action.payload.input };
    case STRATEGY_FLIGHT_RUNS_FETCH_STRATEGIES.STARTED:
    case STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHMS.STARTED:
    case STRATEGY_FLIGHT_RUNS_FETCH_MEMBERS.STARTED:
    case STRATEGY_FLIGHT_RUNS_FETCH_STRATEGIES.FAILED:
    case STRATEGY_FLIGHT_RUNS_FETCH_FLIGHTS.FAILED:
    case STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHMS.FAILED:
    case STRATEGY_FLIGHT_RUNS_FETCH_MEMBERS.FAILED:
      return { ...state };
    case STRATEGY_FLIGHT_RUNS_FETCH_STRATEGIES.COMPLETED:
      return {
        ...state,
        strategies: action.payload.strategies,
        selected: {
          ...state.selected,
          strategy: action.payload.selected,
        },
      };
    case STRATEGY_FLIGHT_RUNS_FETCH_FLIGHTS.STARTED:
      return { ...state, loading: true };
    case STRATEGY_FLIGHT_RUNS_FETCH_FLIGHTS.COMPLETED:
      return {
        ...state,
        flights: action.payload.flights,
        selected: {
          ...state.selected,
          flight: action.payload.selected,
        },
      };
    case STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHMS.COMPLETED:
      return {
        ...state,
        algorithms: action.payload.algorithms,
        selected: {
          ...state.selected,
          algorithm: action.payload.selected,
        },
      };
    case STRATEGY_FLIGHT_RUNS_FETCH_MEMBERS.COMPLETED:
      return {
        ...state,
        members: action.payload,
        selected: {
          ...state.selected,
          member: _.filter(action.payload, (m) => _.includes(state.filter.member, m.id)),
        },
      };
    case STRATEGY_FLIGHT_RUNS_FILTER.UPDATED.ANY:
      return getFilterUpdatedState(state, action);
    default:
      return state;
  }
}
