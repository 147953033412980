import { COPILOT_LAYOUT } from 'globalStyles';
import { CSSProperties } from 'react';

const { SPACING } = COPILOT_LAYOUT;

export const hamburgerWrapper: CSSProperties = { textAlign: 'center', overflowY: 'visible' };

export const noResultsWrapper: CSSProperties = { display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '24px 0px' };

export const noResultsHeading: CSSProperties = { textAlign: 'center', display: 'block', margin: '24px 0px 8px 0px' };

export const noResultsList: CSSProperties = { paddingLeft: SPACING[24], marginTop: SPACING[2] };

export const noResultsContainer: CSSProperties = {
  width: '400px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};

export const strategyFields: { [key: string]: CSSProperties } = {
  strategyTypeDSPContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: SPACING[12],
  },
  captionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[8],
  },
  brandNameContainer: {
    color: 'var(--wpp-primary-color-400)',
    marginRight: SPACING[16],
  },
  brandAdvMemberContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  advertiserContainer: {
    color: 'var(--wpp-success-color-500)',
    marginRight: SPACING[16],
  },
  divider: {
    marginRight: SPACING[16],
    marginLeft: SPACING[16],
  },
  searchDivider: {
    padding: '0px 8px 0px 8px',
  },
};

export const searchStyle: { [key: string]: CSSProperties } = {
  searchInput: {
    width: '240px',
  },
  searchContainer: {
    width: '600px',
    overflowY: 'auto',
    maxHeight: '540px',
    padding: SPACING[4],
  },
};
