import _ from 'lodash';
import moment from 'moment';
import { AnyAction } from 'redux';
import { call, all, put, select, takeLatest } from 'redux-saga/effects';
import {
  apiEnums, OPERATOR, PAGE_CHANGE_ACTION, RESULTS_LIMIT, EXCLUDE_STRATEGY_TYPES, GOAL_TYPES, CYOD_GOAL_TYPES, goalsWithLongTextAsName,
} from 'constantsBase';
import { FETCH_HELIOS_ANALYTICS, DOWNLOAD_HELIOS_ANALYTICS, REPORT_TYPES } from 'containers/StrategyAnalytics/components/View/Helios/constants';
import { LEGACY_PREFIX, flightKey } from 'containers/StrategyAnalytics/constants/strategyAnalyticsConstants';
import { isCampaignLevelOptimization } from 'containers/StrategyAnalytics/utils/tabUtils';
import { GlobalState } from 'reducers';
import { prepareDetachmentConfig } from 'utils/airflow/utils';
import {
  Strategy, Member, Advertiser, StrategyAnalytics, StrategyType, Flight, Microservices, UserSettings, Goal, StrategyGoalAnalytics,
} from 'utils/copilotAPI';
import { ISO_DATE, NUMBER_DATE_WITH_TIME_FORMAT } from 'utils/dateTime';
import { MEMBER } from 'utils/featureFlags';
import { wrapCancelableSagas } from 'utils/functionHelpers';
import {
  Strategy as StratType, StrategyType as StrategyTypeType, Flight as FlightType, Algorithm, SparklineAnalytics, EnhancedStrategy,
} from 'utils/types';
import {
  getStrategyList, deleteStrategyCompleted, getMemberFailed, getMemberCompleted, getAdvertiserFailed, getAdvertiserCompleted, getStrategyFailed, fetchGoalTypeCompleted,
  fetchGoalTypeFailed, fetchStrategyTypeCompleted, fetchStrategyTypeFailed, fetchStrategyCompleted, deleteStrategyFailed, clearStrategyListFiltersCompleted,
} from './actions';
import {
  DOWNLOAD_HELIOS_REPORT, FETCH_MEMBER, FETCH_ADVERTISER, FETCH_STRATEGY_TYPE, FILTER, DEFAULT_FILTER, DELETE_STRATEGY, DELETE_STRATEGY_COMPLETED,
  UPDATE_USER_SETTING_COMPLETED, UPDATE_USER_SETTING, UPDATE_USER_SETTING_FAILED, FETCH_STRATEGY, FETCH_GOAL_TYPE, StrategyListView, CLEAR_FILTERS,
} from './constants';

const { member } = apiEnums;

const maxAdvertiserToRender = 5;

// List of actions that should stop sagas chaining (to fetch strategies list)
const cancelActions = [
  PAGE_CHANGE_ACTION,
  FILTER.USER_INPUT.ADVERTISER.SEARCH_UPDATED,
  FILTER.UPDATED.ANY,
  FILTER.UPDATED.SORT,
];

export const buildAdvertiserQuery = ({ uiFilter, input }) => {
  const filter = { ...uiFilter };

  if (!_.isUndefined(input)) {
    filter.or = [
      { name: { [OPERATOR.CONTAINS]: input } },
      { externalId: { [OPERATOR.CONTAINS]: input } },
    ];
  }

  return {
    advertiserParameter: {
      filter,
    },
  };
};

export const buildStrategyListQuery = ({
  filter, limit, skip, sort,
}) => ({
  strategyParameter: {
    filter,
    limit,
    skip,
    sort,
  },
});

/**
 * Formats/Creates strategy data for displaying on strategies list page
 * @param data
 * @param analytics
 * @returns {Array}
 */
const createDisplayData = (
  data: Array<StratType & { algorithms: Array<Algorithm> }>,
  analytics: { [strategyId: number]: Array<SparklineAnalytics> },
  flightsMeta: { [strategyId: number]: Array<FlightType> },
): Array<EnhancedStrategy> => {
  const dataArray = [];
  if (!_.isEmpty(data)) {
    _.forEach(data, (strategy) => {
      const lastModifiedTime = moment.utc(strategy.updatedAt).format(NUMBER_DATE_WITH_TIME_FORMAT);
      const flights = _.keyBy(_.get(flightsMeta, strategy.id, []), flightKey);
      dataArray.push({
        ...strategy,
        algorithms: _.filter(strategy.algorithms, ['active', true]),
        dspId: strategy.strategyType.dsp,
        lastModifiedTime,
        numFlights: (_.size(flights)),
        flights,
        analytics: (analytics[strategy.id] || []),
      });
    });
  }

  return dataArray;
};

export function* getMemberSaga({
  payload: {
    id = [],
    limit = RESULTS_LIMIT, skip = 0,
    sort = 'displayName ASC',
  },
}: AnyAction) {
  // id can either be the string '*' indicating all members, or it can be an array member ids for non-admins
  try {
    if (_.isEmpty(id)) {
      yield put(getMemberFailed('No permission to view any seats'));
    } else {
      const filter = (id === MEMBER.ALL)
        ? {}
        : { id: _.map(id, _.toNumber) };
      const members = yield call(Member.get, {
        where: filter, limit, skip, sort, populate: [],
      });
      yield put(getMemberCompleted(members.data));
    }
  } catch (error) {
    yield put(getMemberFailed('Failed to fetch seats'));
  }
}

export function* getAdvertiserSaga({
  payload: {
    advertiserParameter: {
      filter = DEFAULT_FILTER,
      limit = maxAdvertiserToRender,
      skip = 0,
      sort = 'name ASC',
    },
  },
}) {
  try {
    const [selected, advertisers] = yield all([
      call(Advertiser.get, {
        where: { id: filter.advertiser },
        limit: filter.advertiser.length,
        skip,
        sort,
        populate: [member],
      }),
      call(Advertiser.get, {
        where: { member: filter.member, id: { '!': filter.advertiser }, or: filter.or },
        limit,
        skip,
        sort,
        populate: [member],
      }),
    ]);
    const payload = {
      selected: (_.isArray(selected.data) ? selected.data : [selected.data]), advertiser: advertisers.data,
    };
    yield put(getAdvertiserCompleted(payload));
  } catch (error) {
    yield put(getAdvertiserFailed('Failed to fetch advertisers'));
  }
}

const createStrategyAnalyticsParams = (strategyIds: Array<number>, stratToFlights: { [stratId: string]: Array<FlightType> }) => (
  _.map(strategyIds, (id: number) => {
    const strategyFlights = stratToFlights[id];
    const startDate = moment.min(_.map(strategyFlights, (f: FlightType) => moment(f.startDate))).format(ISO_DATE);
    const endDate = moment.max(_.map(strategyFlights, (f: FlightType) => moment(f.endDate))).format(ISO_DATE);
    return {
      strategy: id,
      startDate,
      endDate,
    };
  })
);

export function* clearFilters() {
  const { id, settings: { config: { strategyListView } } } = yield select((state: GlobalState) => state.login.user);
  const view = strategyListView === StrategyListView.allUsers ? strategyListView : id;
  yield put(clearStrategyListFiltersCompleted({ ...DEFAULT_FILTER, createdBy: view, updatedBy: view }));
}

export function* getStrategyListSaga({
  payload: {
    strategyParameter: {
      filter = DEFAULT_FILTER,
      limit = RESULTS_LIMIT,
      skip = 0,
      sort = filter.sort,
      order = filter.order,
    },
  },
}) {
  try {
    const sortOrder = `${sort} ${order}`;
    const filterByUsers = {
      member: filter.member,
      advertiser: filter.advertiser,
      strategyType: filter.strategyType,
      goalType: filter.goalType,
      ...(filter.starred && { starred: filter.starred }), // only include starred filter if selected
    };

    const filterByUser = {
      ...filterByUsers,
      or: [
        { createdBy: filter.createdBy },
        { updatedBy: filter.updatedBy },
      ],
    };

    const filterBy = (filter.createdBy === 'all') ? filterByUsers : filterByUser;

    // get the total count of strategies that the user has access to based on their member access
    // get the valid strategies and data based on the filters
    const { data: { strategyCount, strategies, strategyStatuses, flightsData } } = yield call(Strategy.getValidStrategies, { filterBy, limit, skip, sortOrder });
    const flights = _.groupBy(flightsData, 'strategy');

    const campaignLevelOptStratIds = _.map(_.filter(strategies, (s: { strategyType: StrategyTypeType }) => isCampaignLevelOptimization(s.strategyType.id)), 'id');
    const nonCampaignLevelOptStratIds = _.map(_.filter(strategies, (s: { id: number }) => !_.includes(campaignLevelOptStratIds, s.id)), 'id');

    const stratAnalyticsParams = createStrategyAnalyticsParams(campaignLevelOptStratIds, flights);
    const [{ data: campaignLevelOptStratAnalytics }, { data: nonCampaignLevelOptStratAnalytics }] = yield all([
      call(StrategyGoalAnalytics.getAnalyticsData, stratAnalyticsParams),
      call(StrategyAnalytics.multiStrategyPerStrategy, {
        limit, where: { id: nonCampaignLevelOptStratIds }, sort: sortOrder, _days: '7',
      }),
    ]);
    // @ts-ignore date type issue after converting to a moment
    const analyticsData: Array<SparklineAnalytics> = _.map([...campaignLevelOptStratAnalytics, ...nonCampaignLevelOptStratAnalytics], (data) => ({
      ..._(data).map((v, k) => [k, v || 0]).fromPairs().value(),
      date: moment.utc(data.date, 'YYYY-MM-DD').valueOf(),
    }));

    const formattedStrategyList = createDisplayData(strategies, _.groupBy(analyticsData, 'strategyId'), flights);
    yield put(
      fetchStrategyCompleted(formattedStrategyList, strategyStatuses, strategyCount, limit, skip),
    );
  } catch (error) {
    yield put(getStrategyFailed(error));
  }
}

export function* buildAdvertiserQueryPayloadAndFetchData({ payload: { filter, input } }) {
  yield* getAdvertiserSaga({ payload: buildAdvertiserQuery({ uiFilter: filter, input }) });
}

export function* buildStrategyListQueryPayloadAndFetchData({
  payload: {
    filter, limit, skip, sort,
  },
}) {
  // @ts-ignore
  yield* getStrategyListSaga({
    payload: buildStrategyListQuery({
      filter, limit, skip, sort,
    }),
  });
}

export function* fetchStrategyType() {
  try {
    const strategyTypes = yield call(StrategyType.get, { limit: RESULTS_LIMIT });
    const filtered = _.reject(strategyTypes.data, ({ id }) => _.includes(EXCLUDE_STRATEGY_TYPES, id));
    yield put(fetchStrategyTypeCompleted(filtered));
  } catch (error) {
    yield put(fetchStrategyTypeFailed('Failed to fetch strategy type'));
  }
}

const fetchSystemGoals = () => Goal.get({ isSystemGoal: 1, limit: RESULTS_LIMIT });

const getGoalDisplayName = (goalType: string) => {
  const goalName = _.camelCase(goalType);
  if (_.includes(goalsWithLongTextAsName, goalName)) {
    return GOAL_TYPES[goalName].strategyWizardLongText;
  }
  if ((_.includes(CYOD_GOAL_TYPES, goalName)) || (_.includes(goalName, LEGACY_PREFIX))) {
    return _.startCase(goalName);
  }
  return goalName === 'roas' ? `${GOAL_TYPES.conversionRevenue.strategyWizardAbbreviation}` : GOAL_TYPES[goalName].strategyWizardAbbreviation;
};

export function* fetchGoalType() {
  try {
    const { data: goalRes } = yield call(fetchSystemGoals);
    const filteredGoals = _.map(goalRes, (goalData) => ({
      id: goalData.id,
      displayName: getGoalDisplayName(goalData.name),
    }));
    const cyogGoalType = { id: 0, displayName: GOAL_TYPES.awgCreateYourOwn.strategyWizardAbbreviation };
    yield put(fetchGoalTypeCompleted([cyogGoalType, ...filteredGoals]));
  } catch (error) {
    yield put(fetchGoalTypeFailed('Failed to fetch goal type'));
  }
}

export function* deleteStrategy({ payload: strategies }: AnyAction) {
  try {
    const records = _.map(strategies, (s) => ({ ..._.pick(s, ['active', 'id']), active: false }));
    yield call(Strategy.bulkUpdate, records);
    const { filter, limit, skip } = yield select((state: { strategiesList: unknown }) => state.strategiesList);
    yield put(getStrategyList(filter, limit, skip));
    yield put(deleteStrategyCompleted(records));
  } catch (error) {
    yield put(deleteStrategyFailed());
  }
}

export function* triggerDetachFlights({ payload: strategies }: AnyAction) {
  try {
    const stratIds = _.map(strategies, 'id');
    const flights = yield call(Flight.get, { where: { strategy: stratIds }, limit: RESULTS_LIMIT });
    const flightsToDetach = flights.data;
    if (!_.isEmpty(flightsToDetach)) {
      yield call(Microservices.runService, prepareDetachmentConfig(flightsToDetach), 'flight_detachment');
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error detaching flights', error);
  }
}

export function* downloadHeliosReport({
  payload: {
    strategy, flights, reportType, bundle,
  },
}: AnyAction) {
  if (bundle) {
    yield put({
      type: reportType !== REPORT_TYPES.BUCKETED ? DOWNLOAD_HELIOS_ANALYTICS.STARTED : FETCH_HELIOS_ANALYTICS.STARTED,
      payload: {
        strategy,
        flight: flights,
        download: true,
        reportType,
        actionType: DOWNLOAD_HELIOS_REPORT.COMPLETED_ALL,
      },
    });
  } else {
    yield all(flights.map((flight, i) => put({
      type: reportType !== REPORT_TYPES.BUCKETED
        ? DOWNLOAD_HELIOS_ANALYTICS.STARTED : FETCH_HELIOS_ANALYTICS.STARTED,
      payload: {
        strategy,
        flight,
        download: true,
        reportType,
        actionType:
            i === flights.length - 1 ? DOWNLOAD_HELIOS_REPORT.COMPLETED_ALL : DOWNLOAD_HELIOS_REPORT.COMPLETED,
      },
    })));
  }
}

export function* updateUserSetting({ payload: { setting, value, userSettings } }: AnyAction) {
  try {
    const config = { ...userSettings.config, [setting]: value };
    const res = yield call(UserSettings.put, userSettings.id, { config });
    yield put({ type: UPDATE_USER_SETTING_COMPLETED, payload: res.data });
  } catch (error) {
    yield put({ type: UPDATE_USER_SETTING_FAILED, payload: error });
  }
}

const cancelableSaga = [
  [takeLatest, FETCH_ADVERTISER.STARTED, buildAdvertiserQueryPayloadAndFetchData, cancelActions],
  [takeLatest, FETCH_STRATEGY, buildStrategyListQueryPayloadAndFetchData, cancelActions],
  [takeLatest, FILTER.USER_INPUT.ADVERTISER.SEARCH_UPDATED, buildAdvertiserQueryPayloadAndFetchData, cancelActions],
  [takeLatest, FILTER.UPDATED.ANY, buildAdvertiserQueryPayloadAndFetchData, cancelActions],
  [takeLatest, [FILTER.UPDATED.ANY, FILTER.UPDATED.SORT], buildStrategyListQueryPayloadAndFetchData, cancelActions],
  [takeLatest, CLEAR_FILTERS, clearFilters, cancelActions],
];

export const notCancelableSagas = [
  takeLatest(FETCH_MEMBER.STARTED, getMemberSaga),
  takeLatest(FETCH_STRATEGY_TYPE.STARTED, fetchStrategyType),
  takeLatest(FETCH_GOAL_TYPE.STARTED, fetchGoalType),
  takeLatest(DELETE_STRATEGY, deleteStrategy),
  takeLatest(DELETE_STRATEGY_COMPLETED, triggerDetachFlights),
  takeLatest(DOWNLOAD_HELIOS_REPORT.STARTED, downloadHeliosReport),
  takeLatest(UPDATE_USER_SETTING, updateUserSetting),
];

export function* strategiesListSagas() {
  yield all([
    // @ts-ignore
    ...wrapCancelableSagas(cancelableSaga),
    ...notCancelableSagas,
  ]);
}
