import _ from 'lodash';
import React from 'react';
import { WppToggle, WppCheckbox, WppTypography } from 'buildingBlocks';
import { selectedStrategiesPanel } from 'containers/StrategiesList/style';

const { multiStratToggleContainer } = selectedStrategiesPanel;

type ToggleProps = {
  value: boolean | null
  onChange: (x: boolean) => void
};

export const SingleStrategySelectedToggle = ({ value, onChange }: ToggleProps) => (
  <WppToggle
    checked={value}
    onWppChange={({ detail: { checked } }) => onChange(checked)}
  />
);

export const MultipleStrategiesSelectedToggle = ({ value, onChange }: ToggleProps) => {
  const indeterminate = _.isNil(value);
  const valueText = value ? 'On' : 'Off';
  const displayText = indeterminate ? 'Various' : valueText;

  return (
    <div style={multiStratToggleContainer}>
      <WppCheckbox
        indeterminate={_.isNil(value)}
        checked={value}
        onWppChange={({ detail: { checked } }) => onChange(checked)}
      />
      <WppTypography type="s-body">{displayText}</WppTypography>
    </div>
  );
};
