import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Grid, ObjectDropdown, Button } from 'buildingBlocks';

import {
  SORT_DIRECTION, getComparator, Metric,
} from 'containers/StrategyAnalytics/constants/metricsConstants';

import { Goal, Flight } from 'utils/types';
import { numberOrUndefined, replaceAll, truncateFilename } from 'utils/functionHelpers';
import { convertArrayOfObjectsToStringCSV } from 'utils/exportUtils';

import Table from './HeliosTable';
import { downloadHeliosAnalytics } from '../actions';
import {
  METRICS_OPTIONS, NUMBER_OF_TOPS, REPORT_TYPES, DEFAULT_BUCKET, Bucket, getFilenameForReportType, MAX_FILENAME_CHARS,
} from '../constants';

type TableBodyRow = { key: string, data: { [key: string]: Array<any> } };
type HeliosAnalyticsProps = {
  strategy: { id: number, name: string, strategyGoals: Array<Goal> },
  analytics: Array<Array<unknown>>,
  // eslint-disable-next-line react/no-unused-prop-types
  headers: Array<string>,
  downloadHeliosAnalytics: Function,
  downloadingDaily: boolean,
  downloadingWeekly: boolean,
  flight: Flight,
};
type HeliosAnalyticsState = {
  analytics: Array<Array<unknown>>,
  openSpecificMetric: boolean,
  metricSelected: Metric,
  direction: keyof typeof SORT_DIRECTION,
  tableBody: { [key: string]: { [key: string]: any } },
  leafNameIndex: number,
  metricsIndex: { [key: string]: number },
  sortedKeys: Array<string>,
  top10: Array<TableBodyRow>,
  bottom10: Array<TableBodyRow>,
  csvFilename: string,
  csvUrl: string,
  bucketSelected: Bucket,
};

class HeliosAnalytics extends Component<HeliosAnalyticsProps, HeliosAnalyticsState> {
  static sortData(tableBody, metricsIndex, metricSelected, bucketSelected) {
    const indexOfMetricSelected = metricsIndex[metricSelected.value];
    const { comparator, direction } = getComparator(metricSelected.value);

    // sorted with data from bucket last 7 days by the selected metrics
    const sortedKeys = _(tableBody).chain()
      .keys()
      .map((key) => ({
        key,
        data: numberOrUndefined(tableBody[key], `[${bucketSelected}][${indexOfMetricSelected}]`),
      }))
      .filter((v) => _.isFinite(v.data) && v.data > 0)
      .sort((a, b) => comparator(a.data, b.data))
      .map('key')
      .value();

    const top10 = sortedKeys.slice(0, NUMBER_OF_TOPS);
    const bottom10 = sortedKeys.slice(Math.max(sortedKeys.length - NUMBER_OF_TOPS, 0)).reverse();

    return {
      sortedKeys,
      top10: _.map(top10, (key) => ({ key, data: tableBody[key] })),
      bottom10: _.map(bottom10, (key) => ({ key, data: tableBody[key] })),
      direction: direction as keyof typeof SORT_DIRECTION,
    };
  }

  static buildData(
    props: HeliosAnalyticsProps,
    metricSelected: Metric,
    bucketSelected: string,
  ) {
    // get the indexes for the metrics we need to display
    const metricsIndex: { leafName?: number, bucket?: number } = _(props.headers).chain()
      .reduce((res, value, index) => ({ ...res, [value]: index }), {})
      .value();
    const leafNameIndex = metricsIndex.leafName;

    // Group by leaf name, then key by bucket (date)
    const tableBody = _(props.analytics).chain()
      .map((v) => _.map(v, (val: string) => replaceAll(val, '""', '"')))
      .groupBy((v) => v[leafNameIndex])
      .reduce((res, v, k) => ({
        ...res,
        [k]: _.keyBy(v, metricsIndex.bucket),
      }), {})
      .value();

    const csv = convertArrayOfObjectsToStringCSV(props.analytics, props.headers);
    const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const filename = getFilenameForReportType('Bucketed', props.strategy.name, props.flight.name);
    const csvFilename = truncateFilename(filename, MAX_FILENAME_CHARS);
    return ({
      analytics: props.analytics,
      csvUrl: URL.createObjectURL(csvBlob),
      csvFilename,
      leafNameIndex,
      tableBody,
      metricsIndex,
      ...HeliosAnalytics.sortData(tableBody, metricsIndex, metricSelected, bucketSelected),
    });
  }

  constructor(props: HeliosAnalyticsProps) {
    super(props);
    const goalType = props.strategy.strategyGoals[0].type;
    const metricSelected = _.find(METRICS_OPTIONS, (v) => v.value === goalType) || METRICS_OPTIONS[0];

    this.state = {
      bucketSelected: DEFAULT_BUCKET,
      openSpecificMetric: true,
      metricSelected,
      ...HeliosAnalytics.buildData(props, metricSelected, DEFAULT_BUCKET),
    };
  }

  UNSAFE_componentWillReceiveProps(newProps: HeliosAnalyticsProps) {
    if (!_.isEmpty(newProps.analytics) && !_.isEqual(this.props.analytics, newProps.analytics)) {
      this.setState((prevState) => ({
        ...HeliosAnalytics.buildData(newProps, prevState.metricSelected, prevState.bucketSelected),
      }));
    }
  }

  onBucketClick(bucketSelected: Bucket) {
    if (bucketSelected !== this.state.bucketSelected) {
      this.setState((prevState) => ({
        bucketSelected,
        ...HeliosAnalytics.buildData(this.props, prevState.metricSelected, bucketSelected),
      }));
    }
  }

  render() {
    const { csvFilename, csvUrl } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <label htmlFor="metric" style={{ display: 'block', marginBottom: '5px' }}>Metric</label>
            <ObjectDropdown
              // @ts-ignore passthrough props
              id="helios-analytics-metric"
              name="helios-analytics-metric"
              keyFn={(metric: { text: string }) => metric.text}
              options={METRICS_OPTIONS}
              text={this.state.metricSelected.text}
              onChange={(metricSelected) => this.setState((prevState) => ({
                metricSelected,
                ...HeliosAnalytics.sortData(
                  prevState.tableBody,
                  prevState.metricsIndex,
                  metricSelected,
                  prevState.bucketSelected,
                ),
              }))}
              selection
              style={{ marginLeft: '5px' }}
            />
          </Grid.Column>
          <Grid.Column width={8} style={{ border: 'initial', boxShadow: 'initial' }}>
            <a download={csvFilename} href={csvUrl}>
              <Button primary floated="right">Bucketed Report Download</Button>
            </a>
            <Button
              primary
              floated="right"
              loading={this.props.downloadingWeekly}
              onClick={() => this.props.downloadHeliosAnalytics(
                { strategy: this.props.strategy, flight: this.props.flight, reportType: REPORT_TYPES.WEEKLY },
              )}
            >Weekly Report Download
            </Button>
            <Button
              primary
              floated="right"
              loading={this.props.downloadingDaily}
              onClick={() => this.props.downloadHeliosAnalytics(
                { strategy: this.props.strategy, flight: this.props.flight, reportType: REPORT_TYPES.DAILY },
              )}
            >Daily Report Download
            </Button>
          </Grid.Column>
          <Grid.Column width={12} textAlign="right" style={{ lineHeight: '2em' }}>
            Reports are limited to 100,000 rows of data
          </Grid.Column>
        </Grid.Row>
        <Grid.Column width={12}>
          <Grid.Row>
            <Button
              primary
              onClick={() => this.setState((prevState) => ({ openSpecificMetric: !prevState.openSpecificMetric }))}
            >{`${this.state.openSpecificMetric ? '+ Show' : '- Close'}`} All Metrics
            </Button>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={12}>
          <Grid.Row>
            Sorted by {this.state.metricSelected.text} over the last {this.state.bucketSelected} days
            <br />
            Only shows leaves with at least one event for the selected metric
          </Grid.Row>
        </Grid.Column>
        <Grid.Column style={{ paddingLeft: 'initial', paddingRight: 'initial' }} width={12}>
          <Grid.Row>
            <Table
              id="helios-analytics-best-table"
              title={`Best 10 Performing Leaves: ${this.state.metricSelected.text}`}
              metricsToShow={METRICS_OPTIONS}
              // @ts-ignore passthrough props
              leafNameIndex={this.state.leafNameIndex}
              metricsIndex={this.state.metricsIndex}
              tableBody={this.state.top10}
              metricSelected={this.state.metricSelected}
              showSingleMetric={this.state.openSpecificMetric}
              bucketSelected={this.state.bucketSelected}
              onBucketClick={(bucket: Bucket) => this.onBucketClick(bucket)}
              sortDirection={this.state.direction}
            />
            <Table
              id="helios-analytics-worst-table"
              title={`Worst 10 Performing Leaves: ${this.state.metricSelected.text}`}
              metricsToShow={METRICS_OPTIONS}
              // @ts-ignore passthrough props
              leafNameIndex={this.state.leafNameIndex}
              metricsIndex={this.state.metricsIndex}
              tableBody={this.state.bottom10}
              metricSelected={this.state.metricSelected}
              showSingleMetric={this.state.openSpecificMetric}
              bucketSelected={this.state.bucketSelected}
              onBucketClick={(bucket: Bucket) => this.onBucketClick(bucket)}
              sortDirection={this.state.direction}
              opposite
            />
          </Grid.Row>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  downloadingDaily: state.strategyAnalytics.helios.downloadingDaily,
  downloadingWeekly: state.strategyAnalytics.helios.downloadingWeekly,
});

export default connect(mapStateToProps, { downloadHeliosAnalytics })(HeliosAnalytics);
