import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import qs from 'qs';
import { WppGrid, WppPagination } from 'buildingBlocks';
import DataLoading from 'components/DataLoading';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import { wppBodyContainer } from 'components/PageTemplate/style';
import { TimeZoneValue } from 'containers/Jobs/types';
import {
  PaginationChangeEventDetail,
  SelectChangeEventDetail,
  WppSelectCustomEvent,
} from 'utils/types';
import { Permission } from 'utils/featureFlags';
import { camelize } from 'utils/formattingUtils';
import {
  getStrategyFlightRuns,
  getMembers,
  fetchStrategyType,
  fetchAlgorithmType,
  updateFilterAndFetchData,
} from './actions';
import {
  STRATEGY_FLIGHT_RUNS_ELEMENTS_PER_PAGE_OPTIONS,
  STRATEGY_FLIGHT_RUNS_FILTER,
  STRATEGY_FLIGHT_RUNS_STARTING_ELEMENTS_PER_PAGE,
} from './constants';
import { DEFAULT_FILTER } from './reducer';
import { renderFlightRunsSectionStyle } from './style';
import { ExtendedRootState, StrategyFlightRunsProps } from './types';
import StrategyFlightRunsTable from './StrategyFlightRunsTable';
import FilterSection from './FilterSection';

const REQUIRED_PERMISSIONS = [Permission.strategyFlightRuns];
const PAGE_NAME = 'Flight Runs';

const formatAlgoTypeName = (algoType) => camelize(_.replace(algoType.name, /_/g, ' '));

const StrategyFlightRuns: React.FC<StrategyFlightRunsProps> = () => {
  const dispatch = useDispatch();
  const [startingPage, setStartingPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(STRATEGY_FLIGHT_RUNS_STARTING_ELEMENTS_PER_PAGE);
  const [selectedTimezone, setSelectedTimezone] = useState<TimeZoneValue>(TimeZoneValue.utc);

  const {
    strategyFlightRuns,
    strategyFlightRunsCount,
    filter,
    loading,
    userTimeZone,
  } = useSelector((state: ExtendedRootState) => ({
    ...state.strategyFlightRuns,
    userTimeZone: state.login.settings.config.timeZone,
  }));

  const getTriggerType = (sfr) => {
    const triggerType = sfr.triggerType ? 'User Initiated' : 'Programmatic';
    if (sfr.result && sfr.result.dag_run_url) {
      return (
        <a
          href={sfr.result.dag_run_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {triggerType}
        </a>
      );
    }
    return triggerType;
  };

  const updateState = (newLimit: number, newSkip: number) => {
    setLimit(newLimit);
    setStartingPage((newSkip / newLimit) + 1);
  };

  const initFromQueryString = (query: Record<string, any>, initialLimit: number, initialSkip: number) => {
    const getValue = (id: string) => _.chain(query)
      .get(id, [])
      // @ts-ignore field within query should be an array
      .map((val) => (_.isNaN(_.toNumber(val)) ? val : _.toNumber(val)))
      .value();

    const filterFields = [
      STRATEGY_FLIGHT_RUNS_FILTER.INPUT.STRATEGIES,
      STRATEGY_FLIGHT_RUNS_FILTER.INPUT.FLIGHTS,
      STRATEGY_FLIGHT_RUNS_FILTER.INPUT.ALGORITHMS,
      STRATEGY_FLIGHT_RUNS_FILTER.INPUT.MEMBERS,
      STRATEGY_FLIGHT_RUNS_FILTER.STATUS,
      STRATEGY_FLIGHT_RUNS_FILTER.STRATEGY_TYPE,
      STRATEGY_FLIGHT_RUNS_FILTER.ALGORITHM_TYPE,
      STRATEGY_FLIGHT_RUNS_FILTER.TRIGGER_TYPE,
    ];

    const filterInit = _(filterFields)
      .map(({ id }) => ([id, getValue(id)]))
      .fromPairs()
      .defaults(DEFAULT_FILTER)
      .value();

    filterInit.startDate = _.join(query.startDate, '') || DEFAULT_FILTER.startDate;
    filterInit.endDate = _.join(query.endDate, '') || DEFAULT_FILTER.endDate;

    dispatch(updateFilterAndFetchData(filterInit, true, initialLimit, initialSkip));
  };

  const browserUrlUpdate = (filterBrowser: any, limitBrowser: number, skipBrowser: number) => {
    const newFilter = { ...filterBrowser, limitBrowser, skipBrowser };
    const newQueryString = `?${qs.stringify(newFilter)}`;
    window.history.pushState({}, '', `${window.location.pathname}${newQueryString}`);
  };

  const onResetFilter = () => {
    dispatch(updateFilterAndFetchData(DEFAULT_FILTER, true, limit, 0));
    browserUrlUpdate(DEFAULT_FILTER, limit, 0);
    setStartingPage(1);
  };

  const onApplyFilter = () => {
    dispatch(updateFilterAndFetchData(filter, true, limit, 0));
    browserUrlUpdate(filter, limit, 0);
    setStartingPage(1);
  };

  const handleTimeZoneWppChange = (event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    const data = event.detail;
    setSelectedTimezone(data.value);
  };

  useMount(() => {
    const query = qs.parse(window.location.search.replace(/^\?/, ''));
    const parsedLimit = (_.toNumber(query.limit) > 0 && parseInt(query.limit as string, 10))
      || STRATEGY_FLIGHT_RUNS_STARTING_ELEMENTS_PER_PAGE;
    const parsedSkip = (_.toNumber(query.skip) >= 0 && parseInt(query.skip as string, 10)) || 0;

    updateState(parsedLimit, parsedSkip);
    dispatch(getMembers());
    dispatch(fetchStrategyType());
    dispatch(fetchAlgorithmType());
    initFromQueryString(query, parsedLimit, parsedSkip);
  });

  const onDataRangeChange = ({ start, end }: { start: number; end: number }) => {
    updateState(start, end);
    browserUrlUpdate(filter, start, end);
    dispatch(getStrategyFlightRuns(filter, start, end));
  };

  const handleWppPaginationChange = (event: CustomEvent<PaginationChangeEventDetail>) => {
    const { itemsPerPage, page } = event.detail;
    onDataRangeChange({ start: itemsPerPage, end: (page - 1) * itemsPerPage });
  };

  const renderStrategyFlightRunsSection = () => (
    <div style={{ ...wppBodyContainer, ...renderFlightRunsSectionStyle }}>
      <WppGrid fullWidth container>
        <WppGrid item all={24}>
          <StrategyFlightRunsTable
            strategyFlightRuns={strategyFlightRuns}
            strategyFlightRunsCount={strategyFlightRunsCount}
            selectedTimezone={selectedTimezone}
            userTimeZone={userTimeZone}
            formatAlgoTypeName={formatAlgoTypeName}
            getTriggerType={getTriggerType}
            onResetFilter={onResetFilter}
          />
        </WppGrid>
        <WppGrid item all={24}>
          <WppPagination
            count={strategyFlightRunsCount}
            itemsPerPage={STRATEGY_FLIGHT_RUNS_ELEMENTS_PER_PAGE_OPTIONS}
            activePageNumber={startingPage}
            selectedItemPerPage={limit}
            onWppChange={handleWppPaginationChange}
          />
        </WppGrid>
      </WppGrid>
    </div>
  );

  return (
    <DataLoading loading={loading} pageName={PAGE_NAME}>
      <>
        <FilterSection
          selectedTimezone={selectedTimezone}
          formatAlgoTypeName={formatAlgoTypeName}
          onResetFilter={onResetFilter}
          onApplyFilter={onApplyFilter}
          handleTimeZoneWppChange={handleTimeZoneWppChange}
        />
        {renderStrategyFlightRunsSection()}
      </>
    </DataLoading>
  );
};

const PermissionStrategyFlightRuns = (props) => (
  <WppPermissionPageTemplate
    name={PAGE_NAME}
    title={PAGE_NAME}
    permissions={REQUIRED_PERMISSIONS}
    withoutLayout
  >
    <StrategyFlightRuns {...props} />
  </WppPermissionPageTemplate>
);

export default PermissionStrategyFlightRuns;
