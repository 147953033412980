import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { SemanticICONS } from 'buildingBlocks';
import { GOAL_TYPES, GOAL_VALUE_TYPE, MetricsFormattingConstants } from 'constantsBase';
import {
  margin,
  netCpm,
  netVcpm,
  netCpa,
  netCpc,
  netCpcv,
  ctr,
  vcpm,
  cpc,
  cpa,
  cpm,
  cpcv,
  netCpcl,
  ivrMeasured,
  conversionRate,
  completionRate,
  lessThan,
  greaterThan,
  conversionRevenueRate,
  vcpcv,
  netVcpcv,
  costPerImpValue,
  impValuePerImp,
} from 'utils/calculations';
import { Strategy } from 'utils/types';
import { LeafAnalytics } from '../types';

export type Metric = {
  bigFormat?: string,
  format?: string,
  vizFormat?: string,
  func: (leafAnalytics: LeafAnalytics, strategy?: Strategy) => number,
  value: string,
  text: string,
  shortText?: string
  lowerIsBetter?: boolean,
  tooltipContent?: React.ReactElement,
  additionalFormatting?: string
  titleCase?: boolean
};

/**
 * Format metric to display it
 * @param metricsEnum metric selected
 * @param metric metric computed
 * @param statBox boolean to specify if the metric will be displayed in the statbox
 * (we don't want to have big number there)
 */

export const displayMetrics = (metricsEnum: Metric, metric: number, statBox: boolean = false) => {
  const fmt = statBox && metricsEnum.bigFormat ? metricsEnum.bigFormat : metricsEnum.format;
  return numeral(metric).format(fmt);
};

const roundSpreadFnOnArr = (fn, arr, col1, col2, prec) => (
  _.round(fn(_.sumBy(arr, col1) || 0, _.sumBy(arr, col2) || 0), prec)
);

// not a great name....
export type SelectedMetric = {
  ratePercentage: Metric,
  aggregator: Metric,
};

export const formatWithCurrency = (value: string, currencyCode: string): string => `${value} ${currencyCode}`;

enum GoalPerformanceType {
  rate = 'rate',
  cost = 'cost',
}

// This metric is currently only being used in Budget Allocation visualization <Statbox />
const currentPacingConfig = {
  value: 'currentPacing',
  text: 'Current Pacing',
  additionalFormatting: GOAL_VALUE_TYPE.NONE,
  format: MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS,
  tooltipContent: <span>During flight, Current Pacing calculates daily delivery versus daily delivery goals. Once a campaign is completed, Final Pacing calculates total delivery versus total budget goals. A figure at 100% indicates delivery is on track or has fulfilled budget requirements. <a href="https://copilotsupport.freshdesk.com/support/solutions/articles/47001125072-analytics-budget-optimization" rel="noopener noreferrer" target="_blank">Read more.</a></span>,
  // placeholder calculation below (metric is sent from backend)
  func: (arr: LeafAnalytics) => roundSpreadFnOnArr(netCpcl, arr, 'impressions', 'impressions', 3),
  goalPerformanceType: GoalPerformanceType.rate,
};

export const metrics = {
  ratePercentage: {
    margin: {
      ...GOAL_TYPES.margin,
      additionalFormatting: GOAL_VALUE_TYPE.PERCENTAGE,
      format: MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS,
      func: (arr: LeafAnalytics, strategy?: Strategy) => {
        // unit tests fail when trying to use getSingleRevTypeAndValue
        const firstRevTypeObj = _.head(_.values(_.get(strategy, 'revTypeConfig')));
        const revenueType = _.get(firstRevTypeObj, 'outcome', null);
        const clientEventRevenueValue = firstRevTypeObj ? _.toNumber(_.get(firstRevTypeObj, 'revenueValue')) : null;
        if (!_.isNil(clientEventRevenueValue)) {
          let aggNetGoal;
          switch (revenueType) {
            case GOAL_TYPES.cpcv.value:
              aggNetGoal = metrics.ratePercentage.netCpcv.func(arr);
              break;
            case GOAL_TYPES.cpc.value:
              aggNetGoal = metrics.ratePercentage.netCpc.func(arr);
              break;
            case GOAL_TYPES.cpm.value:
            default:
              aggNetGoal = metrics.ratePercentage.netCpm.func(arr);
          }
          return (clientEventRevenueValue - aggNetGoal) / clientEventRevenueValue;
        }
        return roundSpreadFnOnArr(margin, arr, 'advRevenue', 'advCost', 2);
      },
      goalPerformanceType: GoalPerformanceType.rate,
    },
    ivrMeasured: {
      ...GOAL_TYPES.ivrMeasured,
      additionalFormatting: GOAL_VALUE_TYPE.PERCENTAGE,
      format: MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS,
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(ivrMeasured, arr, 'viewableImpressions', 'viewMeasuredImps', 2),
      goalPerformanceType: GoalPerformanceType.rate,
    },
    ctr: {
      ...GOAL_TYPES.ctr,
      additionalFormatting: GOAL_VALUE_TYPE.PERCENTAGE,
      format: MetricsFormattingConstants.PERCENTAGE_THREE_DECIMALS,
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(ctr, arr, 'clicks', 'impressions', 5),
      goalPerformanceType: GoalPerformanceType.rate,
    },
    conversionRate: {
      ...GOAL_TYPES.conversionRate,
      additionalFormatting: GOAL_VALUE_TYPE.PERCENTAGE,
      format: MetricsFormattingConstants.PERCENTAGE_THREE_DECIMALS,
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(conversionRate, arr, 'conversions', 'impressions', 5),
      goalPerformanceType: GoalPerformanceType.rate,
    },
    conversionRevenue: {
      ...GOAL_TYPES.conversionRevenue,
      strategyAnalyticsDropdownOverrideText: 'ROAS',
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.TWO_DECIMALS,
      func: (arr: LeafAnalytics) => (
        roundSpreadFnOnArr(conversionRevenueRate, arr, 'conversionRevenue', 'advRevenue', 5)
      ),
      goalPerformanceType: GoalPerformanceType.rate,
    },
    completionRate: {
      ...GOAL_TYPES.completionRate,
      additionalFormatting: GOAL_VALUE_TYPE.PERCENTAGE,
      format: MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS,
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(completionRate, arr, 'videoCompletes', 'videoStarts', 2),
      goalPerformanceType: GoalPerformanceType.rate,
    },
    cpm: {
      ...GOAL_TYPES.cpm,
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.TWO_DECIMALS,
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(cpm, arr, 'advRevenue', 'impressions', 2),
      lowerIsBetter: true,
      goalPerformanceType: GoalPerformanceType.cost,
    },
    netCpm: {
      ...GOAL_TYPES.netCpm,
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.TWO_DECIMALS,
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(netCpm, arr, 'advCost', 'impressions', 2),
      lowerIsBetter: true,
      goalPerformanceType: GoalPerformanceType.cost,
      textForDemo: 'CPM',
    },
    vcpm: {
      ...GOAL_TYPES.vcpm,
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.TWO_DECIMALS,
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(vcpm, arr, 'advRevenue', 'viewableImpressions', 2),
      lowerIsBetter: true,
      goalPerformanceType: GoalPerformanceType.cost,
    },
    netVcpm: {
      value: 'netVcpm',
      text: 'Net vCPM',
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.TWO_DECIMALS,
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(netVcpm, arr, 'advCost', 'viewableImpressions', 2),
      lowerIsBetter: true,
      goalPerformanceType: GoalPerformanceType.cost,
      textForDemo: 'vCPM',
    },
    cpc: {
      ...GOAL_TYPES.cpc,
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.TWO_DECIMALS,
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(cpc, arr, 'advRevenue', 'clicks', 2),
      lowerIsBetter: true,
      goalPerformanceType: GoalPerformanceType.cost,
    },
    netCpc: {
      ...GOAL_TYPES.netCpc,
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.TWO_DECIMALS,
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(netCpc, arr, 'advCost', 'clicks', 2),
      lowerIsBetter: true,
      goalPerformanceType: GoalPerformanceType.cost,
      textForDemo: 'CPC',
    },
    cpa: {
      ...GOAL_TYPES.cpa,
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.TWO_DECIMALS,
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(cpa, arr, 'advRevenue', 'conversions', 2),
      lowerIsBetter: true,
      goalPerformanceType: GoalPerformanceType.cost,
    },
    netCpa: {
      value: 'netCpa',
      text: 'Net CPA',
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.TWO_DECIMALS,
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(netCpa, arr, 'advCost', 'conversions', 2),
      lowerIsBetter: true,
      goalPerformanceType: GoalPerformanceType.cost,
      textForDemo: 'CPA',
    },
    cpcv: {
      ...GOAL_TYPES.cpcv,
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.THREE_DECIMALS,
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(cpcv, arr, 'advRevenue', 'videoCompletes', 3),
      lowerIsBetter: true,
      goalPerformanceType: GoalPerformanceType.cost,
    },
    netCpcv: {
      ...GOAL_TYPES.netCpcv,
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.THREE_DECIMALS,
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(netCpcv, arr, 'advCost', 'videoCompletes', 3),
      lowerIsBetter: true,
      goalPerformanceType: GoalPerformanceType.cost,
      textForDemo: 'CPCV',
    },
    vcpcv: {
      ...GOAL_TYPES.vcpcv,
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.THREE_DECIMALS,
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(vcpcv, arr, 'advRevenue', 'videoViewableCompletes', 3),
      lowerIsBetter: true,
      goalPerformanceType: GoalPerformanceType.cost,
    },
    netVcpcv: {
      value: 'netVcpcv',
      text: 'Net vCPCV',
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.THREE_DECIMALS,
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(netVcpcv, arr, 'advCost', 'videoViewableCompletes', 3),
      lowerIsBetter: true,
      goalPerformanceType: GoalPerformanceType.cost,
      textForDemo: 'vCPCV',
    },
    // TODO: Per Product this will be added back at a later time
    // cpvYoutube: {
    //   ...GOAL_TYPES.cpvYoutube,
    //   additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
    //   format: MetricsFormattingConstants.THREE_DECIMALS,
    //   // placeholder calculation below (metric is sent from backend)
    //   func: (arr: LeafAnalytics) => roundSpreadFnOnArr(netCpcl, arr, 'impressions', 'impressions', 3),
    //   lowerIsBetter: true,
    //   goalPerformanceType: goalPerformanceType.cost,
    // },
    // vcrYoutube: {
    //   ...GOAL_TYPES.vcrYoutube,
    //   additionalFormatting: GOAL_VALUE_TYPE.PERCENTAGE,
    //   format: MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS,
    //   // placeholder calculation below (metric is sent from backend)
    //   func: (arr: LeafAnalytics) => roundSpreadFnOnArr(netCpcl, arr, 'impressions', 'impressions', 3),
    //   goalPerformanceType: goalPerformanceType.rate,
    // },
    costPerImpValue: {
      value: 'costPerImpValue',
      text: 'Cost per Success Event',
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.THREE_DECIMALS,
      // placeholder calculation below (metric is sent from backend)
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(costPerImpValue, arr, 'advRevenue', 'impressionValue', 3),
      goalPerformanceType: GoalPerformanceType.cost,
    },
    impValuePerImp: {
      value: 'impValuePerImp',
      text: 'Success Event Rate',
      additionalFormatting: GOAL_VALUE_TYPE.PERCENTAGE,
      format: MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS,
      // placeholder calculation below (metric is sent from backend)
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(impValuePerImp, arr, 'impressionValue', 'impressions', 3),
      goalPerformanceType: GoalPerformanceType.rate,
    },
    currentPacing: currentPacingConfig,
    // This metric is currently only being used in Budget Allocation visualization <Statbox />
    currentPacingPaused: {
      ...currentPacingConfig,
      value: 'currentPacingPaused',
      titleCase: true,
      goalPerformanceType: GoalPerformanceType.rate,
    },
    finalPacing: {
      ...currentPacingConfig,
      value: 'finalPacing',
      text: 'Final Pacing',
      goalPerformanceType: GoalPerformanceType.rate,
    },
    upLift: {
      value: 'upLift',
      text: 'Uplift',
      additionalFormatting: GOAL_VALUE_TYPE.NONE,
      format: MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS,
      tooltipContent: (
        <span>Copilot’s performance uplift compared to &nbsp;
          <a href="https://copilotsupport.freshdesk.com/support/solutions/articles/47001125072-monitoring-performance-campaign-level-optimization#How-We-Estimate-Performance-without-Copilot">
            estimated performance without copilot.
          </a>
        </span>),
      // placeholder calculation below (metric is sent from backend)
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(netCpcl, arr, 'impressions', 'impressions', 3),
    },
    eventBudgetDelivered: {
      value: 'eventBudgetDelivered',
      text: 'Budget Delivered',
      additionalFormatting: GOAL_VALUE_TYPE.NONE,
      format: MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS,
      tooltipContent: (
        <div>
          <p>This strategy has delivered its Lifetime Budget in full ahead of its scheduled end date. Therefore Copilot has decelerated the delivery of the campaign.</p>
          <p>Budget Delivered = [units] Delivered / Lifetime Budget</p>
          <ul>To enable further delivery, you must:
            <li style={{ marginLeft: '1em' }}>Revise the Interval Budget Amount in the DSP OR </li>
            <li style={{ marginLeft: '1em' }}>Revise the Revenue Value in Copilot OR </li>
            <li style={{ marginLeft: '1em' }}>Remove Copilot</li>
          </ul>
        </div>),
      // placeholder calculation below (metric is sent from backend)
      func: (arr: LeafAnalytics) => roundSpreadFnOnArr(netCpcl, arr, 'impressions', 'impressions', 3),
    },
  },
  aggregator: {
    impressions: {
      value: 'impressions',
      text: 'Impressions',
      additionalFormatting: GOAL_VALUE_TYPE.NONE,
      format: MetricsFormattingConstants.NO_DECIMALS,
      bigFormat: MetricsFormattingConstants.ROUNDED_ONE_DECIMAL,
      vizFormat: MetricsFormattingConstants.ROUNDED_NO_DECIMALS,
      func: (arr: LeafAnalytics) => _.sumBy(arr, metrics.aggregator.impressions.value),
    },
    viewableImpressions: {
      value: 'viewableImpressions',
      text: 'Viewable Impressions',
      additionalFormatting: GOAL_VALUE_TYPE.NONE,
      format: MetricsFormattingConstants.NO_DECIMALS,
      bigFormat: MetricsFormattingConstants.ROUNDED_ONE_DECIMAL,
      func: (arr: LeafAnalytics) => _.sumBy(arr, metrics.aggregator.viewableImpressions.value),
    },
    clicks: {
      value: 'clicks',
      text: 'Clicks',
      additionalFormatting: GOAL_VALUE_TYPE.NONE,
      format: MetricsFormattingConstants.NO_DECIMALS,
      vizFormat: MetricsFormattingConstants.ROUNDED_NO_DECIMALS,
      bigFormat: MetricsFormattingConstants.ROUNDED_ONE_DECIMAL,
      func: (arr: LeafAnalytics) => _.sumBy(arr, metrics.aggregator.clicks.value),
    },
    conversions: {
      value: 'conversions',
      text: 'Conversions',
      additionalFormatting: GOAL_VALUE_TYPE.NONE,
      format: MetricsFormattingConstants.NO_DECIMALS,
      bigFormat: MetricsFormattingConstants.ROUNDED_ONE_DECIMAL,
      func: (arr: LeafAnalytics) => _.sumBy(arr, metrics.aggregator.conversions.value),
    },
    conversionRevenue: {
      value: 'conversionRevenue',
      text: 'Conversion Revenue',
      strategyAnalyticsDropdownOverrideText: 'Conversion Revenue',
      additionalFormatting: GOAL_VALUE_TYPE.NONE,
      format: MetricsFormattingConstants.TWO_DECIMALS,
      bigFormat: MetricsFormattingConstants.ROUNDED_ONE_DECIMAL,
      func: (arr: LeafAnalytics) => _.sumBy(arr, metrics.aggregator.conversionRevenue.value),
    },
    cost: {
      value: 'cost',
      text: 'Media Cost',
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.TWO_DECIMALS,
      bigFormat: MetricsFormattingConstants.ROUNDED_TWO_DECIMALS,
      func: (arr: LeafAnalytics) => _.sumBy(arr, (_.has(_.head(arr), 'advCost') ? 'advCost' : 'cost')),
    },
    advRevenue: {
      value: 'revenue',
      text: 'Revenue',
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.TWO_DECIMALS,
      bigFormat: MetricsFormattingConstants.ROUNDED_TWO_DECIMALS,
      vizFormat: MetricsFormattingConstants.ROUNDED_NO_DECIMALS,
      func: (arr: LeafAnalytics) => _.sumBy(arr, 'advRevenue'),
    },
    revenue: {
      value: 'revenue',
      text: 'Revenue',
      additionalFormatting: GOAL_VALUE_TYPE.CURRENCY,
      format: MetricsFormattingConstants.TWO_DECIMALS,
      bigFormat: MetricsFormattingConstants.ROUNDED_TWO_DECIMALS,
      vizFormat: MetricsFormattingConstants.ROUNDED_NO_DECIMALS,
      func: (arr: LeafAnalytics) => _.sumBy(arr, (_.has(_.head(arr), 'advRevenue') ? 'advRevenue' : 'revenue')),
    },
    videoStarts: {
      value: 'videoStarts',
      text: 'Video Starts',
      additionalFormatting: GOAL_VALUE_TYPE.NONE,
      format: MetricsFormattingConstants.NO_DECIMALS,
      bigFormat: MetricsFormattingConstants.ROUNDED_ONE_DECIMAL,
      func: (arr: LeafAnalytics) => _.sumBy(arr, metrics.aggregator.videoStarts.value),
    },
    videoCompletes: {
      value: 'videoCompletes',
      text: 'Video Completes',
      additionalFormatting: GOAL_VALUE_TYPE.NONE,
      format: MetricsFormattingConstants.NO_DECIMALS,
      vizFormat: MetricsFormattingConstants.ROUNDED_NO_DECIMALS,
      bigFormat: MetricsFormattingConstants.ROUNDED_ONE_DECIMAL,
      func: (arr: LeafAnalytics) => _.sumBy(arr, metrics.aggregator.videoCompletes.value),
    },
    videoViewableCompletes: {
      value: 'videoViewableCompletes',
      text: 'Viewable Video Completes',
      additionalFormatting: GOAL_VALUE_TYPE.NONE,
      format: MetricsFormattingConstants.NO_DECIMALS,
      bigFormat: MetricsFormattingConstants.ROUNDED_TWO_DECIMALS,
      func: (arr: LeafAnalytics) => _.sumBy(arr, metrics.aggregator.videoViewableCompletes.value),
    },
  },
};

export const RATE_BASED_GOALS = _.map(_.filter(metrics.ratePercentage, (a) => _.get(a, 'goalPerformanceType') === GoalPerformanceType.rate), 'value');
export const COST_BASED_GOALS = _.map(_.filter(metrics.ratePercentage, (a) => _.get(a, 'goalPerformanceType') === GoalPerformanceType.cost), 'value');

export const VIEWABILITY_STANDARD = {
  GROUPM: 'groupM',
  IAB: 'iab',
};

export const SORT_DIRECTION = {
  ascending: { value: 'ascending', icon: 'angle up' as SemanticICONS, opposite: 'descending' },
  descending: { value: 'descending', icon: 'angle down' as SemanticICONS, opposite: 'ascending' },
};

export const getComparator = (metric: string) => {
  switch (metric) {
    case metrics.ratePercentage.cpa.value:
    case metrics.ratePercentage.cpc.value:
      return { comparator: lessThan, direction: SORT_DIRECTION.ascending.value };
    default:
      return { comparator: greaterThan, direction: SORT_DIRECTION.descending.value };
  }
};

export const aggregatorTextLookup = _.keyBy(metrics.aggregator, 'text');
export const ratePercentageTextLookup = _.keyBy(metrics.ratePercentage, 'text');
export const metricLookup = _.merge(aggregatorTextLookup, ratePercentageTextLookup);

export const SYSTEM_GOALS_WITHOUT_ANALYTICS_KEYS = [
  GOAL_TYPES.impactOutcome.value,
  GOAL_TYPES.impValuePerCost.value,
  GOAL_TYPES.engagementScore.value,
  GOAL_TYPES.cpvYoutube.value,
  GOAL_TYPES.vcrYoutube.value,
];

export default metrics;
