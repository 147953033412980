import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import {
  Dimmer, Loader, Grid, Message,
} from 'buildingBlocks';
import { GENERIC_ERROR_MSG } from 'constantsBase';
import { setOptions } from 'containers/StrategyAnalytics/actions';
import { optionsUpdated } from 'containers/StrategyAnalytics/constants/actionsConstants';
import { Flight, Goal } from 'utils/types';

import Options from './Options';
import HeliosAnalytics from './HeliosAnalytics';
import { fetchHeliosAnalytics, resetState } from '../actions';
import { REPORT_TYPES } from '../constants';

type Props = {
  strategy: { id: number, name: string, flights: { [key: string]: Flight }, goals: Goal },
  flights: { [key: string]: Flight },
  flightSelected: string | null,
  loading: boolean,
  loadingAnalytics: boolean,
  noData: boolean,
  errorAnalytics: boolean,
  setOptions: Function,
  fetchHeliosAnalytics: Function,
  resetState: Function,
  analytics: Array<Array<unknown>>,
  headers: Array<string>,
};

class Helios extends Component<Props> {
  componentDidMount() {
    const { strategy, flights, flightSelected } = this.props;

    if (flightSelected) {
      this.props.fetchHeliosAnalytics({
        strategy,
        flight: flights[flightSelected],
        reportType: REPORT_TYPES.BUCKETED,
      });
    }
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  flightChanged(flight: Flight) {
    this.props.setOptions(optionsUpdated.flight, flight.externalId);
    this.props.fetchHeliosAnalytics({ strategy: this.props.strategy, reportType: REPORT_TYPES.BUCKETED, flight });
  }

  render() {
    const {
      analytics,
      flights,
      flightSelected,
      loading,
      loadingAnalytics,
      errorAnalytics,
      noData,
      strategy,
      headers,
    } = this.props;

    return (
      <Dimmer.Dimmable dimmed={loading || loadingAnalytics}>
        <Dimmer active={loading || loadingAnalytics}>
          <Loader>Loading DSP Object data</Loader>
        </Dimmer>
        <Grid padded>
          <Grid.Column>
            {
              _.isEmpty(flights)
                ? (
                  <Grid.Row>
                    <Message warning>This strategy does not have any attached DSP Objects</Message>
                  </Grid.Row>
                )
                : (
                  <Grid.Row>
                    <Grid>
                      <Options
                        flights={flights}
                        flightSelected={flightSelected}
                        flightChange={(flight) => this.flightChanged(flight)}
                      />
                      {
                      !flightSelected
                        ? (
                          <Grid.Row>
                            <Grid.Column>
                              <Message error>Please select a DSP Object to fetch data for.</Message>
                            </Grid.Column>
                          </Grid.Row>
                        )
                        : (noData || !_.isEmpty(analytics))
                        && (
                        <Grid.Row>
                          <Grid.Column>
                            {
                              errorAnalytics
                              && (
                              <Message error>
                                {GENERIC_ERROR_MSG}
                              </Message>
                              )
                            }
                            {
                              noData
                                ? (
                                  <Message warning>
                                    This DSP Object doesn&apos;t have any data currently, please try again later.
                                  </Message>
                                )
                                : (
                                  <HeliosAnalytics
                                    flight={_.get(flights, flightSelected)}
                                    strategy={strategy}
                                    analytics={analytics}
                                    headers={headers}
                                  />
                                )
                            }
                          </Grid.Column>
                        </Grid.Row>
                        )
                    }
                    </Grid>
                  </Grid.Row>
                )
            }
          </Grid.Column>
        </Grid>
      </Dimmer.Dimmable>
    );
  }
}

const mapStateToProps = (state) => {
  const strategy = state.strategyAnalytics.metadata.strategy;
  const loading = !strategy;

  return {
    strategy,
    loading,
    flights: _.keyBy(_.get(state, 'strategyAnalytics.metadata.flights', []), 'externalId'),
    flightSelected: state.strategyAnalytics.optionValue.flight,

    analytics: state.strategyAnalytics.helios.analytics,
    headers: state.strategyAnalytics.helios.headers,

    loadingAnalytics: state.strategyAnalytics.helios.loading,
    errorAnalytics: state.strategyAnalytics.helios.error,
    noData: state.strategyAnalytics.helios.noData,
  };
};

export default connect(mapStateToProps, { setOptions, fetchHeliosAnalytics, resetState })(Helios);
