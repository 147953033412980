import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { GlobalState } from 'reducers';
import { Form, Grid } from 'buildingBlocks';
import DataUploaderForm from './components/DataUploaderForm';
import { CYOD_HELP_CENTER_LINK, INFO_TEXT } from './constants';
import { dataUploader } from './style';
import { ExternalDataForm } from './types';

const { container, infoTextStyle, anchorText, grid } = dataUploader;

const DataUploader = () => {
  const previouslyUploadedFile = useSelector<GlobalState>((state) => state.strategyAnalytics.metadata.uploadedFile);
  const initialFormValues = {
    fileName: _.get(previouslyUploadedFile, 'name'),
    presignedUrl: _.get(previouslyUploadedFile, 'presignedUrl'),
  };
  const formMethods = useForm<ExternalDataForm>({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });

  return (
    <div style={container}>
      <FormProvider {...formMethods}>
        <Form>
          <Grid style={grid}>
            <Grid.Row style={{ padding: '24px 0' }}>
              <Grid.Column>
                <div style={infoTextStyle}>
                  {INFO_TEXT}
                  <a href={CYOD_HELP_CENTER_LINK} target="_blank" rel="noopener noreferrer" style={anchorText}>
                    Upload Your External Data Goal Type.
                  </a>
                </div>
              </Grid.Column>
            </Grid.Row>
            <DataUploaderForm />
          </Grid>
        </Form>
      </FormProvider>
    </div>
  );
};

export default DataUploader;
