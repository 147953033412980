import _ from 'lodash';
import {
  FETCH_SEGMENTS,
  FETCH_SEGMENT_RECENCY_ANALYTICS,
  RESET_DATA,
} from './constants';

export const INITIAL_STATE = {
  headers: [],
  analytics: [],
  data: [],
  loading: true,
  loadingSegments: true,
  error: false,
  noData: false,
  downloading: false,
};

function segmentRecencyAnalyticsReducer(
  state: { headers: Array<unknown>, analytics: Array<unknown>, loading: boolean, error: boolean, noData: boolean } = INITIAL_STATE,
  action: { payload: any, type: string },
) {
  switch (action.type) {
    case RESET_DATA:
      return { ...INITIAL_STATE };
    case FETCH_SEGMENT_RECENCY_ANALYTICS.STARTED:
      return {
        ...state, headers: [], analytics: [], loading: true, error: false,
      };
    case FETCH_SEGMENT_RECENCY_ANALYTICS.COMPLETED:
      return { ...state, loading: false, ...action.payload };
    case FETCH_SEGMENT_RECENCY_ANALYTICS.NO_DATA:
    case FETCH_SEGMENT_RECENCY_ANALYTICS.FAILED:
      return { ...state, loading: false, noData: true };
    case FETCH_SEGMENTS.STARTED:
      return { ...state, loadingSegments: true };
    case FETCH_SEGMENTS.COMPLETED:
      return {
        ...state,
        loadingSegments: false,
        segmentLookup: _.keyBy(action.payload, 'id'),
        segments: action.payload,
      };
    default:
      return state;
  }
}

export default segmentRecencyAnalyticsReducer;
