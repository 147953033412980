import { HOME_PAGE_ELEMENT_TAG } from 'cssTagConstants';
import { Member, Advertiser, StrategyType, GoalType } from 'utils/types';

const PREFIX = 'STRATEGYLIST';
export const sortBy = 'Sort By';

export enum StrategyListView {
  allUsers = 'all',
  myUser = 'my',
  starred = 'starred',
}

export const FETCH_MEMBER = {
  STARTED: `${PREFIX}:FETCH_MEMBER_STARTED`,
  COMPLETED: `${PREFIX}:FETCH_MEMBER_COMPLETED`,
  FAILED: `${PREFIX}:FETCH_MEMBER_FAILED`,
};

export const FETCH_ADVERTISER = {
  STARTED: `${PREFIX}:FETCH_ADVERTISER_STARTED`,
  COMPLETED: `${PREFIX}:FETCH_ADVERTISER_COMPLETED`,
  FAILED: `${PREFIX}:FETCH_ADVERTISER_FAILED`,
};

export const FETCH_STRATEGY_TYPE = {
  STARTED: `${PREFIX}:FETCH_STRATEGY_TYPE_STARTED`,
  COMPLETED: `${PREFIX}:FETCH_STRATEGY_TYPE_COMPLETED`,
  FAILED: `${PREFIX}:FETCH_STRATEGY_TYPE_FAILED`,
};

export const FETCH_GOAL_TYPE = {
  STARTED: `${PREFIX}:FETCH_GOAL_TYPE_STARTED`,
  COMPLETED: `${PREFIX}:FETCH_GOAL_TYPE_COMPLETED`,
  FAILED: `${PREFIX}:FETCH_GOAL_TYPE_FAILED`,
};

export const DOWNLOAD_HELIOS_REPORT = {
  STARTED: `${PREFIX}:DOWNLOAD_HELIOS_REPORT_STARTED`,
  COMPLETED: `${PREFIX}:DOWNLOAD_HELIOS_REPORT_COMPLETED`,
  COMPLETED_ALL: `${PREFIX}:DOWNLOAD_HELIOS_REPORT_COMPLETED_ALL`,
  FAILED: `${PREFIX}:DOWNLOAD_HELIOS_REPORT_FAILED`,
};

export const FILTER = {
  USER_INPUT: {
    MEMBER: {
      ID: 'member',
      NAME: 'Member',
    },
    ADVERTISER: {
      ID: 'advertiser',
      NAME: 'Advertiser',
      SEARCH_UPDATED: `${PREFIX}:FILTER_ADVERTISER_SEARCH_UPDATED`,
    },
    STRATEGY: {
      ID: 'strategyType',
      NAME: 'Strategy Type',
    },
    SORT: {
      ID: 'sort',
      NAME: 'sort',
    },
    ORDER: {
      ID: 'order',
      NAME: 'order',
    },
    CREATED_BY: {
      ID: 'createdBy',
      NAME: 'Created By',
    },
    UPDATED_BY: {
      ID: 'updatedBy',
      NAME: 'Updated By',
    },
    GOAL_TYPE: {
      ID: 'goalType',
      NAME: 'Goal',
    },
    STARRED: {
      ID: 'starred',
      NAME: 'Starred',
    },
  },
  UPDATED: {
    ANY: `${PREFIX}:FILTER_UPDATED_ANY`,
    SORT: `${PREFIX}:FILTER_UPDATED_SORT`,
  },
};

export type StrategyListViewOptionType = {
  text: string
  value: StrategyListView
};

export const STRATEGIES_LIST_VIEW_OPTIONS = [
  { text: 'All Strategies', value: StrategyListView.allUsers },
  { text: 'My Strategies', value: StrategyListView.myUser },
  { text: 'Starred Strategies', value: StrategyListView.starred },
];

export type StrategiesListFilterType = {
  member: Array<number> | Array<Member>
  advertiser: Array<number> | Array<Advertiser>
  strategyType: Array<number> | Array<StrategyType>
  goalType: Array<string>
  createdBy: string
  updatedBy: string
  sort: string
  order: 'ASC' | 'DESC'
  starred: boolean
  or?: string
};

export const DEFAULT_FILTER: StrategiesListFilterType = {
  member: [],
  advertiser: [],
  strategyType: [],
  goalType: [],
  sort: 'updatedAt',
  order: 'DESC',
  createdBy: '',
  updatedBy: '',
  starred: false,
};

export const WPP_SORT_OPTIONS = [
  {
    text: 'Strategy Name (A-Z)',
    value: 'name',
    order: 'ASC',
    id: HOME_PAGE_ELEMENT_TAG.filterByStratName,
  },
  {
    text: 'Strategy Name (Z-A)',
    value: 'name',
    order: 'DESC',
    id: HOME_PAGE_ELEMENT_TAG.filterByStratName,
  },
  {
    text: 'Last Modified (Latest)',
    value: 'updatedAt',
    order: 'DESC',
    id: HOME_PAGE_ELEMENT_TAG.filterByLastMod,
  },
  {
    text: 'Last Modified (Oldest)',
    value: 'updatedAt',
    order: 'ASC',
    id: HOME_PAGE_ELEMENT_TAG.filterByLastMod,
  },
];

export const FETCH_STRATEGY = `${PREFIX}:FETCH_STRATEGY`;
export const FETCH_STRATEGY_COMPLETED = `${PREFIX}:FETCH_STRATEGY_COMPLETED`;
export const FETCH_STRATEGY_FAILED = `${PREFIX}:FETCH_STRATEGY_FAILED`;
export const STRATEGIES_PER_PAGE_OPTIONS = [10, 25, 50, 100];
export const STRATEGIES_STARTING_ELEMENTS_PER_PAGE = 50;

export const STRATEGY_LIST_LIMIT_UPDATE = `${PREFIX}:STRATEGY_LIST_LIMIT`;
export const MARGIN = 'Margin';
export const IMPRESSIONS = 'Impressions';
export const STRATEGIES = 'Strategies';
export const CLEAR_RECENTLY_SAVED = `${PREFIX}:CLEAR_RECENTLY_SAVED`;
export const DELETE_STRATEGY = `${PREFIX}:DELETE_STRATEGY`;
export const DELETE_STRATEGY_COMPLETED = `${PREFIX}:DELETE_STRATEGY_COMPLETED`;
export const DELETE_STRATEGY_FAILED = `${PREFIX}:DELETE_STRATEGY_FAILED`;
export const TOGGLE_MODAL = `${PREFIX}:TOGGLE_MODAL`;
export const SET_SINGLE_STRAT_FOR_DELETION = `${PREFIX}:SET_SINGLE_STRAT_FOR_DELETION`;
export const UPDATE_USER_SETTING = `${PREFIX}:UPDATE_USER_SETTING`;
export const UPDATE_USER_SETTING_COMPLETED = `${PREFIX}:UPDATE_USER_SETTING_COMPLETED`;
export const UPDATE_USER_SETTING_FAILED = `${PREFIX}:UPDATE_USER_SETTING_FAILED`;
export const SAVE_STRATEGY_AND_FLIGHT_SUCCEEDED = `${PREFIX}:SAVE_STRATEGY_AND_FLIGHT_SUCCEEDED`;
export const SAVE_BULK_STRATEGY_IN_PROGRESS = `${PREFIX}:SAVE_BULK_STRATEGY_IN_PROGRESS`;
export const SAVE_STRATEGY_AND_FLIGHT_FAILED = `${PREFIX}:SAVE_STRATEGY_AND_FLIGHT_FAILED`;
export const SAVE_STRATEGY_FAILED = `${PREFIX}:SAVE_STRATEGY_FAILED`;
export const REMOVE_STARRED_STRATEGY_FROM_LIST = `${PREFIX}:REMOVE_STARRED_STRATEGY_FROM_LIST`;
export const UPDATE_CLIENT_TEST_IN_LIST = `${PREFIX}:UPDATE_CLIENT_TEST_IN_LIST`;
export const SELECT_ALL = `${PREFIX}:SELECT_ALL`;
export const SELECT_NONE = `${PREFIX}:SELECT_NONE`;
export const SELECT_ONE = `${PREFIX}:SELECT_ONE`;
export const CLEAR_FILTERS = `${PREFIX}:CLEAR_FILTERS`;
export const CLEAR_FILTERS_COMPLETED = `${PREFIX}:CLEAR_FILTERS_COMPLETED`;

export type EditStrategiesFormType = {
  name?: string
  goal?: GoalType
  goalTarget?: number
  pacing?: number
  viewabilityEnabled?: boolean
  viewabilityTarget?: number
  intelligentChildObjects?: boolean
};
