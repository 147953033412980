import { combineReducers } from 'redux';
import seatListReducer from './reducers/seatListReducer';
import alterSeatReducer from './reducers/alterSeatReducer';
import dspReducer from './reducers/dspReducer';
import businessModelReducer from './reducers/businessModelReducer';
import businessUnitReducer from './reducers/businessUnitReducer';
import countryReducer from './reducers/countryReducer';
import feeOptionReducer from './reducers/feeOptionReducer';
import feeTypeReducer from './reducers/feeTypeReducer';
import regionReducer from './reducers/regionReducer';
import permissionsReducer from './reducers/permissionsReducer';

const rootSeatsReducer = combineReducers({
  seatList: seatListReducer,
  alterSeat: alterSeatReducer,
  businessModels: businessModelReducer,
  businessUnits: businessUnitReducer,
  feeOptions: feeOptionReducer,
  feeTypes: feeTypeReducer,
  dspList: dspReducer,
  countries: countryReducer,
  regions: regionReducer,
  permissions: permissionsReducer,
});

export default rootSeatsReducer;
