import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { DATE_FORMAT_JS_DATE } from 'utils/dateTime';
import { Input } from 'buildingBlocks';

/* eslint-disable react/prop-types */
export default ({ date, input, minDate, maxDate, ...rest }) => (
  <ReactDatePicker
    customInput={<Input />}
    onChange={input.onChange}
    selected={date}
    dateFormat={DATE_FORMAT_JS_DATE}
    autoComplete="off"
    minDate={minDate}
    maxDate={maxDate}
    {...rest}
  />
);
