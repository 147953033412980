import { Dispatch, SetStateAction } from 'react';
import { ADD_STARRED_USER_STRATEGY, REMOVE_STARRED_USER_STRATEGY } from 'containers/Login/constants';
import { StarredUserStrategyType } from 'containers/StrategyAnalytics/types';
import { REMOVE_STARRED_STRATEGY_FROM_LIST } from 'containers/StrategiesList/constants';
import { starredStratsFilterSelected } from 'containers/StrategiesList/utils';
import { StarredUserStrategy } from 'utils/copilotAPI';

export const unstarUserStrategy = async (
  starredUserStrategyId: number,
  strategyId: number,
  setLoading: (x: boolean) => void,
  dispatch: Dispatch<SetStateAction<any>>,
) => {
  try {
    await StarredUserStrategy.unstarStrategy({ id: starredUserStrategyId });
    dispatch({ type: REMOVE_STARRED_USER_STRATEGY, payload: starredUserStrategyId });
    // update strategy list if on strategy list page with starred filter enabled
    if (starredStratsFilterSelected()) {
      dispatch({ type: REMOVE_STARRED_STRATEGY_FROM_LIST, payload: strategyId });
    }
  } catch (e) {
    console.error(e);
  } finally {
    setLoading(false);
  }
};

export const starUserStrategy = async (
  userStrategyObj: Omit<StarredUserStrategyType, 'id'>,
  setLoading: (x: boolean) => void,
  dispatch: Dispatch<SetStateAction<any>>,
) => {
  try {
    const starredStrategy = await StarredUserStrategy.starStrategy(userStrategyObj);
    dispatch({ type: ADD_STARRED_USER_STRATEGY, payload: starredStrategy.data });
  } catch (e) {
    console.error(e);
  } finally {
    setLoading(false);
  }
};
