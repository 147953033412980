import React from 'react';
import { Message } from 'buildingBlocks';
import { GENERIC_ERROR_MSG } from 'constantsBase';
import { PossibleStates } from '../types';

export default ({ state }: { state: PossibleStates }) => {
  const kind = state.kind;
  switch (kind) {
    case 'noData': {
      return (
        <Message size="small" warning>
          This flight doesn&apos;t have any data currently, please try again later.
        </Message>
      );
    }
    case 'error': {
      return (
        <Message size="small" error>
          {GENERIC_ERROR_MSG}
        </Message>
      );
    }
    default: {
      return null;
    }
  }
};
