import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { ObjectMultiDropdown } from 'buildingBlocks';
import { ObjectDropdownSearch } from 'buildingBlocks/ObjectDropdown/ObjectDropdown';
import { GlobalState } from 'reducers';
import { AdvertiserWithPopulatedMember, GoalType, PrettyOption } from 'utils/types';

type FilterDropdownProps = {
  filterType: { ID: string, NAME: string, SELECTED?: string }
  onChange: (options: any) => void
  options: Array<AdvertiserWithPopulatedMember | PrettyOption | GoalType>
  value: Array<AdvertiserWithPopulatedMember | PrettyOption | GoalType>
  keyFn: (option: any) => void
  loading?: boolean
  error?: string
  renderLabel?: Function
  search?: ObjectDropdownSearch
};

export const FilterDropdown = (props: FilterDropdownProps) => {
  const selectedStrategies = useSelector<GlobalState>((state) => state.strategiesList.selectedStrategies);
  return (
    <ObjectMultiDropdown
      {..._.omit(props, 'filterType')}
      fluid
      selection
      scrolling
      name={props.filterType.NAME}
      placeholder={`${props.filterType.NAME}`}
      disabled={props.loading || !!props.error || !_.isEmpty(selectedStrategies)}
      // @ts-ignore id & noResultsMessage don't exist within ObjectMultiDropdown props but do for the semantic dropdown it is built from - jChiu
      id={props.filterType.ID}
      noResultsMessage={props.options.length > 0 ? 'No remaining options.' : `No ${props.filterType.NAME} available.`}
    />
  );
};

type PrettyAdvertiserNameProps = {
  advertiser: AdvertiserWithPopulatedMember
};

export const PrettyAdvertiserName = ({ advertiser }: PrettyAdvertiserNameProps) => (
  <span>
    <span style={{ color: 'grey' }}>{advertiser.member.displayName}</span>
    <span style={{ marginLeft: '.5em' }}>|</span>
    <span style={{ marginLeft: '.5em' }}>
      {advertiser.name} ({advertiser.externalId})
    </span>
  </span>
);
