import { StringMapping } from 'utils/airflow/nameTranslator';

const PREFIX = 'AUDIENCE_REPORT';
export const RESET_FORM = `${PREFIX}:RESET_FORM`;
export const DOWNLOAD_REPORT = `${PREFIX}:DOWNLOAD_REPORT`;
export const DOWNLOAD_REPORT_COMPLETED = `${PREFIX}:DOWNLOAD_REPORT_COMPLETED`;
export const DOWNLOAD_REPORT_FAILED = `${PREFIX}:DOWNLOAD_REPORT_FAILED`;

export const FORM_NAME = 'audience-form';

export const REPORT_DIMENSIONS = {
  pixel_id: { text: 'Pixel ID', value: 'pixel_id' },
  age_bucket: { text: 'Age Bucket', value: 'age_bucket' },
  age_bucket_id: { text: 'Age Bucket ID', value: 'age_bucket_id' },
  hour: { text: 'Hour', value: 'hour' },
  segment_code: { text: 'Segment Code', value: 'segment_code' },
  gender: { text: 'Gender', value: 'gender' },
  month: { text: 'Month', value: 'month' },
  buyer_member_id: { text: 'Buyer Member ID', value: 'buyer_member_id' },
  insertion_order_id: { text: 'Insertion Order ID', value: 'insertion_order_id' },
  campaign_id: { text: 'Campaign ID', value: 'campaign_id' },
  advertiser_id: { text: 'Advertiser ID', value: 'advertiser_id' },
  line_item_id: { text: 'Line Item ID', value: 'line_item_id' },
  advertiser_name: { text: 'Advertiser Name', value: 'advertiser_name' },
  line_item_name: { text: 'Line Item Name', value: 'line_item_name' },
  insertion_order_name: { text: 'Insertion Order Name', value: 'insertion_order_name' },
  segment_owner_member_id: { text: 'Segment Owner Member ID', value: 'segment_owner_member_id' },
  segment_owner_member_name: { text: 'Segment Owner Member Name', value: 'segment_owner_member_name' },
  is_advertiser_segment: { text: 'Advertiser Segment (Y/N)', value: 'is_advertiser_segment' },
};
export const REPORT_METRICS = {
  clicks: { text: 'Clicks', value: 'clicks' },
  convs_per_mm: { text: 'Conversions Per MM', value: 'convs_per_mm' },
  convs_rate: { text: 'Conversion Rate', value: 'convs_rate' },
  cost_ecpa: { text: 'Cost CPA', value: 'cost_ecpa' },
  cost_ecpc: { text: 'Cost CPC', value: 'cost_ecpc' },
  cpm: { text: 'CPM', value: 'cpm' },
  ctr: { text: 'Click-Through Rate', value: 'ctr' },
  profit: { text: 'Profit', value: 'profit' },
  profit_ecpm: { text: 'Profit CPM', value: 'profit_ecpm' },
  revenue_ecpa: { text: 'Revenue CPA', value: 'revenue_ecpa' },
  revenue_ecpc: { text: 'Revenue CPC', value: 'revenue_ecpc' },
  rpm: { text: 'Revenue Per Mille', value: 'rpm' },
  total_convs: { text: 'Total Conversions', value: 'total_convs' },
  post_view_convs: { text: 'Post-View Conversions', value: 'post_view_convs' },
  post_view_revenue: { text: 'Post-View Revenue', value: 'post_view_revenue' },
  post_click_convs: { text: 'Post-Click Conversions', value: 'post_click_convs' },
  post_click_revenue: { text: 'Post-Click Revenue', value: 'post_click_revenue' },
  post_view_convs_rate: { text: 'Post-View Conversion Rate', value: 'post_view_convs_rate' },
  post_click_convs_rate: { text: 'Post-Click Conversion Rate', value: 'post_click_convs_rate' },
  media_cost: { text: 'Media Cost', value: 'media_cost' },
  commissions: { text: 'Commissions', value: 'commissions' },
  serving_fees: { text: 'Serving Fees', value: 'serving_fees' },
  booked_revenue: { text: 'Booked Revenue', value: 'booked_revenue' },
};

export const REPORT_INTERVAL = {
  today: { text: 'Today', value: 'today' },
  yesterday: { text: 'Yesterday', value: 'yesterday' },
  last_7_days: { text: 'Last 7 days', value: 'last_7_days' },
};

export const AUDIENCE_REPORT_MICROSERVICE = 'audience_report_handler';

export const mapping = [
  ['memberExternalId', 'member_ext_id'],
  ['reportDimensions', 'report_dimensions'],
  ['reportMetrics', 'report_metrics'],
  ['reportInterval', 'report_interval'],
  ['strategyId', 'strategy_id'],
] as Array<StringMapping>;

export const REQUEST_SUCCESS_MESSAGE = {
  content: 'Request submitted. You will receive a notification when the report is ready to download. To reduce download times, reduce the time frame or the number of columns selected.',
  icon: 'checkmark',
  className: 'positive',
};

export const REQUEST_FAILURE_MESSAGE = {
  content: 'Your download request has failed to initiate. Please contact the Copilot team.',
  icon: 'warning',
  className: 'negative',
};

export const SUB_HEADER_TEXT = 'The following columns are included in the report by default: Date, Segment Name, Segment ID, Impressions, and Targeted? (Y/N)';

export const INITIAL_VALUES = {
  reportDimensions: [],
  reportMetrics: [],
  reportInterval: REPORT_INTERVAL.last_7_days.value,
};
