import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Button, Form, Grid, Modal, Header, Message, Loader, Dimmer } from 'buildingBlocks';
import Toggle from 'components/Toggle';
import { isQAGlobalReadOnly } from 'utils/functionHelpers';
import { GlobalState } from 'reducers';
import { User } from 'utils/types';
import { NEW_SEAT } from '../reducers/alterSeatReducer';
import SeatFormFields from './SeatFormFields';
import { stopAlterSeat, saveSeat } from '../actions/index';
import { containerStyle } from '../style';
import validator from '../validate';

const SeatForm = ({ open }: { open: boolean }) => {
  const dispatch = useDispatch();

  // Retrieve state values using selectors
  const { dspList, businessUnits, countries, regions, alterSeat: { seat: initialValues, saving, error: errorMessage }, businessModels, feeOptions, feeTypes, permissions } = useSelector<GlobalState>((state) => state.seats) as any;
  const user = useSelector<GlobalState>((state) => state.login.session.data.user) as User;

  const methods = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: validator,
  });

  const { handleSubmit, reset, formState: { isValid }, getValues, control } = methods;

  useEffect(() => {
    if (initialValues?.id) {
      reset(initialValues);
    } else {
      reset(NEW_SEAT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, initialValues]);

  const onSubmit = () => {
    const formValues = getValues();
    const seatObject = { ...formValues, accountId: formValues.prefix };
    dispatch(saveSeat(seatObject));
  };

  const handleCancel = () => {
    dispatch(stopAlterSeat());
  };

  const qAReadOnly = isQAGlobalReadOnly(user);

  return (
    <Modal open={open}>
      {saving && (
        <Dimmer active inverted>
          <Loader active inverted size="large" />
        </Dimmer>
      )}
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid stackable style={{ margin: 'initial' }}>
            <Grid.Row style={{ ...containerStyle.padding, ...containerStyle.header }} columns={1}>
              <Grid.Column>
                <Header
                  content={initialValues?.id ? 'Edit member' : 'Create a new member'}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={containerStyle.padding} columns={1}>
              <Grid.Column>
                <SeatFormFields
                  initialValues={initialValues}
                  dspList={dspList}
                  countries={countries}
                  businessUnits={businessUnits}
                  regions={regions}
                  businessModels={businessModels}
                  feeOptions={feeOptions}
                  feeTypes={feeTypes}
                  permissions={permissions}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ ...containerStyle.padding, ...containerStyle.footer }} columns={2}>
              <Grid.Column>
                <Controller
                  name="enabled"
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      label="Enabled"
                      checked={field.value}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column style={{ textAlign: 'right' }}>
                <Button onClick={handleCancel} content="Cancel" />
                <Button
                  primary
                  disabled={qAReadOnly || !isValid}
                  type="submit"
                  content="Save"
                />
              </Grid.Column>
            </Grid.Row>
            {errorMessage && (
              <Grid.Row centered columns={1}>
                <Grid.Column>
                  <Message
                    className="visible"
                    size="small"
                    error
                    header={errorMessage.title}
                    content={errorMessage.message}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default SeatForm;
