import _ from 'lodash';
import { SetStateAction } from 'react';
import { Dispatch } from 'redux';
import { GOAL_TYPES, GOAL_TYPES_TO_STRATEGY_TYPES, GOAL_VALUE_TYPE, STRATEGY_TYPE } from 'constantsBase';
import { ADD_STARRED_USER_STRATEGY, REMOVE_STARRED_USER_STRATEGY } from 'containers/Login/constants';
import { BUDGET_OPT_CONFIG, BUDGET_OPTIMIZATION_ALGO_TYPES, GROUP_SETTINGS, TARGETING_PLUS_ALGO_TYPES } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { getTargetingPlusAlgoTypeId } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/mapper';
import { getConfigObjByAlgoTypeId } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { DAG } from 'containers/StrategyWizard/ConfigurationByStrategyType/constants';
import { CONVERSION_BASED_GOALS, REVENUE_TYPE_GOAL_TYPES } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { Currency, StarredUserStrategy, Strategy } from 'utils/copilotAPI';
import { Algorithm, EnhancedStrategy, Flight, StrategyGoalsDB, ToastConfig } from 'utils/types';
import { selectNone } from './actions';
import { EditStrategiesFormType, REMOVE_STARRED_STRATEGY_FROM_LIST, StrategiesListFilterType } from './constants';

export const starredStratsFilterSelected = () => _.includes(window.location.search, 'starred=true');

export const starMultipleStrategies = async (
  user: number,
  strategyIds: Array<number>,
  setStarStrategyLoading: (x: boolean) => void,
  dispatch: Dispatch<SetStateAction<any>>,
) => {
  setStarStrategyLoading(true);
  try {
    const newlyStarredStrategies = await StarredUserStrategy.starMultipleStrategies({ user, strategyIds });
    dispatch({ type: ADD_STARRED_USER_STRATEGY, payload: newlyStarredStrategies.data });
  } catch (e) {
    console.error(e);
  } finally {
    setStarStrategyLoading(false);
  }
};

export const unstarMultipleStrategies = async (
  strategyStarredEntries: Array<{ stratId: number, starredId: number }>,
  setStarStrategyLoading: (x: boolean) => void,
  dispatch: Dispatch<SetStateAction<any>>,
) => {
  setStarStrategyLoading(true);
  try {
    const starredUserStrategyIds = _.map(strategyStarredEntries, 'starredId');
    await StarredUserStrategy.unstarMultipleStrategies({ id: starredUserStrategyIds });
    dispatch({ type: REMOVE_STARRED_USER_STRATEGY, payload: starredUserStrategyIds });
    // update strategy list if on strategy list page with starred filter enabled
    if (starredStratsFilterSelected()) {
      dispatch({ type: REMOVE_STARRED_STRATEGY_FROM_LIST, payload: _.map(strategyStarredEntries, 'stratId') });
      dispatch(selectNone());
    }
  } catch (e) {
    console.error(e);
  } finally {
    setStarStrategyLoading(false);
  }
};

export const orderStratsByFilter = (strategies: Array<EnhancedStrategy>, filter: StrategiesListFilterType) => (
  _.orderBy(strategies, filter.sort, _.toLower(filter.order) as 'asc' | 'desc')
);

export const isAmazonStrategy = (strategy: EnhancedStrategy) => _.isEqual(STRATEGY_TYPE.amznBudgetOptimization.id, strategy.strategyType.id);

const getActiveBudgetOptAlgo = (strategy: EnhancedStrategy) => _.find(strategy.algorithms, (algo) => (algo.active && _.includes(BUDGET_OPTIMIZATION_ALGO_TYPES, algo.algorithmType)));

const activeTargetingPlusAlgoCheck = (algo: Algorithm) => (algo.active && _.includes(TARGETING_PLUS_ALGO_TYPES, algo.algorithmType));

export const getPrimaryGoal = (strategyGoals: StrategyGoalsDB) => _.find(strategyGoals, { active: true, priority: 1 });

export const initEditSingleStratFormValues = (strategy: EnhancedStrategy): EditStrategiesFormType => {
  const primaryGoal = getPrimaryGoal(strategy.strategyGoals);
  // the goal is treated as a custom goal if there is no matching goal type
  const goal = _.find(GOAL_TYPES, (gT) => _.isEqual(gT.value, _.camelCase(primaryGoal?.type))) ?? GOAL_TYPES.awgCreateYourOwn;
  const pacing = _.get(getActiveBudgetOptAlgo(strategy), `config.${DAG}.${BUDGET_OPT_CONFIG}.daily_parent_budget_inflation_ratio`);
  const viewabilityGoal = _.find(strategy.strategyGoals, { active: true, priority: 2, type: _.snakeCase(GOAL_TYPES.ivrMeasured.value) });
  const intelligentChildObjects = _.some(strategy.algorithms, activeTargetingPlusAlgoCheck);
  return {
    name: strategy.name,
    goal,
    goalTarget: primaryGoal?.target ?? null,
    pacing,
    viewabilityEnabled: !!viewabilityGoal,
    viewabilityTarget: viewabilityGoal ? viewabilityGoal.target : null,
    intelligentChildObjects,
  };
};

export const initEditMultipleStratFormValues = (selectedStrategies: Array<EnhancedStrategy>) => {
  const individualFormVals = _.map(selectedStrategies, (strat) => initEditSingleStratFormValues(strat));
  const firstStratFormVal = _.head(individualFormVals);

  const allStratsHaveSameFieldVal = (field: keyof EditStrategiesFormType) => _.every(individualFormVals, (val) => _.isEqual(firstStratFormVal[field], val[field]));
  const allStratsHaveSameGoalType = allStratsHaveSameFieldVal('goal');

  return {
    goal: allStratsHaveSameGoalType ? firstStratFormVal.goal : null,
    goalTarget: (allStratsHaveSameGoalType && allStratsHaveSameFieldVal('goalTarget')) ? firstStratFormVal.goalTarget : null,
    pacing: allStratsHaveSameFieldVal('pacing') ? firstStratFormVal.pacing : null,
    viewabilityEnabled: allStratsHaveSameFieldVal('viewabilityEnabled') ? firstStratFormVal.viewabilityEnabled : null,
    viewabilityTarget: allStratsHaveSameFieldVal('viewabilityTarget') ? firstStratFormVal.viewabilityTarget : null,
    intelligentChildObjects: allStratsHaveSameFieldVal('intelligentChildObjects') ? firstStratFormVal.intelligentChildObjects : null,
  };
};

export const editExcludedGoalTypes = [
  ...CONVERSION_BASED_GOALS,
  GOAL_TYPES.cyodCostPerSuccessEvent.value,
  GOAL_TYPES.cyodRateOfSuccessEvent.value,
  GOAL_TYPES.netCpc.value,
  GOAL_TYPES.netCpcv.value,
  GOAL_TYPES.netCpm.value,
  GOAL_TYPES.margin.value,
  GOAL_TYPES.awgCreateYourOwn.value,
  GOAL_TYPES.impactOutcome.value,
];

export const getGoalTypeOptions = (strategies: Array<EnhancedStrategy>) => {
  const strategyTypes = _.uniq(_.map(strategies, 'strategyType.id'));
  // exclude conversion based, weighting required, and revenue type enabled goal types
  // also ensure that the goal types can be applied to every strategy selected
  const goalTypeOptions = _(GOAL_TYPES_TO_STRATEGY_TYPES)
    .chain()
    .pickBy((stratIds: Array<number>, gT: string) => (
      !_.includes(editExcludedGoalTypes, gT) && (_.size(_.intersection(stratIds, strategyTypes)) === _.size(strategyTypes))
    ))
    .keys()
    .map((gT) => _.find(GOAL_TYPES, (goal) => _.isEqual(goal.value, gT)))
    .value();

  if (_.size(strategies) !== 1) {
    return goalTypeOptions;
  }

  // include the original goal if only editing one strategy - for display purposes of custom goals
  const primaryGoal = getPrimaryGoal(_.head(strategies).strategyGoals);
  const primaryGoalType = _.find(GOAL_TYPES, (gT) => _.isEqual(gT.value, _.camelCase(primaryGoal.type))) ?? GOAL_TYPES.awgCreateYourOwn;
  return _.uniqBy([...goalTypeOptions, primaryGoalType], 'value');
};

export const getCurrency = async (flight: Flight) => {
  const currencyRes = await Currency.getById(_.toString(flight.currency));
  return currencyRes.data;
};

export const getGoalTargetLabel = async (valueType: string, singleStrategySelected: EnhancedStrategy) => {
  if (valueType === GOAL_VALUE_TYPE.PERCENTAGE) {
    return '%';
  }
  const firstFlight = singleStrategySelected && _.head(_.values(singleStrategySelected.flights));
  if (valueType === GOAL_VALUE_TYPE.CURRENCY && firstFlight) {
    const currency = await getCurrency(firstFlight);
    return currency.code ?? null;
  }
  return null;
};

export const stratsHaveRevTypeOrCustomGoal = (selectedStrategies: Array<EnhancedStrategy>) => _.some(selectedStrategies, (strat) => {
  const primaryGoalType = getPrimaryGoal(strat.strategyGoals)?.type;
  return _.some(strat.algorithms, { active: true, has_custom_revenue_type: true }) || _.includes(_.map(REVENUE_TYPE_GOAL_TYPES, _.snakeCase), primaryGoalType) || _.isNil(primaryGoalType);
});

export const getEditStrategiesReqObj = (
  selectedStrategies: Array<EnhancedStrategy>,
  formValues: EditStrategiesFormType,
  defaultValues: EditStrategiesFormType,
) => {
  const updatedValues: EditStrategiesFormType = _.transform(defaultValues, (res, v, k) => {
    if (!_.isEqual(v, formValues[k])) {
      // ensure that goal target is a number
      res[k] = (_.isEqual(k, 'goalTarget')) ? _.toNumber(formValues[k]) : formValues[k];
    }
  }, {});
  const goalTypeUpdated = !!updatedValues.goal;
  const updatePrimaryGoal = goalTypeUpdated || updatedValues.goalTarget;
  const includeViewabilityInStratGoals = (!_.isNil(updatedValues.viewabilityEnabled) && updatedValues.viewabilityEnabled)
    || (_.isNil(updatedValues.viewabilityEnabled) && defaultValues.viewabilityEnabled);

  const strategyToSettings = _.transform(selectedStrategies, (res, strat) => {
    const prevCampLvlOptAlgo = getActiveBudgetOptAlgo(strat);
    const prevDagConfig = prevCampLvlOptAlgo.config[DAG];
    const prevGroupSettings = prevDagConfig[GROUP_SETTINGS];
    const prevTargetingPlusAlgo = _.find(strat.algorithms, activeTargetingPlusAlgoCheck);
    const targetingPlusAlgoId = getTargetingPlusAlgoTypeId(prevCampLvlOptAlgo.algorithmType);
    const isAmazonStrat = isAmazonStrategy(strat);
    // intelligent child objects are not available for Amazon
    const includeTargetingPlus = !isAmazonStrat && ((prevTargetingPlusAlgo && _.isNil(updatedValues.intelligentChildObjects)) || updatedValues.intelligentChildObjects);
    const primaryGoal = goalTypeUpdated ? updatedValues.goal.value : _.camelCase(getPrimaryGoal(strat.strategyGoals).type);
    const formValuesForAdditionalMapping = {
      strategyGoals: [{ type: primaryGoal }],
      intelligentChildObjects: includeTargetingPlus,
      bidOptimization: includeTargetingPlus,
    };
    res[strat.id] = {
      ...(updatedValues.name && { name: updatedValues.name }),
      config: {
        [prevCampLvlOptAlgo.algorithmType]: {
          id: prevCampLvlOptAlgo.id,
          name: _.get(prevCampLvlOptAlgo, 'name'),
          enabled: true,
          [DAG]: {
            ...(goalTypeUpdated ? _.omit(prevDagConfig, 'cyod_enabled') : prevDagConfig), // remove cyod references if goal has been changed
            [BUDGET_OPT_CONFIG]: {
              ...prevDagConfig[BUDGET_OPT_CONFIG],
              ...(updatedValues.pacing && { daily_parent_budget_inflation_ratio: updatedValues.pacing }),
            },
          },
        },
        ...(includeTargetingPlus && {
          [targetingPlusAlgoId]: {
            ...(prevTargetingPlusAlgo && { id: prevTargetingPlusAlgo.id }),
            enabled: true,
            [DAG]: {
              // include group settings as backend included references
              ...(prevGroupSettings && { [GROUP_SETTINGS]: prevGroupSettings }),
              // bidOptimization is enabled if intelligentChildObjects is enabled
              ...getConfigObjByAlgoTypeId(targetingPlusAlgoId, formValuesForAdditionalMapping),
              has_custom_revenue_type: (updatedValues.goal ? false : prevDagConfig.has_custom_revenue_type),
            },
          },
        }),
      },
      strategyGoals: [
        // only include primary goal if goal type or target has been changed
        ...(updatePrimaryGoal ? [{ type: _.snakeCase(primaryGoal), target: _.toNumber(updatedValues.goalTarget ?? formValues.goalTarget) }] : []),
        // only include viewability goal for non amazon strategies AND if viewability was previously enabled and unedited OR enabled
        ...((!isAmazonStrat && includeViewabilityInStratGoals) ? [{ type: _.snakeCase(GOAL_TYPES.ivrMeasured.value), target: (updatedValues.viewabilityTarget ?? defaultValues.viewabilityTarget) }] : []),
      ],
    };
  }, {});

  return { strategies: selectedStrategies, strategyToSettings };
};

export const saveEditedStrategies = async (
  selectedStrategies: Array<EnhancedStrategy>,
  formValues: EditStrategiesFormType,
  defaultValues: EditStrategiesFormType,
  setUpdating: (x: boolean) => void,
  afterUpdate: () => void,
  showToast: (config: ToastConfig) => void,
) => {
  setUpdating(true);

  try {
    const resp = await Strategy.bulkEditStrategies(getEditStrategiesReqObj(selectedStrategies, formValues, defaultValues));
    const updates = resp.data;
    const successfulUpdates = _.filter(updates, ['success', true]);
    const failedUpdates = _.filter(updates, ['success', false]);

    if (_.size(successfulUpdates)) {
      showToast({
        type: 'success',
        message: `The following strategies have been successfully updated: ${_.join(_.map(successfulUpdates, 'strategy.id'), ', ')}`,
        duration: 4000,
        maxMessageLines: 4,
      });
    }

    if (_.size(failedUpdates)) {
      showToast({
        type: 'error',
        message: `The following strategies were unable to be updated: ${_.join(_.map(failedUpdates, 'strategy.id'), ', ')}`,
        duration: 4000,
        maxMessageLines: 4,
      });
    }
  } catch (e) {
    console.error(e);
  } finally {
    setUpdating(false);
    afterUpdate();
  }
};
