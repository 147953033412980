import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { WppActionButton, WppDivider, WppIconChevron, WppIconInfo, WppSideModal, WppTooltip, WppTypography } from 'buildingBlocks';
import { truncateText } from 'charts/InsightsViz/utils';
import DspIcon from 'components/DspIcon';
import { selectedStrategiesPanel } from 'containers/StrategiesList/style';
import { COPILOT_COLORS } from 'globalStyles';
import { EnhancedStrategy } from 'utils/types';

const { WPP } = COPILOT_COLORS;
const { editStrategiesListContainer, strategiestListBanner, strategiesListRow, listRowStratName, listDivider } = selectedStrategiesPanel;

type ListRowProps = {
  strategy: EnhancedStrategy
  showDivider: boolean
};

const ListRow = (props: ListRowProps) => {
  const { strategy, showDivider } = props;

  return (
    <>
      <div style={strategiesListRow}>
        <WppTooltip text={strategy.name}>
          <WppIconInfo color={WPP.grey800} />
        </WppTooltip>
        <WppTypography type="s-body" style={listRowStratName}>{truncateText(strategy.name, 140)}</WppTypography>
        <DspIcon dspId={strategy.dspId} />
      </div>
      {showDivider && <WppDivider style={listDivider} />}
    </>
  );
};

type ShowAllObjectsSideModalProps = {
  openShowAll: boolean
  setOpenShowAll: (x: boolean) => void
};

const ShowAllObjectsSideModal = ({ openShowAll, setOpenShowAll }: ShowAllObjectsSideModalProps) => {
  const selectedStrategies = useSelector<GlobalState>((state) => state.strategiesList.selectedStrategies) as Array<EnhancedStrategy>;
  const selectedStrategiesSize = _.size(selectedStrategies);
  const onClose = () => setOpenShowAll(false);

  return (
    <WppSideModal
      size="l"
      withBackButton
      open={openShowAll}
      onWppSideModalClose={onClose}
      onWppSideModalBackButtonClick={onClose}
    >
      <WppTypography type="2xl-heading" slot="header">Selected Objects</WppTypography>
      <div slot="body">
        <div style={{ ...editStrategiesListContainer, marginTop: 0 }}>
          {_.map(selectedStrategies, (strategy, idx) => (
            <ListRow
              key={strategy.id}
              strategy={strategy}
              showDivider={selectedStrategiesSize > 1 && !_.isEqual(selectedStrategiesSize - 1, idx)}
            />
          ))}
        </div>
      </div>
    </WppSideModal>
  );
};

const EditStrategiesList = () => {
  const [openShowAll, setOpenShowAll] = useState<boolean>(false);
  const selectedStrategies = useSelector<GlobalState>((state) => state.strategiesList.selectedStrategies) as Array<EnhancedStrategy>;
  const selectedStrategiesSize = _.size(selectedStrategies);
  const displayShowAllBtn = selectedStrategiesSize > 3;
  const strategiesToShow = displayShowAllBtn ? _.take(selectedStrategies, 3) : selectedStrategies;
  const strategiesToShowSize = _.size(strategiesToShow);
  const bannerTitle = `Selected ${selectedStrategiesSize === 1 ? 'Object' : `Objects (${selectedStrategiesSize})`}`;

  return (
    <div style={editStrategiesListContainer}>
      <ShowAllObjectsSideModal
        openShowAll={openShowAll}
        setOpenShowAll={setOpenShowAll}
      />
      <div style={strategiestListBanner}>
        <WppTypography type="s-strong">{bannerTitle}</WppTypography>
        {displayShowAllBtn && (
          <WppActionButton variant="secondary" onClick={() => setOpenShowAll(true)}>
            View All Objects
            <WppIconChevron direction="right" slot="icon-end" />
          </WppActionButton>
        )}
      </div>
      {_.map(strategiesToShow, (strategy, idx) => (
        <ListRow
          key={strategy.id}
          strategy={strategy}
          showDivider={strategiesToShowSize > 1 && !_.isEqual(strategiesToShowSize - 1, idx)}
        />
      ))}
    </div>
  );
};

export default EditStrategiesList;
