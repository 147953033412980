import { required, applyAllValidators } from 'utils/formValidators';

export const validate = (values) => {
  const validators = {
    reportMetrics: required,
    reportInterval: required,
  };
  return applyAllValidators(values, validators);
};

export default validate;
