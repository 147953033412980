import _ from 'lodash';
import React, { useState } from 'react';
import { AgGrid, WppEmptyNothingFound, WppTypography } from 'buildingBlocks';
import { User, EnhancedStrategy, Strategy, ToastConfig } from 'utils/types';
import HeliosReportModal from 'components/StrategyActions/HeliosReportModal';
import { strategyEmptyMessageContainer } from 'containers/StrategiesList/style';
import { strategiesTableColumns } from './StrategyTableColumns';

type StrategyTableState = {
  isOpen: boolean
  strategy: EnhancedStrategy
};

type StrategyTableProps = {
  strategyCount: number
  filter: {}
  strategies: Array<EnhancedStrategy>
  heliosDownloadReport: (...args: Array<any>) => void
  downloadingReport: boolean
  downloadedReport: number
  toggleDeleteModal: Function
  user: User
  innerRef: any
  showToast: (config: ToastConfig) => void
  loadingStrategy?: boolean
};

const StrategyTable: React.FC<StrategyTableProps> = (props: StrategyTableProps) => {
  const {
    user, filter, toggleDeleteModal, loadingStrategy, heliosDownloadReport,
    downloadedReport, downloadingReport, innerRef, strategies, strategyCount, showToast,
  } = props;
  const [state, setState] = useState<StrategyTableState>({ isOpen: false, strategy: null });
  const rowData = _.lt(strategyCount, 1) ? [] : strategies;

  const handleDeleteStrategy = (strategy: Strategy) => {
    toggleDeleteModal(strategy);
  };

  const onCloseModal = () => {
    setState((prevState: any) => ({ ...prevState, isOpen: false, strategy: null }));
  };

  const handleDownloadStrategy = (data: EnhancedStrategy) => {
    if (data) {
      setState((prevState: any) => ({ ...prevState, isOpen: true, strategy: { ...data, flights: _.values(data.flights) } }));
    }
  };

  const noRowsMessage = `No strategy found. ${_.isEmpty(_.keys(_.omitBy(filter, _.isEmpty))) ? '' : ' Please redefine your filters'}`;

  const renderAgGrid = () => (
    <AgGrid
      rowHeight={60}
      ref={innerRef}
      rowSelection="multiple"
      noRowsMessage={noRowsMessage}
      loading={loadingStrategy}
      className="strategies-list"
      columnDefs={strategiesTableColumns(
        user,
        rowData,
        handleDeleteStrategy,
        handleDownloadStrategy,
        showToast,
      )}
      rowData={rowData}
      suppressRowClickSelection
    />
  );

  const renderEmptyMessage = () => (
    <div style={strategyEmptyMessageContainer}>
      <WppEmptyNothingFound width={120} />
      <WppTypography tag="p" type="s-body">{noRowsMessage}</WppTypography>
    </div>
  );

  return (
    <>
      {state.isOpen && (
        <HeliosReportModal
          {...state.strategy}
          isOpen={state.isOpen}
          downloadedReport={downloadedReport}
          downloadingReport={downloadingReport}
          heliosDownloadReport={heliosDownloadReport}
          onCancel={onCloseModal}
        />
      )}
      {(_.isEmpty(strategies) && !loadingStrategy) ? renderEmptyMessage() : renderAgGrid()}
    </>
  );
};

export default StrategyTable;
