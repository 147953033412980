export default {
  chart: {
    backgroundColor: null,
    borderWidth: 0,
    margin: [2, 0, 2, 0],
    height: 50,
    style: {
      overflow: 'visible',
    },

    // small optimalization, saves 1-2 ms each sparkline
    skipClone: true,
  },
  plotOptions: {
    series: {
      animation: false,
      shadow: false,
      marker: {
        enabled: false,
      },
    },
  },
  tooltip: {
    backgroundColor: null,
    borderWidth: 0,
    shadow: false,
    useHTML: true,
    hideDelay: 0,
    shared: true,
    padding: 0,
    // Enable outside to true because the highcharts tooltip was getting hidden in Ag grid
    outside: true,
    style: {
      fontFamily: 'Inter',
    },
    // Commented below line because enabling the outside to true, the tooltip position was not correct
    // positioner: (w, h, point) => ({ x: ((point.plotX - w) / 2), y: (point.plotY - h) }),
  },
  title: {
    text: '',
  },
  xAxis: {
    type: 'datetime',
    labels: {
      enabled: false,
    },
    title: {
      text: null,
    },
    startOnTick: false,
    endOnTick: false,
    tickPositions: [],
    gridLineWidth: 0,
    visible: true,
    lineColor: 'transparent',
  },
  yAxis: {
    endOnTick: false,
    startOnTick: true,
    labels: {
      enabled: false,
    },
    title: {
      text: null,
    },
    gridLineWidth: 0,
  },
  credits: { enabled: false },
  legend: { enabled: false },
  exporting: { enabled: false },
};
