import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

const style: { [key: string]: CSSProperties } = {
  tableHeaderStyle: {
    color: '#5b6e86',
  },
  marginTop: {
    marginTop: '8px',
  },
  circle: {
    width: '40px',
    height: '40px',
    background: 'green',
    borderRadius: '50px',
    display: 'inline-block',
    marginBottom: '1px',
  },
  tableStyle: {
    backgroundColor: '#f3ebeb',
  },
  td: {
    position: 'relative',
  },
  dropDown: {
    boxShadow: 'none',
    border: 'solid #ddd 1px',
  },
  pending: {
    color: '#ccc',
    background: '#EFEFEF',
  },
  filterContainer: {
    marginBottom: SPACING[16],
    marginTop: SPACING[2],
  },
  filerComponent: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'end',
  },
  textAlignRight: {
    textAlign: 'right',
    flex: 1,
    margin: 'auto',
  },
  filterSegment: {
    boxShadow: 'none',
    borderRadius: '0',
  },
  autoWidth: {
    width: 'auto',
  },
  strategyCellStyle: {
    display: 'flex',
    flexDirection: 'row',
    gap: SPACING[12],
  },
  cellFullHeight: {
    height: '100%',
    display: 'flex',
  },
};

export const snackBar: CSSProperties = {
  position: 'fixed',
  bottom: '0',
  zIndex: 10,
  textAlign: 'center',
  width: '100%',
  boxShadow: '0px 0px 10px 0px',
  padding: SPACING[12],
};

export const sortDropdownContainer: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: SPACING[12],
  minHeight: '34px',
  flexWrap: 'wrap',
};

export const pageHeaderContainer: CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
};

export const strategyTableColumns: { [key: string]: CSSProperties } = {
  strategyTypeDSPContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: SPACING[12],
  },
  brandNameContainer: {
    color: 'var(--wpp-primary-color-400)',
  },
  brandAdvMemberContainer: {
    display: 'flex',
    gap: SPACING[16],
    alignItems: 'center',
  },
  strategiesContainer: {
    lineHeight: '30px',
    padding: '8px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[8],
  },
  strategyDetail: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '24px',
    flexWrap: 'wrap',
    gap: SPACING[16],
  },
  advertiserContainer: {
    color: 'var(--wpp-success-color-500)',
  },
  legendBadgeContainer: {
    display: 'flex',
    gap: SPACING[8],
    height: '100%',
    flexWrap: 'wrap',
    padding: '8px 0px',
  },
  sparklineContainer: {
    padding: '8px 0',
    display: 'flex',
    alignItems: 'center',
  },
  testLabel: {
    height: SPACING[20],
  },
};

export const gridPagination: CSSProperties = {
  marginTop: SPACING[16],
};

export const segmentedControlItem: CSSProperties = {
  width: '80px',
  height: '24px',
};

export const strategyHeaderCell = {
  paddingLeft: '50px',
};

export const secondaryFilterStyles = {
  display: 'flex',
  justifyContent: 'right',
  gap: '50px',
};

export const dropDownColWidth = {
  width: '25%',
};

export default style;
