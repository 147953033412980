import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { GlobalState } from 'reducers';
import { Image, Header, Button, Modal } from 'buildingBlocks';
import { BLACK_INFO_CIRCLE_SRC } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { Microservices } from 'utils/copilotAPI';
import {
  CYOD_ENDPOINT, FileStates as FileStatesEnum,
  SYSTEM_ERROR_STATUS_CODE, SUBHEADER_TEXT,
} from '../constants';
import { dataUploader } from '../style';
import FileBanner from './FileBanner';
import { CSVFile, ExternalDataForm } from '../types';
import { getErrorMsg } from '../utils';

const {
  uploadBtn, modalContentContainer,
  modalHeaderContainer, modal, modalHeader,
  modalFooter, cancelBtn,
} = dataUploader;

type FileStatesProps = {
  file: CSVFile
  onDelete: () => void
  fileUploadState: FileStatesEnum
  setFileUploadState: Dispatch<SetStateAction<FileStatesEnum>>
};

const FileStates = ({ file, onDelete, fileUploadState, setFileUploadState }: FileStatesProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { setValue } = useFormContext<ExternalDataForm>();
  const {
    strategyAnalytics: {
      metadata: {
        strategy: { id: strategyId },
        flights,
      },
    },
    login: {
      user: { id: userId },
    },
  } = useSelector<GlobalState>((state) => state) as any;

  const fileName = file?.name;
  const modalHeaderText = `Copilot will optimize based on ${fileName}. This cannot be undone.`;
  const disableUpload = (fileUploadState in SUBHEADER_TEXT);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const validateFile = async (setFileState: Dispatch<SetStateAction<FileStatesEnum>>) => {
    try {
      const flightIds = _.map(_.flatMap(flights, 'id'), (id) => +id);
      const cyodRes = await Microservices.runService({ strategyId, data: file.data, fileName, userId, flightIds }, CYOD_ENDPOINT);
      setFileState(FileStatesEnum.success);
      // ensure file name is in file Obj
      setValue('fileName', fileName);
      setValue('presignedUrl', cyodRes.data.url);
    } catch (e) {
      const systemError = e.response.status === SYSTEM_ERROR_STATUS_CODE;
      const fileState = systemError ? FileStatesEnum.systemError : FileStatesEnum.failed;
      if (!systemError) {
        const errMsg = getErrorMsg(e);
        setErrorMsg(errMsg);
      }
      setFileState(fileState);
    }
  };

  const confirmUpload = () => {
    setFileUploadState(FileStatesEnum.uploading);
    validateFile(setFileUploadState);
    closeModal();
  };

  return (
    <>
      <FileBanner
        fileName={fileName}
        onDelete={onDelete}
        fileState={fileUploadState}
        error={errorMsg}
      />
      {!_.isEqual(FileStatesEnum.success, fileUploadState) && (
        <Modal
          size="small"
          style={modal}
          open={modalOpen}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          onOpen={openModal}
          dimmer="blurring"
          trigger={<Button content="Upload" style={uploadBtn} disabled={disableUpload} />}
        >
          <Modal.Content style={modalContentContainer}>
            <div style={modalHeaderContainer}>
              <Image alt="info circle" src={BLACK_INFO_CIRCLE_SRC} />
              <Header as="h4" content={modalHeaderText} style={modalHeader} />
            </div>
            <div style={modalFooter}>
              <Button content="Confirm" onClick={confirmUpload} style={uploadBtn} />
              <Button content="Cancel" onClick={closeModal} style={cancelBtn} />
            </div>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

export default FileStates;
