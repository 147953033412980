export const PREFIX = 'SEAT';

export const GET_SEATS_DATA = `${PREFIX}:GET_SEATS_DATA`;
export const GET_DSP_DATA = `${PREFIX}:GET_DSP_DATA`;
export const GET_FEE_OPTION_DATA = `${PREFIX}:GET_FEE_OPTION_DATA`;
export const GET_FEE_TYPE_DATA = `${PREFIX}:GET_FEE_TYPE_DATA`;
export const GET_BUSINESS_UNIT_DATA = `${PREFIX}:GET_BUSINESS_UNIT_DATA`;
export const GET_BUSINESS_MODEL_DATA = `${PREFIX}:GET_BUSINESS_MODEL_DATA`;
export const GET_COUNTRY_DATA = `${PREFIX}:GET_COUNTRY_DATA`;
export const GET_REGION_DATA = `${PREFIX}:GET_REGION_DATA`;
export const GET_PERMISSIONS_DATA = `${PREFIX}:GET_PERMISSIONS_DATA`;

export const START_ADD_SEAT = `${PREFIX}:START_ADD_SEAT`;

export const START_EDIT_SEAT = `${PREFIX}:START_EDIT_SEAT`;
export const STOP_ALTER_SEAT = `${PREFIX}:STOP_ALTER_SEAT`;

export const SAVE_SEAT = `${PREFIX}:SAVE_SEAT`;

export const SUCCESSFULLY_GET_SEATS_DATA = `${PREFIX}:SUCCESSFULLY_GET_SEATS_DATA`;
export const SUCCESSFULLY_GET_DSP_DATA = `${PREFIX}:SUCCESSFULLY_GET DSP_DATA`;
export const SUCCESSFULLY_GET_FEE_OPTION_DATA = `${PREFIX}:SUCCESSFULLY_GET_FEE_OPTION_DATA`;
export const SUCCESSFULLY_GET_FEE_TYPE_DATA = `${PREFIX}:SUCCESSFULLY_GET_FEE_TYPE_DATA`;
export const SUCCESSFULLY_GET_COUNTRY_DATA = `${PREFIX}:SUCCESSFULLY_GET_COUNTRY_DATA`;
export const SUCCESSFULLY_GET_BUSINESS_UNIT_DATA = `${PREFIX}:SUCCESSFULLY_GET_BUSINESS_UNIT_DATA`;
export const SUCCESSFULLY_GET_BUSINESS_MODEL_DATA = `${PREFIX}:SUCCESSFULLY_GET_BUSINESS_MODEL_DATA`;
export const SUCCESSFULLY_GET_REGION_DATA = `${PREFIX}:SUCCESSFULLY_GET_REGION_DATA`;
export const SUCCESSFULLY_GET_PERMISSIONS_DATA = `${PREFIX}:SUCCESSFULLY_GET_PERMISSIONS_DATA`;

export const FETCH_BUSINESS_UNIT_ACTION_FAILED = `${PREFIX}:FETCH_BUSINESS_UNIT_ACTION_FAILED`;
export const FETCH_BUSINESS_MODEL_ACTION_FAILED = `${PREFIX}:FETCH_BUSINESS_MODEL_ACTION_FAILED`;
export const FETCH_COUNTRY_ACTION_FAILED = `${PREFIX}:FETCH_COUNTRY_ACTION_FAILED`;
export const FETCH_FEE_OPTION_ACTION_FAILED = `${PREFIX}:FETCH_FEE_OPTION_ACTION_FAILED`;
export const FETCH_FEE_TYPE_ACTION_FAILED = `${PREFIX}:FETCH_FEE_TYPE_ACTION_FAILED`;
export const FETCH_REGION_ACTION_FAILED = `${PREFIX}:FETCH_REGION_ACTION_FAILED`;
export const FETCH_PERMISSIONS_ACTION_FAILED = `${PREFIX}:FETCH_PERMISSIONS_ACTION_FAILED`;

export const SUCCESSFULLY_SAVED_SEAT = `${PREFIX}:SUCCESSFULLY_SAVED_SEAT`;
export const SUCCESSFULLY_SAVED_SEAT_SECRETS = `${PREFIX}:SUCCESSFULLY_SAVED_SEAT_SECRETS`;

export const FETCH_ACTION_FAILED = `${PREFIX}:FETCH_ACTION_FAILED`;
export const SAVE_SEAT_FAILED = `${PREFIX}:SAVE_SEAT_FAILED`;

export const START_SEAT_CREDENTIALS_TEST = `${PREFIX}:START_SEAT_CREDENTIALS_TEST`;
export const SEAT_CREDENTIALS_TEST_FAILED = `${PREFIX}:SEAT_CREDENTIALS_TEST_FAILED`;
export const SEAT_CREDENTIALS_TEST_COMPLETED = `${PREFIX}:SEAT_CREDENTIALS_TEST_COMPLETED`;
export const SEAT_CREDENTIALS_TEST_COMPLETED_WITH_ERRORS = `${PREFIX}:SEAT_CREDENTIALS_TEST_COMPLETED_WITH_ERRORS`;
