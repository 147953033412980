import _ from 'lodash';
import React, { ReactNode, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, Popup, Icon } from 'buildingBlocks';
import FlightDetailsPopUp from 'components/FlightDetailsPopUp';
import { getFlightPopupMessage } from 'containers/StrategyWizard/utils';
import { DSP } from 'constantsBase';
import { displayDate } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { GlobalState } from 'reducers';
import { Flight, Member } from 'utils/types';
import { displayMetrics, Metric } from '../constants/metricsConstants';
import { tableStyle } from '../styles';
import { StrategyGoalAnalyticsPerFlightDatum } from '../types';

const truncateContent = (content) => _.truncate(content, { length: 120, separator: /[.,!?]? +/ });

const getCellData = (header, flightAnalytics, { name, endDate, externalType, timezone }, popupContent) => {
  if (header.func) {
    return displayMetrics(header, flightAnalytics[header.value]);
  }
  return (header.value === 'endDate')
    ? displayDate(endDate, externalType, timezone)
    : (
      <div>
        <Popup
          hoverable
          positioning="bottom left"
          wide="very"
          trigger={<span style={{ float: 'left' }}><Icon name="info circle" /></span>}
          content={popupContent}
        />
        <span className="flight-name" title={name}>{truncateContent(name)}</span>
      </div>
    );
};

export type Metadata = {
  name: string
  id: string
  endDate: string | null
  externalId: string
  timezone: string
  externalType: number
  dsp: number
};

type FlightTableCellProps = {
  header: Metric
  flightAnalytics: {}
  metadata: Metadata
  popupContent: ReactNode
};

export const FlightTableCell = ({
  header, flightAnalytics, metadata, popupContent,
}: FlightTableCellProps) => (
  <Table.Cell
    key={header.value}
  >
    {getCellData(header, flightAnalytics, metadata, popupContent)}
  </Table.Cell>
);

type Data = {
  externalId: string,
  metadata: Flight & Metadata & { member: Member },
  activeStatus: {
    isInactive: boolean
  },
};

type FlightTableBodyProps = {
  headers?: Array<Metric>,
  data?: Array<Data>,
  flightRuns: any,
  onClick: Function,
  selected: Array<string | number>,
};

const FlightTableBody = ({
  headers, data, flightRuns, selected, onClick,
}: FlightTableBodyProps) => {
  const [dataToUse, setDataToUse] = useState<Array<Data>>(data);
  const { metadata: strategyAnalyticsMetadata } = useSelector<GlobalState>((state) => state.strategyAnalytics) as any;
  const cumData = _.get(strategyAnalyticsMetadata, 'cumData');

  useEffect(() => {
    if (cumData) {
      // use the pre-calculated cumulative data if cumData is available via the strategyGoalAnalytics microservice
      // this data is the most accurate
      const cumFlightData = _.groupBy(cumData.analyticsPerFlight, 'externalId');
      const updatedDataToUse = _.map(dataToUse, (flightData: Data) => {
        const latestCumFlightData = _.maxBy(_.get(cumFlightData, flightData.externalId, []), (analytics: StrategyGoalAnalyticsPerFlightDatum) => analytics.date);
        return latestCumFlightData ? { ...flightData, ..._.pick(latestCumFlightData, _.keys(flightData)) } : flightData;
      });
      setDataToUse(updatedDataToUse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cumData]);

  return (
    <Table.Body>
      {
        // data to the component via a higher order component
        _.map(dataToUse || [], ({ activeStatus, metadata, ...metrics }) => {
          const flightRun = _.find(flightRuns, ['flight', metadata.id]);
          const member = metadata.member;
          const messages = getFlightPopupMessage(metadata, _.get(metadata, 'advertiser.externalId'), _.get(metadata, 'member.externalId'), flightRun);
          messages.unshift(`${_.get(DSP.getById(metadata.dsp), 'displayName')} | ${_.get(member, 'displayName')} | ${_.get(member, 'externalId')}`);

          return (
            <Table.Row
              key={metadata.externalId}
              className="hover-link"
              style={_.includes(selected, metadata.externalId) ? tableStyle.activeRow : undefined}
              // @ts-ignore
              onClick={() => onClick(metadata.externalId)}
            >
              {
                _.map(headers || [], (header) => (
                  <FlightTableCell
                    header={header}
                    flightAnalytics={metrics}
                    metadata={metadata}
                    key={header.value}
                    popupContent={(
                      <FlightDetailsPopUp
                        activeStatus={activeStatus}
                        header="DSP Object Details"
                        messages={messages}
                      />
                    )}
                  />
                ))
              }
            </Table.Row>
          );
        })
      }
    </Table.Body>
  );
};

export default FlightTableBody;
