import React, { SyntheticEvent, useRef } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import DspIcon from 'components/DspIcon';
import EditStrategyStep from 'components/EditStrategyStep';
import Hamburger from 'components/Hamburger';
import StarredUserStrategy from 'containers/StarredUserStrategy';
import { WppTag, WppToastContainer } from 'buildingBlocks';
import { toCamelCase, formatGoal, getGoalStr } from 'utils/formattingUtils';
import { GOAL_TYPES, CROSS_PLATFORM_ROAS_GOAL_NAME } from 'constantsBase';
import { heliosDownloadReport } from 'containers/StrategiesList/actions';
import { Strategy, Flight, User, Currency } from 'utils/types';
import NoGoalMessage from './NoGoalMessage';
import { starAndHamburgerMenuContainer, strategyMetadataStyles } from '../styles';
import { toggleDeleteModal, updateClientTestForStrategy } from '../actions';
import { ToastConfig } from '../types';

type MetaInfoProp = {
  heading: string
  content: string
};

type MetaTextProps = {
  strategy: Strategy
  currencyCode: string
  goalStr: string
  revenueStr: string
  targetStr: string
};

type MetadataProps = {
  strategy: Strategy
  flights: Array<Flight>
  downloadingReport: boolean
  downloadedReport: number
  user: User
  currency: Currency
  toggleDeleteModal: (event: SyntheticEvent, data: unknown) => void
  updateClientTestForStrategy: Function
  heliosDownloadReport: Function
};

const getMetaText = (strategy: Strategy, currencyCode: string, goalStr: string, revenueStr : string, targetStr : string) => [
  { heading: 'Brand', content: strategy.brand ? strategy.brand.name : '' },
  { heading: 'Member', content: strategy.member ? (_.join([strategy.member.displayName, strategy.member.externalId], ' ')) : '' },
  { heading: 'Advertiser', content: strategy.advertiser ? (_.join([strategy.advertiser.name, strategy.advertiser.externalId], ' ')) : '' },
  { heading: 'Currency', content: currencyCode },
  { heading: 'Goal', content: goalStr },
  { heading: 'Target', content: targetStr },
  { heading: 'Revenue Type', content: revenueStr },
];

const {
  container: strategyContainer,
  title: {
    container: titleContainer,
    subContainer: titleSubContainer,
    content: titleContent,
  },
  additionalInformation: {
    container: additionalInformationContainer,
    rightContent: additionalInformationRightContent,
    details,
    pipe,
  },
  starStrategyIcon,
} = strategyMetadataStyles;

const MetaText = (props: MetaTextProps) => {
  const { strategy, currencyCode, goalStr, revenueStr, targetStr } = props;
  const meta: Array<MetaInfoProp> = getMetaText(strategy, currencyCode, goalStr, revenueStr, targetStr);
  const filteredMeta = meta.filter((item) => _.size(item.content) > 0);
  return (
    <>
      {_.map(filteredMeta, (item, index: number) => (
        <div key={item.heading}>
          <span style={additionalInformationRightContent}>
            {item.heading}:
          </span>
          <span>
            {item.content}
          </span>
          {index !== (filteredMeta.length - 1) && (
            <span style={pipe}>|</span>
          )}
        </div>
      ))}
    </>
  );
};

const Metadata = ({ strategy, ...rest }: MetadataProps) => {
  const { currency: { code }, user: { id: userId } } = rest;
  const clientTest = strategy.clientTest;

  const toastRef = useRef<HTMLWppToastContainerElement | null>(null);
  const showToast = (config: ToastConfig) => {
    toastRef.current?.addToast(config);
  };

  if (_.isEmpty(strategy.strategyGoals)) {
    return <NoGoalMessage strategy={strategy} />;
  }
  const goal = strategy.strategyGoals[0]; // Display just the first one in case there are multiple goals
  // multi outcome or value is % despite Margin goal card having valueType of Currency
  const hasMultiOutcomesOrValues = _.isEqual(goal.type, GOAL_TYPES.margin.value);
  let goalType = goal.type;
  // if goal.type is roas convert to conversionRevenue
  if (_.isEqual(goalType, CROSS_PLATFORM_ROAS_GOAL_NAME)) {
    goalType = GOAL_TYPES.conversionRevenue.value;
  }
  const formattedGoalTarget = formatGoal(GOAL_TYPES[toCamelCase(goalType)], goal.target, code, strategy.strategyGoals[0]?.customGoal, hasMultiOutcomesOrValues);
  const { goalStr, revenueStr, targetStr } = getGoalStr(strategy, goal, code, formattedGoalTarget);
  const dsp = _.get(strategy, 'strategyType.dsp');

  const handleTestClick = () => {
    rest.updateClientTestForStrategy(strategy.id, !clientTest, false, showToast);
  };

  return (
    <div id="strategyMetadata" style={strategyContainer}>
      <div style={titleContainer}>
        <div style={titleSubContainer}>
          <div style={titleContent}>
            {`${strategy.name} (${strategy.id})`}
          </div>
          <div style={starAndHamburgerMenuContainer}>
            <StarredUserStrategy
              userId={userId}
              strategyId={strategy.id}
              className="star-strategy"
              iconStyle={starStrategyIcon}
            />
            <EditStrategyStep
              trigger={<Hamburger />}
              id={strategy.id}
              onDeleteClick={rest.toggleDeleteModal}
              heliosDownloadReport={rest.heliosDownloadReport}
              downloadingReport={rest.downloadingReport}
              downloadedReport={rest.downloadedReport}
              user={rest.user}
              flights={rest.flights}
              disabled={false}
              onTestClick={handleTestClick}
              {...strategy}
            />
          </div>
        </div>
        <div style={additionalInformationContainer}>
          <DspIcon dspId={dsp} />
          {strategy.clientTest && (
            <WppTag
              label="Test"
              variant="warning"
              className="tag"
            />
          )}
          <div style={additionalInformationRightContent}>
            {strategy.strategyType.displayName}
          </div>
        </div>
        <div style={{ ...additionalInformationContainer, ...details }}>
          <MetaText
            strategy={strategy}
            currencyCode={code}
            goalStr={goalStr}
            revenueStr={revenueStr}
            targetStr={targetStr}
          />
        </div>
      </div>
      <WppToastContainer maxToastsToDisplay={10} ref={toastRef} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    strategy: state.strategyAnalytics.metadata.strategy,
    flights: state.strategyAnalytics.metadata.flights,
    downloadingReport: state.strategiesList.downloadingReport,
    downloadedReport: state.strategiesList.downloadedReport,
    user: _.get(state, 'login.session.data.user'),
    currency: state.strategyAnalytics.metadata.currency,
    starredUserStrategies: state.strategiesList.starredUserStrategies,
  };
}

export default connect(mapStateToProps, {
  toggleDeleteModal,
  heliosDownloadReport,
  updateClientTestForStrategy,
})(Metadata);
