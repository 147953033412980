// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 4px 0px;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
}
.searchItem:hover {
  background-color: var(--wpp-grey-color-200);
}

.strategy-name:hover {
  color: var(--wpp-primary-color-500);
}`, "",{"version":3,"sources":["webpack://./src/containers/Search/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;AACF;AACE;EACE,2CAAA;AACJ;;AAGA;EACE,mCAAA;AAAF","sourcesContent":[".searchItem {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin: 4px 0px;\n  cursor: pointer;\n  padding: 8px;\n  border-radius: 8px;\n\n  &:hover {\n    background-color: var(--wpp-grey-color-200);\n  }\n}\n\n.strategy-name:hover {\n  color: var(--wpp-primary-color-500);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
