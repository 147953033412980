import _ from 'lodash';
import { STRATEGY_TYPE } from 'constantsBase';
import createEnum from 'utils/Enum';
import { createIsoDate } from 'charts/utils';
import { Flight } from 'utils/types';
import { StrategyGoalAnalyticsData } from '../types';

export const INSIGHTS_TAB_LABEL = 'Insights';
export const BUDGET_OPT_TAB_LABEL = 'Monitoring';
export const DATA_UPLOADER_TAB_LABEL = 'External Measurement Uploader';

export const overviewTab = {
  label: 'Overview',
  to: (id: number | string) => `/strategies/${id}`,
};

export const audienceReportTab = {
  label: 'Audience Reporting',
  to: (strategyId: string) => `/strategies/${strategyId}/audience`,
};

const budgetOptimizationVizTab = {
  label: BUDGET_OPT_TAB_LABEL,
  to: (strategyId: string) => `/strategies/${strategyId}/monitoring`,
};

const insightsVizTab = {
  label: INSIGHTS_TAB_LABEL,
  to: (strategyId: string) => `/strategies/${strategyId}/insights`,
};

const dataUploaderTab = {
  label: DATA_UPLOADER_TAB_LABEL,
  to: (strategyId: string) => `/strategies/${strategyId}/datauploader`,
};

export const defaultTabs = [overviewTab, audienceReportTab];

export const TABS = createEnum({
  HELIOS_SEGMENT_RECENCY: {
    id: STRATEGY_TYPE.heliosSegmentRecency.id,
    tabs: [
      overviewTab,
      {
        label: 'Segment Performance',
        to: (strategyId: string) => `/strategies/${strategyId}/segmentrecency`,
      },
      {
        label: 'Leaf Performance',
        to: (strategyId: string) => `/strategies/${strategyId}/helios`,
      },
      audienceReportTab,
    ],
  },
  HELIOS: {
    id: STRATEGY_TYPE.helios.id,
    tabs: [
      overviewTab,
      {
        label: 'Leaf Performance',
        to: (strategyId: string) => `/strategies/${strategyId}/helios`,
      },
      {
        label: 'Tree Visualization',
        to: (strategyId: string) => `/strategies/${strategyId}/heliosgraph`,
      },
      audienceReportTab,
    ],
  },
  CUSTOM_TREE: {
    id: STRATEGY_TYPE.customTree.id,
    tabs: [
      overviewTab,
      {
        label: 'Leaf Performance',
        to: (strategyId: string) => `/strategies/${strategyId}/customtree`,
      },
      audienceReportTab,
    ],
  },
  APN_BUDGET_OPTIMIZATION: {
    id: STRATEGY_TYPE.apnBudgetOptimization.id,
    tabs: [
      overviewTab,
      budgetOptimizationVizTab,
      insightsVizTab,
      dataUploaderTab,
    ],
  },
  TTD_BUDGET_OPTIMIZATION: {
    id: STRATEGY_TYPE.ttdBudgetOptimization.id,
    tabs: [
      overviewTab,
      budgetOptimizationVizTab,
      insightsVizTab,
      dataUploaderTab,
    ],
  },
  WMT_BUDGET_OPTIMIZATION: {
    id: STRATEGY_TYPE.wmtBudgetOptimization.id,
    tabs: [
      overviewTab,
      budgetOptimizationVizTab,
      insightsVizTab,
      dataUploaderTab,
    ],
  },
  DBM_BUDGET_OPTIMIZATION: {
    id: STRATEGY_TYPE.dbmBudgetOptimization.id,
    tabs: [
      overviewTab,
      budgetOptimizationVizTab,
      insightsVizTab,
      dataUploaderTab,
    ],
  },
  AMZN_BUDGET_OPTIMIZATION: {
    id: STRATEGY_TYPE.amznBudgetOptimization.id,
    tabs: [
      overviewTab,
      budgetOptimizationVizTab,
      insightsVizTab,
    ],
  },
  CROSS_PLATFORM_OPTIMIZATION: {
    id: STRATEGY_TYPE.crossPlatformOptimization.id,
    tabs: [
      overviewTab,
      budgetOptimizationVizTab,
      insightsVizTab,
      dataUploaderTab,
    ],
  },
});

export const ALL_TABS = _.flatten(_.values(TABS));

export const isCampaignLevelOptimization = (strategyTypeId: number) => {
  switch (strategyTypeId) {
    case STRATEGY_TYPE.ttdBudgetOptimization.id:
    case STRATEGY_TYPE.wmtBudgetOptimization.id:
    case STRATEGY_TYPE.apnBudgetOptimization.id:
    case STRATEGY_TYPE.dbmBudgetOptimization.id:
    case STRATEGY_TYPE.amznBudgetOptimization.id:
    case STRATEGY_TYPE.crossPlatformOptimization.id:
      return true;
    default:
      return false;
  }
};

// back end returns date strings with time and timezone
export const formatAnalyticsDataDates = (strategyAnalyticsData: Array<StrategyGoalAnalyticsData>) => (_.map(
  strategyAnalyticsData,
  (data: StrategyGoalAnalyticsData) => ({
    ...data,
    date: createIsoDate(data.date),
  }),
));

export const formatAnalayticsByFlight = (flights: Array<Flight>, dailyTotals: Array<StrategyGoalAnalyticsData>) => {
  const extIdToFlight = _.reduce(flights, (result: { [key: string]: { externalType: number, flightId: number } }, flight: Flight) => {
    // eslint-disable-next-line no-param-reassign
    result[flight.externalId] = { externalType: flight.externalType, flightId: flight.id };
    return result;
  }, {});

  return _.map(dailyTotals, (data: StrategyGoalAnalyticsData) => {
    const formattedObj = {
      ..._.get(extIdToFlight, data.parentExtId),
      ..._.omit(data, 'parentExtId'),
      date: createIsoDate(data.date),
      externalId: data.parentExtId,
    };
    return formattedObj;
  });
};

export default TABS;
