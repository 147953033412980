import _ from 'lodash';
import METRICS from 'containers/StrategyAnalytics/constants/metricsConstants';
import { options as OPTIONS } from 'containers/StrategyAnalytics/constants/strategyAnalyticsConstants';
import {
  yLabelsFormatter,
  legendFormatter,
  defaultChartOptions,
} from 'containers/StrategyAnalytics/constants/chartSeriesConfigs';
import { buildSeries, AGGREGATABLE_COLS } from 'containers/StrategyAnalytics/utils/metricsUtils';
import { OptionValue, LeafAnalyticsPerFlight } from 'containers/StrategyAnalytics/types';
import { Strategy } from 'utils/types';

export const groupByAndMergeByDate = (data: LeafAnalyticsPerFlight, includeCols: Array<string>) => {
  const groups = _.groupBy(data, 'date');
  const mergeFn = (left, right, key) => {
    if (_.includes(includeCols, key)) {
      return left + right;
    }
    return left;
  };
  // @ts-ignore wrong overload
  const summed = _.mapValues(groups, (v) => _.mergeWith(...v, mergeFn));
  return _.values(summed);
};

export const getChartConfig = (
  filteredName: string | null,
  filteredData: LeafAnalyticsPerFlight,
  optionValue: OptionValue,
  data: LeafAnalyticsPerFlight,
  strategy: Strategy,
) => {
  const metricsSelected = {
    aggregator: METRICS.aggregator[optionValue.aggregator],
    ratePercentage: METRICS.ratePercentage[optionValue.ratePercentage],
  };
  const showCumulativeData = optionValue.shownAs === OPTIONS.shownAs.Cumulative.value;
  const displayFilteredData = !_.isUndefined(filteredName);
  const summedDailyData = groupByAndMergeByDate(data, AGGREGATABLE_COLS);
  const summedDailyFilteredData = groupByAndMergeByDate(filteredData, AGGREGATABLE_COLS);

  // Series are stacked so strategy chart needs to be in a lighter color than flight
  // Build strategy series
  const series = buildSeries(
    metricsSelected,
    'Strategy',
    summedDailyData,
    showCumulativeData,
    'default',
    displayFilteredData,
    optionValue.dateRange,
    strategy,
  );

  // Build flight series
  if (displayFilteredData) {
    series.push(
      ...buildSeries(
        metricsSelected,
        filteredName,
        summedDailyFilteredData,
        showCumulativeData,
        'selected',
        displayFilteredData,
        optionValue.dateRange,
        strategy,
      ),
    );
  }

  const yAxis = [
    {
      title: {
        text: metricsSelected.aggregator.text,
      },
      labels: {
        formatter() {
          return yLabelsFormatter(
            this,
            _.get(metricsSelected.aggregator, 'bigFormat', metricsSelected.aggregator.format),
          );
        },
      },
    },
    {
      title: {
        text: metricsSelected.ratePercentage.text,
      },
      labels: {
        formatter() {
          return yLabelsFormatter(this, _.get(metricsSelected.ratePercentage, 'format'));
        },
      },
    },
  ];

  const legend = legendFormatter(defaultChartOptions.legend, metricsSelected);

  return {
    yAxis, series, legend,
  };
};

export default getChartConfig;
