import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { EvenlySpacedGrid } from 'components/EvenlySpacedGrid';
import { Statistic, Popup, Icon } from 'buildingBlocks';
import { GlobalState } from 'reducers';
import { Strategy, EnvironmentVariables } from 'utils/types';
import { statBoxStyle } from '../styles';
import { displayMetrics, Metric, metrics as METRICS } from '../constants/metricsConstants';
import { getStatboxMetricsByStrategy, processDailyDataPerMetrics } from '../utils/metricsUtils';
import { AnalyticsData, StrategyGoalAnalyticsData, StrategyGoalAnalyticsMetadataType } from '../types';

type Props = {
  metrics: { [key: string]: number | string },
  metricsConfig: {
    [metricName: string]: Metric
  },
};

export const Statbox = (props: Props) => {
  const { metrics, metricsConfig } = props;
  if (_.isEmpty(metricsConfig)) {
    return null;
  }
  const stat = _.values(metricsConfig);
  if (_.some(stat, (s) => s.value === METRICS.ratePercentage.finalPacing.value) && !metrics[METRICS.ratePercentage.finalPacing.value]) {
    metrics[METRICS.ratePercentage.finalPacing.value] = metrics[METRICS.ratePercentage.currentPacing.value] ?? '-';
  }

  return (
    <EvenlySpacedGrid style={_.isEmpty(metrics) ? statBoxStyle.disabled : statBoxStyle.container}>
      {
        _.map(stat, (metric, i: number) => {
          const style = i === stat.length - 1 ? statBoxStyle.contentLast : statBoxStyle.content;

          return (
            <Statistic
              key={metric.value}
              style={style}
              id={metric.value}
            >
              <Statistic.Label>
                <h4 style={statBoxStyle.title}>{metric.text}</h4>
              </Statistic.Label>
              <Statistic.Value>
                <h2 style={{ ...statBoxStyle.text, ...(metric.titleCase ? statBoxStyle.textTitleCase : {}) }}>
                  {_.isString(metrics[metric.value]) ? metrics[metric.value] : displayMetrics(metric, (metrics[metric.value] as number), true)}
                </h2>
              </Statistic.Value>
              {metric.tooltipContent && (
                <Popup
                  hoverable
                  wide="very"
                  trigger={<span style={statBoxStyle.popup}><Icon name="question circle outline" /></span>}
                >
                  <p>{metric.tooltipContent}</p>
                </Popup>
              )}
            </Statistic>
          );
        })
      }
    </EvenlySpacedGrid>
  );
};

type WrapperProps = {
  analytics: AnalyticsData,
  strategy: Strategy,
  env: EnvironmentVariables,
  strategyGoalAnalyticsMetadata: StrategyGoalAnalyticsMetadataType,
};

const demoMetricsConfig = (strategy: Strategy, analytics: AnalyticsData, strategyGoalAnalyticsMetadata: StrategyGoalAnalyticsMetadataType) => {
  const demoMetrics = _.pickBy(getStatboxMetricsByStrategy(strategy, analytics, strategyGoalAnalyticsMetadata), (k) => k.text !== 'Lift');
  return _.mapValues(demoMetrics, (m) => ({ ...m, text: _.replace(m.text, /^Net /, '') }));
};

const StatboxWrapper = ({ analytics, strategy, env, strategyGoalAnalyticsMetadata }: WrapperProps) => {
  const { metadata } = useSelector<GlobalState>((state) => state.strategyAnalytics) as any;
  // cumData will exist in redux if analytics data is populated via strategyGoalAnalytics microservice
  // the cumData is already pre-calculated by the back end
  const cumData = _.get(metadata, 'cumData');
  const metricsConfig = env === EnvironmentVariables.demo
    ? demoMetricsConfig(strategy, analytics, strategyGoalAnalyticsMetadata)
    : getStatboxMetricsByStrategy(strategy, analytics, strategyGoalAnalyticsMetadata);

  // directly pick the metric keys from latest date of cumulative analytics if cumData is available
  const metrics = cumData
    ? _.pick(_.maxBy(cumData.analytics, (a: StrategyGoalAnalyticsData) => a.date), _.map(metricsConfig, 'value'))
    : processDailyDataPerMetrics(analytics, metricsConfig, strategy);
  return <Statbox metricsConfig={metricsConfig} metrics={metrics} />;
};

export default StatboxWrapper;
