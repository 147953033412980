import _ from 'lodash';
import React from 'react';
import {
  Table, Divider, Message, Icon,
} from 'buildingBlocks';
import { tableStyle } from 'containers/StrategyAnalytics/styles';
import { Metric, SORT_DIRECTION } from 'containers/StrategyAnalytics/constants/metricsConstants';
import LeafDataRow from './LeafDataRow';
import { makeBorderStyle, bucketSelectedStyle } from '../style';
import { Bucket, HEADERS } from '../constants';

export type TableRow = { [key: string]: Array<unknown> };

const topBorderStyle = makeBorderStyle('Top');
const borderRightStyle = makeBorderStyle('Right');
const widerBorderBottomStyle = makeBorderStyle('Bottom', 2);

type Props = {
  id: string,
  title: string,
  tableBody: Array<{ key: string, data: { [key: string]: Array<unknown> } }>,
  metricsToShow: Array<Metric>,
  metricsIndex: { [key: string]: number },
  metricSelected: Metric,
  showSingleMetric: boolean,
  onBucketClick: Function,
  sortDirection: keyof typeof SORT_DIRECTION,
  bucketSelected: Bucket,
  opposite?: boolean,
};

type Header = {
  text: string,
  value?: string,
};
type TableHeaderCellProps = {
  header: Header,
  onBucketClick: Function,
  bucketSelected: Bucket,
  sortDirection: keyof typeof SORT_DIRECTION,
  opposite?: boolean,
};
type TableHeaderProps = {
  onBucketClick: Function,
  bucketSelected: Bucket,
  sortDirection: keyof typeof SORT_DIRECTION,
  opposite?: boolean,
};

const TableHeaderCell = (props: TableHeaderCellProps) => {
  let sortable;
  let additionalStyle = {};
  const isHeaderSelected = props.bucketSelected === props.header.value;
  if (props.header.value) {
    sortable = () => props.onBucketClick(props.header.value);
    if (isHeaderSelected) {
      additionalStyle = bucketSelectedStyle;
    } else {
      additionalStyle = { cursor: 'pointer' };
    }
  }
  let direction = SORT_DIRECTION[props.sortDirection];
  if (props.opposite) {
    direction = SORT_DIRECTION[direction.opposite];
  }

  return (
    <Table.HeaderCell
      style={{ ...tableStyle.header, ...additionalStyle }}
      onClick={sortable}
    >
      {props.header.text} {isHeaderSelected && <Icon name={direction.icon} />}
    </Table.HeaderCell>
  );
};

const TableHeader = (props: TableHeaderProps) => (
  <Table.Header>
    <Table.Row>
      {_.map(HEADERS, (header) => (
        <TableHeaderCell
          key={header.text}
          header={header}
          {...props}
        />
      ))}
    </Table.Row>
  </Table.Header>
);

const MetricsTable = ({
  id,
  title,
  tableBody,
  metricsToShow,
  metricSelected,
  metricsIndex,
  showSingleMetric,
  bucketSelected,
  onBucketClick,
  sortDirection,
  opposite,
}: Props) => (
  <Table striped id={id} basic="very" textAlign="center" style={topBorderStyle}>
    <caption><Divider horizontal>{title}</Divider></caption>
    <TableHeader
      bucketSelected={bucketSelected}
      onBucketClick={onBucketClick}
      sortDirection={sortDirection}
      opposite={opposite}
    />
    <Table.Body>
      {
        tableBody.length > 0
          ? _.map(tableBody, ({ data, key }) => (
            showSingleMetric
              ? (
                <LeafDataRow
                  key={`${key}-${metricSelected.value}`}
                  name={key}
                  leafRowSpan={1}
                  metric={metricSelected}
                  metricsIndex={metricsIndex}
                  data={data}
                  shouldRowSpan
                  bucketSelected={bucketSelected}
                />
              )
              : _.map(metricsToShow, (metric, i: number) => (
                <LeafDataRow
                  key={`${key}-${metric.value}`}
                  name={key}
                  leafRowSpan={metricsToShow.length}
                  metric={metric}
                  metricsIndex={metricsIndex}
                  data={data}
                  shouldRowSpan={i === 0}
                  index={i}
                  bucketSelected={bucketSelected}
                />
              ))
          ))
          : (
            <Table.Row>
              <Table.Cell
                colSpan={6}
                style={{
                  ...borderRightStyle,
                  ...widerBorderBottomStyle,
                }}
              >
                <Message warning>
                  There are no leaves with at least one event for the selected metric
                </Message>
              </Table.Cell>
            </Table.Row>
          )
      }
    </Table.Body>
  </Table>
);

export default MetricsTable;
