import { CSSProperties } from 'react';

const style: { [key: string]: CSSProperties } = {
  starIconStyle: {
    cursor: 'pointer',
    alignItems: 'center',
  },
  starFilledIconStyle: {
    color: '#85A3FF',
  },
};

export default style;
