import { call, put, takeLatest, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { Role, Permission } from 'utils/copilotAPI';
import { RESULTS_LIMIT } from 'constantsBase';
import {
  FETCH_ROLES_COMPLETED,
  FETCH_ROLES_FAILED,
  FETCH_ROLES,
  FETCH_ROLE_COMPLETED,
  FETCH_ROLE_AND_PERMISSIONS,
  DELETE_ROLE,
  DELETE_ROLE_COMPLETED,
  DELETE_ROLE_FAILED,
  SAVE_ROLE,
  SAVE_ROLE_COMPLETED,
  SAVE_ROLE_FAILED,
  FETCH_PERMISSIONS_COMPLETED,
  FETCH_PERMISSIONS_FAILED,
  errorMsgMap,
} from './constants';

export function* getRoles() {
  try {
    const roles = yield call(Role.get, { limit: RESULTS_LIMIT });
    yield put({
      type: FETCH_ROLES_COMPLETED,
      payload: {
        roles: roles.data,
      },
    });
  } catch (error) {
    yield put({ type: FETCH_ROLES_FAILED, payload: 'Failed to fetch roles' });
  }
}

export function* getPermissions() {
  try {
    const permissions = yield call(Permission.get, { limit: RESULTS_LIMIT });
    yield put({
      type: FETCH_PERMISSIONS_COMPLETED,
      payload: {
        permissions: permissions.data,
      },
    });
  } catch (error) {
    yield put({ type: FETCH_PERMISSIONS_FAILED });
  }
}

export function* getRole({ payload: { roleId } } : AnyAction) {
  try {
    if (roleId > 0) {
      const role = yield call(Role.getById, roleId, { populate: ['permissions'] });
      yield put({ type: FETCH_ROLE_COMPLETED, payload: role.data });
    }
  } catch (error) {
    yield put({ type: FETCH_ROLES_FAILED });
  }
}

function* createRole(name, description, permissions, type) {
  const role = yield call(Role.count, { where: { name } });
  const roleExists = role.data.count === 1;
  if (roleExists) {
    throw new Error(errorMsgMap.ROLE_ALREADY_EXIST);
  } else {
    yield call(Role.post, {
      name, description, permissions, type,
    });
    yield put({ type: SAVE_ROLE_COMPLETED });
  }
}

function* updateRole(id, name, description, permissions) {
  yield call(Role.put, id, { name, description, permissions });
  yield put({ type: SAVE_ROLE_COMPLETED });
}

export function* saveRole({
  payload: {
    id, name, description, permissions, type, router,
  },
} : AnyAction) {
  try {
    if (!id) {
      yield* createRole(name, description, permissions, type);
    } else {
      yield* updateRole(id, name, description, permissions);
    }
    router.navigate('/roles');
  } catch (error) {
    yield put({ type: SAVE_ROLE_FAILED, error });
  }
}

export function* deleteRole({ payload: { roleId, router } } : AnyAction) {
  try {
    yield call(Role.delete, roleId);
    yield put({ type: DELETE_ROLE_COMPLETED });
    router.navigate('/roles');
  } catch (error) {
    yield put({ type: DELETE_ROLE_FAILED });
  }
}

export function* rolesSagas() {
  yield all([
    takeLatest(FETCH_ROLES, getRoles),
    takeLatest(SAVE_ROLE, saveRole),
    takeLatest(DELETE_ROLE, deleteRole),
    takeLatest(FETCH_ROLE_AND_PERMISSIONS, getRole),
    takeLatest(FETCH_ROLE_AND_PERMISSIONS, getPermissions),
  ]);
}

// All sagas to be loaded
export default [
  rolesSagas,
];
