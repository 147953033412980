import React, { useEffect } from 'react';
import _ from 'lodash';
import { useFormContext, Controller } from 'react-hook-form';
import { Form, ObjectDropdown, ObjectMultiDropdown, ValidatedCustomWppInput, WppGrid, WppTypography } from 'buildingBlocks';
import { setNumberValue, getRealValue } from 'buildingBlocks/ReactHookFormComponents/utils';
import { Permission, SeatToSaveProps, SeatFormFiledsProps, InputChangeEventDetail, WppInputCustomEvent } from 'utils/types';
import { ACCESS_REVENUE_TYPE_PERMISSION, COPILOT_FOR_GROUPM, DSP, FeeOptions, FeeTypes } from 'constantsBase';
import FileUploadZone from './FileUploadZone';
import { memberStyle } from '../style';

const { setFormFieldContainerStyle, setFormFieldLabelStyle } = memberStyle;

const PasswordInputField = ({ title, placeholder }: PasswordInputFieldProps) => (
  <Controller
    name="apiPassword"
    render={({ field, fieldState }) => (
      <ValidatedCustomWppInput
        onWppChange={field.onChange}
        field={field}
        fieldState={fieldState}
        title={title}
        type="password"
        placeholder={placeholder}
        skipIsDirtyCheck
        autocomplete="new-password"
      />
    )}
  />
);

type PasswordInputFieldProps = {
  title: string
  placeholder: string
};

const getFeeValueProps = (seatToSave: {}) => {
  const selectedFeeTypeId = _.get(seatToSave, 'feeType.id');
  if (selectedFeeTypeId) {
    switch (selectedFeeTypeId) {
      case FeeTypes.cpm:
        return { component: ValidatedCustomWppInput, label: 'USD', placeholder: 'Please enter a decimal Fee Value' };
      case FeeTypes.percentOfMediaCost:
        return { component: ValidatedCustomWppInput, label: '%', placeholder: 'Please enter a percentage Fee Value' };
      default:
        break;
    }
  }
  return { component: ValidatedCustomWppInput, placeholder: 'Please enter a Fee Value' };
};

const checkAccessRevenueTypePermission = (seatToSave: SeatToSaveProps) => (
  _.some(_.get(seatToSave, 'permissions', []), { displayName: ACCESS_REVENUE_TYPE_PERMISSION })
);

const getAccessRevenueTypeFromPermissions = (permissions: Array<Permission>) => (
  _.find(permissions, (permission) => _.isEqual(_.get(permission, 'displayName'), ACCESS_REVENUE_TYPE_PERMISSION))
);

const SeatFormFields: React.FC<SeatFormFiledsProps> = ({
  dspList, countries, businessUnits, businessModels, feeOptions, feeTypes, regions, permissions, initialValues,
}) => {
  const { control, setValue, watch, clearErrors, trigger } = useFormContext();
  const seatToSave = watch();
  const { feeOption, feeType, externalId, dsp } = seatToSave;
  const shouldValidatePercentage = _.get(feeType, 'id') === FeeTypes.percentOfMediaCost;

  useEffect(() => {
    if (feeOption?.id === FeeOptions.noFee) {
      setValue('feeType', null);
      setValue('feeValue', null);
    }
    if (externalId) {
      setValue('externalId', _.trim(externalId));
    }
    // Re-validate jsonObject when dsp changes
    trigger('jsonObject');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeOption, externalId, setValue, dsp]);

  const onBusinessModelChange = (v: {}) => {
    setValue('businessModel', v);
    clearErrors('businessModel');
    const isCopilotForGroupMSelected = _.isEqual(_.get(v, 'name'), COPILOT_FOR_GROUPM);
    const hasAccessRevenueTypePermission = checkAccessRevenueTypePermission(seatToSave);
    if (seatToSave && isCopilotForGroupMSelected && !hasAccessRevenueTypePermission) {
      const accessRevenueTypePermission = getAccessRevenueTypeFromPermissions(permissions);
      setValue('permissions', [accessRevenueTypePermission, ..._.get(seatToSave, 'permissions', [])]);
    }
  };

  const feeValueProps = getFeeValueProps(seatToSave);

  return (
    <>
      <WppGrid container fullWidth all={24} style={setFormFieldContainerStyle}>
        <WppGrid item all={8}>
          <Form.Field>
            <WppTypography style={setFormFieldLabelStyle} type="s-strong">DSP</WppTypography>
            <Controller
              name="dsp"
              control={control}
              render={({ field }) => (
                <ObjectDropdown
                  {...field}
                  keyFn={(dspObj) => dspObj.displayName}
                  options={dspList}
                  placeholder="Please choose a DSP"
                  fluid
                  selection
                  search={{ searchType: 'local' }}
                />
              )}
            />
          </Form.Field>
        </WppGrid>
        <WppGrid item all={8}>
          <Form.Field>
            <Controller
              name="externalId"
              control={control}
              render={(properties) => (
                <ValidatedCustomWppInput
                  placeholder="Enter External ID"
                  disabled={!!initialValues.id}
                  title="External ID"
                  onWppChange={properties.field.onChange}
                  {..._.omit(properties, 'formState')}
                  skipIsDirtyCheck
                />
              )}
            />
          </Form.Field>
        </WppGrid>
        <WppGrid item all={8}>
          <Form.Field>
            <Controller
              name="prefix"
              control={control}
              render={({ field, fieldState }) => (
                <ValidatedCustomWppInput
                  onWppChange={field.onChange}
                  type="number"
                  field={field}
                  title="Prefix"
                  fieldState={fieldState}
                  placeholder="Enter Prefix"
                  disabled={!!initialValues.id}
                  skipIsDirtyCheck
                />
              )}
            />
          </Form.Field>
        </WppGrid>
      </WppGrid>
      {dsp?.id === DSP.DBM.id && (
        <WppGrid container fullWidth all={24} style={setFormFieldContainerStyle}>
          <Controller
            name="jsonObject"
            control={control}
            render={() => (
              <FileUploadZone id={initialValues.id} />
            )}
          />
        </WppGrid>
      )}
      <WppGrid container fullWidth all={24} style={setFormFieldContainerStyle}>
        <WppGrid item all={12}>
          <Form.Field>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <ValidatedCustomWppInput
                  onWppChange={field.onChange}
                  field={field}
                  title="Name"
                  fieldState={fieldState}
                  placeholder="Enter Name"
                  skipIsDirtyCheck
                />
              )}
            />
          </Form.Field>
        </WppGrid>
        <WppGrid item all={12}>
          <Form.Field>
            <Controller
              name="displayName"
              control={control}
              render={({ field, fieldState }) => (
                <ValidatedCustomWppInput
                  onWppChange={field.onChange}
                  field={field}
                  title="Display Name"
                  fieldState={fieldState}
                  placeholder="Enter Display Name"
                  skipIsDirtyCheck
                />
              )}
            />
          </Form.Field>
        </WppGrid>
      </WppGrid>
      <WppGrid container fullWidth all={24} style={setFormFieldContainerStyle}>
        <WppGrid item all={12}>
          <Form.Field>
            <Controller
              name="apiUser"
              control={control}
              render={({ field, fieldState }) => (
                <ValidatedCustomWppInput
                  onWppChange={field.onChange}
                  field={field}
                  title="API User"
                  fieldState={fieldState}
                  placeholder="Enter API User"
                  skipIsDirtyCheck
                  autocomplete="username"
                />
              )}
            />
          </Form.Field>
        </WppGrid>
        <WppGrid item all={12}>
          <Form.Field>
            {initialValues.id
              ? <PasswordInputField title="API Password (Optional)" placeholder="▪▪▪▪▪▪" />
              : <PasswordInputField title="API Password" placeholder="Enter the password for this member" />}
          </Form.Field>
        </WppGrid>
      </WppGrid>
      <WppGrid container fullWidth all={24} style={setFormFieldContainerStyle}>
        <WppGrid item all={8}>
          <Form.Field>
            <WppTypography style={setFormFieldLabelStyle} type="s-strong">Country</WppTypography>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <ObjectDropdown
                  {...field}
                  keyFn={(country) => country.name}
                  options={countries}
                  placeholder="Please choose a Country"
                  fluid
                  selection
                  search={{ searchType: 'local' }}
                  onChange={field.onChange}
                />
              )}
            />
          </Form.Field>
        </WppGrid>
        <WppGrid item all={8}>
          <Form.Field>
            <WppTypography style={setFormFieldLabelStyle} type="s-strong">Business Unit</WppTypography>
            <Controller
              name="businessUnit"
              control={control}
              render={({ field }) => (
                <ObjectDropdown
                  {...field}
                  keyFn={(businessUnit) => businessUnit.name}
                  options={businessUnits}
                  placeholder="Please choose a Business Unit"
                  fluid
                  selection
                  search={{ searchType: 'local' }}
                  onChange={field.onChange}
                />
              )}
            />
          </Form.Field>
        </WppGrid>
        <WppGrid item all={8}>
          <Form.Field>
            <WppTypography style={setFormFieldLabelStyle} type="s-strong">Region</WppTypography>
            <Controller
              name="region"
              control={control}
              render={({ field }) => (
                <ObjectDropdown
                  {...field}
                  keyFn={(region) => region.name}
                  options={regions}
                  placeholder="Please choose a Region"
                  fluid
                  selection
                  search={{ searchType: 'local' }}
                  onChange={field.onChange}
                />
              )}
            />
          </Form.Field>
        </WppGrid>
      </WppGrid>
      <WppGrid container fullWidth all={24} style={setFormFieldContainerStyle}>
        <WppGrid item all={12}>
          <Form.Field>
            <WppTypography style={setFormFieldLabelStyle} type="s-strong">Business Model</WppTypography>
            <Controller
              name="businessModel"
              control={control}
              render={({ field }) => (
                <ObjectDropdown
                  {...field}
                  keyFn={(businessModel) => businessModel.name}
                  options={businessModels}
                  placeholder="Please choose a Business Model"
                  fluid
                  selection
                  search={{ searchType: 'local' }}
                  onChange={onBusinessModelChange}
                />
              )}
            />
          </Form.Field>
        </WppGrid>
        <WppGrid item all={12}>
          <Form.Field>
            <WppTypography style={setFormFieldLabelStyle} type="s-strong">Fee Option</WppTypography>
            <Controller
              name="feeOption"
              control={control}
              render={({ field }) => (
                <ObjectDropdown
                  {...field}
                  keyFn={(feeOptionObj) => feeOptionObj.name}
                  options={feeOptions}
                  placeholder="Please choose a Fee Option"
                  fluid
                  selection
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
          </Form.Field>
        </WppGrid>
      </WppGrid>
      {(feeOption?.id === FeeOptions.fee) && (
        <WppGrid container fullWidth all={24} style={setFormFieldContainerStyle}>
          <WppGrid item all={12}>
            <Form.Field>
              <WppTypography style={setFormFieldLabelStyle} type="s-strong">Fee Type</WppTypography>
              <Controller
                name="feeType"
                control={control}
                render={({ field }) => (
                  <ObjectDropdown
                    {...field}
                    keyFn={(fT) => fT.name}
                    options={feeTypes}
                    placeholder="Please choose a Fee Type"
                    fluid
                    selection
                    search={{ searchType: 'local' }}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue('feeValue', '');
                    }}
                  />
                )}
              />
            </Form.Field>
          </WppGrid>
          <WppGrid item all={12}>
            <Form.Field>
              <WppTypography style={setFormFieldLabelStyle} type="s-strong">Fee Value</WppTypography>
              <Controller
                name="feeValue"
                control={control}
                render={({ field, fieldState }) => (
                  <feeValueProps.component
                    onChange={field.onChange}
                    onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => field.onChange(setNumberValue(e.detail.value, shouldValidatePercentage))}
                    value={(shouldValidatePercentage && _.isNumber(field.value)) ? getRealValue(field.value, 2) : field.value}
                    field={field}
                    fieldState={fieldState}
                    skipIsDirtyCheck
                    {..._.omit(feeValueProps, 'component')}
                  />
                )}
              />
            </Form.Field>
          </WppGrid>
        </WppGrid>
      )}
      <WppGrid container fullWidth all={24} style={setFormFieldContainerStyle}>
        <WppGrid item all={24}>
          <Form.Field>
            <WppTypography style={setFormFieldLabelStyle} type="s-strong">Permissions Blacklist</WppTypography>
            <Controller
              name="permissions"
              control={control}
              render={({ field }) => (
                // ts-ignore is for type issue asking for input field
                // @ts-ignore
                <ObjectMultiDropdown
                  {...field}
                  keyFn={(permission: Permission) => permission.displayName}
                  options={permissions}
                  placeholder="Excluding permissions"
                  fluid
                  selection
                  search={{ searchType: 'local' }}
                />
              )}
            />
          </Form.Field>
        </WppGrid>
      </WppGrid>
    </>
  );
};

export default SeatFormFields;
