import { required, applyAllValidators } from 'utils/formValidators';

// const required = 'This field is required';

export const validateRolePage = (values) => {
  const validators = {
    roleName: required,
    roleDescription: required,
    roleType: required,
    rolePermissions: required,
  };

  return applyAllValidators(values, validators);
};

// eslint-disable-next-line no-restricted-exports
export { validateRolePage as default };
