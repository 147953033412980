import React, { useCallback, useRef, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { WppIconClose, WppIconSearch, WppInput, WppPopover, WppProgressIndicator } from 'buildingBlocks';
import { Strategy } from 'utils/copilotAPI';
import { WppInputCustomEvent, InputChangeEventDetail } from 'utils/types';
import { ELEMENT_ID } from 'cssTagConstants';
import { formatResult, generateNoResultsObj } from '../utils';
import SearchDropDownItem from './DropdownItem';
import { searchStyle } from '../style';
import '../style.scss';

type State = {
  isLoading: boolean
  value: string
  results: Array<{}>
};

const Search: React.FC = (): React.ReactElement => {
  const [state, setState] = useState({
    isLoading: false,
    value: '',
    results: [],
  });
  const popoverRef = useRef<HTMLWppPopoverElement>(null);
  const user = useSelector((reduxState) => _.get(reduxState, 'login.session.data.user'));

  const reset = () => {
    setState((prevState: State) => ({ ...prevState, isLoading: false, value: '', results: [] }));
  };

  const generateResults = async (query: string) => {
    if (_.trim(query) === '') {
      reset();
      return;
    }

    setState((prevState: State) => ({ ...prevState, isLoading: true, results: [] }));
    const { data } = await Strategy.search({ q: query.trim(), limit: 10 });
    const results = data.length ? _.map(data, (obj) => formatResult(obj, user)) : [generateNoResultsObj()];
    setState((prevState: State) => ({ ...prevState, isLoading: false, results }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchStrategies = useCallback(_.debounce(generateResults, 400), []);

  const onSearchChange = (searchQuery: string) => {
    setState((prevState: State) => ({ ...prevState, value: searchQuery, isLoading: false }));
    fetchStrategies(searchQuery);
  };

  const { isLoading, value, results } = state;

  return (
    <WppPopover ref={popoverRef} config={{ appendTo: () => document.querySelector('#app')! }}>
      <WppInput
        value={value}
        slot="trigger-element"
        placeholder="Search for strategies"
        onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => onSearchChange(event.detail.value)}
        style={searchStyle.searchInput}
        id={ELEMENT_ID.navBar.search}
        size="s"
      >
        <WppIconSearch slot="icon-start" />
        { isLoading && <WppProgressIndicator variant="circle" slot="icon-end" width={20} />}
        { !_.isEmpty(value) && !isLoading && <WppIconClose slot="icon-end" onClick={reset} />}
      </WppInput>
      {
        !_.isEmpty(results) ? (
          <div style={searchStyle.searchContainer}>
            { results.map((result) => (
              <SearchDropDownItem key={result.id} {...result} searchValue={value} handleClearSearchInput={reset} handleClick={() => popoverRef.current?.closePopover()} />
            ))}
          </div>
        ) : <div />
      }
    </WppPopover>
  );
};

export default Search;
