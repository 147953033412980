// @ts-nocheck redux-form & ModalMultiSelect props issues
import React from 'react';
import { Field } from 'redux-form';
import { FieldProps, ModalMultiSelect } from 'buildingBlocks';
import { validatedFieldWrapper } from 'utils/wrapperComponents';
import {
  ModalMultiSelectProps, DataProviderInterface, Input,
} from 'buildingBlocks/TwoListMultiSelect/TwoListMultiSelect';
import { SUB_HEADER_TEXT } from '../constants';

type Option = {
  text: string,
  value: string,
};

type AudienceReportMultiSelectProps = {
  name: string,
  dataProvider: DataProviderInterface,
  pluralize: (x: number) => string,
};

const ModalMultiSelectWrapper = validatedFieldWrapper(ModalMultiSelect);

const onMultiSelectChange = (input: Input, selected: Array<unknown>) => {
  if (input && input.onChange) {
    input.onChange(selected);
  }
};

export const AudienceReportMultiSelect = (props: AudienceReportMultiSelectProps) => (
  // @ts-ignore
  <Field<FieldProps<ModalMultiSelectProps>>
    component={ModalMultiSelectWrapper}
    name={props.name}
    dataProvider={props.dataProvider}
    keyFn={(option: Option) => option.value}
    displayFn={(option: Option) => option.text}
    sortFn={(option: Option) => option.text}
    // @ts-ignore form field
    onMultiSelectChangeFn={onMultiSelectChange}
    pluralize={props.pluralize}
    btnParams={{ attached: 'bottom' }}
    header={(
      <div style={{ fontSize: '12px', fontWeight: 'normal', marginTop: '10px' }}>
        {SUB_HEADER_TEXT}
      </div>
    )}
  />
);

export default AudienceReportMultiSelect;
