/* eslint-disable no-case-declarations */
import _ from 'lodash';
import { Strategy } from 'utils/types';
import {
  FETCH_MEMBER,
  FETCH_ADVERTISER,
  FETCH_STRATEGY_TYPE,
  FETCH_STRATEGY,
  FETCH_STRATEGY_COMPLETED,
  FETCH_STRATEGY_FAILED,
  FILTER,
  CLEAR_RECENTLY_SAVED,
  DEFAULT_FILTER,
  DELETE_STRATEGY,
  DELETE_STRATEGY_COMPLETED,
  TOGGLE_MODAL,
  SET_SINGLE_STRAT_FOR_DELETION,
  DELETE_STRATEGY_FAILED,
  DOWNLOAD_HELIOS_REPORT,
  SAVE_STRATEGY_AND_FLIGHT_SUCCEEDED,
  SAVE_STRATEGY_AND_FLIGHT_FAILED,
  SAVE_STRATEGY_FAILED,
  FETCH_GOAL_TYPE,
  REMOVE_STARRED_STRATEGY_FROM_LIST,
  UPDATE_CLIENT_TEST_IN_LIST,
  SELECT_ALL,
  SELECT_NONE,
  SELECT_ONE,
  CLEAR_FILTERS_COMPLETED,
  SAVE_BULK_STRATEGY_IN_PROGRESS,
} from './constants';
import { orderStratsByFilter, starredStratsFilterSelected } from './utils';

export const INITIAL_STATE = {
  strategies: [],
  strategyCount: 0,
  advertiser: [],
  member: [],
  strategyTypes: [],
  goalTypes: [],
  advertiserSearch: '',
  filter: { ...DEFAULT_FILTER },
  selected: { ...DEFAULT_FILTER },
  analytics: { countByStrategyType: {}, multiStrategy: [] },
  selectedStrategies: [],
  loadingMember: false,
  loadingAdvertiser: false,
  loadingStrategyType: false,
  loadingGoalType: false,
  loadingStrategy: true,
  advertiserFetchError: false,
  goalTypeFetchError: false,
  memberFetchError: false,
  strategyMessage: null,
  modalOpen: false,
  deleting: false,
  singleStratForDeletion: null,
  downloadingReport: false,
  downloadedReport: 0,
};

const getFilterUpdatedState = (state, action, loadingAdvertiser) => {
  const {
    strategyType,
    member,
    goalType,
    sort,
    order,
    createdBy,
    updatedBy,
    starred,
  } = action.payload.filter;

  return {
    ...state,
    filter: action.payload.filter,
    selected: {
      ...state.selected,
      strategyType: _.filter(state.strategyTypes, (s) => _.includes(strategyType, s.id)),
      member: _.filter(state.member, (m) => _.includes(member, m.id)),
      goalType: _.filter(state.goalTypes, (s) => _.includes(goalType, s.id)),
      starred,
      sort,
      order,
      createdBy,
      updatedBy,
    },
    loadingAdvertiser,
    loadingStrategy: true,
  };
};

const strategiesListReducer = (state = INITIAL_STATE, action: any = {}) => {
  switch (action.type) {
    case FETCH_MEMBER.STARTED:
      return { ...state, loadingMember: true };
    case FETCH_MEMBER.COMPLETED:
      return {
        ...state,
        member: action.payload,
        selected: {
          ...state.selected,
          member: _.filter(action.payload, (m) => _.includes(state.filter.member, m.id)),
        },
        loadingMember: false,
      };
    case FETCH_MEMBER.FAILED:
      return { ...state, loadingMember: false, memberFetchError: true };
    case FETCH_ADVERTISER.STARTED:
      return { ...state, loadingAdvertiser: true };
    case FETCH_ADVERTISER.COMPLETED:
      return {
        ...state,
        advertiser: action.payload.advertiser,
        selected: {
          ...state.selected,
          advertiser: action.payload.selected,
        },
        loadingAdvertiser: false,
      };
    case FETCH_STRATEGY_TYPE.STARTED:
      return { ...state, loadingStrategyType: true };
    case FETCH_STRATEGY_TYPE.FAILED:
      return { ...state, loadingStrategyType: false };
    case FETCH_STRATEGY_TYPE.COMPLETED:
      return {
        ...state,
        strategyTypes: action.payload,
        selected: {
          ...state.selected,
          strategyType: _.filter(action.payload, (s) => _.includes(state.filter.strategyType, s.id)),
        },
        loadingStrategyType: false,
      };
    case FETCH_GOAL_TYPE.STARTED:
      return { ...state, loadingGoalType: true };
    case FETCH_GOAL_TYPE.FAILED:
      return { ...state, loadingGoalType: false, goalTypeFetchError: true };
    case FETCH_GOAL_TYPE.COMPLETED:
      return {
        ...state,
        goalTypes: action.payload,
        selected: {
          ...state.selected,
          goalType: _.filter(action.payload, (s) => _.includes(state.filter.goalType, s.id)),
        },
        loadingGoalType: false,
      };
    case FETCH_ADVERTISER.FAILED:
      return { ...state, loadingAdvertiser: false, advertiserFetchError: true };
    case FETCH_STRATEGY:
      return { ...state, loadingStrategy: true };
    case FETCH_STRATEGY_COMPLETED:
      return {
        ...state,
        strategies: action.payload.strategyList,
        strategyCount: action.payload.strategyCount,
        strategyStatuses: action.payload.strategyStatuses,
        loadingStrategy: false,
        limit: action.payload.limit,
        skip: action.payload.skip,
      };
    case FETCH_STRATEGY_FAILED:
      return {
        ...state,
        loadingStrategy: false,
      };
    case FILTER.USER_INPUT.ADVERTISER.SEARCH_UPDATED:
      return { ...state, advertiserSearch: action.payload.input, loadingAdvertiser: true };
    case FILTER.UPDATED.SORT:
      return getFilterUpdatedState(state, action, false);
    case FILTER.UPDATED.ANY:
      return getFilterUpdatedState(state, action, true);
    case SAVE_STRATEGY_AND_FLIGHT_SUCCEEDED:
      return {
        ...state,
        strategyMessage: {
          header: 'Strategy saved!',
          body: `Strategy ${action.payload.name} (${action.payload.id}) saved successfully`,
          isFailure: false,
        },
      };
    case SAVE_BULK_STRATEGY_IN_PROGRESS:
      return {
        ...state,
        strategyMessage: {
          header: '',
          body: 'Strategy creation task in progress. We will notify you via email when it is complete.',
          isFailure: false,
        },
      };
    case SAVE_STRATEGY_AND_FLIGHT_FAILED:
      return {
        ...state,
        strategyMessage: {
          header: 'Error!',
          body: `Strategy ${action.payload.name} (${action.payload.id}) saved but failed to attach/detach flight(s)`,
          isFailure: true,
        },
      };
    case SAVE_STRATEGY_FAILED:
      return {
        ...state,
        strategyMessage: {
          header: 'Error!',
          body: `Strategy ${action.payload.name} (${action.payload.id}) failed to save`,
          isFailure: true,
        },
      };
    case CLEAR_RECENTLY_SAVED:
      return { ...state, strategyMessage: null };
    case CLEAR_FILTERS_COMPLETED:
      return { ...state, filter: { ...state.filter, ...action.payload } };
    case DELETE_STRATEGY:
      return { ...state, deleting: true };
    case DELETE_STRATEGY_COMPLETED:
      return {
        ...state,
        modalOpen: false,
        deleting: false,
        singleStratForDeletion: null,
        strategyMessage: {
          header: 'Strategy Deletion Successful!',
          body: `Deleted ${_.size(action.payload)} Strategy`,
          isFailure: false,
        },
      };
    case DELETE_STRATEGY_FAILED:
      return {
        ...state,
        modalOpen: false,
        deleting: false,
        singleStratForDeletion: null,
        strategyMessage: {
          header: 'Strategy Deletion failed!',
          body: 'There was a problem in the deletion process.',
          isFailure: true,
        },
      };
    case REMOVE_STARRED_STRATEGY_FROM_LIST:
      const strategyIds = _.isArray(action.payload) ? action.payload : [action.payload];
      const updatedStrategies = _.filter(state.strategies, (strat: Strategy) => !_.includes(strategyIds, strat.id));
      return {
        ...state,
        strategies: updatedStrategies,
        ...(starredStratsFilterSelected() && { strategyCount: _.size(updatedStrategies) }),
      };
    case TOGGLE_MODAL:
      return { ...state, modalOpen: !state.modalOpen };
    case SET_SINGLE_STRAT_FOR_DELETION:
      return { ...state, singleStratForDeletion: action.payload };
    case DOWNLOAD_HELIOS_REPORT.STARTED:
      return { ...state, downloadedReport: 0, downloadingReport: true };
    case DOWNLOAD_HELIOS_REPORT.COMPLETED:
      return { ...state, downloadedReport: state.downloadedReport + 1 };
    case DOWNLOAD_HELIOS_REPORT.COMPLETED_ALL:
      return { ...state, downloadedReport: 0, downloadingReport: false };
    case UPDATE_CLIENT_TEST_IN_LIST:
      const updatedStrategy = _.find(state.strategies, ['id', action.payload.strategyId]);
      updatedStrategy.clientTest = action.payload.clientTest;
      // update strategies so that the updated strategy changes persist
      return { ...state, strategies: state.strategies };
    case SELECT_ALL:
      return { ...state, selectedStrategies: orderStratsByFilter(state.strategies, state.filter) };
    case SELECT_NONE:
      return { ...state, selectedStrategies: [] };
    case SELECT_ONE:
      const strategy = action.payload;
      const updatedSelectedStrategies = _.some(state.selectedStrategies, ['id', strategy.id])
        ? _.filter(state.selectedStrategies, (strat) => !_.isEqual(strat.id, strategy.id))
        : orderStratsByFilter([...state.selectedStrategies, strategy], state.filter);
      return { ...state, selectedStrategies: updatedSelectedStrategies };
    default:
      return state;
  }
};

export default strategiesListReducer;
