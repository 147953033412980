import _ from 'lodash';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import React, { useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  WppPagination,
  WppGrid,
  WppButton,
  WppIconPlus,
  WppTypography,
  Message,
  Transition,
} from 'buildingBlocks';
import { HOME_PAGE_ELEMENT_TAG } from 'cssTagConstants';
import WppPageTemplate from 'components/PageTemplate/WppPageTemplate';
import WppDeleteConfirmationModal from 'components/WppDeleteConfirmationModal';
import PermissionWrapper from 'components/PermissionWrapper';
import { Permission } from 'utils/featureFlags';
import { EnhancedStrategy, PaginationChangeEventDetail } from 'utils/types';
import { CLEAR, WizardSteps, WIZARD_STEPS, WIZARD_BASE } from 'containers/StrategyWizard/constants';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import {
  getStrategyList, clearRecentlySaved, deleteStrategy, toggleModal, setStratForDeletion, heliosDownloadReport, updateUserSetting,
} from './actions';
import Filter from './components/StrategyFilter';
import StrategyTable from './components/StrategyTable';
import { STRATEGIES_PER_PAGE_OPTIONS, STRATEGIES_STARTING_ELEMENTS_PER_PAGE } from './constants';
import { pageHeaderContainer, gridPagination } from './style';

const pageName = 'Strategies';

const strategyListReducer = (state) => ({
  strategies: state.strategiesList.strategies,
  strategyStatuses: state.strategiesList.strategyStatuses,
  strategyCount: state.strategiesList.strategyCount,
  loadingStrategy: state.strategiesList.loadingStrategy,
  filter: state.strategiesList.filter,
  user: _.get(state, 'login.session.data.user'),
  strategyMessage: state.strategiesList.strategyMessage,
  modalOpen: state.strategiesList.modalOpen,
  deleting: state.strategiesList.deleting,
  singleStratForDeletion: state.strategiesList.singleStratForDeletion,
  downloadingReport: state.strategiesList.downloadingReport,
  downloadedReport: state.strategiesList.downloadedReport,
  userSettings: state.login.settings,
});

type StrategiesListProps = {
  component?: string
  // deleteStrategy: (...args: Array<any>) => void
};

type StrategiesListState = {
  limit: number
  startingPage: number
  skip?: number
  fetchedQueryParams?: boolean
};

const CustomHeader: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch();
  const ATTACH_FLIGHTS_LINK = `${WIZARD_BASE}${WIZARD_STEPS[WizardSteps.attachFlightsStep].subSteps.optimizationType.id}`;
  return (
    <WppGrid container fullWidth>
      <WppGrid item all={24} style={pageHeaderContainer}>
        <WppTypography tag="h1" type="3xl-heading">
          {pageName}
        </WppTypography>
        <PermissionWrapper permission={Permission.accessStrategyWizard}>
          <Link to={ATTACH_FLIGHTS_LINK} onClick={() => dispatch({ type: CLEAR })}>
            <WppButton id={HOME_PAGE_ELEMENT_TAG.createStratBtn}>
              <WppIconPlus slot="icon-start" /> New Strategy
            </WppButton>
          </Link>
        </PermissionWrapper>
      </WppGrid>
    </WppGrid>
  );
};

const StrategiesList: React.FC<StrategiesListProps> = (props: StrategiesListProps): React.ReactElement => {
  const [state, setState] = useState<StrategiesListState>({ startingPage: 1, limit: STRATEGIES_STARTING_ELEMENTS_PER_PAGE, fetchedQueryParams: false });
  const gridRef = useRef<AgGridReact>();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    strategies,
    strategyCount,
    loadingStrategy,
    filter,
    user,
    strategyMessage,
    modalOpen,
    deleting,
    singleStratForDeletion,
    downloadingReport,
    downloadedReport,
    userSettings,
  } = useSelector(strategyListReducer);

  const dispatch = useDispatch();

  const updateState = (limit: number, skip: number, fetchedQueryParams: boolean) => setState((prevState: StrategiesListState) => ({ ...prevState,
    limit,
    skip,
    startingPage: skip / limit + 1,
    fetchedQueryParams,
  }));

  const getLimit = (limit) => {
    if (!limit) {
      return _.get(userSettings, 'config.strategyListElements', STRATEGIES_STARTING_ELEMENTS_PER_PAGE);
    }
    return parseInt(limit, 10) || STRATEGIES_STARTING_ELEMENTS_PER_PAGE;
  };

  const browserUrlUpdate = (paramFilter, limit, skip) => {
    const newFilter = { ...paramFilter, limit, skip };
    updateState(limit, skip, state.fetchedQueryParams);
    navigate(
      `${location.pathname}?${qs.stringify(newFilter)}`,
      { replace: true },
    );
  };

  const onDataRangeChange = ({ limit, start }) => {
    dispatch(updateUserSetting(
      'strategyListElements',
      limit,
      userSettings,
    ));
    const skip = start;
    browserUrlUpdate(filter, limit, skip);
    dispatch(getStrategyList(filter, limit, skip));
  };

  const onToggleModal = (strategy?: EnhancedStrategy) => {
    if (strategy) {
      dispatch(setStratForDeletion([strategy]));
    } else {
      dispatch(setStratForDeletion([]));
    }
    dispatch(toggleModal());
  };

  useMount(() => {
    const { limit, skip: skipStr } = qs.parse(location.search.replace(/^\?/, ''));
    const parsedLimit = getLimit(limit);
    const skip = _.toNumber(skipStr);
    const parsedSkip = (skip >= 0 && skip) || 0;
    updateState(parsedLimit, parsedSkip, true);

    return () => { dispatch(clearRecentlySaved()); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const animatedMessage = strategyMessage && (
    <Message
      size="small"
      success={!strategyMessage.isFailure}
      error={strategyMessage.isFailure}
      icon={strategyMessage.isFailure ? 'remove' : 'check'}
      header={strategyMessage.header}
      content={strategyMessage.body}
      onDismiss={() => dispatch(clearRecentlySaved())}
    />
  );

  return (
    <WppPageTemplate
      title={props.component}
      name={pageName}
      customHeader={<CustomHeader />}
    >
      <WppGrid container fullWidth>
        <WppGrid item all={24}>
          <Transition.Group animation="fade" duration={500}>
            {animatedMessage}
          </Transition.Group>
          <WppGrid container fullWidth>
            <WppGrid item>
              {state.fetchedQueryParams && user && (
                <Filter
                  user={user}
                  location={location}
                  browserUrlUpdate={browserUrlUpdate}
                  limit={state.limit}
                  skip={state.skip}
                />
              )}
              <StrategyTable
                strategyCount={strategyCount}
                filter={filter}
                strategies={strategies}
                innerRef={gridRef}
                toggleDeleteModal={onToggleModal}
                heliosDownloadReport={heliosDownloadReport}
                downloadingReport={downloadingReport}
                downloadedReport={downloadedReport}
                user={user}
                loadingStrategy={loadingStrategy}
              />
              <WppDeleteConfirmationModal
                itemsToDelete={singleStratForDeletion}
                onCancel={() => onToggleModal()}
                open={modalOpen}
                onDelete={() => dispatch(deleteStrategy(singleStratForDeletion))}
                deleting={deleting}
                modalName="Delete Strategy"
                deleteMessage="The flights attached to this strategy will be deactivated in the DSP and stop buying immediately."
              />
            </WppGrid>
          </WppGrid>
          <WppPagination
            style={gridPagination}
            count={strategyCount}
            itemsPerPage={STRATEGIES_PER_PAGE_OPTIONS}
            activePageNumber={state.startingPage}
            selectedItemPerPage={state.limit}
            onWppChange={(event: Event) => {
              const { itemsPerPage, page } = (event as CustomEvent<PaginationChangeEventDetail>).detail;
              onDataRangeChange({ limit: itemsPerPage, start: (page - 1) * itemsPerPage });
            }}
          />
        </WppGrid>
      </WppGrid>
    </WppPageTemplate>
  );
};

export default StrategiesList;
