import _ from 'lodash';
import React, { Component } from 'react';
import { Table, Message } from 'buildingBlocks';
import { Metric, displayMetrics } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { LeafAnalyticsPerFlight } from 'containers/StrategyAnalytics/types';
import { tableStyle } from 'containers/StrategyAnalytics/styles';
import { SEGMENT_AGE } from '../constants';

type Props = {
  data: LeafAnalyticsPerFlight,
  noData: boolean,
  metrics: Array<Metric>,
  loading: boolean,
};

type TableHeaderProps = {
  headers: Array<Metric>,
};

const getDefaultSegmentAgeTable = () => _.reduce(SEGMENT_AGE, (res, { id }) => {
  res[id] = [];
  return res;
}, {});

const SEGMENT_AGE_INTERVAL_NAME = 'segmentAgeInterval';
const SEGMENT_AGE_INTERVAL_COL = { text: 'Segment Age Interval', value: SEGMENT_AGE_INTERVAL_NAME, func: () => 0 };

type State = {
  headers: Array<Metric>,
};

const TableHeader = ({ headers }: TableHeaderProps) => (
  <Table.Header>
    <Table.Row>
      {(_.map(headers, (header) => (
        <Table.HeaderCell
          key={header.value}
          style={tableStyle.header}
          colSpan={header.value === SEGMENT_AGE_INTERVAL_NAME ? 2 : undefined}
          textAlign="center"
        >
          {header.text}
        </Table.HeaderCell>
      )))}
    </Table.Row>
  </Table.Header>
);

type SegmentAgeCellProps = {
  id: string,
  value: Array<string>,
};

const SegmentAgeCell = ({ id, value }: SegmentAgeCellProps) => _.map(value, (segmentAge, i: number) => (
  <Table.Cell
    key={`${id}-${i}`}
    width={1}
    style={tableStyle.content}
    textAlign={i === 0 ? 'right' : 'left'}
  >
    {segmentAge}
  </Table.Cell>
));

class SegmentAgeTable extends Component<Props, State> {
  static buildHeaders(props: Props) {
    return { headers: [SEGMENT_AGE_INTERVAL_COL, ...props.metrics] };
  }

  static buildTableData(props: Props) {
    return _.reduce(props.data, (res, row) => {
      const segmentAgeIntervalData = SEGMENT_AGE[row.segmentAgeInterval];
      if (segmentAgeIntervalData) {
        res[segmentAgeIntervalData.id].push(row);
      }
      return res;
    }, getDefaultSegmentAgeTable());
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      ...SegmentAgeTable.buildHeaders(props),
      ...SegmentAgeTable.buildTableData(props),
    };
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    if (!_.isEqual(this.props.data, newProps.data)) {
      this.setState(SegmentAgeTable.buildTableData(newProps));
    }
    if (!_.isEqual(this.props.metrics, newProps.metrics)) {
      this.setState(SegmentAgeTable.buildHeaders(newProps));
    }
  }

  render() {
    if (this.props.loading) {
      return null;
    }

    return (
      <Table
        id="segment-age-analytics"
        basic="very"
        style={{ borderTop: '1px solid rgba(34,36,38,.1)', width: '100%' }}
      >
        <TableHeader
          headers={this.state.headers}
        />
        {
        this.props.noData
          ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={_.size(this.state.headers)}>
                  <Message warning>This table currently has no analytics data to display.</Message>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )
          : (
            <Table.Body>
              {
              // TODO: look into why we have to make data optional. something to do with clone element supplying
              // data to the component via a higher order component
              _.map(SEGMENT_AGE, (segmentAge) => (
                <Table.Row
                  key={segmentAge.id}
                >
                  {
                    _.map(this.state.headers, (header) => (header.value === SEGMENT_AGE_INTERVAL_NAME
                      // @ts-ignore array of JSX
                      ? <SegmentAgeCell key={`${segmentAge.id}-${header.value}`} {...segmentAge} />
                      : (
                        <Table.Cell
                          key={`${segmentAge.id}-${header.value}`}
                          style={tableStyle.content}
                          textAlign="center"
                        >
                          {displayMetrics(header, header.func(_.get(this.state, segmentAge.id)))}
                        </Table.Cell>
                      )))
                  }
                </Table.Row>
              ))
            }
            </Table.Body>
          )
      }
      </Table>
    );
  }
}

export default SegmentAgeTable;
