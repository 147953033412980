// Action names.
import _ from 'lodash';
import { dateTimeFormatter } from 'utils/dateTime';
import METRICS_ENUM from 'containers/StrategyAnalytics/constants/metricsConstants';

const PREFIX = 'HELIOS_ANALYTICS:';

export const FETCH_HELIOS_ANALYTICS = {
  STARTED: `${PREFIX}FETCH_HELIOS_ANALYTICS`,
  COMPLETED: `${PREFIX}FETCH_HELIOS_ANALYTICS_COMPLETED`,
  NO_DATA: `${PREFIX}FETCH_HELIOS_ANALYTICS_NO_DATA`,
  FAILED: `${PREFIX}FETCH_HELIOS_ANALYTICS_FAILED`,
};

export const DOWNLOAD_HELIOS_ANALYTICS = {
  STARTED: `${PREFIX}DOWNLOAD_HELIOS_ANALYTICS`,
  COMPLETED: `${PREFIX}DOWNLOAD_HELIOS_ANALYTICS_COMPLETED`,
  FAILED: `${PREFIX}DOWNLOAD_HELIOS_ANALYTICS_FAILED`,
};

export const RESET_DATA = `${PREFIX}RESET_DATA`;

export const HELIOS_TASK_KEY = 'result.tasks.helios_summary_report';
export const HELIOS_RESULT_SUFFIX = 'summary_report_00.gz';

export const NUMBER_OF_TOPS = 10;
export const COLLAPSED_FEATURES_LENGTH = 100;

export const METRICS = {
  ..._.pick(
    METRICS_ENUM.ratePercentage,
    'cpa',
    'cpc',
    'conversionRate',
    'ctr',
    'cpcv',
  ),
  ..._.pick(
    METRICS_ENUM.aggregator,
    'conversions',
    'clicks',
    'revenue',
    'cost',
    'impressions',
    'videoCompletes',
  ),
};
export const METRICS_OPTIONS = _.values(METRICS);

export const BUCKET_HEADERS = [
  { text: 'Last 60 Days', value: '60' },
  { text: 'Last 30 Days', value: '30' },
  { text: 'Last 14 Days', value: '14' },
  { text: 'Last 7 Days', value: '7' },
];
export const DEFAULT_BUCKET = _.last(BUCKET_HEADERS).value as Bucket;
export const HEADERS = [
  { text: 'Leaf Description' },
  { text: 'Metric' },
  ...BUCKET_HEADERS,
];
export type Bucket = '7' | '14' | '30' | '60';

export const REPORT_TYPES = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  BUCKETED: 'bucketed',
};

export const MAPPING_CSV_KEY = {
  FEATURES: {
    INCLUDED: 'includedFeatures',
    EXCLUDED: 'excludedFeatures',
  },
  SEGMENT_GROUP: 'segmentGroupName',
  NAME: 'leafName',
};

export const getFilenameForReportType = (reportType: string, stratName: string, flightName: string) => `${dateTimeFormatter.isoDate(undefined, true)}-${reportType}-Analytics-${stratName}-${flightName}.csv`;

export const MAX_FILENAME_CHARS = 200;
