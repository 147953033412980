import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ImageFile } from 'react-dropzone';
import { Divider, Image, Dimmer, Loader, WppIconInfo } from 'buildingBlocks';
import { dataUploader } from 'containers/StrategyAnalytics/components/View/DataUploader/style';
import { FileStates as FileStatesEnum, FILE_UPLOADER_PATH, FileStates, JSON_FILE_NAME, JsonErrTypes } from 'containers/StrategyAnalytics/components/View/DataUploader/constants';
import BrowseDragDropPasteInputTextZone from 'components/BrowseDragDropPasteInputTextZone';
import { DSP } from 'constantsBase';
import { Member } from 'utils/copilotAPI';
import { useAsyncEffect } from 'utils/functionHelpers';
import { JsonDataType } from 'utils/types';
import FileToUpload from './FileToUpload';
import FileToDownload from './FileToDownload';

const {
  uploaderSection, dropzoneContainer, dropzoneButton, grid,
  dropzoneText, dropzoneContent, dropzoneIcon, dropzoneInfo,
  fileText,
} = dataUploader;

type FileUploadProps = {
  id?: number
  dsp?: number
};

const FileUploadZone = ({ id: memberId, dsp }: FileUploadProps) => {
  const { setValue, formState: { errors }, trigger, watch } = useFormContext();
  const [reader, setReader] = useState<FileReader>(new FileReader());
  const [fileUploadState, setFileUploadState] = useState<FileStatesEnum>(FileStatesEnum.initial);
  const [jsonData, setJsonData] = useState<JsonDataType>(null);
  const [fileName, setFileName] = useState<string>('');
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const jsonObjErr = _.get(errors, 'jsonObject', null);
  const jsonFormObj = watch('jsonObject');
  const disableDrop = _.isEqual(FileStatesEnum.failed, fileUploadState) || (_.isEmpty(jsonFormObj) && !!jsonData);

  useEffect(() => {
    if (!reader) {
      setReader(new FileReader());
    }

    // Cleanup function
    return () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reader]);

  useEffect(() => {
    if (_.includes(jsonObjErr, JsonErrTypes.invalid)) {
      setFileUploadState(FileStates.failed);
    }
  }, [jsonObjErr]);

  const updateJsonDetails = (jsonObj: JsonDataType, newFileName: string, newFileUrl: string) => {
    setJsonData(jsonObj);
    setFileName(newFileName);
    setFileUrl(newFileUrl);
  };

  const updateJsonObjectState = (jsonString: JsonDataType) => {
    setValue('jsonObject', jsonString);
    trigger('jsonObject');
    setFileUploadState(FileStates.success);
  };

  useAsyncEffect(async () => {
    if (!!memberId && _.isEqual(dsp, DSP.DBM.id)) {
      setLoader(true);
      try {
        const resultObject = await Member.getSeatSecret(memberId);
        const jsonObject = _.get(resultObject, 'data');
        const jsonString = JSON.stringify(jsonObject, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const newFileUrl = URL.createObjectURL(blob);
        updateJsonDetails(jsonString, JSON_FILE_NAME, newFileUrl);
        updateJsonObjectState(jsonString);
      } catch (error) {
        console.log(error);
      }
      setLoader(false);
    }
  }, [memberId]);

  const resetFileState = () => {
    setJsonData(null);
    setFileUploadState(FileStatesEnum.initial);
    setFileName('');
    setValue('jsonObject', '');
  };

  const onDrop = (accepted: Array<ImageFile>) => {
    setFileUploadState(FileStatesEnum.initial);
    const file = _.first(accepted);
    if (fileUrl) URL.revokeObjectURL(fileUrl);
    if (file) {
      reader.onload = () => {
        setValue('jsonObject', '');
        trigger('jsonObject');
        const jsonObj = reader.result;
        updateJsonDetails(jsonObj, _.get(file, 'name'), URL.createObjectURL(file));
      };
      reader.readAsText(file);
    }
  };

  const uploadClickHandle = async () => {
    setFileUploadState(FileStates.uploading);
    if (!_.isEqual(fileName, JSON_FILE_NAME)) {
      setFileUploadState(FileStates.failed);
    } else {
      setValue('jsonObject', jsonData);
      setFileUploadState(FileStates.success);
      updateJsonObjectState(jsonData);
    }
  };

  return (
    <div style={{ ...uploaderSection, ...grid }}>
      <BrowseDragDropPasteInputTextZone
        name="jsonObject"
        dropzonestyle={dropzoneContainer}
        onDrop={onDrop}
        accept="application/json"
        isDisabled={disableDrop}
        onClick={resetFileState}
      >
        <div style={dropzoneContent}>
          <Image
            alt="file uploader"
            src={FILE_UPLOADER_PATH}
            style={dropzoneIcon}
          />
          <p style={dropzoneText}>
            Drag and drop your file or
          </p>
          <p style={dropzoneButton}>Choose file
          </p>
          <p style={dropzoneInfo}>
            <WppIconInfo size="m" color="var(--wpp-grey-color-500)" style={{ margin: '4px 5px 0 0' }} /> File name must be &quot;sa.json&quot;.
          </p>
        </div>
      </BrowseDragDropPasteInputTextZone>
      {!_.isEmpty(jsonData)
        && (
          <FileToUpload
            fileName={fileName}
            fileUploadState={fileUploadState}
            resetFileState={resetFileState}
            uploadClickHandle={uploadClickHandle}
            errType={_.get(jsonObjErr, 'type')}
          />
        )}
      <Divider style={{ margin: 0 }} />
      {!_.isEmpty(jsonData) && !_.size(jsonObjErr) && _.isEqual(JSON_FILE_NAME, fileName)
        ? (
          <FileToDownload
            fileName={fileName}
            fileUrl={fileUrl}
          />
        )
        : (<div style={fileText}>No file uploaded yet</div>)}
      <Dimmer active={loader} inverted>
        <Loader />
      </Dimmer>
    </div>
  );
};

export default FileUploadZone;
