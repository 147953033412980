import _ from 'lodash';
import { DSP, FeeOptions, FeeTypes } from 'constantsBase';
import { required, applyAllValidators } from 'utils/reactHookFormValidators';

const DBM_JSON_PROPS = [
  'type',
  'project_id',
  'private_key_id',
  'private_key',
  'client_email',
  'client_id',
  'auth_uri',
  'token_uri',
  'auth_provider_x509_cert_url',
  'client_x509_cert_url',
  'universe_domain',
];

const validatePassword = (formValues: {}, fieldName: string) => {
  const id = _.get(formValues, 'id');
  if (id) return {};

  const value = _.get(formValues, fieldName);
  if (_.isEmpty(value)) {
    return { [fieldName]: { type: 'required', message: 'Password is required' } };
  }

  return {};
};

const validateFeeType = (formValues: {}, fieldName: string) => {
  const selectedFeeOptionId = _.get(formValues, 'feeOption.id');
  if (selectedFeeOptionId === FeeOptions.fee) {
    const value = _.get(formValues, fieldName);
    if (_.isEmpty(value)) {
      return { [fieldName]: { type: 'required', message: 'Fee type is required' } };
    }
  }
  return {};
};

const validateJsonObject = (formValues: {}, fieldName: string) => {
  const dsp = _.get(formValues, 'dsp');
  if (_.get(dsp, 'id') === DSP.DBM.id) {
    const value = _.get(formValues, fieldName);
    if (_.isEmpty(value)) {
      return { [fieldName]: { type: 'required', message: 'Required' } };
    }

    try {
      const jsonObj = JSON.parse(value);

      // Check if all required properties are present and truthy
      const allPropsValid = _.every(DBM_JSON_PROPS, (key) => _.has(jsonObj, key) && jsonObj[key]);

      // Check for additional properties that are not in the required list
      const jsonProps = _.keys(jsonObj);
      const additionalPropsExist = !_.every(jsonProps, (key) => _.includes(DBM_JSON_PROPS, key));

      if (!allPropsValid || additionalPropsExist) {
        return { [fieldName]: { type: 'invalid', message: 'Invalid' } };
      }
    } catch (error) {
      return { [fieldName]: { type: 'invalid', message: 'Invalid' } };
    }
  }
  return {};
};

const validateFeeValue = (formValues: {}, fieldName: string) => {
  const selectedFeeOptionId = _.get(formValues, 'feeOption.id');
  const selectedFeeTypeId = _.get(formValues, 'feeType.id');
  const field = _.get(formValues, fieldName);
  const feeValueErrors: { [key: string]: { type: string; message: string } } = {};

  if (selectedFeeOptionId === FeeOptions.fee) {
    if (!field) {
      feeValueErrors[fieldName] = { type: 'required', message: 'Fee value is required' };
    } else if (field <= 0) {
      feeValueErrors[fieldName] = { type: 'invalid', message: 'Must be greater than 0' };
    } else if (selectedFeeTypeId === FeeTypes.percentOfMediaCost && field >= 1) {
      feeValueErrors[fieldName] = { type: 'invalid', message: 'Must be between 0 and 100' };
    }
  }
  return feeValueErrors;
};

export default (values) => {
  const validators = {
    externalId: [required],
    prefix: [required],
    name: [required],
    dsp: [required],
    country: [required],
    businessModel: [required],
    businessUnit: [required],
    feeOption: [required],
    region: [required],
    displayName: [required],
    apiUser: [required],
    apiPassword: [validatePassword],
    feeType: [validateFeeType],
    feeValue: [validateFeeValue],
    jsonObject: [validateJsonObject],
  };
  return { values, errors: applyAllValidators(values, validators) };
};
