import {
  RESET_PROPS,
  optionsUpdated,
} from '../constants/actionsConstants';
import metrics from '../constants/metricsConstants';
import { options } from '../constants/strategyAnalyticsConstants';

export const INITIAL_STATE = {
  aggregator: metrics.aggregator.impressions.value,
  ratePercentage: metrics.ratePercentage.margin.value,
  byObject: options.byObject.Strategy.value,
  bySegment: options.bySegment.all.value,
  shownAs: options.shownAs.Daily.value,
  dateRange: options.dateRange[60].value,
  flight: null,
  tableSortBy: metrics.aggregator.impressions.value,
  sortDescending: true,
  segment: null,
  segmentGroup: null,
};

const optionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case optionsUpdated.dateRange:
      return {
        ...state,
        dateRange: action.payload.dateRange,
      };
    case optionsUpdated.aggregator:
      return { ...state, aggregator: action.payload };
    case optionsUpdated.ratePercentage:
      return { ...state, ratePercentage: action.payload };
    case optionsUpdated.shownAs:
      return { ...state, shownAs: action.payload };

    case optionsUpdated.byObject:
      return {
        ...state,
        byObject: action.payload,
        flight: null,
      };

    case optionsUpdated.bySegment:
      return {
        ...state,
        bySegment: action.payload,
        segment: null,
        segmentGroup: null,
      };

    case optionsUpdated.segment:
      if (state.segment === action.payload) {
        // If flight already selected we deselect it
        return {
          ...state,
          bySegment: options.bySegment.all.value,
          segment: null,
        };
      }
      return {
        ...state,
        bySegment: options.bySegment.id.value,
        segment: action.payload,
        segmentGroup: null,
      };

    case optionsUpdated.segmentGroup:
      if (state.segmentGroup === action.payload) {
        // If segment group already selected we deselect it
        return {
          ...state,
          bySegment: options.bySegment.all.value,
          segmentGroup: null,
        };
      }
      return {
        ...state,
        bySegment: options.bySegment.group.value,
        segment: null,
        segmentGroup: action.payload,
      };

    case optionsUpdated.flight:
      if (state.flight === action.payload) {
        // If flight already selected we deselect it
        return {
          ...state,
          byObject: options.byObject.Strategy.value,
          flight: null,
        };
      }
      return {
        ...state,
        byObject: options.byObject.Flight.value,
        flight: action.payload,
      };

    case optionsUpdated.tableSort:
      return {
        ...state,
        tableSortBy: action.payload,
        sortDescending: action.payload !== state.tableSortBy
        || (action.payload === state.tableSortBy && !state.sortDescending),
      };
    case RESET_PROPS:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default optionReducer;
