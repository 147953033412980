import { SUCCESSFULLY_GET_COUNTRY_DATA, FETCH_COUNTRY_ACTION_FAILED } from '../constants/ActionTypes';

const INITIAL_STATE = [];

const countryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUCCESSFULLY_GET_COUNTRY_DATA:
      return action.payload;
    case FETCH_COUNTRY_ACTION_FAILED:
      return action.payload;
    default:
      return state;
  }
};

export default countryReducer;
