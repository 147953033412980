import _ from 'lodash';
import { call, put, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { RESULTS_LIMIT } from 'constantsBase';
import { StratSegRecLeafAnalytics, APNSegment } from 'utils/copilotAPI';
import { convertArrayOfObjectsToStringCSV, exportFile } from 'utils/exportUtils';
import { dateTimeFormatter } from 'utils/dateTime';
import { replaceAll } from 'utils/functionHelpers';
import { Strategy, Flight } from 'utils/types';
import { REPORT_TYPES } from 'containers/StrategyAnalytics/components/View/Helios/constants';
import {
  FETCH_SEGMENTS,
  FETCH_SEGMENT_RECENCY_ANALYTICS,
  DOWNLOAD_SEGMENT_RECENCY_ANALYTICS,
} from './constants';

type HeliosData = Array<{}>;

type FetchAnalytics = {
  payload: { strategy: Strategy, flight: Flight, download: boolean, actionType: string | null },
};

const getHeaders = (data: HeliosData) => (_.isEmpty(data) ? [] : _.map(_.keys(data[0]), _.camelCase));

const fixCSVFormat = (data: HeliosData) => _.map(
  data,
  (row) => _.map(row, (cell: string) => (_.isNil(cell) ? '' : replaceAll(cell.toString(), '"', '""'))),
);

const createCSVAndDownload = (headers, analytics, filename) => {
  const csv = _.isEmpty(analytics) ? '' : convertArrayOfObjectsToStringCSV(analytics, headers);
  const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  exportFile(filename, csvBlob);
};

const getFlightName = (flight: Flight) => (flight && !_.isUndefined(flight.name) ? `-${flight.name}` : '');

export function* downloadAnalytics(action: FetchAnalytics & AnyAction) {
  const {
    strategy: { id: strategyId, name: stratName },
    flight,
    actionType,
  } = action.payload;

  const { data } = yield call(StratSegRecLeafAnalytics.getSegRecLeafAnalytics, {
    strategyId,
    reportType: REPORT_TYPES.DAILY,
  });
  const headers = getHeaders(data);
  const analytics = fixCSVFormat(data);
  const flightName = getFlightName(flight);
  const filename = `${dateTimeFormatter.isoDate(undefined, true)}-Bucketed-Analytics-${stratName}${flightName}.csv`;
  createCSVAndDownload(headers, analytics, filename);

  return yield put({ type: actionType });
}

/**
 * Fetch csv data for the curent strategy-flight selected.
 */
export function* fetchAnalytics(action: FetchAnalytics & AnyAction) {
  const {
    strategy: { id: strategyId },
    actionType,
  } = action.payload;

  try {
    const { data } = yield call(
      StratSegRecLeafAnalytics.getSegRecLeafAnalytics,
      { strategyId, reportType: REPORT_TYPES.DAILY },
    );
    if (!_.isEmpty(data)) {
      return yield put({
        type: FETCH_SEGMENT_RECENCY_ANALYTICS.COMPLETED,
        payload: {
          noData: false,
          data: _.orderBy(data, 'date'),
        },
      });
    }
    return yield put({ type: actionType || FETCH_SEGMENT_RECENCY_ANALYTICS.NO_DATA, noData: true });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return yield put({ type: FETCH_SEGMENT_RECENCY_ANALYTICS.FAILED });
  }
}

function* getSegments(action) {
  const { memberId, advertiserId, ids } = action.payload;
  try {
    const params = {
      where: {
        member: memberId,
        extAdvertiserId: advertiserId,
        externalId: ids,
      },
      limit: RESULTS_LIMIT,
      skip: 0,
    };
    const segmentData = yield call(APNSegment.get, params);
    yield put({ type: FETCH_SEGMENTS.COMPLETED, payload: segmentData.data });
  } catch (error) {
    yield put({ type: FETCH_SEGMENTS.FAILED, error });
  }
}

const sagas = [
  takeEvery(FETCH_SEGMENTS.STARTED, getSegments),
  takeEvery(FETCH_SEGMENT_RECENCY_ANALYTICS.STARTED, fetchAnalytics),
  takeEvery(DOWNLOAD_SEGMENT_RECENCY_ANALYTICS.STARTED, downloadAnalytics),
];

export default sagas;
