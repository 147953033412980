import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WppActionButton, WppIconClose, WppIconTrash, WppIconFavourites, WppButton, WppIconEdit, WppIconFavouritesFilled } from 'buildingBlocks';
import { selectNone } from 'containers/StrategiesList/actions';
import { selectedStrategiesPanel } from 'containers/StrategiesList/style';
import { starMultipleStrategies, unstarMultipleStrategies } from 'containers/StrategiesList/utils';
import { SINGLE_CAMPAIGN_LEVEL_OPT_STRATEGIES } from 'containers/StrategyWizard/constants';
import { GlobalState } from 'reducers';
import useSideModalHideFreshdesk from 'utils/hooks/useSideModalHideFreshdesk';
import { EnhancedStrategy, ToastConfig } from 'utils/types';
import EditStrategiesSideModal from './EditStrategiesSideModal';
import DeleteStrategiesModal from './DeleteStrategiesModal';

const { panelContainer, countCloseIcon } = selectedStrategiesPanel;

type SelectedStrategiesPanelProps = {
  showToast: (config: ToastConfig) => void
};

const SelectedStrategiesPanel = ({ showToast }: SelectedStrategiesPanelProps) => {
  const [starStrategyLoading, setStarStrategyLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const user = useSelector<GlobalState>((state) => state.login.user) as { id: number, starredUserStrategies: Array<{ strategy: number, user: number, id:number }> };
  const { deleting, selectedStrategies } = useSelector<GlobalState>((state) => state.strategiesList) as { deleting: boolean, selectedStrategies: Array<EnhancedStrategy> };
  const dispatch = useDispatch();
  // hide freshdesk button when side modal is open
  useSideModalHideFreshdesk(editModalOpen);

  const { id: userId, starredUserStrategies } = user;
  const alreadyStarredStrategyIds = _.map(starredUserStrategies, 'strategy');
  const allSingleCampaignLevelOptStrats = _.every(selectedStrategies, (strat) => (
    _.includes(SINGLE_CAMPAIGN_LEVEL_OPT_STRATEGIES, strat.strategyType.id) && strat.numFlights > 0
  ));
  const allStratsStarred = _.every(selectedStrategies, (s) => _.includes(alreadyStarredStrategyIds, s.id));

  const onStarClick = () => {
    if (allStratsStarred) {
      const strategyStarredEntries = _.map(selectedStrategies, (s) => ({ stratId: s.id, starredId: _.find(starredUserStrategies, ['strategy', s.id]).id }));
      unstarMultipleStrategies(strategyStarredEntries, setStarStrategyLoading, dispatch);
    } else {
      starMultipleStrategies(userId, _.map(selectedStrategies, 'id'), setStarStrategyLoading, dispatch);
    }
  };

  return (
    <div style={panelContainer}>
      <EditStrategiesSideModal
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        showToast={showToast}
      />
      <DeleteStrategiesModal
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
      />
      <WppActionButton
        className="selected-strats-count-btn"
        onClick={() => dispatch(selectNone())}
      >
        {_.size(selectedStrategies)} selected
        <WppIconClose style={countCloseIcon} />
      </WppActionButton>
      <WppActionButton
        variant="destructive"
        disabled={deleting || deleteModalOpen}
        onClick={() => setDeleteModalOpen(true)}
      >
        <WppIconTrash slot="icon-start" />
        Delete
      </WppActionButton>
      <WppActionButton
        variant="primary"
        disabled={starStrategyLoading}
        onClick={starStrategyLoading ? null : onStarClick}
      >
        {allStratsStarred ? <WppIconFavouritesFilled slot="icon-start" /> : <WppIconFavourites slot="icon-start" />}
        {allStratsStarred ? 'Unstar' : 'Star'}
      </WppActionButton>
      {allSingleCampaignLevelOptStrats && (
        <WppButton variant="secondary" size="s" onClick={() => setEditModalOpen(true)}>
          <WppIconEdit slot="icon-start" />
          Edit
        </WppButton>
      )}
    </div>
  );
};

export default SelectedStrategiesPanel;
