import _ from 'lodash';
import { call, put, takeEvery } from 'redux-saga/effects';

import { StratHeliosLeafAnalytics } from 'utils/copilotAPI';
import { convertArrayOfObjectsToStringCSV, exportFile } from 'utils/exportUtils';
import { replaceAll, truncateFilename } from 'utils/functionHelpers';
import { LeafIdentifierFromDB } from 'containers/StrategyAnalytics/components/View/HeliosSankey/types';

import {
  DOWNLOAD_HELIOS_ANALYTICS,
  FETCH_HELIOS_ANALYTICS,
  getFilenameForReportType,
  MAX_FILENAME_CHARS,
} from './constants';

import { fetchHeliosAnalyticsCompleted } from './actions';

type HeliosData = Array<LeafIdentifierFromDB>;

const getHeaders = (data: HeliosData) => (_.isEmpty(data) ? [] : _.map(_.keys(data[0]), _.camelCase));

const getAnalytics = (data: HeliosData) => _.map(
  data,
  (row) => _.map(row, (cell: string) => (_.isNil(cell) ? '' : replaceAll(cell.toString(), '"', '""'))),
);

const createCSVAndDownload = (headers, analytics, filename) => {
  const csv = _.isEmpty(analytics) ? '' : convertArrayOfObjectsToStringCSV(analytics, headers);
  const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  exportFile(filename, csvBlob);
};

const getFlightInfo = (flight) => {
  const flightId = _.get(flight, 'id', _.map(flight, 'id'));
  return { flightId, flightName: !_.isUndefined(flight.name) ? `-${flight.name}` : '' };
};

/**
 * Fetch csv data for the curent strategy-flight selected.
 */
export function* getHeliosAnalytics(action) {
  const {
    strategy: { id: strategyId, name: stratName },
    flight,
    download,
    actionType,
    reportType,
  } = action.payload;
  const { flightId, flightName } = getFlightInfo(flight);

  try {
    const { data } = yield call(
      StratHeliosLeafAnalytics.getHeliosLeafAnalytics,
      { strategyId, flightId, reportType },
    );
    const headers = getHeaders(data);
    const analytics = getAnalytics(data);
    if (!_.isEmpty(analytics)) {
      if (download) {
        const csvFilename = getFilenameForReportType('Bucketed', stratName, flightName);
        const truncatedFilename = truncateFilename(csvFilename, MAX_FILENAME_CHARS);
        createCSVAndDownload(headers, analytics, truncatedFilename);
        yield put({ type: actionType });
      }
      return yield put(fetchHeliosAnalyticsCompleted(headers, analytics, data));
    }
    return yield put({ type: actionType || FETCH_HELIOS_ANALYTICS.NO_DATA });
  } catch (error) {
    return yield put({ type: FETCH_HELIOS_ANALYTICS.FAILED });
  }
}

export function* downloadHeliosLeafAnalytics(action) {
  const {
    strategy: { id: strategyId, name: stratName },
    flight,
    reportType,
    actionType,
  } = action.payload;
  const { flightId, flightName } = getFlightInfo(flight);

  try {
    const { data } = yield call(
      StratHeliosLeafAnalytics.getHeliosLeafAnalytics,
      { strategyId, flightId, reportType },
    );
    const headers = getHeaders(data);
    const analytics = getAnalytics(data);
    const csvFilename = getFilenameForReportType(reportType, stratName, flightName);
    const truncatedFilename = truncateFilename(csvFilename, MAX_FILENAME_CHARS);
    createCSVAndDownload(headers, analytics, truncatedFilename);
    if (actionType) {
      yield put({ type: actionType });
    }
    return yield put({ type: DOWNLOAD_HELIOS_ANALYTICS.COMPLETED, payload: { reportType } });
  } catch (error) {
    return yield put({ type: DOWNLOAD_HELIOS_ANALYTICS.FAILED });
  }
}

const sagas = [
  takeEvery(FETCH_HELIOS_ANALYTICS.STARTED, getHeliosAnalytics),
  takeEvery(DOWNLOAD_HELIOS_ANALYTICS.STARTED, downloadHeliosLeafAnalytics),
];

export default sagas;
