import _ from 'lodash';
import React from 'react';
import {
  WppTable,
  WppTableHeader,
  WppTableHeaderRow,
  WppTableHeaderCell,
  WppTypography,
  WppEmptyNothingFound,
  WppActionButton,
} from 'buildingBlocks';
import { TimeZoneValue } from 'containers/Jobs/types';
import { AlgorithmType } from 'utils/types';
import { strategyFlightRunsTable, tableHeader, noFlightRunContainer } from './style';
import { ExtendedRootState } from './types';
import { STRATEGY_FLIGHT_RUNS_COLUMNS } from './constants';
import StrategyFlightRunsTableBody from './StrategyFlightRunsTableBody';

type StrategyFlightRunsTableProps = {
  strategyFlightRuns: ExtendedRootState['strategyFlightRuns']['strategyFlightRuns'];
  strategyFlightRunsCount: number
  selectedTimezone: TimeZoneValue
  userTimeZone: TimeZoneValue
  formatAlgoTypeName: (algoType: AlgorithmType) => string
  getTriggerType: Function
  onResetFilter: () => void
};

const StrategyFlightRunsTable: React.FC<StrategyFlightRunsTableProps> = ({ strategyFlightRuns, strategyFlightRunsCount, selectedTimezone, userTimeZone, formatAlgoTypeName, getTriggerType, onResetFilter }) => (
  <>
    {strategyFlightRunsCount ? (
      <WppTable style={strategyFlightRunsTable}>
        <WppTableHeader>
          <WppTableHeaderRow>
            {_.map(STRATEGY_FLIGHT_RUNS_COLUMNS, (column) => (
              <WppTableHeaderCell
                key={column.value}
                style={tableHeader}
              >
                <WppTypography type="s-strong">{column.text}</WppTypography>
              </WppTableHeaderCell>
            ))}
          </WppTableHeaderRow>
        </WppTableHeader>
        <StrategyFlightRunsTableBody
          strategyFlightRuns={strategyFlightRuns}
          selectedTimezone={selectedTimezone}
          userTimeZone={userTimeZone}
          formatAlgoTypeName={formatAlgoTypeName}
          getTriggerType={getTriggerType}
        />
      </WppTable>
    ) : (
      <div style={noFlightRunContainer}>
        <WppEmptyNothingFound width={120} />
        <WppTypography tag="p" type="m-strong">No Matching Runs</WppTypography>
        <WppTypography tag="p" type="s-body">Try changing your filters</WppTypography>
        <WppActionButton onClick={onResetFilter} tabIndex={0}>Reset Filter</WppActionButton>
      </div>
    )}
  </>
);

export default StrategyFlightRunsTable;
