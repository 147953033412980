import _ from 'lodash';
import React, { ComponentType } from 'react';
import { Menu, Dropdown } from 'buildingBlocks';
import { Flight, EnvironmentVariables } from 'utils/types';
import { menuStyle } from '../styles';
import { optionsUpdated } from '../constants/actionsConstants';
import METRICS from '../constants/metricsConstants';
import { getMetricOptions } from '../utils/metricsUtils';
import { options, shownAsValue, dateRangeValue } from '../constants/strategyAnalyticsConstants';
import { OptionValue, SegmentGroup } from '../types';

type DropdownItems = Array<{ key?: string | number, value: string | number, text: string | number }>;
type BySegment = { value: string, text: string };

type DisplayBySegmentItemProps = {
  bySegment: BySegment,
  isSelected: string,
  onClick: Function,
};

type DisplayBySegmentDropdownProps = {
  array: DropdownItems,
  isSelected: string,
  onClick: Function,
  actionType: string,
  type: string,
};

type DisplaySegmentFilterProps = {
  segments: DropdownItems,
  segmentGroups: Array<SegmentGroup>,
  defaultValue: string,
  onClick: Function,
  id: string,
};

type DisplayByObjectItemProps = {
  byObject: {
    value: string,
    text: string,
  },
  isSelected: string,
  onClick: Function,
};

type DisplayByObjectDropdownProps = {
  flights: { [key: string]: Flight } | Array<Flight>,
  isSelected: string,
  onClick: Function,
};

type DisplayDropdownOptionProps = {
  defaultValue: string | number,
  optionsValue: DropdownItems,
  onChange: Function,
  id: string,
  style?: any,
};

type DisplayByObjectFilterProps = {
  flights: { [key: string]: Flight } | Array<Flight>,
  defaultValue: string,
  onClick: Function,
  id: string,
};

type StrategyAnalyticsOptionsProps = {
  optionValue: OptionValue,
  onChange: Function,
  segments: DropdownItems,
  segmentGroups: Array<SegmentGroup>,
  showSegments: boolean,
  flights: { [key: string]: Flight } | Array<Flight>,
  onDateRangeChange: Function,
  env: EnvironmentVariables,
  metricOptions?: typeof METRICS,
};

const DisplayBySegmentItem = ({ bySegment, onClick, isSelected }: DisplayBySegmentItemProps) => (
  <Dropdown.Item
    key={bySegment.value}
    value={bySegment.value}
    active={isSelected === bySegment.value}
    onClick={() => onClick(optionsUpdated.bySegment, bySegment.value)}
    style={{ minWidth: '168px' }}
  >
    {bySegment.text}
  </Dropdown.Item>
);

const DisplayBySegmentDropdown = ({ array, onClick, isSelected, type, actionType }: DisplayBySegmentDropdownProps) => (
  <Dropdown
    fluid
    scrolling
    text={options.bySegment[type].text}
    className={isSelected === options.bySegment[type].text ? 'item active' : 'item'}
  >
    <Dropdown.Menu className="responsive">
      {_.map(array, (obj) => (
        <Dropdown.Item
          {...obj}
          style={{ whiteSpace: 'normal' }}
          onClick={() => onClick(actionType, obj.value)}
        />
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

const myBranch = (test: (any) => boolean, Left: ComponentType<any>, Right: ComponentType<any>) => {
  const inner = (props: any) => {
    if (test(props)) {
      return <Left {...props} />;
    }
    return <Right {...props} />;
  };
  return inner;
};

const DisplaySegmentOptions = myBranch(
  ({ array }) => _.isEmpty(array),
  DisplayBySegmentItem,
  DisplayBySegmentDropdown,
);

const DisplaySegmentFilter = ({ id, segments, segmentGroups, defaultValue, onClick }: DisplaySegmentFilterProps) => (
  <Menu id={id} compact style={menuStyle.dropdown.container}>
    <Dropdown
      fluid
      className="item"
      text={options.bySegment[defaultValue].text}
    >
      <Dropdown.Menu>
        <DisplayBySegmentItem
          bySegment={options.bySegment.all}
          onClick={onClick}
          isSelected={options.bySegment[defaultValue].text}
        />
        <DisplaySegmentOptions
          bySegment={options.bySegment.group}
          array={segmentGroups}
          onClick={onClick}
          isSelected={options.bySegment[defaultValue].text}
          type={options.bySegment.group.value}
          actionType={optionsUpdated.segmentGroup}
        />
        <DisplaySegmentOptions
          bySegment={options.bySegment.id}
          array={segments}
          onClick={onClick}
          isSelected={options.bySegment[defaultValue].text}
          type={options.bySegment.id.value}
          actionType={optionsUpdated.segment}
        />
      </Dropdown.Menu>
    </Dropdown>
  </Menu>
);

const DisplayByObjectItem = ({ byObject, onClick, isSelected }: DisplayByObjectItemProps) => (
  <Dropdown.Item
    key={byObject.value}
    value={byObject.value}
    active={isSelected === byObject.value}
    onClick={() => onClick(optionsUpdated.byObject, byObject.value)}
    style={{ minWidth: '168px' }}
  >
    {byObject.text}
  </Dropdown.Item>
);

const DisplayByObjectDropdown = ({ flights, onClick, isSelected }: DisplayByObjectDropdownProps) => (
  <Dropdown
    fluid
    scrolling
    text={options.byObject.Flight.text}
    className={isSelected === options.byObject.Flight.text ? 'item active' : 'item'}
  >
    <Dropdown.Menu className="responsive" style={{ bottom: 'auto' }}>
      {_.map(flights, (flight: Flight) => {
        const key = flight.externalId;
        return (
          <Dropdown.Item
            key={key}
            value={key}
            text={flight.name}
            style={{ whiteSpace: 'normal' }}
            onClick={() => onClick(optionsUpdated.flight, key)}
          />
        );
      })}
    </Dropdown.Menu>
  </Dropdown>
);

const DisplayObjectOptions = myBranch(
  ({ flights }) => _.isEmpty(flights),
  DisplayByObjectItem,
  DisplayByObjectDropdown,
);

const DisplayByObjectFilter = ({ id, flights, defaultValue, onClick }: DisplayByObjectFilterProps) => (
  <Menu id={id} compact style={menuStyle.dropdown.container}>
    <Dropdown
      fluid
      className="item"
      text={options.byObject[defaultValue].text}
    >
      <Dropdown.Menu>
        <DisplayByObjectItem
          byObject={options.byObject.Strategy}
          onClick={onClick}
          isSelected={options.byObject[defaultValue].text}
        />
        <DisplayObjectOptions
          flights={flights}
          byObject={options.byObject.Flight}
          onClick={onClick}
          isSelected={options.byObject[defaultValue].text}
        />
      </Dropdown.Menu>
    </Dropdown>
  </Menu>
);

const DisplayDropdownOption = ({ id, defaultValue, optionsValue, onChange, style }: DisplayDropdownOptionProps) => (
  <Menu id={id} compact style={menuStyle.dropdown.container}>
    <Dropdown
      fluid
      className="item"
      value={_.find(optionsValue, { value: defaultValue }) ? defaultValue : optionsValue[0].value}
      options={optionsValue}
      onChange={(_event, selected) => onChange(selected.value)}
      style={style}
      scrolling
    />
  </Menu>
);

const StrategyAnalyticsOptions = (props: StrategyAnalyticsOptionsProps) => {
  const {
    optionValue, onChange, segments, segmentGroups, showSegments, flights, onDateRangeChange, env, metricOptions,
  } = props;

  const aggregatorOptions = env === EnvironmentVariables.demo
    ? _.filter(getMetricOptions('aggregator', metricOptions), (o: { value: string, text: string }) => o.value !== 'cost')
    : getMetricOptions('aggregator', metricOptions);

  const filterFn = env === EnvironmentVariables.demo
    ? (o: { value: string, text: string }) => o.value !== 'currentPacing' && !o.text.includes('Net') && o.value !== 'margin'
    : (o: { value: string, text: string }) => o.value !== 'currentPacing';

  const ratePercentageOptions = _.filter(getMetricOptions('ratePercentage', metricOptions), filterFn);

  return (
    <div id="analytics-options" style={menuStyle.container}>
      <DisplayDropdownOption
        id="option-metric"
        defaultValue={optionValue.aggregator}
        // @ts-ignore
        optionsValue={aggregatorOptions}
        onChange={(selected) => onChange(optionsUpdated.aggregator, selected)}
      />
      vs
      <DisplayDropdownOption
        id="option-rate"
        defaultValue={optionValue.ratePercentage}
        // @ts-ignore
        optionsValue={ratePercentageOptions}
        onChange={(selected) => onChange(optionsUpdated.ratePercentage, selected)}
      />
      shown
      <DisplayDropdownOption
        id="option-aggregate"
        defaultValue={optionValue.shownAs}
        optionsValue={shownAsValue}
        onChange={(selected) => onChange(optionsUpdated.shownAs, selected)}
      />
      by
      <DisplayByObjectFilter
        id="option-by"
        flights={flights}
        defaultValue={optionValue.byObject}
        onClick={onChange}
      />
      {
        showSegments
        && (
          <div>
            and
            <DisplaySegmentFilter
              id="option-segments"
              segments={segments}
              segmentGroups={segmentGroups}
              defaultValue={optionValue.bySegment}
              onClick={onChange}
            />
          </div>
        )
      }
      for
      <DisplayDropdownOption
        id="option-range"
        defaultValue={optionValue.dateRange}
        optionsValue={dateRangeValue}
        onChange={(selected) => onDateRangeChange(selected)}
      />
    </div>
  );
};

export default StrategyAnalyticsOptions;
