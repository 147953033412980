import React from 'react';
import { WppInlineMessage } from 'buildingBlocks';
import { selectedStrategiesPanel } from 'containers/StrategiesList/style';

const { amznSettingsWarning } = selectedStrategiesPanel;

export const EditingRevTypeOrCustomGoalWarning = () => (
  <WppInlineMessage
    size="m"
    message="Goal and goal target are not editable for strategies with custom revenue types or custom goals."
    type="warning"
    className="prevent-editing-goal-msg"
  />
);

export const AmznSettingsWarning = () => (
  <div style={amznSettingsWarning}>
    <WppInlineMessage
      size="m"
      message="Viewability and Intelligent Line Items settings will only apply to non-Amazon strategies."
      type="warning"
      className="amzn-settings-warning"
    />
    <a
      href="https://copilotsupport.freshdesk.com/support/solutions/articles/47001212592-amazon-dsp-general"
      target="_blank"
      rel="noreferrer"
    >
      Learn More
    </a>
  </div>
);
