import { FetchState } from 'utils/types';
import {
  SUCCESSFULLY_GET_SEATS_DATA, SEAT_CREDENTIALS_TEST_FAILED,
  SEAT_CREDENTIALS_TEST_COMPLETED, START_SEAT_CREDENTIALS_TEST,
} from '../constants/ActionTypes';

export const INITIAL_STATE = {
  seats: [],
  seatConnectionStatus: {},
  loading: true,
};

const seatListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUCCESSFULLY_GET_SEATS_DATA:
      return { ...state, loading: false, seats: action.payload };
    case START_SEAT_CREDENTIALS_TEST:
      return {
        ...state,
        seatConnectionStatus: {
          ...state.seatConnectionStatus,
          [action.payload.id]: { kind: FetchState.loading },
        },
      };
    case SEAT_CREDENTIALS_TEST_FAILED:
      return {
        ...state,
        seatConnectionStatus: {
          ...state.seatConnectionStatus,
          [action.payload.seatId]: { kind: FetchState.error, errors: action.payload.errors },
        },
      };
    case SEAT_CREDENTIALS_TEST_COMPLETED:
      return {
        ...state,
        seatConnectionStatus: {
          ...state.seatConnectionStatus,
          [action.payload.seatId]: { kind: FetchState.success },
        },
      };
    default:
      return state;
  }
};

export default seatListReducer;
