import _ from 'lodash';

export const options = {
  byObject: {
    Strategy: { text: 'Strategy', value: 'Strategy' },
    Flight: { text: 'Flight', value: 'Flight' },
  },
  bySegment: {
    all: { text: 'All Segments', value: 'all' },
    group: { text: 'Segment Group', value: 'group' },
    id: { text: 'Segment', value: 'id' },
  },
  dateRange: {
    /* eslint-disable quote-props */
    '60': { value: 60, text: 'Last 60 days' },
    '30': { value: 30, text: 'Last 30 days' },
    '7': { value: 7, text: 'Last week' },
    /* eslint-enable */
  },
  shownAs: {
    Cumulative: { text: 'Cumulative', value: 'Cumulative' },
    Daily: { text: 'Daily', value: 'Daily' },
  },
};

export const shownAsValue = _.values(options.shownAs);
export const dateRangeValue = _.values(options.dateRange);

export const NUM_DAYS_BACK = 60;
export const STRATEGY_GOAL_ANALYTICS_MICROSERVICE = 'strategy_goal_analytics';

export const flightKeySeparator = '_';

type FlightKey = { externalType: string, externalId: string };
export const flightKey = ({ externalType, externalId }: FlightKey) => (
  _.join([externalType, externalId], flightKeySeparator)
);

export const LEGACY_PREFIX = 'legacy';

export enum AggregationLevel {
  strategy = 'strategy',
  dsp = 'dsp',
  dspIsClone = 'dspIsClone',
  member = 'member',
  parent = 'parent',
  child = 'child',
  lineItemPair = 'lineItemPair',
  isClone = 'isClone',
  budgetGroup = 'budgetGroup',
  budgetGroupIsClone = 'budgetGroupIsClone',
}
