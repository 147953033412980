import {
  DOWNLOAD_CUSTOM_TREE_ANALYTICS,
  RESET_DATA,
} from './constants';

export const INITIAL_STATE = {
  compState: {
    kind: 'initial',
    headers: [],
    analytics: [],
  },
  filter: {
    startDate: null,
    endDate: null,
  },
};

function customTreeAnalyticsReducer(
  state: {
    compState: {
      kind: string,
      headers: Array<any>,
      analytics: Array<any>,
    },
    filter: {} } = INITIAL_STATE,
  action: { payload: any, type: string },
) {
  switch (action.type) {
    case RESET_DATA:
      return { ...INITIAL_STATE };
    case DOWNLOAD_CUSTOM_TREE_ANALYTICS.STARTED:
      return {
        ...state,
        compState: {
          kind: 'loading', headers: [], analytics: [],
        },
      };
    case DOWNLOAD_CUSTOM_TREE_ANALYTICS.COMPLETED:
      return {
        ...state,
        compState: {
          kind: 'hasData',
          headers: action.payload.headers,
          analytics: action.payload.analytics,
        },
      };
    case DOWNLOAD_CUSTOM_TREE_ANALYTICS.NO_DATA:
      return {
        ...state,
        compState: {
          kind: 'noData',
        },
      };
    case DOWNLOAD_CUSTOM_TREE_ANALYTICS.FAILED:
      return {
        ...state,
        compState: {
          kind: 'error',
        },
      };
    default:
      return state;
  }
}

export default customTreeAnalyticsReducer;
