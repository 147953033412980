import _ from 'lodash';
import React from 'react';
import { Container, Image, Header, Button, Loader, WppIconTrash, WppIconFile } from 'buildingBlocks';
import { dataUploader } from 'containers/StrategyAnalytics/components/View/DataUploader/style';
import {
  FileStates, RED_EXCLAMATION_IMG_PATH, SUBHEADER_TEXT, buttonDisableStates, incorrectFile, incorrectFileName, JsonErrTypes,
} from 'containers/StrategyAnalytics/components/View/DataUploader/constants';

const {
  fileContainer, uploadButtonWidth, subheader, fileDownloadDiv, fileUploadDiv, downloadIcon,
  fileNameText, dropzoneButton, loader, loaderText, subHeaderContainer, subheaderDescription,
} = dataUploader;

type FileToUploadProps = {
  fileName: string
  fileUploadState: FileStates
  resetFileState: () => void
  uploadClickHandle: () => void
  errType: string
};

type UploadContainerProps = {
  fileName: string
  resetFileState: () => void
  onClickHandle: () => void
  fileUploadState: FileStates
  errType: string
};

type SubHeaderProps = {
  fileState: FileStates
  errType: string
};

const SubHeader = ({ fileState, errType }: SubHeaderProps) => {
  const subheaderObj = SUBHEADER_TEXT[fileState];
  return (
    <>
      {_.isEqual(fileState, FileStates.uploading)
        ? (<div className="memberFileLoader" style={{ ...loader, ...loaderText }}>Uploading <Loader size="mini" active inline /></div>)
        : (
          <div style={subHeaderContainer}>
            <div style={subheader}>
              {subheaderObj.subheader}
              <Image src={RED_EXCLAMATION_IMG_PATH} alt="info circle" />
            </div>
            {fileState === FileStates.failed
              && (
                <div style={subheaderDescription}>
                  {_.isEqual(errType, JsonErrTypes.required) ? incorrectFileName : incorrectFile}
                </div>
              )}
          </div>
        )}
    </>
  );
};

const UploadContainer = ({ fileName, resetFileState, onClickHandle, fileUploadState, errType }: UploadContainerProps) => {
  const nonSuccessState = (fileUploadState in SUBHEADER_TEXT);
  return (
    <>
      <Container style={fileContainer}>
        <div style={fileUploadDiv}>
          <div style={fileDownloadDiv}>
            <WppIconFile size="m" color="var(--wpp-grey-color-600)" />
            <Header style={fileNameText} content={fileName} />
          </div>
          <WppIconTrash size="s" color="var(--wpp-dataviz-color-seq-negative-600)" onClick={resetFileState} style={_.omit(downloadIcon, 'color')} />
        </div>
        {nonSuccessState && <SubHeader fileState={fileUploadState} errType={errType} />}
      </Container>
      <Button
        content="Upload"
        style={{ ...dropzoneButton, ...uploadButtonWidth }}
        onClick={onClickHandle}
        disabled={_.includes(buttonDisableStates, fileUploadState)}
      />
    </>
  );
};

const FileToUpload = ({ fileName, fileUploadState, resetFileState, uploadClickHandle, errType }: FileToUploadProps) => (
  <>
    {fileUploadState !== FileStates.success
      && (
        <UploadContainer
          fileName={fileName}
          resetFileState={resetFileState}
          onClickHandle={uploadClickHandle}
          fileUploadState={fileUploadState}
          errType={errType}
        />
      )}
  </>
);

export default FileToUpload;
