import _ from 'lodash';
import numeral from 'numeral';
import { hexToRgb } from 'utils/functionHelpers';
import { dateTimeFormatter } from 'utils/dateTime';
import { tableStyle } from '../styles';
import METRICS, { metricLookup, Metric, SelectedMetric } from './metricsConstants';

/**
 * CSS-Color with alpha
 * @param r red value
 * @param g green value
 * @param b blue value
 * @param alpha opacity value
 */
export const opacityColored = ({ r, g, b }, alpha) => `rgba(${r}, ${g}, ${b}, ${alpha})`;
// TODO: Play more with color
const aggregatorColor = {
  default: { color: hexToRgb('#CCCCCC'), alpha: 1 },
  selected: { color: hexToRgb(tableStyle.activeRow.backgroundColor), alpha: 1 },
};
const ratePercentageColor = {
  default: { color: hexToRgb('#FB8C00'), alpha: 0.5 },
  selected: { color: hexToRgb('#FB8C00'), alpha: 1 },
};

type HighChartPoint = {
  x: number;
  y: number;
  color: string;
  series: {
    name: string;
  };
};

// Formatter for highcharts
export const tooltipFormatMetric = (
  point: HighChartPoint,
  metric: Metric,
  useSeriesName: boolean,
) => {
  const y = numeral(point.y).format(metric.format);
  return (
    `<span style="color:${point.color}">\u25CF</span>`
    + `${
      useSeriesName
        ? ` ${point.series.name}: <b>${y} ${metric.text}</b>`
        : `${metric.text}: <b>${y}</b>`
    }`
    + '<br/>'
  );
};
export const yLabelsFormatter = (yAxis: { value: number }, format: string) => numeral(yAxis.value).format(format);

export const legendFormatter = (
  config: {} = {},
  metricsSelected: SelectedMetric,
) => ({
  ...config,
  labelFormatter() {
    const isRateSeries = this.type === 'spline';
    return `${this.name} (${
      (isRateSeries
        ? metricsSelected.ratePercentage
        : metricsSelected.aggregator
      )?.text
    })`;
  },
});

export const chartSeriesConfigs = {
  // Aggregator configs
  aggregator: {
    type: 'column',
    yAxis: 0,
    // @ts-ignore dynamic arg number
    color: (key: string) => opacityColored(..._.values(aggregatorColor[key])),
    stack: 'aggregator',
  },
  ratePercentage: {
    type: 'spline',
    yAxis: 1,
    zIndex: 1,
    // @ts-ignore dynamic arg number
    color: (key: string) => opacityColored(..._.values(ratePercentageColor[key])),
  },
};

export const defaultChartOptions = {
  chart: {
    zoomType: 'none',
    fontFamily: 'Gilroy',
  },
  xAxis: {
    type: 'datetime',
    labels: { step: 2, align: 'center' },
  },
  tooltip: {
    formatter() {
      const date = dateTimeFormatter.date(this.x, true, 'x');
      const useSeriesName = this.points.length > 2;

      const lines = _.map(this.points, (point) => {
        const axisName = _.get(point, 'series.yAxis.axisTitle.textStr', null);
        if (axisName === null) {
          return '';
        }
        const metric = metricLookup[axisName];
        return tooltipFormatMetric(point, metric, useSeriesName);
      });

      return `${date}<br />${_.join(lines, '')}`;
    },
    shared: true,
    enabled: true,
    borderColor: '#cccccc',
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
    itemStyle: { fontWeight: '500' },
  },
  credits: {
    enabled: false,
  },
  yAxis: [
    {
      title: { enabled: true },
    },
    {
      opposite: true,
      title: { enabled: true },
      softMin: 0,
    },
  ],
  plotOptions: {
    column: {
      grouping: false,
      pointPadding: 0.001,
      groupPadding: 0.15,
    },
    spline: {
      marker: {
        enabled: false,
        symbol: 'circle',
      },
      shadow: {
        width: 3,
        offsetY: 1,
      },
    },
  },
  exporting: {
    enabled: true,
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  subtitle: {
    enabled: false,
  },
  title: {
    text: '',
  },
};

export const getDefaultChartOptions = (metrics: typeof METRICS) => {
  if (_.isEqual(metrics, METRICS)) return defaultChartOptions;

  const aggregatorTextLookup = _.keyBy(metrics.aggregator, 'text');
  const ratePercentageTextLookup = _.keyBy(metrics.ratePercentage, 'text');
  const metricDict = _.merge(aggregatorTextLookup, ratePercentageTextLookup);

  return {
    ...defaultChartOptions,
    tooltip: {
      ...defaultChartOptions.tooltip,
      metricDict,
      formatter() {
        const date = dateTimeFormatter.date(this.x, true, 'x');
        const useSeriesName = this.points.length > 2;

        const lines = _.map(this.points, (point) => {
          const axisName = _.get(point, 'series.yAxis.axisTitle.textStr', null);
          if (axisName === null) {
            return '';
          }
          const metric = metricDict[axisName];
          return tooltipFormatMetric(point, metric, useSeriesName);
        });

        return `${date}<br />${_.join(lines, '')}`;
      },
    },
  };
};
