const PREFIX = 'STRATEGY_FLIGHT_RUNS';

const FetchAction = (prefix) => (name) => ({
  STARTED: `${prefix}:${name}_STARTED`,
  COMPLETED: `${prefix}:${name}_COMPLETED`,
  FAILED: `${prefix}:${name}_FAILED`,
});

const FlightRunFetchAction = FetchAction(PREFIX);

export const STRATEGY_FLIGHT_RUNS_STARTING_ELEMENTS_PER_PAGE = 25;
export const STRATEGY_FLIGHT_RUNS_ELEMENTS_PER_PAGE_OPTIONS = [10, 25, 50];

export const STRATEGY_FLIGHT_RUNS_FETCH = `${PREFIX}:FETCH`;
export const STRATEGY_FLIGHT_RUNS_FETCH_COMPLETED = `${PREFIX}:FETCH_COMPLETED`;
export const STRATEGY_FLIGHT_RUNS_FETCH_FAILED = `${PREFIX}:FETCH_FAILED`;

export const STRATEGY_FLIGHT_RUNS_FETCH_STRATEGY_TYPE = FlightRunFetchAction('FETCH_STRATEGY_TYPE');
export const STRATEGY_FLIGHT_RUNS_FETCH_STRATEGIES = FlightRunFetchAction('FETCH_STRATEGIES');
export const STRATEGY_FLIGHT_RUNS_FETCH_FLIGHTS = FlightRunFetchAction('FETCH_FLIGHTS');
export const STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHM_TYPE = FlightRunFetchAction('FETCH_ALGORITHM_TYPE');
export const STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHMS = FlightRunFetchAction('FETCH_ALGORITHMS');
export const STRATEGY_FLIGHT_RUNS_FETCH_MEMBERS = FlightRunFetchAction('FETCH_MEMBERS');

export const STRATEGY_FLIGHT_RUNS_COLUMNS = [
  { text: 'ID', value: 'ID' },
  { text: 'Strategy Type', value: 'Type' },
  { text: 'Algorithm Type', value: 'Algorithm Type' },
  { text: 'Strategy ID', value: 'Strategy' },
  { text: 'Algorithm ID', value: 'Algorithm' },
  { text: 'Flight ID', value: 'Int. Flight ID' },
  { text: 'Ext. Flight ID', value: 'Ext. Flight ID' },
  { text: 'Member', value: 'Member ID' },
  { text: 'Status', value: 'Status' },
  { text: 'Trigger Type', value: 'Trigger Type' },
  { text: 'Last Updated', value: 'Last Updated (UTC)' },
];

export const STRATEGY_FLIGHT_RUNS_FILTER = {
  STATUS: {
    placeholder: 'Status',
    name: 'status',
    id: 'status',
    options: [
      { text: 'FAILED', value: 'FAILED' },
      { text: 'FLIGHT_EXTERNAL_NOT_FOUND', value: 'FLIGHT_EXTERNAL_NOT_FOUND' },
      { text: 'NO_DATA', value: 'NO_DATA' },
      { text: 'PENDING', value: 'PENDING' },
      { text: 'RUNNING', value: 'RUNNING' },
      { text: 'SUCCESS', value: 'SUCCESS' },
      { text: 'ABORT', value: 'ABORT' },
      { text: 'CANCELLED', value: 'CANCELLED' },
    ],
  },
  STRATEGY_TYPE: {
    placeholder: 'Strategy Type',
    name: 'strategy type',
    id: 'strategyType',
    options: [],
  },
  INPUT: {
    STRATEGIES: {
      placeholder: 'Strategy ID',
      name: 'strategy',
      id: 'strategy',
      SEARCH_UPDATED: `${PREFIX}:FILTER_STRATEGY_SEARCH_UPDATED`,
      options: [],
    },
    FLIGHTS: {
      placeholder: 'Flight ID',
      name: 'flight',
      id: 'flight',
      SEARCH_UPDATED: `${PREFIX}:FILTER_FLIGHT_SEARCH_UPDATED`,
      options: [],
    },
    ALGORITHMS: {
      placeholder: 'Algorithm ID',
      name: 'algorithm',
      id: 'algorithm',
      SEARCH_UPDATED: `${PREFIX}:FILTER_ALGORITHM_SEARCH_UPDATED`,
      options: [],
    },
    MEMBERS: {
      placeholder: 'Member',
      name: 'member',
      id: 'member',
      SEARCH_UPDATED: `${PREFIX}:FILTER_MEMBER_SEARCH_UPDATED`,
      options: [],
    },
  },
  UPDATED: {
    ANY: `${PREFIX}:FILTER_UPDATED_ANY`,
  },
  ALGORITHM_TYPE: {
    placeholder: 'Algorithm Type',
    name: 'algorithm type',
    id: 'algorithmType',
    options: [],
  },
  TRIGGER_TYPE: {
    placeholder: 'Trigger Type',
    name: 'trigger type',
    id: 'triggerType',
    options: [
      { text: 'Programmatic', value: 0 },
      { text: 'User Initiated', value: 1 },
    ],
  },
};
