import _ from 'lodash';
import moment, { Moment } from 'moment';
import { connect } from 'react-redux';
import React, { Component, ReactNode } from 'react';
import { compose } from 'redux';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import FormSectionHeader from 'components/FormSectionHeader';
import { dateTimeFormatter } from 'utils/dateTime';

import {
  Dimmer, Loader, Grid, Message, Button, Label,
} from 'buildingBlocks';
import { Flight } from 'utils/types';
import { REPORT_TYPES } from '../constants';
import RFDatePicker from './DatePicker';
import CompStateMessage from './CompStateMessage';

import FlightSelect from './FlightSelect';
import { resetState, getCustomTreeAnalytics } from '../actions';
import { PossibleStates } from '../types';

export type BasicLabelProps = {
  children?: ReactNode,
};

const BasicLabel = (props: BasicLabelProps) => (
  <Label basic size="medium" style={{ border: 0, paddingLeft: 0 }}>{props.children}</Label>
);

type Props = {
  flights: Array<Flight>,
  flightsSelected: Array<number>,
  compState: PossibleStates,
  loading: boolean,
  resetState: Function,
  startDate: Moment,
  endDate: Moment,
  getCustomTreeAnalytics: Function,
};

class CustomTree extends Component<Props> {
  componentWillUnmount() {
    this.props.resetState();
  }

  render() {
    const {
      flightsSelected,
      loading,
      startDate,
      endDate,
      compState,
      flights,
    } = this.props;

    const dldReportRequiredChk = _.isEmpty(flightsSelected) || _.isNil(startDate) || _.isNil(endDate);

    return (
      <Dimmer.Dimmable dimmed={loading || compState.kind === 'loading'}>
        <Dimmer active={loading || compState.kind === 'loading'}>
          <Loader>Loading flight data</Loader>
        </Dimmer>
        <Grid padded>
          <Grid.Column>
            <FormSectionHeader
              title="Create Leaf Performance Report"
              group="Custom Tree"
              help="Live long and prosper"
            />
            <p style={{ fontSize: '12px' }}>Select the flights and a date range of which you wish to create a report</p>
            {
              _.isEmpty(flights)
                ? (
                  <Grid.Row>
                    <Message size="small" warning>This strategy does not have any attached flights</Message>
                  </Grid.Row>
                )
                : (
                  <Grid.Row>
                    <Grid stackable width="equal">
                      <Grid.Row>
                        <Grid.Column computer={3}>
                          <BasicLabel>Flights</BasicLabel>
                          <FlightSelect flights={flights} />
                        </Grid.Column>
                        <Grid.Column
                          computer={6}
                          style={{ display: 'inline-flex', justifyContent: 'space-between' }}
                        >
                          <div>
                            <BasicLabel>Start date</BasicLabel>
                            <Field
                              name="startDate"
                              component={RFDatePicker}
                              date={startDate}
                            />
                          </div>
                          <div>
                            <BasicLabel>End date</BasicLabel>
                            <Field
                              name="endDate"
                              component={RFDatePicker}
                              date={endDate}
                              id="endDate"
                              minDate={startDate}
                              maxDate={moment().toDate()}
                            />
                          </div>
                          <div style={{ alignSelf: 'flex-end' }}>
                            <Button
                              primary
                              disabled={compState.kind === 'noData' || dldReportRequiredChk}
                              onClick={() => this.props.getCustomTreeAnalytics(
                                {
                                  flightId: flightsSelected,
                                  reportType: REPORT_TYPES.DAILY,
                                  startDate: `${dateTimeFormatter.isoDate(startDate)}`,
                                  endDate: `${dateTimeFormatter.isoDate(endDate)}`,
                                  download: true,
                                },
                              )}
                            >Download Report
                            </Button>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid>
                        <Grid.Row style={{ margin: '10px' }}>
                          {
                            compState && <div><CompStateMessage state={compState} /></div>
                          }
                        </Grid.Row>
                      </Grid>
                      <Grid.Column width={12} textAlign="right">
                        <Grid.Row>
                          <p style={{ marginTop: '10px' }}>Reports are limited to 100,000 rows of data</p>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid>
                  </Grid.Row>
                )
            }
          </Grid.Column>
        </Grid>
      </Dimmer.Dimmable>
    );
  }
}

const mapStateToProps = (state) => {
  const selector = formValueSelector('customTreeAnalytics');
  const selectedFlightValues = selector(state, 'flights');
  const startDate = selector(state, 'startDate');
  const endDate = selector(state, 'endDate');
  const flights = state.strategyAnalytics.metadata.flights;
  const loading = !flights;

  return {
    loading,
    flights: state.strategyAnalytics.metadata.flights,
    flightsSelected: selectedFlightValues,
    startDate,
    endDate,
    compState: state.strategyAnalytics.customTree.compState,
  };
};

// when form values change, fetch data (if all fields are extant)
const getAnalytics = (values, dispatch) => {
  const { flights, endDate, startDate } = values;
  if ((!_.isEmpty(flights) && !_.isNil(startDate) && !_.isNil(endDate))) {
    dispatch(
      {
        flightId: flights,
        reportType: REPORT_TYPES.DAILY,
        startDate: `${dateTimeFormatter.isoDate(startDate)}`,
        endDate: `${dateTimeFormatter.isoDate(endDate)}`,
        download: false,
      },
    );
  }
};

const CustomTreeForm = reduxForm({
  form: 'customTreeAnalytics',
  onChange: (values, dispatch) => getAnalytics(values, compose(dispatch, getCustomTreeAnalytics)),
  // @ts-ignore redux form
})(CustomTree);

export default connect(mapStateToProps, { resetState, getCustomTreeAnalytics })(CustomTreeForm);
