import { RouterProps } from 'utils/withRouter';
import {
  FETCH_ROLES,
  FETCH_ROLE_AND_PERMISSIONS,
  SAVE_ROLE,
  DELETE_ROLE,
  RESET_ALL,
  RESET_PERMISSIONS,
} from './constants';

export const getRoles = () => (
  {
    type: FETCH_ROLES,
  }
);

export const getRole = (roleId) => (
  {
    type: FETCH_ROLE_AND_PERMISSIONS,
    payload: { roleId },
  }
);

export const resetPermissions = (type) => (
  {
    type: RESET_PERMISSIONS,
    payload: { type },
  }
);

export const saveRole = (id, {
  name, description, permissions, type,
}, router: RouterProps) => (
  {
    type: SAVE_ROLE,
    payload: {
      id, name, description, permissions, type, router,
    },
  }
);

export const deleteRole = (roleId, router: RouterProps) => (
  {
    type: DELETE_ROLE,
    payload: { roleId, router },
  }
);

export function resetAll() {
  return {
    type: RESET_ALL,
  };
}
