import { AnalyticsData } from 'containers/StrategyAnalytics/types';
import {
  DOWNLOAD_HELIOS_ANALYTICS,
  FETCH_HELIOS_ANALYTICS,
  RESET_DATA,
  REPORT_TYPES,
} from './constants';

export const INITIAL_STATE = {
  headers: [],
  analytics: [],
  rawAnalytics: [],
  loading: false,
  error: false,
  noData: false,
  downloadingDaily: false,
  downloadingWeekly: false,
};

type State = {
  headers: Array<string>,
  analytics: Array<AnalyticsData>,
  rawAnalytics: Array<AnalyticsData>,
  loading: boolean,
  error: boolean,
  noData: boolean,
};

export function heliosAnalyticsReducer(
  state: State = INITIAL_STATE,
  action: { payload: any, type: string },
) {
  switch (action.type) {
    case RESET_DATA:
      return INITIAL_STATE;
    case FETCH_HELIOS_ANALYTICS.STARTED:
      return {
        ...state, headers: [], analytics: [], rawAnalytics: [], loading: true, error: false, noData: false,
      };
    case FETCH_HELIOS_ANALYTICS.COMPLETED:
      return {
        ...state,
        headers: action.payload.headers,
        analytics: action.payload.analytics,
        rawAnalytics: action.payload.data,
        loading: false,
      };
    case FETCH_HELIOS_ANALYTICS.NO_DATA:
    case FETCH_HELIOS_ANALYTICS.FAILED:
      return { ...state, loading: false, noData: true };
    case DOWNLOAD_HELIOS_ANALYTICS.STARTED:
      if (action.payload.reportType === REPORT_TYPES.DAILY) {
        return { ...state, downloadingDaily: true };
      }
      return { ...state, downloadingWeekly: true };
    case DOWNLOAD_HELIOS_ANALYTICS.COMPLETED:
    case DOWNLOAD_HELIOS_ANALYTICS.FAILED:
      if (action.payload.reportType === REPORT_TYPES.DAILY) {
        return { ...state, downloadingDaily: false };
      }
      return { ...state, downloadingWeekly: false };
    default:
      return state;
  }
}

export default heliosAnalyticsReducer;
