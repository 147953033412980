// @ts-nocheck issue with redux-form and ObjectMultiDropdown props
import _ from 'lodash';
import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button, Form, Segment, Grid, Label, Input, FieldProps,
  ObjectMultiDropdown, ObjectMultiDropdownProps, FormDropdownProps,
} from 'buildingBlocks';
import { Permission } from 'utils/featureFlags';
import PermissionPageTemplate from 'components/PageTemplate/PermissionPageTemplate';
import FormDropdown from 'components/FormDropdown';
import { validatedWrapper } from 'utils/wrapperComponents';
import { User } from 'utils/types';
import { isQAGlobalReadOnly } from 'utils/functionHelpers';
import WithRouter, { RouterProps } from 'utils/withRouter';
import { noBorderRadius, inputField, newRoleButton } from './style';
import { saveRole, getRole, resetAll, resetPermissions, deleteRole } from './actions';
import { validateRolePage as validate } from './validate';
import { errorMsgMap, roleTypes } from './constants';
import { DeleteRoleConfirm } from './components/DeleteRoleConfirm';

const REQUIRED_PERMISSIONS = [Permission.manageRoles];

type ErrorProps = {
  error: string,
};

const Error = ({ error }: ErrorProps) => (
  <Grid.Column floated="left" width={8}>
    <Label basic htmlFor="saveError" size="large" style={noBorderRadius} color="red">{error}</Label>
  </Grid.Column>
);

type DropdownErrorProps = {
  errorMsg: string,
};

const DropdownError = ({ errorMsg }: DropdownErrorProps) => (
  <span style={{ color: '#DB2828' }}>{errorMsg}</span>
);

type RenderInputProps = {
  name: string,
  label: string,
  input: {},
  disabled: boolean,
};

export const RenderInput = (props: RenderInputProps) => (
  <Form.Field>
    <label htmlFor={props.name}>{props.label}</label>
    <Input fluid type="text" disabled={props.disabled}>
      <input {...props.input} />
    </Input>
  </Form.Field>
);

export const ValidatedRenderInput = validatedWrapper(RenderInput);

type RenderFormInputFieldProps = {
  name: string,
  description: string,
  disabled?: boolean,
  label: string,
};

const RenderFormInputField = ({ name, ...rest }: RenderFormInputFieldProps) => (
  <Grid.Row>
    <Grid.Column width={6}>
      <Form.Field>
        <Field<FieldProps<RenderFormInputFieldProps>>
          {...rest}
          id={name}
          name={name}
          style={inputField}
          component={ValidatedRenderInput}
        />
      </Form.Field>
    </Grid.Column>
  </Grid.Row>
);

type RoleProps = RouterProps & {
  permissions: Array<{}>,
  role: {
    id: number,
    name: string,
    description: string,
    permissions: Array<{}>,
    type: number,
  },
  getRole: Function,
  saveRole: Function,
  deleteRole: Function,
  resetAll: Function,
  saveError: boolean,
  fetchPermissionsError: boolean,
  invalid: boolean,
  resetPermissions: Function,
  user: User,
};

export class Role extends Component<RoleProps> {
  constructor(props: RoleProps) {
    super(props);
    const roleId = this.props.router.params.roleId;
    this.props.resetAll();
    this.props.getRole(roleId);
  }

  onChangeRoleType = (_e, type) => {
    this.props.resetPermissions(type);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.saveRole(
      this.props.router.params.roleId,
      this.props.role,
      this.props.router,
    );
  };

  renderSaveSection() {
    const qAReadOnly = isQAGlobalReadOnly(this.props.user);
    return (
      <Grid.Row columns={2}>
        {this.props.saveError && <Error error={errorMsgMap.ROLE_ALREADY_EXIST} />}
        <DeleteRoleConfirm
          deleteRole={this.props.deleteRole}
          role={this.props.role}
          router={this.props.router}
          qAReadOnly={qAReadOnly}
        />
        <Grid.Column floated="right">
          <Form.Field>
            <Button
              content="Save"
              type="submit"
              style={newRoleButton}
              floated="right"
              disabled={qAReadOnly || this.props.invalid}
            />
            <Link to="/roles">
              <Button content="Cancel" style={noBorderRadius} floated="right" />
            </Link>
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    );
  }

  render() {
    const { router, fetchPermissionsError, permissions, role } = this.props;
    const isEdit = !_.isUndefined(router.params.roleId);

    return (
      <Segment clearing>
        <Form onSubmit={this.handleSubmit}>
          <Grid>
            <RenderFormInputField
              name="roleName"
              description="Enter a Role Name"
              label="Role Name"
              disabled={isEdit}
            />
            <RenderFormInputField
              name="roleDescription"
              description="Tell us something about this role"
              label="Role Description"
            />
            <Grid.Row>
              <Grid.Column floated="left" width={6}>
                <Form.Field>
                  <label htmlFor="roleType">Role Type</label>
                  <Field<FieldProps<FormDropdownProps>>
                    onChange={(e, val) => this.onChangeRoleType(e, val)}
                    id="roleType"
                    name="roleType"
                    component={FormDropdown}
                    options={roleTypes}
                    parse={(value) => parseInt(value, 10)}
                    fluid
                    search
                    selection
                    disabled={isEdit}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column floated="left" width={6}>
                <Form.Field>
                  <label htmlFor="rolePermissions1">Role Permissions</label>
                  <Field<FieldProps<ObjectMultiDropdownProps<unknown>>>
                    component={ObjectMultiDropdown}
                    id="rolePermissions"
                    name="rolePermissions"
                    options={_.filter(permissions, { type: role.type })}
                    keyFn={(obj: { displayName: string }) => obj.displayName}
                    error={fetchPermissionsError}
                    disabled={fetchPermissionsError}
                    selection
                    value={role.permissions}
                  />
                  {fetchPermissionsError && <DropdownError errorMsg={errorMsgMap.FETCH_PERMISSIONS_FAILED} />}
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            {this.renderSaveSection()}
          </Grid>
        </Form>
      </Segment>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector('roleDetails');
  return {
    permissions: state.roles.permissions,
    role: {
      id: state.roles.role.id,
      name: selector(state, 'roleName'),
      description: selector(state, 'roleDescription'),
      permissions: selector(state, 'rolePermissions'),
      type: selector(state, 'roleType'),
    },
    initialValues: {
      roleName: state.roles.role.name,
      roleDescription: state.roles.role.description,
      rolePermissions: state.roles.role.permissions,
      roleType: state.roles.role.type,
    },
    saveError: state.roles.saveError,
    fetchPermissionsError: state.roles.fetchPermissionsError,
    user: state.login.session.data.user,
  };
}

const PermissionRole = (props) => {
  const roleId = _.get(props, 'router.params');
  return (
    <PermissionPageTemplate
      title="Role"
      name={`${roleId ? 'Edit' : 'Create'} Role`}
      permissions={REQUIRED_PERMISSIONS}
    >
      <Role {...props} />
    </PermissionPageTemplate>
  );
};

export default connect(
  mapStateToProps,
  {
    getRole,
    saveRole,
    deleteRole,
    resetAll,
    resetPermissions,
  },
)(reduxForm({
  form: 'roleDetails',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(WithRouter(PermissionRole)));
