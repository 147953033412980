import React from 'react';
import { strategyMetadataStyles } from '../styles';

type NoGoalProps = {
  strategy: {
    name: string,
    id: number,
  }
};

const NoGoalMessage = ({ strategy }: NoGoalProps) => (
  <div id="strategyMetadata" style={strategyMetadataStyles.container}>
    <div style={strategyMetadataStyles.title.container}>
      <h2 style={strategyMetadataStyles.title.content}>
        {`${strategy.name} (${strategy.id})`}
      </h2>
      <div style={strategyMetadataStyles.additionalInformation.container}>
        No Strategy Goals
      </div>
    </div>
  </div>
);

export default NoGoalMessage;
