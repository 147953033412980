import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WppTooltip, WppIconFavouritesFilled, WppIconFavourites } from 'buildingBlocks';
import { StarredUserStrategyType } from 'containers/StrategyAnalytics/types';
import { GlobalState } from 'reducers';
import style from './style';
import { unstarUserStrategy, starUserStrategy } from './utils';

const { starIconStyle, starFilledIconStyle } = style;

type StarredUserStrategyProps = {
  userId: number
  strategyId: number
  className: string
  iconStyle?: React.CSSProperties
};

const StarredUserStrategy = ({ userId, strategyId, className, iconStyle }: StarredUserStrategyProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const starredUserStrategies = useSelector<GlobalState>((state) => state.login.user.starredUserStrategies) as Array<StarredUserStrategyType>;
  const starredUserStrategyId = _.get(_.find(starredUserStrategies, { strategy: strategyId }), 'id');
  const content = starredUserStrategyId
    ? 'Un-star strategy. You will stop receiving notifications.'
    : 'Star Strategy. You will receive notifications for this strategy.';
  const baseIconStyles = { ...starIconStyle, ...iconStyle, ...(loading && { cursor: 'default' }) };

  const onStarClick = () => {
    setLoading(true);
    if (starredUserStrategyId) {
      unstarUserStrategy(starredUserStrategyId, strategyId, setLoading, dispatch);
    }
    else {
      starUserStrategy({ user: userId, strategy: strategyId }, setLoading, dispatch);
    }
  };
  const handleStarClicked = loading ? null : onStarClick;

  return (
    <WppTooltip text={content} theme="light">
      {starredUserStrategyId ? (
        <WppIconFavouritesFilled
          style={{ ...baseIconStyles, ...starFilledIconStyle }}
          onClick={handleStarClicked}
          className={className}
        />
      ) : (
        <WppIconFavourites
          style={baseIconStyles}
          onClick={handleStarClicked}
          className={className}
        />
      )}
    </WppTooltip>
  );
};

export default StarredUserStrategy;
