import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, getFormSyncErrors, InjectedFormProps } from 'redux-form';
import { Form } from 'buildingBlocks';
import { User, Metadata } from 'utils/types';
import { remap } from 'utils/airflow/nameTranslator';
import { Microservices } from 'utils/copilotAPI';
import SubmitMessage from './components/SubmitMessage';
import {
  FORM_NAME, REQUEST_SUCCESS_MESSAGE, mapping, AUDIENCE_REPORT_MICROSERVICE, REQUEST_FAILURE_MESSAGE, INITIAL_VALUES,
} from './constants';
import { validate } from './validate';
import AudienceReportForm from './components/AudienceReportForm';

const REQUIRED_FIELDS = ['reportMetrics', 'reportInterval'];

type StatusMsg = {
  content?: string,
  icon?: string,
  className?: string,
};

type Props = {
  canDownload: boolean,
  user: User,
  metadata: Metadata,
} & InjectedFormProps;

type Data = {
  reportDimensions: Array<string>,
  reportMetrics: Array<string>,
  reportInterval: string,
};

const resetFields = (e, reset, setRequestReceived: Function, setStatusMsg: Function) => {
  e.preventDefault();
  reset();
  setRequestReceived(false);
  setStatusMsg({});
};

const onSubmit = (data: Data, user: User, metadata: Metadata, setRequestReceived: Function, setStatusMsg: Function) => {
  const { reportDimensions, reportMetrics, reportInterval } = data;
  const { strategy } = metadata;
  const payload = remap(
    mapping,
    {
      reportDimensions: _.map(reportDimensions, 'value'),
      reportMetrics: _.map(reportMetrics, 'value'),
      memberExternalId: _.get(strategy, 'member.externalId'),
      strategyId: _.get(strategy, 'id'),
      reportInterval,
    },
  );
  setRequestReceived(true);
  try {
    Microservices.runService({ ...payload, user }, AUDIENCE_REPORT_MICROSERVICE);
    setStatusMsg(REQUEST_SUCCESS_MESSAGE);
  } catch (error) {
    setStatusMsg(REQUEST_FAILURE_MESSAGE);
  }
};

const AudienceReport = (props: Props) => {
  const { user, metadata } = props;
  const [requestReceived, setRequestReceived] = useState(false);
  const [statusMsg, setStatusMsg] = useState<StatusMsg>({});
  const isStatusMsgEmpty = _.isEmpty(statusMsg);
  return (
    <Form onSubmit={props.handleSubmit(
      (data: Data) => onSubmit(data, user, metadata, setRequestReceived, setStatusMsg),
    )}
    >
      {
        !requestReceived && isStatusMsgEmpty
          ? (
            <AudienceReportForm
              canDownload={props.canDownload}
            />
          )
          : !isStatusMsgEmpty && (
            <div style={{ marginTop: '50px' }}>
              <SubmitMessage
                content={statusMsg.content}
                icon={statusMsg.icon}
                className={statusMsg.className}
                resetFields={(e) => resetFields(e, props.reset, setRequestReceived, setStatusMsg)}
              />
            </div>
          )
      }
    </Form>
  );
};

const mapStateToProps = (state) => {
  const selector = formValueSelector(FORM_NAME);
  return {
    initialValues: INITIAL_VALUES,
    reportDimensions: _.map(selector(state, 'reportDimensions'), 'value'),
    reportMetrics: _.map(selector(state, 'reportMetrics'), 'value'),
    reportInterval: selector(state, 'reportInterval'),
    canDownload: _.isEmpty(_.pick(getFormSyncErrors(FORM_NAME)(state), REQUIRED_FIELDS)),
    user: state.login.user,
    metadata: state.strategyAnalytics.metadata,
  };
};

export default connect(mapStateToProps, null)(
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  // @ts-ignore redux form
  })(AudienceReport),
);
