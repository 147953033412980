import { IMG_ICONS_16_BASE_PATH } from 'constantsBase';
import { dataUploader } from './style';

export const CYOD_HELP_CENTER_LINK = 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001212691-upload-your-external-data';
const CYOD_DOWNLOAD_TEMPLATE_LINK = 'https://copilotsupport.freshdesk.com/helpdesk/attachments/47282328912?download=true';

export const BULLET_POINTS = [
  'To increase cumulative learning, upload as much prior data as possible, including all previous submissions.',
  'Each CSV should only contain data for one Strategy. Include all measurements.',
  'Ensure there are no missing “date,” “success_events” or “child_ext_id” entries in rows. Blank entries will cause errors.',
  'Remove duplicates. To ensure an accurate sum of success events, each row should contain only one date and Line Item or Ad Group combination.',
  'Remove blank rows from the bottom of the file.',
  `<a href=${CYOD_DOWNLOAD_TEMPLATE_LINK} download style={${JSON.stringify(dataUploader.anchorText)}}>Download</a> a template file for your reference.`,
];

export const ACCEPTED_TYPE = 'text/csv';

export const SUBHEADER_TEXT = {
  systemError: {
    subheader: 'Something went wrong',
    description: `Please try again later, or contact <a href=${CYOD_HELP_CENTER_LINK} target="_blank" rel="noopener noreferrer" style={${JSON.stringify(dataUploader.anchorText)}}>Copilot Support.</a>`,
  },
  failed: {
    subheader: 'Validation Failed',
    description: '',
  },
  uploading: {
    subheader: 'Uploading',
    description: '',
  },
};

export const CSV_FILE_IMG_PATH = `${IMG_ICONS_16_BASE_PATH}/csv-file.svg`;
export const GREEN_CHECK_IMG_PATH = `${IMG_ICONS_16_BASE_PATH}/green-check.svg`;
export const THIN_X_IMG_PATH = `${IMG_ICONS_16_BASE_PATH}/thin-x.svg`;
export const RED_EXCLAMATION_IMG_PATH = `${IMG_ICONS_16_BASE_PATH}/red-exclamation-circle.svg`;
export const DOWNLOAD_FILE_IMG_PATH = `${IMG_ICONS_16_BASE_PATH}/download-file.svg`;
export const FILE_UPLOADER_PATH = `${IMG_ICONS_16_BASE_PATH}/file-uploader.svg`;

export enum FileStates {
  initial = 'initial',
  uploading = 'uploading',
  success = 'success',
  failed = 'failed',
  systemError = 'systemError',
}

export enum JsonErrTypes {
  required = 'required',
  invalid = 'invalid',
}
export const SYSTEM_ERROR_STATUS_CODE = 500;
export const CYOD_ENDPOINT = 'ext_data_upload';
export const GET_CYOD_FILE_ENDPOINT = 'get_file_info';

export const INFO_TEXT = 'Upload external data files here. Upload fresh data at least every 3 days and only once per day. Learn more about the ';
export const SUCCESS_DESC = 'Copilot will use the data just uploaded to optimize your campaign.';
export const incorrectFile = 'Incorrect File.';
export const incorrectFileName = 'File name must be sa.json.';
export const buttonDisableStates = [FileStates.failed, FileStates.systemError, FileStates.uploading];
export const JSON_FILE_NAME = 'sa.json';
