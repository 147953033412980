/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { WppButton, WppInlineMessage, WppSideModal, WppTypography } from 'buildingBlocks';
import { EditStrategiesFormType } from 'containers/StrategiesList/constants';
import { selectedStrategiesPanel } from 'containers/StrategiesList/style';
import { initEditMultipleStratFormValues, initEditSingleStratFormValues } from 'containers/StrategiesList/utils';
import { WIZARD_STEPS, WizardSteps } from 'containers/StrategyWizard/constants';
import { GlobalState } from 'reducers';
import { pluralizer } from 'utils/formattingUtils';
import { EnhancedStrategy, ToastConfig } from 'utils/types';
import EditStrategiesForm from './EditStrategiesForm';
import EditStrategiesList from './EditStrategiesList';
import EditConfirmationModal from './EditConfirmationModal';

const { modalButtonContainer, editModalBanner } = selectedStrategiesPanel;

type BulkEditSideModalProps = {
  editModalOpen: boolean
  setEditModalOpen: (x: boolean) => void
  showToast: (config: ToastConfig) => void
};

const BulkEditSideModal = (props: BulkEditSideModalProps) => {
  const { editModalOpen, setEditModalOpen, showToast } = props;
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { selectedStrategies } = useSelector<GlobalState>((state) => state.strategiesList) as { selectedStrategies: Array<EnhancedStrategy> };
  const selectedStrategiesCount = _.size(selectedStrategies);
  const editingSingleStrategy = _.size(selectedStrategies) === 1;
  const singleStrategySelected = editingSingleStrategy && _.head(selectedStrategies);
  const strategyText = pluralizer('Strategy', 'Strategies')(selectedStrategiesCount);
  const titleText = `${editingSingleStrategy ? 'Quick Edit' : 'Bulk Editing'} ${strategyText}`;
  const actionBtnText = editingSingleStrategy ? 'Edit Other Settings' : 'Learn More';
  const bannerMessage = editingSingleStrategy
    ? 'You can quickly edit a few settings here. For other settings, please go to the strategy wizard.'
    : 'You are about to update multiple strategies. Please double check and make sure everything is correct before proceeding.';

  const defaultValues = editingSingleStrategy ? initEditSingleStratFormValues(singleStrategySelected) : initEditMultipleStratFormValues(selectedStrategies);
  const formMethods = useForm<EditStrategiesFormType>({ defaultValues, mode: 'onChange' });
  const { reset, resetField, watch, unregister, setValue, control, formState: { errors } } = formMethods;
  const formValues = useWatch({ control }) as EditStrategiesFormType;
  const goal = watch('goal');
  const goalTarget = watch('goalTarget');
  const viewabilityEnabled = watch('viewabilityEnabled');
  const viewabilityTarget = watch('viewabilityTarget');
  const goalChanged = !_.isEqual(goal, defaultValues.goal);
  const viewabilityEnabledChanged = !_.isEqual(viewabilityEnabled, defaultValues.viewabilityEnabled);
  const disabled = _.isEqual(formValues, defaultValues) || !_.isEmpty(errors)
    // goal was changed and goal target was not updated or is not valid
    || (goalChanged && (!goalTarget || _.isNaN(_.isNumber(goalTarget))))
    || (viewabilityEnabled && (!viewabilityTarget || _.isNaN(_.isNumber(viewabilityTarget))));

  useEffect(() => {
    // editing the name field is only available when editing a single strategy
    if (!editingSingleStrategy) {
      unregister('name');
    }
    reset(defaultValues);
  }, [selectedStrategies, editModalOpen]);

  useEffect(() => {
    // reset the goal target field if goal is changed
    if (goalChanged) {
      setValue('goalTarget', null);
    } else {
      resetField('goalTarget');
    }
  }, [goal]);

  useEffect(() => {
    // reset the viewability target field if viewability is changed
    if (viewabilityEnabledChanged) {
      setValue('viewabilityTarget', null);
    } else {
      resetField('viewabilityTarget');
    }
  }, [viewabilityEnabled]);

  const onActionBtnClick = () => {
    if (editingSingleStrategy) {
      navigate(`/strategies/wizard/${singleStrategySelected.id}/${WIZARD_STEPS[WizardSteps.attachFlightsStep].id}`);
    } else {
      window.open('https://copilotsupport.freshdesk.com/support/solutions/articles/47001267993', '_blank');
    }
  };

  const handleModalClose = () => setEditModalOpen(false);

  return (
    <FormProvider {...formMethods}>
      <EditConfirmationModal
        confirmModalOpen={confirmModalOpen}
        setConfirmModalOpen={setConfirmModalOpen}
        setEditModalOpen={setEditModalOpen}
        defaultValues={defaultValues}
        showToast={showToast}
      />
      <WppSideModal
        size="l"
        open={editModalOpen}
        onWppSideModalClose={handleModalClose}
      >
        <WppTypography type="2xl-heading" slot="header">{titleText}</WppTypography>
        <div slot="body">
          <WppInlineMessage
            size="l"
            type="information"
            hideCloseBtn
            style={editModalBanner}
            titleText={titleText}
            message={bannerMessage}
            actionBtnText={actionBtnText}
            onWppClickActionBtn={onActionBtnClick}
          />
          <EditStrategiesList />
          <EditStrategiesForm />
        </div>
        <div slot="actions" style={modalButtonContainer}>
          <WppButton variant="secondary" size="s" onClick={handleModalClose}>Close</WppButton>
          <WppButton
            variant="primary"
            size="s"
            disabled={disabled}
            onClick={() => setConfirmModalOpen(true)}
          >
            Save
          </WppButton>
        </div>
      </WppSideModal>
    </FormProvider>
  );
};

export default BulkEditSideModal;
