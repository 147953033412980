import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ImageFile } from 'react-dropzone';
import { useWatch } from 'react-hook-form';
import { Button, Image, Divider } from 'buildingBlocks';
import BrowseDragDropPasteInputTextZone from 'components/BrowseDragDropPasteInputTextZone';
import { dataUploader } from '../style';
import { ACCEPTED_TYPE, FileStates as FileStatesEnum, FILE_UPLOADER_PATH } from '../constants';
import FileStates from './FileStates';
import FileToUpload from './FileToUpload';
import { CSVFile, ExternalDataForm } from '../types';

const {
  uploaderSection, dropzoneContainer, dropzoneButton,
  dropzoneText, dropzoneContent, dropzoneIcon,
  fileText, disabledUploadBtn, disabledDropzoneIcon,
} = dataUploader;

const FileUploaderSection = () => {
  const [reader, setReader] = useState<FileReader>(new FileReader());
  const [fileUploadState, setFileUploadState] = useState<FileStatesEnum>(FileStatesEnum.initial);
  const [csvFile, setCsvFile] = useState<CSVFile>(null);

  const fileName = useWatch<ExternalDataForm>({ name: 'fileName' });
  const disableDrop = _.isEqual(FileStatesEnum.uploading, fileUploadState)
    || (_.isEqual(FileStatesEnum.initial, fileUploadState) && !!csvFile);

  useEffect(() => {
    if (!reader) {
      setReader(new FileReader());
    }
  }, [reader]);

  const onDrop = (accepted: Array<ImageFile>) => {
    const file = _.first(accepted);
    if (file) {
      reader.onload = () => {
        const csvData = reader.result;
        setCsvFile(_.assign(file, { data: csvData }));
      };
    }

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.readAsText(file, 'UTF-8');
  };

  const resetFileState = () => {
    setCsvFile(null);
    setFileUploadState(FileStatesEnum.initial);
  };

  return (
    <div style={uploaderSection}>
      <BrowseDragDropPasteInputTextZone
        name="fileName"
        dropzonestyle={dropzoneContainer}
        onDrop={onDrop}
        accept={ACCEPTED_TYPE}
        isDisabled={disableDrop}
        onClick={resetFileState}
      >
        <div style={dropzoneContent}>
          <Image
            alt="file uploader"
            src={FILE_UPLOADER_PATH}
            style={{ ...dropzoneIcon, ...(disableDrop && disabledDropzoneIcon) }}
          />
          <p style={dropzoneText}>
            Drag and drop your file or
          </p>
          <Button
            content="Choose file"
            style={{ ...dropzoneButton, ...(disableDrop && disabledUploadBtn) }}
          />
        </div>
      </BrowseDragDropPasteInputTextZone>
      {csvFile && (
        <FileStates
          file={csvFile}
          onDelete={resetFileState}
          fileUploadState={fileUploadState}
          setFileUploadState={setFileUploadState}
        />
      )}
      <Divider style={{ margin: 0 }} />
      {_.isEmpty(fileName)
        ? (<div style={fileText}>No file uploaded yet</div>)
        : (
          <FileToUpload
            fileName={fileName}
            fileUploadState={fileUploadState}
          />
        )}
    </div>
  );
};

export default FileUploaderSection;
