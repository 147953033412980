import { combineReducers } from 'redux';
import {
  FETCH_STRATEGY,
  FETCH_STRATEGY_COMPLETED,
  FETCH_STRATEGY_FAILED,
  RESET_PROPS,
  FETCH_SEGMENTS,
  FETCH_SEGMENTS_COMPLETED,
  FETCH_PIXELS,
  FETCH_PIXELS_COMPLETED,
  TOGGLE_DELETE_MODAL,
  DELETE_STRATEGY,
  DELETE_STRATEGY_COMPLETED,
  DELETE_STRATEGY_FAILED,
  UPDATE_CLIENT_TEST_FOR_STRATEGY_COMPLETED,
  UPDATE_CLIENT_TEST_FOR_STRATEGY_FAILED,
} from '../constants/actionsConstants';
import optionValue from './option';
import segmentRecency from '../components/View/SegmentRecency/reducer';
import helios from '../components/View/Helios/reducer';
import customTree from '../components/View/CustomTree/reducer';

/**
 * strategy: strategy metadata
 * analytics: strategy + flights analytics returned by the database
 * loading: loading metadata + analytics
 * processingData: processing data from database
 * error: error thrown by sagas
 * data: strategy + flights analytics (processed)
 * slicedData: strategy + flights analytics per day (processed)
 */
export const INITIAL_STATE = {
  strategy: {},
  strategyConfig: {},
  flights: [],
  latestRuns: [],
  status: {},
  analytics: [],
  analyticsPerFlight: [],
  strategyGoalAnalyticsMetadata: {},
  loading: true,
  processingData: false,
  error: null,
  data: {},
  segments: [],
  pixels: [],
  isDeleteModalOpen: false,
  deleting: false,
  showChanges: false,
  deletionFailed: false,
  hasIncompleteData: false,
};

/**
 * Reducer for analytics
 * loading is flipped to true when we fetch data from database (strategy metadata + strategy/flights analytics)
 * processingData is flipped to true when we are processing the data from the database (after analytics call return)
 * error is not null when an error gets thrown by any sagas (for displaying purpose)
 * @param state
 * @param action
 * @returns {*}
 */
export function metadata(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_STRATEGY:
      return {
        ...state,
        loading: true,
        processingData: false,
        error: null,
      };
    case FETCH_STRATEGY_COMPLETED:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case FETCH_STRATEGY_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        processingData: false,
        status: action.payload.status,
      };
    case FETCH_SEGMENTS:
      return { ...state, loadingSegments: true };
    case FETCH_SEGMENTS_COMPLETED:
      return {
        ...state,
        loadingSegments: false,
        segments: action.payload,
      };
    case FETCH_PIXELS:
      return { ...state, loadingPixels: true };
    case FETCH_PIXELS_COMPLETED:
      return {
        ...state,
        loadingPixels: false,
        pixels: action.payload,
      };
    case RESET_PROPS:
      return INITIAL_STATE;
    case TOGGLE_DELETE_MODAL:
      return { ...state, isDeleteModalOpen: !state.isDeleteModalOpen };
    case DELETE_STRATEGY:
      return { ...state, deleting: true };
    case DELETE_STRATEGY_COMPLETED:
      return {
        ...state,
        deleting: false,
        isDeleteModalOpen: false,
      };
    case DELETE_STRATEGY_FAILED:
      return {
        ...state,
        deleting: false,
        deletionFailed: true,
      };
    case UPDATE_CLIENT_TEST_FOR_STRATEGY_COMPLETED:
      return {
        ...state,
        strategy: { ...state.strategy, clientTest: action.payload },
      };
    case UPDATE_CLIENT_TEST_FOR_STRATEGY_FAILED:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export default combineReducers({
  metadata,
  optionValue,
  helios,
  segmentRecency,
  customTree,
});
