import {
  GET_SEATS_DATA,
  START_ADD_SEAT,
  START_EDIT_SEAT,
  STOP_ALTER_SEAT,
  SAVE_SEAT,
  GET_DSP_DATA,
  START_SEAT_CREDENTIALS_TEST,
  GET_COUNTRY_DATA,
  GET_BUSINESS_MODEL_DATA,
  GET_BUSINESS_UNIT_DATA,
  GET_FEE_OPTION_DATA,
  GET_FEE_TYPE_DATA,
  GET_REGION_DATA,
  SEAT_CREDENTIALS_TEST_COMPLETED,
  SEAT_CREDENTIALS_TEST_FAILED,
  GET_PERMISSIONS_DATA,
} from '../constants/ActionTypes';

export function getSeatsData() {
  return {
    type: GET_SEATS_DATA,
  };
}

export function getDSPs() {
  return {
    type: GET_DSP_DATA,
  };
}

export function getCountries() {
  return {
    type: GET_COUNTRY_DATA,
  };
}

export function getBusinessUnits() {
  return {
    type: GET_BUSINESS_UNIT_DATA,
  };
}

export function getBusinessModels() {
  return {
    type: GET_BUSINESS_MODEL_DATA,
  };
}

export function getFeeOptions() {
  return {
    type: GET_FEE_OPTION_DATA,
  };
}

export function getFeeTypes() {
  return {
    type: GET_FEE_TYPE_DATA,
  };
}

export function getRegions() {
  return {
    type: GET_REGION_DATA,
  };
}

export function addNewSeat() {
  return {
    type: START_ADD_SEAT,
  };
}

export function stopAlterSeat() {
  return {
    type: STOP_ALTER_SEAT,
  };
}

export function editSeat(seat) {
  return {
    type: START_EDIT_SEAT,
    payload: seat,
  };
}

export function saveSeat(seat) {
  return {
    type: SAVE_SEAT,
    payload: seat,
  };
}

export function checkSeatCredentials(seat) {
  return {
    type: START_SEAT_CREDENTIALS_TEST,
    payload: seat,
  };
}

export function seatTestCompleted(seatId: number) {
  return {
    type: SEAT_CREDENTIALS_TEST_COMPLETED,
    payload: { seatId },
  };
}

export function seatTestFailed(seatId: number, errors) {
  return {
    type: SEAT_CREDENTIALS_TEST_FAILED,
    payload: { seatId, errors },
  };
}

export const getPermissions = () => ({
  type: GET_PERMISSIONS_DATA,
});
