import _ from 'lodash';
import React, { Component, cloneElement } from 'react';
import { Table, Message } from 'buildingBlocks';

type TableHeaderProps = {
  headers: Array<{ value: string, text: string }>,
  sortBy: string,
  sortDescending: boolean,
  onClick: Function,
};

const TableHeader = ({
  headers, sortBy, sortDescending, onClick,
}: TableHeaderProps) => (
  <Table.Header>
    <Table.Row>
      {(_.map(headers, (header) => (
        <Table.HeaderCell
          key={header.value}
          onClick={() => onClick && onClick(header.value)}
          className={
            `${sortBy === header.value ? `sorted ${sortDescending ? 'descending' : 'ascending'}` : ''}
             ${header.text === 'Flight' ? 'six wide' : ''}
             ${header.text === 'End Date' ? 'two wide' : ''}`
}
          title={header.text}
        >
          {header.text}
        </Table.HeaderCell>
      )))}
    </Table.Row>
  </Table.Header>
);

/* eslint-disable react/no-unused-prop-types */
type Props = {
  headers: Array<{ text: string, value: string }>,
  sortBy: string,
  sortDescending: boolean,
  body?: Array<unknown>,
  id: string,
  noDataMsg?: string,
  children: JSX.Element,

  onHeaderClick: Function,
  customSort?: Function,
};
/* eslint-enable */

type State = {
  data: Array<unknown>,
};

class MetricsTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { data: [] };
  }

  UNSAFE_componentWillMount() {
    this.setSortedBody(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    const newVal = _.pick(newProps, ['sortBy', 'body', 'sortDescending']);
    const oldVal = _.pick(this.props, ['sortBy', 'body', 'sortDescending']);
    if (!_.isEqual(oldVal, newVal)) {
      this.setSortedBody(newProps);
    }
  }

  setSortedBody({
    body, sortBy, sortDescending, customSort,
  }: Props) {
    let data;
    if (customSort) {
      data = customSort(body, sortBy, sortDescending);
    } else if (body) {
      data = [...body];
      if (sortBy) {
        data = _.sortBy(data, sortBy);
        if (sortDescending) {
          data = _.reverse(data);
        }
      }
    }

    this.setState({
      data,
    });
  }

  render() {
    const {
      id,
      headers,
      sortBy,
      sortDescending,
      noDataMsg,
      onHeaderClick,
      children,
    } = this.props;
    return (
      <Table id={id} sortable basic="very" style={{ borderTop: '1px solid rgba(34,36,38,.1)' }}>
        <TableHeader
          headers={headers}
          sortBy={sortBy}
          sortDescending={sortDescending}
          onClick={onHeaderClick}
        />
        {
          _.isEmpty(this.state.data) ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={_.size(headers)}>
                  <Message warning>{noDataMsg || 'This table currently has no analytics data to display.'}</Message>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : cloneElement(children, { headers, ...this.state })
        }
      </Table>
    );
  }
}

export default MetricsTable;
