import React from 'react';
import _ from 'lodash';
import { Field } from 'redux-form';
import { Form, Grid, FieldProps, Button, ButtonProps } from 'buildingBlocks';
import FormDropdown from 'components/FormDropdown';
import { validatedFieldWrapper } from 'utils/wrapperComponents';
import { pluralizer } from 'utils/formattingUtils';
import { ArrayDataProvider } from 'buildingBlocks/TwoListMultiSelect/DataProvider';
import { hyperLinkText, gridStyle, pStyle } from '../style';
import { REPORT_METRICS, REPORT_DIMENSIONS, REPORT_INTERVAL } from '../constants';
import { AudienceReportMultiSelect } from './AudienceReportMultiSelect';

const ButtonWrapper = validatedFieldWrapper(Button);

const metricsDataProvider = new ArrayDataProvider(_.values(REPORT_METRICS));
const dimensionsDataProvider = new ArrayDataProvider(_.values(REPORT_DIMENSIONS));

const WIKI_LINK = `
  https://copilotsupport.freshdesk.com/support/solutions/articles/47001125170-general-strategy-analytics
`;

type Props = {
  canDownload: boolean,
};

const AudienceReportForm = ({ canDownload }: Props) => (
  <Grid padded style={gridStyle}>
    <Grid.Row>
      <p style={pStyle}>
        View performance metrics related to individual targeted and untargeted segments that saw an impression.
        <span>
          <a style={hyperLinkText} rel="noopener noreferrer" href={WIKI_LINK} target="_blank">READ MORE</a>
        </span>
      </p>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={4}>
        <Form.Field>
          <label htmlFor="reportMetrics">Select Metric Columns</label>
          <AudienceReportMultiSelect
            name="reportMetrics"
            dataProvider={metricsDataProvider}
            pluralize={pluralizer('Metric', 'Metrics')}
          />
        </Form.Field>
      </Grid.Column>
      <Grid.Column width={4}>
        <Form.Field>
          <label htmlFor="reportDimensions">Select Additional Columns</label>
          <AudienceReportMultiSelect
            name="reportDimensions"
            dataProvider={dimensionsDataProvider}
            pluralize={pluralizer('Column', 'Columns')}
          />
        </Form.Field>
      </Grid.Column>
      <Grid.Column width={3}>
        <Form.Field>
          <label htmlFor="reportInterval">Time Frame</label>
          <Field
            name="reportInterval"
            component={FormDropdown}
            options={_.values(REPORT_INTERVAL)}
            selection
            fluid
          />
        </Form.Field>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row centered>
      <Field<FieldProps<ButtonProps>>
        component={ButtonWrapper}
        name="download-report"
        disabled={!canDownload}
        content="Download Report"
        primary
        noInput
      />
    </Grid.Row>
  </Grid>
);

export default AudienceReportForm;
