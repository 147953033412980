import {
  STRATEGY_FLIGHT_RUNS_FETCH,
  STRATEGY_FLIGHT_RUNS_STARTING_ELEMENTS_PER_PAGE,
  STRATEGY_FLIGHT_RUNS_FETCH_STRATEGY_TYPE,
  STRATEGY_FLIGHT_RUNS_FETCH_STRATEGIES,
  STRATEGY_FLIGHT_RUNS_FETCH_FLIGHTS,
  STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHM_TYPE,
  STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHMS,
  STRATEGY_FLIGHT_RUNS_FETCH_MEMBERS,
  STRATEGY_FLIGHT_RUNS_FILTER,
} from './constants';

/**
 * Fetch a page of strategy flight runs.
 */
export function getStrategyFlightRuns(filter = {}, limit = STRATEGY_FLIGHT_RUNS_STARTING_ELEMENTS_PER_PAGE, skip = 0) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FETCH,
    payload: { limit, skip, filter },
  };
}

export function fetchStrategyType() {
  return {
    type: STRATEGY_FLIGHT_RUNS_FETCH_STRATEGY_TYPE.STARTED,
  };
}

export function fetchStrategyTypeCompleted(strategyTypes) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FETCH_STRATEGY_TYPE.COMPLETED,
    payload: strategyTypes,
  };
}

export function fetchStrategyTypeFailed(message) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FETCH_STRATEGY_TYPE.FAILED,
    payload: message,
  };
}

export function fetchAlgorithmType() {
  return {
    type: STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHM_TYPE.STARTED,
  };
}

export function fetchAlgorithmTypeCompleted(strategyTypes) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHM_TYPE.COMPLETED,
    payload: strategyTypes,
  };
}

export function fetchAlgorithmTypeFailed(message) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHM_TYPE.FAILED,
    payload: message,
  };
}

export function getStrategiesFailed(message) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FETCH_STRATEGIES.FAILED,
    payload: message,
  };
}

export function getStrategiesCompleted(selectedStrategy) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FETCH_STRATEGIES.COMPLETED,
    payload: selectedStrategy,
  };
}

export function getAlgorithmsFailed(message) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHMS.FAILED,
    payload: message,
  };
}

export function getAlgorithmsCompleted(selectedStrategy) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FETCH_ALGORITHMS.COMPLETED,
    payload: selectedStrategy,
  };
}

export function getFlightsFailed(message) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FETCH_FLIGHTS.FAILED,
    payload: message,
  };
}

export function getFlightsCompleted(selectedFlight) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FETCH_FLIGHTS.COMPLETED,
    payload: selectedFlight,
  };
}

export function getMembers() {
  return {
    type: STRATEGY_FLIGHT_RUNS_FETCH_MEMBERS.STARTED,
  };
}

export function getMembersCompleted(members) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FETCH_MEMBERS.COMPLETED,
    payload: members,
  };
}

export function getMembersFailed(message) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FETCH_MEMBERS.FAILED,
    payload: message,
  };
}

export function strategySearchUpdated(filter, input) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FILTER.INPUT.STRATEGIES.SEARCH_UPDATED,
    payload: { filter, input },
  };
}

export function flightSearchUpdated(filter, input) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FILTER.INPUT.FLIGHTS.SEARCH_UPDATED,
    payload: { filter, input },
  };
}

export function algorithmSearchUpdated(filter, input) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FILTER.INPUT.ALGORITHMS.SEARCH_UPDATED,
    payload: { filter, input },
  };
}

export function updateFilterAndFetchData(filter, limit = STRATEGY_FLIGHT_RUNS_STARTING_ELEMENTS_PER_PAGE, skip = 0) {
  return {
    type: STRATEGY_FLIGHT_RUNS_FILTER.UPDATED.ANY,
    payload: {
      filter,
      limit,
      skip,
    },
  };
}
