import _ from 'lodash';
import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { apiEnums, DSP, RESULTS_LIMIT } from 'constantsBase';
import { Member, DSPs, Country, BusinessUnit, Region, Microservices, FeeOption, BusinessModel, FeeType, Permission } from 'utils/copilotAPI';
import {
  SUCCESSFULLY_GET_SEATS_DATA,
  GET_SEATS_DATA,
  SUCCESSFULLY_SAVED_SEAT,
  SAVE_SEAT_FAILED,
  SAVE_SEAT,
  SUCCESSFULLY_GET_DSP_DATA,
  GET_DSP_DATA,
  GET_FEE_OPTION_DATA,
  GET_FEE_TYPE_DATA,
  GET_COUNTRY_DATA,
  GET_BUSINESS_MODEL_DATA,
  GET_BUSINESS_UNIT_DATA,
  GET_REGION_DATA,
  SUCCESSFULLY_SAVED_SEAT_SECRETS,
  FETCH_ACTION_FAILED,
  FETCH_BUSINESS_UNIT_ACTION_FAILED,
  FETCH_BUSINESS_MODEL_ACTION_FAILED,
  FETCH_COUNTRY_ACTION_FAILED,
  FETCH_FEE_OPTION_ACTION_FAILED,
  FETCH_FEE_TYPE_ACTION_FAILED,
  FETCH_REGION_ACTION_FAILED,
  START_SEAT_CREDENTIALS_TEST,
  SUCCESSFULLY_GET_COUNTRY_DATA,
  SUCCESSFULLY_GET_BUSINESS_UNIT_DATA,
  SUCCESSFULLY_GET_BUSINESS_MODEL_DATA,
  SUCCESSFULLY_GET_FEE_OPTION_DATA,
  SUCCESSFULLY_GET_FEE_TYPE_DATA,
  SUCCESSFULLY_GET_REGION_DATA,
  GET_PERMISSIONS_DATA,
  SUCCESSFULLY_GET_PERMISSIONS_DATA,
  FETCH_PERMISSIONS_ACTION_FAILED,
} from './constants/ActionTypes';
import { seatTestCompleted, seatTestFailed } from './actions';

export function* getSeats() {
  try {
    const response = yield call(
      Member.get,
      {
        limit: RESULTS_LIMIT,
        populate: [apiEnums.dsp, apiEnums.country, apiEnums.businessUnit, apiEnums.region,
          apiEnums.businessModel, apiEnums.feeOption, apiEnums.feeType, apiEnums.permissions],
      },
    );
    yield put({ type: SUCCESSFULLY_GET_SEATS_DATA, payload: response.data });
  } catch (exception) {
    yield put({ type: FETCH_ACTION_FAILED, payload: exception });
  }
}

export function* getDSPs() {
  try {
    const response = yield call(DSPs.get, { limit: RESULTS_LIMIT });
    yield put({ type: SUCCESSFULLY_GET_DSP_DATA, payload: response.data });
  } catch (exception) {
    yield put({ type: FETCH_ACTION_FAILED, payload: exception });
  }
}

export function* getBusinessUnits() {
  try {
    const response = yield call(BusinessUnit.get, { limit: RESULTS_LIMIT, active: 1 });
    yield put({ type: SUCCESSFULLY_GET_BUSINESS_UNIT_DATA, payload: response.data });
  } catch (exception) {
    yield put({ type: FETCH_BUSINESS_UNIT_ACTION_FAILED, payload: exception });
  }
}

export function* getCountries() {
  try {
    const response = yield call(Country.get, { limit: RESULTS_LIMIT, sort: 'name asc' });
    yield put({ type: SUCCESSFULLY_GET_COUNTRY_DATA, payload: response.data });
  } catch (exception) {
    yield put({ type: FETCH_COUNTRY_ACTION_FAILED, payload: exception });
  }
}

export function* getRegions() {
  try {
    const response = yield call(Region.get, { limit: RESULTS_LIMIT });
    yield put({ type: SUCCESSFULLY_GET_REGION_DATA, payload: response.data });
  } catch (exception) {
    yield put({ type: FETCH_REGION_ACTION_FAILED, payload: exception });
  }
}

export function* saveSeat({ payload: { apiPassword, jsonObject, ...rest } }: AnyAction) {
  try {
    const memberData = { ...rest };
    const date = (new Date()).toUTCString();
    const member = {
      ...memberData,
      ...(!memberData.id && { createdAt: date }),
      updatedAt: date,
      dsp: _.get(memberData, 'dsp.id'),
      country: _.get(memberData, 'country.id'),
      region: _.get(memberData, 'region.id'),
      businessModel: _.get(memberData, 'businessModel.id'),
      businessUnit: _.get(memberData, 'businessUnit.id'),
      feeOption: _.get(memberData, 'feeOption.id'),
      permissions: _.map(memberData.permissions, 'id'),
    };

    const response = yield call(Member.save, member);
    // Construct the payload with jsonObject conditionally included
    const payload = {
      seat: response.data,
      apiPassword,
      ...(member.dsp === DSP.DBM.id ? { jsonObject } : {}),
    };
    yield put({ type: SUCCESSFULLY_SAVED_SEAT, payload });
  } catch (exception) {
    yield put({
      type: SAVE_SEAT_FAILED,
      payload: {
        title: 'Saving of the seat failed.',
        message: exception.response.data.message,
      },
    });
  }
}

export function* saveSeatSecrets(action) {
  try {
    const { seat, apiPassword, jsonObject } = action.payload;
    if (!seat.id) {
      yield put({
        type: SAVE_SEAT_FAILED,
        payload: {
          title: 'Update password failed',
          message: 'This seat has no id.',
        },
      });
      return;
    }
    const params = _.pickBy({ apiPassword, jsonObject }, _.identity);
    if (!_.isEmpty(params)) {
      yield call(Member.storeSeatSecrets, seat.id, params);
    }
    yield put({ type: SUCCESSFULLY_SAVED_SEAT_SECRETS });
  } catch (exception) {
    yield put({
      type: SAVE_SEAT_FAILED,
      payload: {
        title: 'Update Password Failed.',
        message: exception.message,
      },
    });
  }
}

export function* checkSeatCredentials(action) {
  const seat = { ...action.payload };
  try {
    const testResult = yield call(
      Microservices.runService,
      {
        member_id: seat.id },
      'validate_member',
    );
    const { errors } = testResult.data;
    if (_.isEmpty(errors)) {
      yield put(seatTestCompleted(seat.id));
    } else {
      yield put(seatTestFailed(seat.id, errors));
    }
  } catch (e) {
    yield put(seatTestFailed(seat.id, [e.message]));
  }
}

export function* getBusinessModels() {
  try {
    const response = yield call(BusinessModel.get, { limit: RESULTS_LIMIT });
    yield put({ type: SUCCESSFULLY_GET_BUSINESS_MODEL_DATA, payload: response.data });
  } catch (exception) {
    yield put({ type: FETCH_BUSINESS_MODEL_ACTION_FAILED, payload: exception });
  }
}

export function* getFeeOptions() {
  try {
    const response = yield call(FeeOption.get, { limit: RESULTS_LIMIT });
    yield put({ type: SUCCESSFULLY_GET_FEE_OPTION_DATA, payload: response.data });
  } catch (exception) {
    yield put({ type: FETCH_FEE_OPTION_ACTION_FAILED, payload: exception });
  }
}

export function* getFeeTypes() {
  try {
    const response = yield call(FeeType.get, { limit: RESULTS_LIMIT });
    yield put({ type: SUCCESSFULLY_GET_FEE_TYPE_DATA, payload: response.data });
  } catch (exception) {
    yield put({ type: FETCH_FEE_TYPE_ACTION_FAILED, payload: exception });
  }
}

export function* getPermissions() {
  try {
    const response = yield call(Permission.get, { limit: RESULTS_LIMIT });
    yield put({ type: SUCCESSFULLY_GET_PERMISSIONS_DATA, payload: response.data });
  } catch (exception) {
    yield put({ type: FETCH_PERMISSIONS_ACTION_FAILED, payload: exception });
  }
}

export const sagas = [
  takeEvery(GET_SEATS_DATA, getSeats),
  takeEvery(SUCCESSFULLY_SAVED_SEAT_SECRETS, getSeats),
  takeEvery(GET_DSP_DATA, getDSPs),
  takeEvery(GET_BUSINESS_MODEL_DATA, getBusinessModels),
  takeEvery(GET_BUSINESS_UNIT_DATA, getBusinessUnits),
  takeEvery(GET_COUNTRY_DATA, getCountries),
  takeEvery(GET_REGION_DATA, getRegions),
  takeEvery(GET_FEE_OPTION_DATA, getFeeOptions),
  takeEvery(GET_FEE_TYPE_DATA, getFeeTypes),
  takeEvery(SUCCESSFULLY_SAVED_SEAT, saveSeatSecrets),
  takeEvery(GET_PERMISSIONS_DATA, getPermissions),
  takeLatest(SAVE_SEAT, saveSeat),
  takeLatest(START_SEAT_CREDENTIALS_TEST, checkSeatCredentials),

];

export function* seatsSagas() {
  yield all([
    ...sagas,
  ]);
}
