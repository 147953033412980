import _ from 'lodash';

export const getErrorMsg = (err: Error) => {
  const error: string = _.get(err, 'response.data.error');
  // if there's a response.data.error we know there's a custom Validation msg
  if (error) {
    const regEx = /ValueError:\s(.+)/;
    const match = error.match(regEx);
    return match[1];
  }
  return err.message;
};
