import _ from 'lodash';
import React from 'react';
import { Table } from 'buildingBlocks';
import { displayMetrics, Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import DisplayFeatures from './Features';

import {
  BUCKET_HEADERS,
  MAPPING_CSV_KEY,
  Bucket,
} from '../constants';
import {
  makeBorderStyle, bucketSelectedStyle,
} from '../style';
import { TableRow } from './HeliosTable';

const borderRightStyle = makeBorderStyle('Right');
const widerBorderBottomStyle = makeBorderStyle('Bottom', 2);

type Props = {
  name: string,
  metric: Metric,
  metricsIndex: { [key: string]: number },
  leafRowSpan: number,
  data: TableRow,
  shouldRowSpan: boolean,
  index?: number,
  bucketSelected: Bucket,
};

const LeafDataRow = ({
  name,
  leafRowSpan,
  metric,
  metricsIndex,
  data,
  shouldRowSpan,
  index,
  bucketSelected,
}: Props) => {
  // Select the most recent one to display leaf infos
  const metadata = data[_.first(_.keys(data))];
  const groupName = _.get(metadata, `[${_.get(metricsIndex, MAPPING_CSV_KEY.SEGMENT_GROUP)}]`);
  const displayedGroupName = _.isNil(groupName) || groupName === '' ? 'N/A' : groupName;
  const lastRowStyle = index === (leafRowSpan - 1) ? widerBorderBottomStyle : {};

  return (
    <Table.Row>
      {
        shouldRowSpan
        && (
        <Table.Cell
          rowSpan={leafRowSpan}
          style={{
            ...borderRightStyle,
            ...(index !== undefined ? widerBorderBottomStyle : {}),
          }}
          width={5}
        >
          <div>
            &nbsp;
            <span style={{ float: 'left' }}>Name: {name}</span>
            <span style={{ float: 'right' }}>Segment Group Name: {displayedGroupName}</span>
          </div>
          <DisplayFeatures
            data={metadata}
            metricsIndex={metricsIndex}
            feature={MAPPING_CSV_KEY.FEATURES.INCLUDED}
            positive
          />
          <DisplayFeatures
            data={metadata}
            metricsIndex={metricsIndex}
            feature={MAPPING_CSV_KEY.FEATURES.EXCLUDED}
          />
        </Table.Cell>
        )
      }
      <Table.Cell style={lastRowStyle}>{metric.text}</Table.Cell>
      {
        _.map(BUCKET_HEADERS, (bucket) => {
          const bucketData = _.get(data, bucket.value, {});
          const value = _.get(bucketData, _.get(metricsIndex, metric.value)) as number;
          let style;
          if (bucket.value === bucketSelected) {
            style = { ...lastRowStyle, ...bucketSelectedStyle };
          } else {
            style = lastRowStyle;
          }

          return (
            <Table.Cell
              key={`${name}-${bucket.value}-${metric.text}`}
              style={style}
            >
              {isFinite(value) ? displayMetrics(metric, value) : 'N/A'}
            </Table.Cell>
          );
        })
      }
    </Table.Row>
  );
};

export default LeafDataRow;
