import _ from 'lodash';
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import parse from 'html-react-parser';
import { Container, Image, Icon, Header, Loader } from 'buildingBlocks';
import { dataUploader } from '../style';
import {
  CSV_FILE_IMG_PATH, FileStates,
  GREEN_CHECK_IMG_PATH, RED_EXCLAMATION_IMG_PATH,
  SUBHEADER_TEXT, THIN_X_IMG_PATH, SUCCESS_DESC,
} from '../constants';

const {
  fileContainer, fileIcon, fileNameText, trashIcon,
  subheader, subheaderDescription, subHeaderContainer, successHeader,
  green, successDescription, loader, successHeaderContainer,
} = dataUploader;

type SuccessBannerProps = {
  onClose: Dispatch<SetStateAction<boolean>>
};

const SuccessBanner = ({ onClose }: SuccessBannerProps) => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div style={successHeaderContainer}>
      <Image alt="green check" src={GREEN_CHECK_IMG_PATH} style={{ marginTop: '2px' }} />
      <Header
        style={successHeader}
        content="File Successfully Uploaded."
        subheader={<div style={successDescription}>{SUCCESS_DESC}</div>}
      />
    </div>
    <Image alt="thin x" src={THIN_X_IMG_PATH} onClick={onClose} style={{ cursor: 'pointer' }} />
  </div>
);

type SubHeaderProps = {
  fileState: FileStates
  error: string | null
};

const SubHeader = ({ fileState, error }: SubHeaderProps) => {
  const subheaderObj = SUBHEADER_TEXT[fileState];
  return (
    <>
      {_.isEqual(fileState, FileStates.uploading)
        ? (<div style={loader}>{subheaderObj.subheader} <Loader size="mini" active inline /></div>)
        : (
          <div style={subHeaderContainer}>
            <div style={subheader}>
              {subheaderObj.subheader}
              <Image src={RED_EXCLAMATION_IMG_PATH} alt="info circle" />
            </div>
            <div style={subheaderDescription}>{error ?? parse(subheaderObj.description)}</div>
          </div>
        )}
    </>
  );
};

type FilerBannerProps = {
  fileName: string
  onDelete: () => void
  fileState: FileStates
  error: string | null
};

const FileBanner = ({ fileName, onDelete, fileState, error }: FilerBannerProps) => {
  const [showBanner, setShowBanner] = useState<boolean>(!!fileName);
  const successState = _.isEqual(fileState, FileStates.success);
  const nonSuccessState = (fileState in SUBHEADER_TEXT);
  const displayFileName = nonSuccessState || _.isEqual(FileStates.initial, fileState);

  useEffect(() => {
    setShowBanner(!!fileName);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileState]);

  const closeBanner = () => {
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <Container style={{ ...fileContainer, ...(successState && green) }}>
          {displayFileName && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Image alt="csv file" src={CSV_FILE_IMG_PATH} style={fileIcon} />
              <Header style={fileNameText} content={fileName} />
              <Icon
                name="trash alternate"
                style={trashIcon}
                onClick={onDelete}
                disabled={_.isEqual(FileStates.uploading, fileState)}
              />
            </div>
          )}
          {nonSuccessState && (<SubHeader fileState={fileState} error={error} />)}
          {successState && (<SuccessBanner onClose={closeBanner} />)}
        </Container>
      )}
    </>
  );
};

export default FileBanner;
