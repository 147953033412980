const borderStyle = '1px solid rgba(34, 36, 38, 0.15)';

export const containerStyle = {
  padding: {
    padding: '1.25em 1em',
  },
  header: {
    borderBottom: borderStyle,
  },
  footer: {
    borderTop: borderStyle,
    display: 'flex',
    alignItems: 'center',
  },
};

export const newSeatButton = {
  float: 'right',
  background: '#1BA6EF',
  color: 'white',
};

export const memberButtons = {
  padding: '9px',
};
