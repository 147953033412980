import { SUCCESSFULLY_GET_BUSINESS_MODEL_DATA, FETCH_BUSINESS_MODEL_ACTION_FAILED } from '../constants/ActionTypes';

const INITIAL_STATE = [];

const businessModelReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUCCESSFULLY_GET_BUSINESS_MODEL_DATA:
      return action.payload;
    case FETCH_BUSINESS_MODEL_ACTION_FAILED:
      return action.payload;
    default:
      return state;
  }
};

export default businessModelReducer;
