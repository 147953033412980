import React, { Component } from 'react';
import { Button, Confirm, Grid } from 'buildingBlocks';
import { RouterProps } from 'utils/withRouter';

type Props = RouterProps & {
  deleteRole: Function,
  role: {
    id: number,
    name: string,
  }
  qAReadOnly: boolean,
};

type State = {
  open: boolean,
};

export class DeleteRoleConfirm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  toggleOpen = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  delete = () => {
    this.toggleOpen();
    this.props.deleteRole(this.props.role.id, this.props.router);
  };

  render() {
    const { role: { id, name }, qAReadOnly } = this.props;
    return (
      <Grid.Column floated="left">
        <Button
          negative
          type="button"
          onClick={this.toggleOpen}
          content="Delete"
          icon="trash"
          disabled={!id || qAReadOnly}
        />
        <Confirm
          content={`All users who currently have this role will lose the permissions associated with it.
          Are you sure you want to delete role "${name}" (${id})?`}
          confirmButton="Delete"
          onCancel={this.toggleOpen}
          onConfirm={this.delete}
          open={this.state.open}
        />
      </Grid.Column>
    );
  }
}

export default DeleteRoleConfirm;
