import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WppCheckbox } from 'buildingBlocks';
import { selectAll, selectNone, selectOneStrategy } from 'containers/StrategiesList/actions';
import { StrategiesListFilterType } from 'containers/StrategiesList/constants';
import { strategyTableColumns } from 'containers/StrategiesList/style';
import { orderStratsByFilter } from 'containers/StrategiesList/utils';
import { EnhancedStrategy } from 'utils/types';
import { GlobalState } from 'reducers';

const { selectCheckbox } = strategyTableColumns;

type HeaderCheckBoxProps = {
  rowData: Array<EnhancedStrategy>
};

export const HeaderCheckBox = ({ rowData }: HeaderCheckBoxProps) => {
  const strategiesListState = useSelector<GlobalState>((state) => state.strategiesList) as { selectedStrategies: Array<EnhancedStrategy>, filter: StrategiesListFilterType };
  const { filter, selectedStrategies } = strategiesListState;
  const dispatch = useDispatch();
  const sortedRowData = orderStratsByFilter(rowData, filter);
  const checked = _.isEqual(sortedRowData, selectedStrategies);
  const disabled = _.isEmpty(rowData);
  return (
    <WppCheckbox
      disabled={disabled}
      checked={disabled ? false : checked}
      onWppChange={() => dispatch(checked ? selectNone() : selectAll())}
    />
  );
};

type RowCheckBoxProps = {
  strategy: EnhancedStrategy
};

export const RowCheckBox = ({ strategy }: RowCheckBoxProps) => {
  const selectedStrategies = useSelector<GlobalState>((state) => state.strategiesList.selectedStrategies) as Array<EnhancedStrategy>;
  const checked = _.some(selectedStrategies, ['id', strategy.id]);
  const dispatch = useDispatch();
  return (
    <div style={selectCheckbox}>
      <WppCheckbox
        checked={checked}
        onWppChange={() => dispatch(selectOneStrategy(strategy))}
        className={checked ? 'visible-checkbox' : 'wpp-checkbox'}
      />
    </div>
  );
};
