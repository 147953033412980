import { Strategy } from 'utils/types';
import { APNSegment } from 'containers/StrategyWizard/ConfigurationByStrategyType/segmentUtils';
import { Router } from 'utils/withRouter';
import {
  FETCH_STRATEGY,
  FETCH_STRATEGY_FAILED,
  FETCH_STRATEGY_COMPLETED,
  RESET_PROPS,
  FETCH_SEGMENTS,
  FETCH_SEGMENTS_COMPLETED,
  FETCH_PIXELS,
  FETCH_PIXELS_COMPLETED,
  TOGGLE_DELETE_MODAL,
  DELETE_STRATEGY,
  DELETE_STRATEGY_COMPLETED,
  DELETE_STRATEGY_FAILED,
  DATE_RANGE_CHANGED,
  UPDATE_CLIENT_TEST_FOR_STRATEGY_COMPLETED,
  UPDATE_CLIENT_TEST_FOR_STRATEGY_FAILED,
  UPDATE_CLIENT_TEST_FOR_STRATEGY,
} from './constants/actionsConstants';
import { ApiData } from './types';

export const fetchStrategy = (id: string) => ({
  type: FETCH_STRATEGY,
  payload: {
    id,
  },
});

export const fetchStrategyCompleted = (apiData: ApiData) => ({
  type: FETCH_STRATEGY_COMPLETED,
  payload: apiData,
});

export const fetchStrategyFailed = (error: {}, status?: number) => ({
  type: FETCH_STRATEGY_FAILED,
  payload: { error, status },
});

export const setOptions = (type: string, value: any) => ({
  type,
  payload: value,
});

export const resetProps = () => ({
  type: RESET_PROPS,
});

export const fetchSegments = (memberId: number, advertiserId: string) => ({
  type: FETCH_SEGMENTS,
  payload: { memberId, advertiserId },
});

export const fetchSegmentsCompleted = (segmentData: APNSegment) => ({
  type: FETCH_SEGMENTS_COMPLETED,
  payload: segmentData,
});

export const fetchPixels = (memberId: number, advertiserId: string) => ({
  type: FETCH_PIXELS,
  payload: { memberId, advertiserId },
});

export const fetchPixelsCompleted = (pixelData: Object) => ({
  type: FETCH_PIXELS_COMPLETED,
  payload: pixelData,
});

export const toggleDeleteModal = () => ({
  type: TOGGLE_DELETE_MODAL,
});

export function deleteStrategy(strategy: Strategy, router: Router) {
  return {
    type: DELETE_STRATEGY,
    payload: { strategy, router },
  };
}

export const deleteStrategyCompleted = (strategy: Strategy) => ({
  type: DELETE_STRATEGY_COMPLETED,
  payload: strategy,
});

export const deleteStrategyFailed = () => ({
  type: DELETE_STRATEGY_FAILED,
});

export const handleDateRangeChange = (dateRange: number) => ({
  type: DATE_RANGE_CHANGED,
  payload: { dateRange },
});

export function updateClientTestForStrategy(strategyId: number, clientTest: boolean, isStrategiesList: boolean, showToast: Function) {
  return {
    type: UPDATE_CLIENT_TEST_FOR_STRATEGY,
    payload: { strategyId, clientTest, isStrategiesList, showToast },
  };
}

export const updateClientTestForStrategyCompleted = (clientTest: boolean) => ({
  type: UPDATE_CLIENT_TEST_FOR_STRATEGY_COMPLETED,
  payload: clientTest,
});

export const updateClientTestForStrategyFailed = (error) => ({
  type: UPDATE_CLIENT_TEST_FOR_STRATEGY_FAILED,
  payload: { error },
});
