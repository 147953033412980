import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WppButton, WppModal, WppSpinner } from 'buildingBlocks';
import { truncateText } from 'charts/InsightsViz/utils';
import { deleteStrategy, selectNone } from 'containers/StrategiesList/actions';
import { selectedStrategiesPanel } from 'containers/StrategiesList/style';
import { GlobalState } from 'reducers';
import { pluralizer } from 'utils/formattingUtils';
import { EnhancedStrategy } from 'utils/types';

const { modalBody, strategyModalList, modalButtonContainer } = selectedStrategiesPanel;

type BulkDeleteModalProps = {
  deleteModalOpen: boolean
  setDeleteModalOpen: (x: boolean) => void
};

const BulkDeleteModal = ({ deleteModalOpen, setDeleteModalOpen }: BulkDeleteModalProps) => {
  const dispatch = useDispatch();
  const [pendingClose, setPendingClose] = useState<boolean>(false);
  const { deleting, selectedStrategies } = useSelector<GlobalState>((state) => state.strategiesList) as { deleting: boolean, selectedStrategies: Array<EnhancedStrategy> };
  const selectedStrategiesCount = _.size(selectedStrategies);
  const strategyText = pluralizer('Strategy', 'Strategies')(selectedStrategiesCount);
  const lowerStrategyText = _.lowerCase(strategyText);
  const warningText = `Are you sure you want to delete ${selectedStrategiesCount} ${lowerStrategyText}? This action cannot be undone.`;
  const handleDelete = () => {
    dispatch(deleteStrategy(selectedStrategies));
    setPendingClose(true);
  };

  // close modal after delete strategy saga completes
  useEffect(() => {
    if (pendingClose && !deleting) {
      setPendingClose(false);
      dispatch(selectNone());
      setDeleteModalOpen(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleting, pendingClose]);

  return (
    <WppModal open={deleteModalOpen}>
      <h3 slot="header">Deleting {strategyText}</h3>
      <div slot="body" className="bulkModalBody" style={modalBody}>
        <p>{warningText}</p>
        <p>The following {lowerStrategyText} will be deleted:</p>
        <ul style={strategyModalList}>
          {_.map(selectedStrategies, (s) => <li key={s.id}>{truncateText(s.name, 90)}</li>)}
        </ul>
      </div>
      <div slot="actions" style={modalButtonContainer}>
        <WppButton variant="secondary" size="s" disabled={deleting} onClick={() => setDeleteModalOpen(false)}>Close</WppButton>
        <WppButton id="bulk-delete-btn" variant="destructive" size="s" onClick={handleDelete} disabled={deleting}>
          {deleting ? <WppSpinner color="white" /> : `Delete ${strategyText}`}
        </WppButton>
      </div>
    </WppModal>
  );
};

export default BulkDeleteModal;
