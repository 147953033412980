import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const tableStyle = {
  header: {
    padding: '1rem 1.5rem',
  },
  content: {
    padding: '1rem 1.5rem',
  },
  activeRow: {
    backgroundColor: '#3B6E8F',
    color: 'white',
  },
};

export const strategyContainer = {
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  flex: '1',
  minWidth: '1248px',
};

export const starAndHamburgerMenuContainer = {
  display: 'flex',
  flexDirection: 'row',
  gap: SPACING[24],
  alignContent: 'center',
} as CSSProperties;

export const menuStyle = {
  container: {
    padding: '1rem 0 2rem 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropdown: {
    container: {
      margin: '0.5rem',
    },
  },
};

export const strategyMetadataStyles = {
  container: {
    display: 'flex',
    padding: '1.5rem 1.5rem',
  },
  title: {
    container: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    } as CSSProperties,
    content: {
      lineHeight: '1.8rem',
      fontWeight: '600',
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      fontSize: '1.25rem',
      paddingRight: '1.25rem',
      marginLeft: '0.25rem',
    } as CSSProperties,
    subContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    } as CSSProperties,
  },
  additionalInformation: {
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    rightContent: {
      margin: '0.5rem 0.2rem',
      fontWeight: '500',
      textAlign: 'right',
    } as CSSProperties,
    button: {
      marginRight: 0,
      padding: '0.5rem',
    },
    pipe: {
      margin: '0.25rem 0.5rem',
    },
    details: {
      marginLeft: '0.25rem',
      flexWrap: 'wrap',
    } as CSSProperties,
  },
  starStrategyIcon: {
    marginTop: SPACING[4],
    alignItems: 'start',
  },
  testLabel: {
    height: SPACING[20],
  },
};

export const statBoxStyle = {
  container: {
    display: 'flex',
    margin: '1rem 0',
    borderBottom: '1px solid #e4e4e4',
  },
  disabled: {
    opacity: '0.2',
    display: 'flex',
    margin: '1rem 0',
    borderBottom: '1px solid #e4e4e4',
  },
  title: {
    textTransform: 'initial',
    opacity: 0.5,
    fontWeight: 'normal',
  } as CSSProperties,
  text: {
    fontWeight: 'normal',
  } as CSSProperties,
  textTitleCase: {
    textTransform: 'none',
  } as CSSProperties,
  content: {
    border: '0',
    borderRight: '1px solid #e4e4e4',
    marginBottom: '0',
    fontWeight: 'normal',
  },
  contentLast: {
    marginRight: 0,
    borderRight: 'initial',
    fontWeight: 'normal',
  },
  popup: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
  } as CSSProperties,
};

export const inlineBlock = {
  display: 'inline-block',
};

export const dspObjectUpdateMessage = {
  textAlign: 'center',
} as CSSProperties;
