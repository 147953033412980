import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { WppActionButton, WppDivider, WppTypography, WppEmptyNothingFound } from 'buildingBlocks';
import DspIcon from 'components/DspIcon/DspIcon';
import StratTypeBadge from 'components/StratTypeBadge';
import {
  noResultsWrapper, noResultsContainer, noResultsList, noResultsHeading, strategyFields,
} from '../style';

const {
  brandAdvMemberContainer,
  brandNameContainer,
  strategyTypeDSPContainer,
  advertiserContainer,
  captionContainer,
  divider,
  searchDivider,
} = strategyFields;

type Props = {
  name: string
  dsp: number
  stratType: string
  lastModified: string
  stratId: string
  id: number
  noResultsFound: boolean
  handleClearSearchInput: React.MouseEventHandler<HTMLWppActionButtonElement>
  handleClick: () => void
  searchValue: string
  brand?: string
  member?: string
  advertiser?: string
};

type StratInfoProps = {
  name: string
  dspId: number
  lastModifiedTime: string
  type: string
  stratId: string
  id: number
  brand?: string
  member?: string
  advertiser?: string
  handleClick: () => void
};

const NoResultsFound = (props: { handleClearSearchInput: React.MouseEventHandler<HTMLWppActionButtonElement> }) => {
  const searchableItems: Array<string> = ['Copilot Strategy', 'Amazon Order', 'DV360 Insertion Order',
    'The Trade Desk Ad Group', 'The Trade Desk Campaign', 'Xandr Insertion Order', 'Xandr Line Item'];

  return (
    <div style={noResultsWrapper}>
      <WppEmptyNothingFound />
      <div style={noResultsContainer}>
        <WppTypography tag="p" type="m-strong" style={noResultsHeading}>Sorry, no results found.</WppTypography>
        <WppTypography tag="p" type="s-body">You may have mistyped, or Search does not support the object you are looking for. Search supports the following object types, entered by ID or name:
          <ul style={noResultsList}>{_.map(searchableItems, (item: string) => (<li key={item}>{item}</li>))}</ul>
        </WppTypography>
        <WppActionButton onClick={props.handleClearSearchInput}>Search Again</WppActionButton>
      </div>
    </div>
  );
};

export function StratInfo({
  name,
  dspId,
  lastModifiedTime,
  type,
  member,
  advertiser,
  brand,
  stratId,
  id,
  handleClick,
}: StratInfoProps) {
  return (
    <>
      <Link
        key={id}
        className="searchItem"
        to={`/strategies/${stratId}`}
        onClick={() => handleClick()}
      >
        <WppTypography tag="span" className="strategy-name" type="s-midi" slot="label">{name}</WppTypography>
        <div slot="caption" style={captionContainer}>
          <div style={brandAdvMemberContainer}>
            {brand && (
              <WppTypography tag="span" style={brandNameContainer} type="xs-body">
                {brand}
              </WppTypography>
            )}
            {advertiser && (
              <WppTypography tag="span" style={advertiserContainer} type="xs-body">
                {advertiser}
              </WppTypography>
            )}
            {member && (
              <WppTypography tag="span" type="xs-body">
                {member}
              </WppTypography>
            )}
            <WppDivider style={divider} alignment="vertical" />
            <WppTypography tag="span" type="xs-body">
              Last Modified:&nbsp;
              {lastModifiedTime}
            </WppTypography>
          </div>
          <div style={strategyTypeDSPContainer}>
            <StratTypeBadge stratType={type} />
            <DspIcon dspId={dspId} />
          </div>
        </div>
      </Link>
      <WppDivider style={searchDivider} />
    </>
  );
}

const SearchDropDownItem = ({
  name, dsp, stratType, lastModified, stratId, id, noResultsFound, brand, member, advertiser,
  handleClearSearchInput, handleClick, searchValue,
}: Props) => {
  if (_.isEmpty(searchValue)) return <></>;

  if (noResultsFound) {
    return <NoResultsFound handleClearSearchInput={handleClearSearchInput} />;
  }

  return (
    <StratInfo
      name={name}
      dspId={dsp}
      lastModifiedTime={lastModified}
      type={stratType}
      member={member}
      advertiser={advertiser}
      brand={brand}
      stratId={stratId}
      id={id}
      handleClick={handleClick}
    />
  );
};

export default SearchDropDownItem;
