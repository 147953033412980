export const FETCH_ROLES = 'ROLES:FETCH_ROLES';
export const FETCH_ROLE_AND_PERMISSIONS = 'ROLES:FETCH_ROLE_AND_PERMISSIONS';
export const FETCH_ROLES_COMPLETED = 'ROLES:FETCH_ROLES_COMPLETED';
export const FETCH_ROLES_FAILED = 'ROLES:FETCH_ROLES_FAILED';
export const FETCH_ROLE_COMPLETED = 'ROLES:FETCH_ROLE_COMPLETED';
export const SAVE_ROLE = 'ROLES:SAVE_ROLE';
export const SAVE_ROLE_COMPLETED = 'ROLES:SAVE_ROLE_COMPLETED';
export const SAVE_ROLE_FAILED = 'ROLES:SAVE_ROLE_FAILED';
export const DELETE_ROLE = 'ROLES:DELETE_ROLE';
export const DELETE_ROLE_FAILED = 'ROLES:DELETE_ROLE_FAILED';
export const DELETE_ROLE_COMPLETED = 'ROLES:DELETE_ROLE_COMPLETED';
export const FETCH_PERMISSIONS_COMPLETED = 'ROLES:FETCH_PERMISSIONS_COMPLETED';
export const FETCH_PERMISSIONS_FAILED = 'ROLES:FETCH_PERMISSIONS_FAILED';
export const RESET_ALL = 'ROLE:RESET_ALL';
export const RESET_PERMISSIONS = 'ROLE:RESET_PERMISSIONS';

export const TYPE_GLOBAL = 0;
export const TYPE_MEMBER = 1;

export const rolePermissions = [
  { text: 'Manage Permissions', value: 'Manage Permissions' },
  { text: 'Manage Users', value: 'Manage Users' },
  { text: 'Administer Users', value: 'Administer Users' },
  { text: 'Manage Roles', value: 'Manage Roles' },
  { text: 'Viewability Calculator', value: 'Viewability Calculator' },
  { text: 'Algo V2', value: 'Algo V2' },
  { text: 'Edit Strategies', value: 'Edit Strategies' },
];

export const roleListingTableColumns = [
  { text: 'ID', value: 'ID' },
  { text: 'Role', value: 'Role' },
  { text: 'Description', value: 'Description' },
  { text: 'Type', value: 'Role Type' },
];

export const errorMsgMap = {
  FETCH_ROLES_FAILED: 'Failed to fetch roles',
  FETCH_ROLE_FAILED: 'Failed to fetch role',
  FETCH_PERMISSIONS_FAILED: 'Failed to fetch Permissions',
  ROLE_ALREADY_EXIST: 'Role Name already exists',
};

export const roleTypes = [
  { text: 'Global', value: 0 },
  { text: 'Member', value: 1 },
];
