import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { ALGORITHM_TYPE } from 'constantsBase';
import { WppTableBody, WppTableBodyRow, WppTableBodyCell, WppTypography, WppTag } from 'buildingBlocks';
import { dateTimeFormatter } from 'utils/dateTime';
import { TimeZoneValue } from 'containers/Jobs/types';
import { AlgorithmType } from 'utils/types';
import { cellPadding, flightWordBreakStyle } from './style';
import { ExtendedRootState } from './types';
import { FLIGHT_RUNS_STATUS_COLOR_CODE } from './constants';

type StrategyFlightRunsTableBodyProps = {
  strategyFlightRuns: ExtendedRootState['strategyFlightRuns']['strategyFlightRuns'];
  selectedTimezone: TimeZoneValue
  userTimeZone: TimeZoneValue
  formatAlgoTypeName: (algoType: AlgorithmType) => string
  getTriggerType: Function
};

const StrategyFlightRunsTableBody: React.FC<StrategyFlightRunsTableBodyProps> = ({ strategyFlightRuns, selectedTimezone, userTimeZone, formatAlgoTypeName, getTriggerType }) => {
  const toLink = (sfr) => `dashboard?strategy=${sfr.strategy}&flight=${sfr.flight}&run=${sfr.id}`;

  const typeLabel = (run) => run?.strategyType?.displayName;
  const algoTypeLabel = (run) => run.algorithmType && formatAlgoTypeName(run.algorithmType);

  const memberLabel = (member, algorithmTypeId) => (algorithmTypeId === ALGORITHM_TYPE.crossPlatformOptimization.id
    ? 'Multiple'
    : `${member.displayName} (${member.externalId})`);

  return (
    <WppTableBody>
      {_.map(strategyFlightRuns, (strategyFlightRun) => (
        <WppTableBodyRow key={strategyFlightRun.id}>
          <WppTableBodyCell>
            <WppTypography type="s-body">
              <Link to={toLink(strategyFlightRun)}>
                {strategyFlightRun.id}
              </Link>
            </WppTypography>
          </WppTableBodyCell>
          <WppTableBodyCell style={cellPadding}>
            <WppTypography type="s-body">
              {typeLabel(strategyFlightRun)}
            </WppTypography>
          </WppTableBodyCell>
          <WppTableBodyCell style={cellPadding}>
            {strategyFlightRun.algorithm && (
              <WppTypography type="s-body">
                {algoTypeLabel(strategyFlightRun)}
              </WppTypography>
            )}
          </WppTableBodyCell>
          <WppTableBodyCell>
            <WppTypography type="s-body">
              <Link to={`/strategies/${strategyFlightRun.strategy}`} target="_blank">
                {strategyFlightRun.strategy}
              </Link>
            </WppTypography>
          </WppTableBodyCell>
          <WppTableBodyCell>
            <WppTypography type="s-body">{strategyFlightRun.algorithm}</WppTypography>
          </WppTableBodyCell>
          <WppTableBodyCell>
            <WppTypography type="s-body">{strategyFlightRun.flight}</WppTypography>
          </WppTableBodyCell>
          <WppTableBodyCell>
            <WppTypography type="s-body" style={flightWordBreakStyle}>{strategyFlightRun.extFlightId}</WppTypography>
          </WppTableBodyCell>
          <WppTableBodyCell>
            <WppTypography type="s-body" style={flightWordBreakStyle}>
              {memberLabel(strategyFlightRun.member, strategyFlightRun.algorithmType.id)}
            </WppTypography>
          </WppTableBodyCell>
          <WppTableBodyCell>
            <WppTag label={strategyFlightRun.status} categoricalColorIndex={_.get(FLIGHT_RUNS_STATUS_COLOR_CODE, strategyFlightRun.status)} />
          </WppTableBodyCell>
          <WppTableBodyCell>
            <WppTypography type="s-body">{getTriggerType(strategyFlightRun)}</WppTypography>
          </WppTableBodyCell>
          <WppTableBodyCell>
            <WppTypography type="s-body">
              {dateTimeFormatter.timeZoneBasedDateTime(selectedTimezone, strategyFlightRun.updatedAt, userTimeZone)}
            </WppTypography>
          </WppTableBodyCell>
        </WppTableBodyRow>
      ))}
    </WppTableBody>
  );
};

export default StrategyFlightRunsTableBody;
