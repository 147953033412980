import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Table } from 'buildingBlocks';
import { Permission } from 'utils/featureFlags';
import { FetchState } from 'utils/types';
import DataLoading from 'components/DataLoading';
import ErrorBoundary from 'components/ErrorBoundary';
import MaterialButton from 'components/MaterialButton';
import PermissionPageTemplate from 'components/PageTemplate/PermissionPageTemplate';
import {
  getSeatsData,
  getDSPs,
  getRegions,
  addNewSeat,
  editSeat,
  checkSeatCredentials,
  getCountries,
  getBusinessUnits,
  getBusinessModels,
  getFeeOptions,
  getFeeTypes,
  getPermissions,
} from '../actions/index';
import SeatDetailsContainer, { Seat, SeatStatusStates } from './SeatDetails';
import SeatFormContainer from './SeatForm';

const PAGE_NAME = 'Members';
const REQUIRED_PERMISSIONS = [Permission.manageMembers];
const headers = [
  'ID',
  'External ID',
  'Prefix',
  'Name',
  'DSP',
  'Display Name',
  'Country',
  'Business Unit',
  'Region',
  'API User',
  'Enabled',
  'Created',
  'Updated',
];

type AddNewSeatButtonProps = {
  addNewSeat: Function,
};

const AddNewSeatButton = (props: AddNewSeatButtonProps) => (
  <MaterialButton
    name={PAGE_NAME}
    iconName="add"
    style={{ marginLeft: '-10px' }}
    onClick={() => props.addNewSeat()}
  />
);

type SeatListProps = {
  getSeatsData: Function,
  getDSPs: Function,
  getCountries: Function,
  getBusinessUnits: Function,
  getBusinessModels: Function,
  getFeeOptions: Function,
  getFeeTypes: Function,
  getRegions: Function,
  editSeat: Function,
  checkSeatCredentials: Function,
  seats: Array<Seat>,
  alterSeat: {
    modalOpen: boolean,
    seat: { id: number },
  },
  seatConnectionStatus: { [seatId: string]: SeatStatusStates },
  loading: boolean,
  getPermissions: Function,
};

export class SeatList extends Component<SeatListProps> {
  UNSAFE_componentWillMount() {
    this.props.getSeatsData();
    this.props.getDSPs();
    this.props.getCountries();
    this.props.getBusinessUnits();
    this.props.getBusinessModels();
    this.props.getFeeOptions();
    this.props.getFeeTypes();
    this.props.getRegions();
    this.props.getPermissions();
  }

  render() {
    return (
      <DataLoading loading={this.props.loading} pageName={PAGE_NAME}>
        <ErrorBoundary>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Table fixed>
                  <Table.Header>
                    <Table.Row>
                      {headers.map((header) => <Table.HeaderCell key={header}>{header}</Table.HeaderCell>)}
                      <Table.HeaderCell textAlign="center" colSpan="2" key="Actions">Actions</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {_.map(this.props.seats, (seat) => (
                      <SeatDetailsContainer
                        key={seat.id}
                        editSeat={this.props.editSeat}
                        checkSeatCredentials={this.props.checkSeatCredentials}
                        seatConnectionStatus={this.props.seatConnectionStatus[seat.id] || { kind: FetchState.initial }}
                        seat={seat}
                      />
                    ))}
                  </Table.Body>
                </Table>
                <SeatFormContainer open={this.props.alterSeat.modalOpen} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ErrorBoundary>
      </DataLoading>
    );
  }
}

function mapStateToProps(state) {
  return {
    seats: state.seats.seatList.seats,
    alterSeat: state.seats.alterSeat,
    seatConnectionStatus: state.seats.seatList.seatConnectionStatus,
    loading: state.seats.seatList.loading,
  };
}

const mapDispatchToProps = {
  getSeatsData,
  getDSPs,
  getCountries,
  getBusinessUnits,
  getBusinessModels,
  getFeeOptions,
  getFeeTypes,
  getRegions,
  getPermissions,
  addNewSeat,
  editSeat,
  checkSeatCredentials,
};

const PermissionSeatList = (props: AddNewSeatButtonProps) => (
  // @ts-ignore child class
  <PermissionPageTemplate
    title={PAGE_NAME}
    name={PAGE_NAME}
    customHeader={<AddNewSeatButton addNewSeat={props.addNewSeat} />}
    permissions={REQUIRED_PERMISSIONS}
  >
    {/*
      // @ts-ignore */}
    <SeatList {...props} />
  </PermissionPageTemplate>
);

export default connect(mapStateToProps, mapDispatchToProps)(PermissionSeatList);
