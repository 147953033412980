import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Dimmer, Loader } from 'buildingBlocks';
import HighchartsWrapper from 'components/HighchartsWrapper';
import { setOptions, handleDateRangeChange } from 'containers/StrategyAnalytics/actions';
import Options from 'containers/StrategyAnalytics/components/StrategyAnalyticsOptions';
import { getDefaultKPIsByStrategy } from 'containers/StrategyAnalytics/utils/metricsUtils';
import {
  defaultChartOptions,
} from 'containers/StrategyAnalytics/constants/chartSeriesConfigs';
import { Strategy, Flight, EnvironmentVariables } from 'utils/types';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { OptionValue, LeafAnalyticsPerFlight, SegmentGroup } from 'containers/StrategyAnalytics/types';
import { getSegmentIdentifier } from 'containers/StrategyWizard/ConfigurationByStrategyType/segmentUtils';

import { fetchAnalytics, fetchSegments, resetState } from '../actions';
import Table from './SegmentAgeTable';
import { getChartConfig } from '../utils';

type Props = {
  metrics: { [key: string]: Metric },
  strategy: Strategy,
  loading: boolean,
  noData: boolean,
  errorAnalytics: boolean,
  onChange: Function,
  fetchAnalytics: Function,
  fetchSegments: Function,
  resetState: Function,
  optionValue: OptionValue,
  flightSelected: string | null,
  segmentSelected: number | null,
  segmentGroupSelected: string | null,
  flights: Array<Flight>,
  analytics: Array<unknown>,
  data: LeafAnalyticsPerFlight,
  segments: Array<{}>,
  segmentGroups: Array<SegmentGroup>,
  handleDateRangeChange: Function,
  env: EnvironmentVariables
};

type State = {
  filteredName: string | null,
  filteredData: LeafAnalyticsPerFlight,
};

const SEGMENT_IDENTIFIER_FIELD = 'externalId';

class SegmentRecency extends Component<Props, State> {
  static getSegmentOptions(segments) {
    return [
      ..._.map(segments, (segment) => {
        const segIdentifier = getSegmentIdentifier(segment);
        return {
          key: segIdentifier,
          value: segIdentifier,
          text: segment.shortName,
        };
      }),
    ];
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      filteredName: null,
      filteredData: [],
    };
  }

  componentDidMount() {
    this.props.fetchAnalytics({
      strategy: this.props.strategy,
    });
    const segments = _.flatten(_.map(_.get(this.props, 'strategy.config.segmentGroups', []), 'segments'));
    this.props.fetchSegments({
      memberId: this.props.strategy.member.id,
      advertiserId: this.props.strategy.advertiser.externalId,
      ids: segments,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!_.isEqual(this.props.flightSelected, nextProps.flightSelected)
      || !_.isEqual(this.props.segmentSelected, nextProps.segmentSelected)
      || !_.isEqual(this.props.segmentGroupSelected, nextProps.segmentGroupSelected)) {
      this.updateFilter({
        selectedFlight: nextProps.flightSelected,
        selectedSegment: nextProps.segmentSelected,
        selectedSegmentGroup: nextProps.segmentGroupSelected,
      });
    }
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  updateFilter({ selectedFlight, selectedSegment, selectedSegmentGroup }) {
    const { data, segments, flights } = this.props;
    const filter: { flight?: number, segmentId?: number, segmentGroupName?: string } = {};
    let filteredName: string;
    if (selectedFlight) {
      const flight = _.get(flights, selectedFlight);
      filter.flight = flight.id;
      filteredName = `Flight: ${flight.name}`;
    }
    if (selectedSegment) {
      filter.segmentId = selectedSegment;
      filteredName = `Segment ${
        _.get(_.find(segments, { [SEGMENT_IDENTIFIER_FIELD]: selectedSegment }), 'shortName')
      }`;
    }
    if (selectedSegmentGroup) {
      filter.segmentGroupName = selectedSegmentGroup;
      filteredName = `Segment Group ${selectedSegmentGroup}`;
    }

    const filteredData = !_.isEmpty(filter) ? _.filter(data, filter) : [];
    // @ts-ignore hard
    this.setState({
      filteredName,
      // @ts-ignore
      filteredData,
    });
  }

  onDateRangeChange = (dateRange: number) => this.props.handleDateRangeChange(dateRange);

  render() {
    const {
      loading,
      noData,
      segmentGroups,
      segments,
      strategy,
      flights,
      optionValue,
      data,
      env,
    } = this.props;
    const metrics = getDefaultKPIsByStrategy(strategy);

    return (
      <Dimmer.Dimmable dimmed={loading}>
        <Dimmer active={loading}>
          <Loader>Loading data</Loader>
        </Dimmer>
        <Options
          segmentGroups={segmentGroups}
          segments={SegmentRecency.getSegmentOptions(segments || [])}
          showSegments
          optionValue={optionValue}
          onChange={this.props.onChange}
          flights={flights}
          onDateRangeChange={this.onDateRangeChange}
          env={env}
        />
        {
          !loading
          && (
          <div>
            <HighchartsWrapper
              noData={noData}
              defaultChartOptions={defaultChartOptions}
              {...getChartConfig(this.state.filteredName, this.state.filteredData, optionValue, data, strategy)}
            />
            <Table
              {...this.state}
              noData={noData}
              loading={loading}
              metrics={_.values(metrics)}
              data={_.isEmpty(this.state.filteredName) ? this.props.data : this.state.filteredData}
            />
          </div>
          )
        }
      </Dimmer.Dimmable>
    );
  }
}

const mapStateToProps = (state) => {
  const strategy = state.strategyAnalytics.metadata.strategy;
  const data = state.strategyAnalytics.segmentRecency.data;
  const segmentGroups = _.map(_.get(strategy, 'config.segmentGroups', []), (sg) => ({
    key: sg.name,
    value: sg.name,
    text: sg.name,
    segments: sg.segments,
  }));

  return {
    strategy,
    analytics: state.strategyAnalytics.metadata.analytics,
    metrics: state.strategyAnalytics.metadata.metrics,
    flights: _.keyBy(state.strategyAnalytics.metadata.flights, 'externalId'),
    flightSelected: state.strategyAnalytics.optionValue.flight,
    segmentSelected: state.strategyAnalytics.optionValue.segment,
    segmentGroupSelected: state.strategyAnalytics.optionValue.segmentGroup,
    segmentGroups,
    data,
    noData: state.strategyAnalytics.segmentRecency.noData,
    loading: !strategy
      || state.strategyAnalytics.segmentRecency.loading
      || state.strategyAnalytics.segmentRecency.loadingSegments,
    segments: state.strategyAnalytics.segmentRecency.segments,
    optionValue: state.strategyAnalytics.optionValue,

    errorAnalytics: state.strategyAnalytics.segmentRecency.error,
  };
};

export default connect(mapStateToProps, {
  onChange: setOptions,
  fetchAnalytics,
  fetchSegments,
  resetState,
  handleDateRangeChange,
})(SegmentRecency);
