import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Icon } from 'buildingBlocks';
import { APP_NAME } from 'constantsBase';
import HighchartsWrapper from 'components/HighchartsWrapper';
import { StrategyGoalAnalyticsDatum, Headers, ChartBody, FailedFlights, ChartConfig } from 'containers/StrategyAnalytics/types';
import METRICS from 'containers/StrategyAnalytics/constants/metricsConstants';
import { optionsUpdated } from 'containers/StrategyAnalytics/constants/actionsConstants';
import { options } from 'containers/StrategyAnalytics/constants/strategyAnalyticsConstants';
import {
  buildSeries,
  processDailyDataPerMetrics,
  getDefaultKPIsByStrategy,
  includeAdditionalMetrics,
} from 'containers/StrategyAnalytics/utils/metricsUtils';
import {
  getDefaultChartOptions,
  yLabelsFormatter,
  legendFormatter,
} from 'containers/StrategyAnalytics/constants/chartSeriesConfigs';
import {
  setOptions,
  handleDateRangeChange,
} from 'containers/StrategyAnalytics/actions';
import { GlobalState } from 'reducers';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { EnvironmentVariables } from 'utils/types';
import Statbox from '../Statbox';
import Options from '../StrategyAnalyticsOptions';
import MetricsTable from '../MetricsTable';
import FlightTableBody from '../FlightTableBody';

const checkInactiveFlight = (failedFlights, flight, flightStatus) => {
  let isInactive = false;
  let message = (
    <div>
      {APP_NAME} is not able to update this DSP Object. An error has been logged and we will investigate this issue.
      Please <a href="#support">open a support ticket</a> for immediate assistance.
    </div>
  );

  if (_.get(flightStatus, 'error')) {
    message = flightStatus.errorMessage || message;
    isInactive = true;
  } else if (failedFlights[flight?.id]) {
    isInactive = true;
  }

  return { id: flight?.id, isInactive, message };
};

// add rows containing all zeros to data tables for flights that have no data.
// the api does not return any rows for flights with no data.
const fillZeros = (dataRows, flightExtIds, defaultKPIs) => {
  let headers;
  if (_.isEmpty(dataRows)) {
    headers = _.keys(defaultKPIs);
  } else {
    headers = _.keys(_.first(dataRows));
  }
  const extIdsWithdata = _.map(dataRows, 'externalId');
  const zeros = _.fill(Array(headers.length - 1), 0);

  return _(flightExtIds)
    .difference(extIdsWithdata)
    .map((extId) => ({ ..._.zipObject(headers, zeros), externalId: extId }))
    .concat(dataRows)
    .value();
};

const demoHeaders = (headers) => {
  const metricsWithOutLift = _.filter(headers, (h) => h.text !== 'Lift');
  return _.map(metricsWithOutLift, (m) => ({ ...m, text: m.textForDemo ?? m.text }));
};

export const Default = () => {
  const { metadata, optionValue } = useSelector<GlobalState>((state) => state.strategyAnalytics) as any;
  const {
    strategy, latestRuns, analytics, analyticsPerFlight,
    flights, status, segments, segmentGroups, showSegments,
  } = metadata;
  // cumData key will only exist in redux if analytics data is populated via strategyGoalAnalytics microservice
  const cumData = _.get(metadata, 'cumData');
  const strategyGoalAnalyticsMetadata = _.get(metadata, 'strategyGoalAnalyticsMetadata');
  const env = useSelector<GlobalState>((state) => state.login.env) as EnvironmentVariables;
  const dispatch = useDispatch();

  const [body, setBody] = useState<Array<ChartBody>>([]);
  const [expiredFlightsBody, setExpiredFlightsBody] = useState<Array<ChartBody>>([]);
  const [chartConfig, setChartConfig] = useState<ChartConfig>({});
  const [accordionOpen, setAccordionOpen] = useState<boolean>(false);
  const [failedFlights, setFailedFlights] = useState<FailedFlights>({});
  const [headers, setHeaders] = useState<Headers>([]);
  const [metrics, setMetrics] = useState<typeof METRICS>(METRICS);

  const defaultKPIs = getDefaultKPIsByStrategy(strategy, analytics, strategyGoalAnalyticsMetadata);
  const headersToDisplay = _.isEqual(env, EnvironmentVariables.demo) ? demoHeaders(headers) : headers;

  const updateMetrics = () => {
    if (!_.isEmpty(strategyGoalAnalyticsMetadata)) {
      const updatedMetrics = includeAdditionalMetrics(
        strategyGoalAnalyticsMetadata.goal,
        _.head(analytics) as StrategyGoalAnalyticsDatum,
        dispatch,
      );
      setMetrics(updatedMetrics);
    }
  };

  useMount(() => {
    // update the metrics and their calculations (if necessary) on load
    updateMetrics();
  });

  const buildTableBodyState = (flightMetrics) => {
    const flightsByExtId = _.keyBy(flights, 'externalId');
    return _.map(flightMetrics, (f) => {
      const flight = flightsByExtId[f.externalId];
      return {
        ...f,
        metadata: { ...flightsByExtId[f.externalId] },
        activeStatus: checkInactiveFlight(
          failedFlights,
          flight,
          _.get(status[strategy.id], `flightStatuses.${flight?.id}`),
        ),
      };
    });
  };

  const setTableBody = () => {
    const flightExtIds = _.map(flights, 'externalId');

    const expiredFlightExtIds = _(flights)
      .filter((flight) => moment().diff(moment(flight.endDate)) > 0)
      .map('externalId')
      .value();

    const [expiredFlightsWithMetrics, activeFlightsWithMetrics] = _(analyticsPerFlight)
      .groupBy('externalId')
      .map((f, key) => ({ ...processDailyDataPerMetrics(f, defaultKPIs, strategy), externalId: key }))
      .thru((value) => fillZeros(value, flightExtIds, defaultKPIs))
      .partition((fwm) => _.includes(expiredFlightExtIds, fwm.externalId))
      .value();

    setBody(buildTableBodyState(activeFlightsWithMetrics));
    setExpiredFlightsBody(buildTableBodyState(expiredFlightsWithMetrics));
  };

  const initTable = () => {
    setFailedFlights(_(latestRuns)
      .filter((fr) => fr.status === 'FAILED')
      .keyBy((fr) => fr.flight)
      .value());
    setHeaders(_.concat(
      [{ value: 'name', text: 'DSP Object' }],
      _.values(defaultKPIs),
      [{ value: 'endDate', text: 'End Date' }],
    ));
    setTableBody();
  };

  const getChartConfig = () => {
    // Save a reference to this context, as it will be lost inside highcharts event
    const shouldDisplayFlight = optionValue.byObject === options.byObject.Flight.value;
    const metricsSelected = {
      aggregator: metrics.aggregator[optionValue.aggregator],
      ratePercentage: metrics.ratePercentage[optionValue.ratePercentage],
    };
    const showCumulativeData = optionValue.shownAs === options.shownAs.Cumulative.value;
    const plotOpt = _.get(chartConfig, 'plotOptions', {}) as any;
    // cumData is the pre-calculated cumulative data provided by back end
    const analyticsData = cumData && showCumulativeData ? cumData.analytics : analytics;
    const analyticsPerFlightData = cumData && showCumulativeData ? cumData.analyticsPerFlight : analyticsPerFlight;

    // Series are stacked so strategy chart needs to be in a lighter color than flight
    // Build strategy series
    const series = buildSeries(
      metricsSelected,
      'Strategy',
      analyticsData,
      // set to false if cumData is available as the analytics data will already be cumulatively calculated
      cumData ? false : showCumulativeData,
      'default',
      shouldDisplayFlight,
      optionValue.dateRange,
      strategy,
    );

    // Build flight series
    if (shouldDisplayFlight) {
      const groupedFlights = _.groupBy(analyticsPerFlightData, 'externalId');
      const key = optionValue.flight;
      const flightName = _.get(_.find(flights, ['externalId', _.toString(optionValue.flight)]), 'name', '');
      series.push(
        ...buildSeries(
          metricsSelected,
          `Flight: ${flightName}`,
          groupedFlights[key] || [],
          // set to false if cumData is available as the analytics data will already be cumulatively calculated
          cumData ? false : showCumulativeData,
          'selected',
          shouldDisplayFlight,
          optionValue.dateRange,
          strategy,
        ),
      );
    }

    const plotOptions = {
      ...plotOpt,
      column: { ...plotOpt.column },
      spline: { ...plotOpt.spline },
    };

    const yAxis = [
      {
        title: { text: metricsSelected.aggregator.text },
        labels: {
          formatter() {
            return yLabelsFormatter(this, _.get(metricsSelected.aggregator, 'bigFormat', metricsSelected.aggregator.format));
          },
        },
      },
      {
        title: { text: metricsSelected.ratePercentage?.text },
        labels: {
          formatter() {
            return yLabelsFormatter(this, _.get(metricsSelected.ratePercentage, 'format'));
          },
        },
      },
    ];

    const legend = legendFormatter(chartConfig.legend || {}, metricsSelected);
    setChartConfig({
      ...chartConfig,
      plotOptions,
      yAxis,
      series,
      legend,
    });
  };

  useEffect(() => {
    initTable();
    getChartConfig();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionValue, metrics]);

  const onDateRangeChange = (dateRange: number) => dispatch(handleDateRangeChange(dateRange));
  const onOptionChange = (type: string, value: string) => dispatch(setOptions(type, value));

  return (
    <>
      <Statbox
        strategy={strategy}
        analytics={analytics}
        env={env}
        strategyGoalAnalyticsMetadata={strategyGoalAnalyticsMetadata}
      />
      <Options
        metricOptions={metrics}
        optionValue={optionValue}
        onChange={onOptionChange}
        segments={segments}
        segmentGroups={segmentGroups}
        showSegments={showSegments}
        flights={flights}
        onDateRangeChange={onDateRangeChange}
        env={env}
      />
      <HighchartsWrapper
        noData={_.isEmpty(analytics)}
        defaultChartOptions={getDefaultChartOptions(metrics)}
        {...chartConfig}
      />
      <MetricsTable
        id="strategy-flights"
        headers={headersToDisplay}
        body={body}
        onHeaderClick={(key) => {
          onOptionChange(optionsUpdated.tableSort, key);
        }}
        noDataMsg="This strategy does not have any active attached flights."
        sortBy={optionValue.tableSortBy}
        sortDescending={optionValue.sortDescending}
      >
        <FlightTableBody
          flightRuns={latestRuns}
          selected={[optionValue.flight]}
          onClick={(key) => {
            onOptionChange(optionsUpdated.flight, key);
          }}
        />
      </MetricsTable>
      {
        !_.isEmpty(expiredFlightsBody)
        && (
          <Accordion>
            <Accordion.Title onClick={() => setAccordionOpen(!accordionOpen)} active={accordionOpen}>
              <Icon name="dropdown" />
              <b>The following flights have expired</b>
            </Accordion.Title>
            <Accordion.Content active={accordionOpen}>
              <MetricsTable
                id="strategy-expired-flights"
                headers={headers}
                body={expiredFlightsBody}
                onHeaderClick={(key) => {
                  onOptionChange(optionsUpdated.tableSort, key);
                }}
                sortBy={optionValue.tableSortBy}
                sortDescending={optionValue.sortDescending}
              >
                <FlightTableBody
                  flightRuns={latestRuns}
                  selected={[optionValue.flight]}
                  onClick={(key) => {
                    onOptionChange(optionsUpdated.flight, key);
                  }}
                />
              </MetricsTable>
            </Accordion.Content>
          </Accordion>
        )
      }
    </>
  );
};

export default Default;
